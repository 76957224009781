import { useLocation, useHistory, useRouteMatch, useParams } from 'react-router-dom';

const useRouter = () => {
   const location = useLocation();
   const history = useHistory();
   const match = useRouteMatch();
   const params = useParams();
   return {
      location,
      history,
      match,
      params,
   };
};

export default useRouter;
