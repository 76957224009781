import React, { useEffect, useState } from 'react';
import { Environment } from '../../../env';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import ProgressBar from 'progressbar.js';
import PopUps from '../../../components/PopUps/PopUps';
import { propTypes } from 'react-time-ago';
import { GenerateUniqueLink } from '../../../redux/actions/auth/actions';
import { isObjEmpty } from '../../../utils/utils';

const Messenger = props => {
   const store = useSelector(store => store);
   const [progress, setprogress] = useState(0);
   const [messenger, setmessenger] = useState(null);
   const [bar, setbar] = useState(null);
   const history = useHistory();
   const [show, setshow] = useState(false);
   const language = localStorage.getItem('langage');

   useEffect(() => {
      if ((get(store, 'cart.userMissions', null) !== null) & (bar !== null)) {
         let messenger = get(store, 'cart.userMissions', null).available.find(item => {
            return item.name == 'The Ambassador';
         });
         setmessenger(messenger);
         CalcProgress(messenger);
         Showpopup(messenger);
      }
   }, [get(store, 'cart.userMissions', null), bar]);

   const GetProgress = () => {
      let progress = get(store, 'mambo.data', null);
      if (!isObjEmpty(progress)) {
         progress = get(store, 'mambo.data.points', []).find(point => point.pointId == '5fe9d0057126540c24a3e0c6');

         return progress.balance;
      }
      return 0;
   };

   const CalcProgress = messenger => {
      let completed = messenger.attrs.progress.completed;

      /* if (completed == 0) {
            bar.set(0);
            bar.animate(0);
            let required = messenger.attrs.rewards[0].attrs.behaviours[0].times - messenger.attrs.rewards[0].attrs.behaviours[0].timesCompleted
            setprogress(0)
        }
        else { */
      let total = messenger.attrs.rewards[messenger.attrs.rewards.length - 1].attrs.behaviours[0].times;
      let current = GetProgress(); //messenger.attrs.rewards[completed].attrs.behaviours[0].timesCompleted
      let required =
         messenger.attrs.rewards[completed].attrs.behaviours[0].times -
         current; /* messenger.attrs.rewards[completed].attrs.behaviours[0].timesCompleted */

      setprogress(Math.round((current / total) * 100));

      bar.set(Math.round((current / total) * 100) / 100);
      bar.animate(Math.round((current / total) * 100) / 100);
      /* } */
   };

   useEffect(() => {
      setTimeout(() => {
         setshow(true);
      }, 500);
      setTimeout(() => {
         var bar = new ProgressBar.Path('#heart-path', {
            easing: 'easeInOut',
            duration: 1400,
         });

         setbar(bar);

         bar.set(0);
         //bar.set(Math.round((current / total) * 100)/100);
         bar.animate(0);
      }, 10);
   }, []);

   const Showpopup = messenger => {
      if (props.setData == null) {
         return;
      }
      let completed = messenger.attrs.progress.completed;

      let data = messenger.attrs.rewards;

      let isShown = parseInt(localStorage.getItem('messenger-popup'));

      if ((completed > 0) & (isShown != completed)) {
         props.setData({
            message: data[completed - 1].message,
            image: data[completed - 1].imageUrl,
         });
         setTimeout(() => {
            props.setShow();
         }, 1000);
         localStorage.setItem('messenger-popup', completed);
      }
   };

   const generateLink = async () => {
      const udid = get(store, 'auth.userinfo', { id: 0 });
      console.log(udid.id);
      var link = await GenerateUniqueLink(udid.id);
      if (link != false)
         // window.ReactNativeWebView.postMessage("Hey! Checkout this cool app - " + link);
         window.ReactNativeWebView.postMessage(
            JSON.stringify({
               type: 'share',
               message: 'Hey! It’s your turn to win big. Download KANZI now… \n \n' + link,
            })
         );
   };

   return (
      <div className="messenger-parent">
         <div className="head">
            <img src={`${Environment.img_path}icons/badge1.svg`} />
            <p>{props.title ? props.title : props.Environment.messenger}</p>
         </div>
         <div className="bg">
            <p>
               {messenger != null ? messenger.translations.find(trans => trans.languageCode == language).hint : ''}
               {/* Earn Koinz when you refer friends to Kanzi. Each time you hit a certain number of referrals, you’ll unlock a new badge to get free Koinz.
                    If you unlock all badges, your mission is complete: */}
            </p>

            <div className="progress-container">
               <div className="path">
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="229.982"
                     height="192.982"
                     viewBox="0 0 229.982 192.982"
                  >
                     <g>
                        <g>
                           <path
                              fill="#f6f6f6"
                              stroke="#ddd"
                              stroke-linecap="round"
                              stroke-miterlimit="10"
                              stroke-width="12px"
                              d="M-24.245.5h171.42a46.563 46.563 0 0 1 0 93.125h-55.2a46.563 46.563 0 0 0-46.563 46.563v41.294"
                              transform="translate(30.245 5.794) translate(0 .206) translate(0 -.5)"
                           />
                        </g>
                        <path
                           id="heart-path"
                           fill="transparent"
                           stroke="#3277f3"
                           stroke-linecap="round"
                           stroke-miterlimit="10"
                           stroke-width="12px"
                           d="M-24.245.5h171.42a46.563 46.563 0 0 1 0 93.125h-55.2a46.563 46.563 0 0 0-46.563 46.563v41.294"
                           transform="translate(30.245 5.794) translate(0 -.294)"
                        />
                     </g>
                  </svg>
               </div>
               {(messenger != null) & show ? (
                  <div className="badge-container">
                     {messenger.attrs.rewards.map((item, index) => {
                        return (
                           <div className={`badge${index + 1}`}>
                              <span className="koin-cont">
                                 <img src={`${Environment.img_path}icons/doercoin.svg`} />
                                 {item.prizes.points[0].points}
                              </span>
                              <img style={{ height: '40px' }} src={item.imageUrl} />
                              {/* <span>{item.attrs.behaviours[0].times}</span> */}
                           </div>
                        );
                     })}
                     {/* <div className="badge1">
                            <span className="koin-cont">
                                <img src={`${Environment.img_path}icons/doercoin.svg`} />
                                50
                            </span>
                            <img src={`${Environment.img_path}icons/bluebadge.svg`} />
                            <span>{messenger.attrs.rewards[0].attrs.behaviours[0].times}</span>
                        </div>
                        <div className="badge2">
                            <span className="koin-cont">
                                <img src={`${Environment.img_path}icons/doercoin.svg`} />
                                100
                            </span>
                            <img src={`${Environment.img_path}icons/bluebadge.svg`} />
                            <span>{messenger.attrs.rewards[1].attrs.behaviours[0].times}</span>
                        </div>
                        <div className="badge3">
                            <img src={`${Environment.img_path}icons/bluebadge.svg`} />
                            <span>{messenger.attrs.rewards[2].attrs.behaviours[0].times}</span>
                            <span className="koin-cont">
                                <img src={`${Environment.img_path}icons/doercoin.svg`} />
                                100
                            </span>
                        </div>
                        <div className="badge4">
                            <span className="koin-cont">
                                <img src={`${Environment.img_path}icons/doercoin.svg`} />
                                350
                            </span>
                            <img src={`${Environment.img_path}icons/bluebadge.svg`} />
                            <span>{messenger.attrs.rewards[3].attrs.behaviours[0].times}</span>
                        </div>
                        <div className="badge5">
                            <span className="koin-cont">
                                <img src={`${Environment.img_path}icons/doercoin.svg`} />
                                600
                            </span>
                            <img src={`${Environment.img_path}icons/bluebadge.svg`} />
                            <span>{messenger.attrs.rewards[4].attrs.behaviours[0].times}</span>
                        </div> */}
                  </div>
               ) : null}
            </div>
         </div>

         <div
            className="bt-action"
            onClick={() => {
               generateLink();
            }}
         >
            {props.Environment.refertfrd}
         </div>
      </div>
   );
};

export default Messenger;
