import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import Cookie from 'js-cookie';
import NavBar from '../../components/NavBar/NavBar';
import { Environment } from '../../env';
import { Tween } from 'react-gsap';

const PaymentMethod = props => {
   const history = useHistory();
   return (
      <div>
         <NavBar paymentmethods="paymentmethods" />
         <div className="payment-card-container">
            <Tween
               from={{
                  x: '100%',
                  opacity: 1,
                  duration: Environment.popupContainer.duration,
                  delay: Environment.popupContainer.delay,
               }}
            >
               <div className="payment-card-list">
                  <img
                     src="images/card-4.svg"
                     alt=""
                     onClick={() =>
                        history.push({
                           pathname: '/addcards_payment',
                           cardEditable: true,
                        })
                     }
                  />
               </div>

               <Link to="/addcards_payment" className="Saved-list-address">
                  <div className="list-cards add-address-btn">
                     <img src={`${Environment.img_path}makeicons/plus.png`} alt="" />
                  </div>
               </Link>
            </Tween>
         </div>
      </div>
   );
};

const mapStateToProps = state => {
   return {
      token: state.auth.token,
      userinfo: state.auth.userinfo,
   };
};

export default connect(mapStateToProps)(PaymentMethod);
