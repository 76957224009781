import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Environment } from "./../../env";
import Button from "../Button/Button";
import NavBar from "../../components/NavBar/NavBar";
import { TweenMax } from "gsap"
import { Tween } from "react-gsap";


const NotanAccount = () => {
  const [active, setActive] = useState(false);
  const emtyicons = useRef(null);

  const topicons = () => {
    setActive(false)
    TweenMax.fromTo(emtyicons.current, 0.7, { y: "-30" }, { y: "0" });
    setTimeout(() => {
    }, 1000)
  }


  return (
    <div>
      <NavBar account="Account"></NavBar>
      <div className="notanaccount-container">

        <div className="notanaccount-main">
          <Tween from={{ y: "-100%", opacity: 0, duration: 0.5, }}>
            <div className="ic-cart-empty">
              <img src={`${Environment.img_path}makeicons/ic_no_account.svg`} alt="" />
            </div>
          </Tween>
          <h2>{Environment.notanaccount_title}</h2>
          <p>{Environment.notanaccountbodytxt}</p>
          <Button className="button-outline-red" data="Create an account"></Button>

          <div className="howtowiner">
            {Environment.alreadyan}<Link to="/">{Environment.login}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotanAccount;
