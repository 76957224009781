import {
  CART_FOR_GTAG,
  ADDTOCART,
  VIEWCART,
  UPDATECART,
  DELETEITEM,
  SHIPPINGINFO,
  ORDERID,
  UPDATECARTITEMSELECTION,
  UPDATEKOINZMODE,
  ADDKOINZPROD,
  REMOVEKOINZPROD,
  EMPTYKOINZPROD,
  SETUSEKOINZ,
  ADDCONSTANTS,
  SETLOYALTYDATA,
  ADDKOINZDETAILS,
  SETUSERMISSIONS,
  SETBADGEDATA,
  SETCLUSTEREDDATA,
  SETPREVIOUSORDERS,
  DELETE_ADDRESS,
  REPLACEPRODUCT,
  SETCUSTPROFILE,
  SETSAVEDCARDS,
  EMPTYCART,
  SETORDERAMOUNT,
  SETPROMOCODE
} from "../actions/cart/actionTypes";
import { REGISTER } from "../actions/auth/actionTypes";
import { ADD_ADDRESS, EDIT_ADDRESS } from "../actions/account/actionTypes";
import { isArrEmpty } from "../../utils/utils";
import { LOGOUT } from "../actions/auth/actionTypes";
// import { LoyaltyData } from "../actions/cart/actions";

const initialState = {
  cart: [],
  cartItemsForGTAG: [],
  customerData: [],
  shippingInfo: null,
  isViewCartLoaded: false,
  koinzMode: [],
  koinz_replacement: [],
  useKoinz: false,
  config: [],
  loyaltyData: null,
  koinzDetails: [],
  userMissions: null,
  badgeData: null,
  clusteredData: [],
  previousorders: [],
  paymentFormLoaded: false,
  custProfile: null,
  savedCards: [],
  orderAmount: 0,
  promocode: null
};

const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    case CART_FOR_GTAG:
      return {
        ...state,
        cartItemsForGTAG: action.payload
      };
    case ADDTOCART:
      return {
        ...state,
        cart: state.cart.concat(action.payload),
        koinzMode: [
          ...state.koinzMode,
          {
            item_id: action.payload.item_id,
            mode: false
          }
        ]
      };
    case VIEWCART:
      return {
        ...state,
        cart: action.payload.items,
        // cart: state.cart.concat(action.payload.items),
        customerData:
          action.payload.customer.addresses || initialState.customerData,
        isViewCartLoaded: true,
        koinzMode: isArrEmpty(state.koinzMode)
          ? action.payload.items.map(cur => ({
              item_id: cur.item_id,
              mode: false
            }))
          : state.koinzMode
      };
    case UPDATECART:
      return {
        ...state,
        cart: state.cart.map(item => {
          if (item.item_id === action.payload.item_id) {
            item.qty = action.payload.qty;
          }
          return item;
        })
      };
    case EMPTYCART:
      return {
        ...state,
        cart: []
      };
    case REPLACEPRODUCT:
      return {
        ...state,
        cart: state.cart.map(item => {
          if (item.sku == action.payload.sku) {
            return action.payload;
          }
          return item;
        })
      };
    case UPDATECARTITEMSELECTION:
      return {
        ...state,
        cart: state.cart.map(item => {
          if (item.item_id === action.payload.item_id) {
            item.koinzMode = action.payload.koinzMode;
          }
          return item;
        })
      };
    case UPDATEKOINZMODE:
      console.log(action.payload);
      let data;
      const checkData = state.koinzMode.some(
        cur => cur.item_id === action.payload.item_id
      );
      if (!checkData) {
        data = [...state.koinzMode, action.payload];
      } else {
        data = state.koinzMode.map(cur => {
          if (+cur.item_id === +action.payload.item_id) {
            return action.payload;
          }
          return cur;
        });
      }

      return {
        ...state,
        koinzMode: data
      };

    case DELETEITEM:
      return {
        ...state,
        cart: state.cart.filter(item => item.item_id !== action.payload),
        koinz_replacement: state.koinz_replacement.filter(item => {
          return item !== action.payload;
        })
        //koinzMode: state.cart.koinzMode.filter(item => item.item_id !== action.payload.item_id) //sanchit_change
      };
    case SHIPPINGINFO:
      return {
        ...state,
        shippingInfo: action.payload
      };
    case ORDERID:
      return {
        ...state,
        orderid: action.payload,
        cart: [],
        koinzMode: []
      };
    case REGISTER:
      return {
        ...state,
        cart: [],
        koinzMode: []
      };
    case ADD_ADDRESS:
      return {
        ...state,
        customerData: [...state.customerData, action.payload]
      };
    case EDIT_ADDRESS:
      const addresses = state.customerData.map(
        cur => (cur.id === action.payload.id ? action.payload : cur)
      );
      return {
        ...state,
        customerData: addresses
      };
    case DELETE_ADDRESS:
      const add = state.customerData.filter(cur => cur.id !== action.payload);
      return {
        ...state,
        customerData: add
      };
    case LOGOUT:
      return {
        ...state,
        cart: [],
        customerData: [],
        shippingInfo: null,
        isViewCartLoaded: false,
        koinzMode: []
      };
    case ADDKOINZPROD:
      return {
        ...state,
        koinz_replacement: [...state.koinz_replacement, action.payload]
      };
    case REMOVEKOINZPROD:
      return {
        ...state,
        koinz_replacement: state.koinz_replacement.filter(item => {
          return item !== action.payload;
        })
      };
    case SETUSEKOINZ:
      return {
        ...state,
        useKoinz: !state.useKoinz
      };
    case EMPTYKOINZPROD:
      return {
        ...state,
        koinz_replacement: [],
        useKoinz: false
      };
    case ADDCONSTANTS:
      return {
        ...state,
        config: action.payload
      };
    case SETLOYALTYDATA:
      console.log(action);
      return {
        ...state,
        loyaltyData: action.payload
      };
    case ADDKOINZDETAILS:
      return {
        ...state,
        koinzDetails: action.payload
      };
    case SETUSERMISSIONS:
      return {
        ...state,
        userMissions: action.payload
      };
    case SETBADGEDATA:
      return {
        ...state,
        badgeData: action.payload
      };
    case SETCLUSTEREDDATA:
      return {
        ...state,
        clusteredData: action.payload
      };
    case SETPREVIOUSORDERS:
      return {
        ...state,
        previousorders: action.payload
      };
    case SETCUSTPROFILE:
      return {
        ...state,
        custProfile: action.payload
      };
    case SETSAVEDCARDS:
      return {
        ...state,
        savedCards: action.payload
      };
    case SETORDERAMOUNT:
      return {
        ...state,
        orderAmount: action.payload
      };
    case SETPROMOCODE:
      return {
        ...state,
        promocode: action.payload
      };

    default:
      return state;
  }
};

export default CartReducer;
