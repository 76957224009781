import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllFavourite, RemoveFavourite } from '../../redux/actions/favourites/actions';
import { DeleteNotifications, GetAllProducts, GetCampaignManager } from '../../redux/actions/home/actions';
import { Environment } from '../../env';
import NavBar from '../../components/NavBar/NavBar';
import CustomProgressbar from '../../components/PrizeList/CustomProgressbar';
import EmptyWatchlist from './EmptyWatchlist';
import { TweenMax } from 'gsap';
import { Swipeable } from 'react-swipeable';
import { AddToCart, UpdateCartItem } from '../../redux/actions/cart/actions';
import $ from 'jquery';

import { GuestAddToCart, GuestUpdateCartItem, CreateQuote } from '../../redux/actions/guestcart/actions';
import { get } from 'lodash';
import PopUps from '../../components/PopUps/PopUps';
import Loader from '../../components/Loader/Loader';
import ReactPixel from 'react-facebook-pixel';
import {
   currencyconvertrate,
   calcDaysFromCurrentDate,
   showTimeBasedCampaigns,
   prefixZeroIfSingleDigit,
} from '../../utils/Function';
const script = document.createElement('script');
script.src =
   'https://i.ctnsnet.com/int/integration?pixel=67834349&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}';
script.async = true;
const Watchlist = props => {
   const [qty, setQty] = useState(0);
   const [order, setorder] = useState('DESC');
   const token = useSelector(store => store.auth.token);
   const campaign = useSelector(store => store.home.campaign_manager);
   const all_products = useSelector(store => store.home.allproducts);
   const fav = useSelector(store => store.favourite.favourites);
   const cart = useSelector(store => store.cart.cart);
   const [favourites, setfav] = useState([]);
   const [search_active, setsearchactive] = useState(false);
   const dispatch = useDispatch();
   const [search_text, setsearchtext] = useState('');
   const card = useRef([]);
   const store = useSelector(store => store);
   const [showpopup, setshowpopup] = useState(false);
   const [parent, setparent] = useState(null);
   const [config, setconfig] = useState([]);
   const [item, setitem] = useState(null);
   const [show, setshow] = useState(true);

   useEffect(() => {
      if ((get(store, 'home.campaign_manager', []).length !== 0) & (get(store, 'home.allproducts', []).length != 0)) {
         setFav();
      }
   }, [get(store, 'home.campaign_manager', []), get(store, 'home.allproducts', [])]);

   useEffect(() => {
      favourites.forEach((item, index) => {
         if (card.current[index] == null) return;
         card.current[index].addEventListener('touchend', () => {
            if (
               18 > card.current[index].getBoundingClientRect().x &&
               card.current[index].getBoundingClientRect().x > -150
            ) {
               TweenMax.to(card.current[index], 0.5, { x: 0 });
            }
            if (
               18 > card.current[index].getBoundingClientRect().x &&
               card.current[index].getBoundingClientRect().x < -150
            ) {
               TweenMax.fromTo(
                  card.current[index],
                  0.5,
                  { x: card.current[index].getBoundingClientRect().x },
                  { x: -400 }
               );
               OnDelete(item.wishlist_item_id, item.product_id);
            }
         });
         card.current[index].addEventListener('click', () => {
            if (card.current[index].getBoundingClientRect().x < -120) {
               TweenMax.to(card.current[index], 0.5, { x: 0 });
            }
         });
      });
   }, [favourites]);

   useEffect(() => {
      if (favourites.length !== 0) {
         setfav(fav);
      }
      if (store.auth.token == null) {
         setfav(fav);
      }
   }, [fav]);

   useEffect(() => {
      favourites.forEach((item, index) => {
         //setCount(props.item.qty);
         TweenMax.to(card.current[index], 0, { x: 0 });
      });
   }, [favourites]);
   useEffect(() => {
      if (document.getElementById('favourites-page-wrapper'))
         document.getElementById('favourites-page-wrapper').appendChild(script);
      return () => {
         if (document.getElementById('favourites-page-wrapper'))
            document.getElementById('favourites-page-wrapper').removeChild(script);
      };
   }, []);

   const setFav = async () => {
      var fav = await dispatch(GetAllFavourite(token));
      setshow(false);
      if (Array.isArray(fav)) {
         setfav(fav.reverse());
      }
   };

   const Sort = () => {
      if (order === 'DESC') {
         setorder('ASC');
      } else {
         setorder('DESC');
      }
      setfav(favourites.reverse());
   };

   const search = () => {
      setsearchactive(!search_active);
      if (search_active) {
         // TweenMax.to('.input', 0.8, { height: 0 });
         TweenMax.to('.search-bar', 0.8, { display: 'none' });
      } else {
         // TweenMax.to('.input', 0.8, { height: 45 });
         TweenMax.to('.search-bar', 0.8, { display: 'block' });
      }
   };

   const search_change = value => {
      var favs = fav.filter(item => {
         return item.product.name.toLowerCase().includes(value.toLowerCase());
      });

      setfav(favs);
   };

   const getPrizePercentage = (sales, stock, campaign) => {
      let prizePercentage = 0;
      if (showTimeBasedCampaigns(campaign?.campaign_type)) {
         let finished =
            calcDaysFromCurrentDate(campaign?.campaign_end_time, campaign?.activation_date) -
            calcDaysFromCurrentDate(campaign?.campaign_end_time);
         let total = calcDaysFromCurrentDate(campaign?.campaign_end_time, campaign?.activation_date);
         prizePercentage = (finished / total) * 100;
      } else {
         prizePercentage = (sales / stock) * 100;
      }

      return prizePercentage;
   };

   const defaultRender = (stocks, sale, type, camp) => {
      const sales = sale;
      const stock = stocks;
      var prizePercentage = 0;
      if (type == 2) {
         prizePercentage = 100;
      } else {
         prizePercentage = (sales / stock) * 100;
      }

      return (
         <div className="semi-circle-progressbar">
            <div className="dialdots-line">
               <img src={`${props.Environment.img_path}makeicons/dots.svg`} alt="" />
            </div>
            {showTimeBasedCampaigns(camp?.campaign_type) ? (
               <div className="progress-percentage">
                  <p style={{ width: '107%' }} className="total-value">
                     {props.Environment.Ends_In}
                  </p>
                  <p className="current-value">
                     {' '}
                     {prefixZeroIfSingleDigit(calcDaysFromCurrentDate(camp?.campaign_end_time))}
                  </p>
                  <p className="total-value">{props.Environment.Days}</p>
               </div>
            ) : (
               <>
                  {type === 1 ? (
                     <div className="progress-percentage">
                        <p className="current-value">{sales}</p>
                        <p className="sold-out site-e">
                           {props.Environment.SOLDTETX} <br /> {props.Environment.OUTSNT}
                        </p>
                        <p className="sold-out site-a">{props.Environment.ddds}</p>
                        <p className="total-value">{stock}</p>
                     </div>
                  ) : null}
                  {type === 2 ? (
                     <div className="progress-percentage" style={{ top: 0 }}>
                        <p className="sold-out1">
                           SOLD
                           <br />
                           OUT
                           {/* <img src="makeicons/sold-out.svg" alt="" /> */}
                        </p>
                     </div>
                  ) : null}
               </>
            )}

            <CustomProgressbar
               progress={getPrizePercentage(sales, stock, camp)}
               soldOut={stock === sales ? true : false}
            />
         </div>
      );
   };

   const addAddtocartFbPixel = element => {
      let isAlreadyAdded = false;
      isAlreadyAdded = cart.some(item => item.sku.includes(element.product.sku));

      if (!isAlreadyAdded) {
         ReactPixel.track('AddToCart');
      }
   };
   const Cart = async item => {
      if (item.product.type_id == 'configurable') {
         var product = get(store, 'home.allproducts', []).find(prod => prod.id == item.product_id);
         if (product == undefined) return;
         setconfig(product.extension_attributes.configurable_product_links);
         setparent(product);
         setitem(item);
         setshowpopup(true);
      } else {
         addAddtocartFbPixel(item);
         var cart_item = get(store, 'cart.cart', []).find(cart => cart.sku == item.product.sku);

         setshow(true);
         if (cart_item == undefined) {
            if (store.auth.token == null) {
               if (store.auth.cartid === null) {
                  const quote = await dispatch(CreateQuote());

                  await dispatch(GuestAddToCart(quote, item.product.sku));
               } else {
                  await dispatch(GuestAddToCart(get(store, 'auth.cartid', null), item.product.sku));
               }
            } else {
               await dispatch(AddToCart(get(store, 'auth.cartid', null), item.product.sku));
            }
         } else {
            if (store.auth.token == null) {
               await dispatch(
                  GuestUpdateCartItem(
                     get(store, 'auth.cartid', null),
                     cart_item.sku,
                     cart_item.qty + 1,
                     cart_item.item_id
                  )
               );
            } else {
               await dispatch(
                  UpdateCartItem(get(store, 'auth.cartid', null), cart_item.sku, cart_item.qty + 1, cart_item.item_id)
               );
            }
         }

         await OnDelete(item.wishlist_item_id, item.product_id);
         setshow(false);
      }
   };

   const OnDelete = (id, product_id) => {
      if (store.auth.token == null) {
         dispatch(RemoveFavourite(null, null, product_id));
      } else {
         dispatch(RemoveFavourite(token, id, product_id));
      }
   };

   const OnSwiping = (e, index) => {
      console.log(index);
      console.log(card);
      if (e.dir === 'Left' /* && card.current[index].getBoundingClientRect().x >= -120 */) {
         card.current[index].style.transform = 'translateX(-' + e.absX + 'px)';
      }
   };

   return (
      <div id="favourites-page-wrapper">
         {showpopup ? (
            <PopUps
               type="size-selection"
               parent={parent}
               details={config}
               onClose={() => setshowpopup(false)}
               Callback={() => {
                  OnDelete(item.wishlist_item_id, item.product_id);
               }}
               onSubmit={() => setshowpopup(false)}
            />
         ) : null}

         <NavBar watchlist="Favorites" />

         <div className="watchlist-container watchlist-container2">
            {/* <div className="desktop-header">{props.Environment.emptyyourtickrs}</div> */}

            {fav.length === 0 ? (
               <EmptyWatchlist {...props} />
            ) : (
               <div className="order-container">
                  {show == true ? <Loader style={{ margin: '10px auto 60px' }} show={show} back={true}></Loader> : null}
                  <div className="order-details">
                     {fav.length !== 0 ? (
                        <div className="ticket-list-sort">
                           <span
                              className="sort-container"
                              onClick={() => {
                                 Sort();
                              }}
                           >
                              <img src={`${props.Environment.img_path}icons/ic-sort.png`} alt="" />
                              {props.Environment.recentlypurchasedtxt}
                           </span>
                           <span
                              className="image-container"
                              onClick={() => {
                                 search();
                              }}
                           >
                              <img src={`${props.Environment.img_path}icons/search.svg`} alt="" />
                           </span>
                        </div>
                     ) : null}
                     <div className="search-bar">
                        <input
                           className="input"
                           placeholder="Search"
                           onChange={e => {
                              search_change(e.currentTarget.value);
                           }}
                        />
                     </div>
                     {favourites.map((item, index) => {
                        console.log(favourites.length);
                        if (favourites.length == 1) {
                           $('.tempsi').addClass('tempsinew');
                        } else {
                           $('.tempsi').removeClass('tempsinew');
                        }
                        var camp = campaign.find(camp => camp.campaign_product_id == item.product_id);
                        console.log(camp);
                        let price = null;

                        if (item.product.type_id == 'configurable') {
                           let product = store.home.allproducts.find(prod => {
                              return prod.id == item.product_id;
                           });

                           if (product == undefined) {
                              return;
                           }

                           var child_prod = store.home.allproducts.find(
                              item => item.id == product.extension_attributes.configurable_product_links[0]
                           );

                           if (child_prod != undefined) {
                              price = child_prod.price;
                           }
                        }

                        if (camp == undefined) {
                           OnDelete(item.wishlist_item_id, item.product_id);
                           return;
                        }

                        // let prize_line =
                        // 	localStorage.getItem('langage') == 'en'
                        // 		? camp.campaign_prize_line.replace(
                        // 				'WIN 123',
                        // 				'&nbsp;<span style="color: #e03a3f;">WIN </span>'
                        // 		  )
                        // 		: camp.campaign_prize_line_ar.replace(
                        // 				'لربح 123',
                        // 				'<span style="color: #e03a3f;">لربح</span>'
                        // 		  )

                        let prize_line =
                           localStorage.getItem('langage') == 'en'
                              ? camp.campaign_prize_line
                              : camp.campaign_prize_line_ar;

                        let product_line =
                           localStorage.getItem('langage') == 'en'
                              ? camp.campaign_product_line
                              : camp.campaign_product_line_ar;

                        item.product.total_stock = camp.campaign_total_tickets;
                        item.product.total_sales = camp.campaign_total_sales;

                        var prod = all_products.find(product => product.id == camp.campaign_prize_id);
                        var prod1 = all_products.find(product => product.id == camp.campaign_product_id);

                        if (prod1 !== undefined) {
                           if (camp.campaign_total_tickets == camp.campaign_total_sales) {
                              OnDelete(item.wishlist_item_id, item.product_id);
                              return;
                           }
                        }

                        return (
                           <div className="tempsi" id="tempsi">
                              <div class="wishit" style={{ marginBottom: 20 }} key={item.product_id}>
                                 {/* <div
                                    className='delete-card'
                                    onClick={() => {
                                       // OnDelete(item.wishlist_item_id, item.product_id);
                                    }}
                                 >
                                    <img src={`${props.Environment.img_path}images/delete.svg`} />
                                 </div> */}
                                 <Swipeable
                                    onSwiping={e => {
                                       OnSwiping(e, index);
                                    }}
                                 >
                                    <div
                                       className="ticket-listing"
                                       style={{ borderColor: '#c6cfd9' }}
                                       ref={e => (card.current[index] = e)}
                                    >
                                       {defaultRender(item.product.total_stock, item.product.total_sales, 1, camp)}
                                       <div className="icon-container">
                                          <img
                                             src={`${props.Environment.img_path}icons/cart-inactive.svg`}
                                             alt=""
                                             onClick={() => {
                                                Cart(item);
                                             }}
                                          />
                                          <img
                                             src={`${props.Environment.img_path}icons/trash.svg`}
                                             alt=""
                                             onClick={() => {
                                                OnDelete(item.wishlist_item_id, item.product_id);
                                             }}
                                          />
                                       </div>
                                       <div className="tkt-prize">
                                          <div className="tkt-prize-name">
                                             <div
                                                className="temdfix"
                                                dangerouslySetInnerHTML={{
                                                   __html: prize_line,
                                                }}
                                             />
                                          </div>
                                       </div>
                                       <div className="tkt-product">
                                          <div className="tkt-product-img">
                                             <div className="product-img">
                                                <img src={props.Environment.productUrl + item.product.image} alt="" />
                                             </div>
                                             <div className="tkt-product-detail">
                                                <p
                                                   className="product-name product-namedss"
                                                   dangerouslySetInnerHTML={{
                                                      __html: product_line,
                                                   }}
                                                   /* dangerouslySetInnerHTML={{
                                                    __html: product_line.substring(0, 15) + '...',
                                                //	__html: product_line,
                                                }} */
                                                />
                                                {/* <div className="cost">AED {price == null ? parseInt(item.product.price) : price}</div> */}
                                                <div className="cost">
                                                   {store.account.currency}{' '}
                                                   {price != null
                                                      ? currencyconvertrate(price, store)
                                                      : currencyconvertrate(item.product.price, store)}
                                                </div>
                                             </div>
                                          </div>
                                          <div className="tkt-prize-img">
                                             <img
                                                src={
                                                   props.Environment.productUrl +
                                                   prod.custom_attributes.find(
                                                      attr => attr.attribute_code == 'thumbnail'
                                                   ).value
                                                }
                                                alt=""
                                             />
                                          </div>
                                       </div>
                                    </div>
                                 </Swipeable>
                              </div>
                           </div>
                        );
                     })}
                  </div>
               </div>
            )}
         </div>
      </div>
   );
};

export default Watchlist;
