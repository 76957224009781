import React, { useEffect, useState } from 'react';
import NavBar from '../../components/NavBar/NavBar';
import { Environment } from '../../env';
import { Tween } from 'react-gsap';
import { useHistory } from 'react-router-dom';
import QuestionComponent from './QuestionComponent';
import { Link } from 'react-router-dom';
const script = document.createElement('script');
script.src =
   'https://i.ctnsnet.com/int/integration?pixel=67834349&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}';
script.async = true;
const FAQs = props => {
   const [radiobtnvalue, setRadioBtnVal] = useState('aboutkanzi');
   const [radiobtnchecked, setradiobtnchecked] = useState(true);
   const history = useHistory();
   useEffect(() => {}, [radiobtnvalue, radiobtnchecked]);

   useEffect(() => {
      if (document.getElementById('faqs-page-wrapper'))
         document.getElementById('faqs-page-wrapper').appendChild(script);
      return () => {
         if (document.getElementById('faqs-page-wrapper'))
            document.getElementById('faqs-page-wrapper').removeChild(script);
      };
   }, []);

   const selectedvalue = (e, c) => {
      setRadioBtnVal(e);
      setradiobtnchecked(c);
   };
   return (
      <div id="faqs-page-wrapper" className="">
         <NavBar faqs="FAQs" />
         <div className="faq-main-container">
            {/* <Tween from={{ x: '100%', opacity: 1, duration: 0.5 }}> */}
            <h1 className="desktop-header"> {props.Environment.FequentlyAskedQuestions}</h1>
            <div>
               <div className="radio-btns">
                  <input
                     type="radio"
                     id="aboutkanzi"
                     name="filter"
                     value="aboutkanzi"
                     defaultChecked
                     onChange={e => selectedvalue(e.target.value, e.target.checked)}
                  />
                  <label className="radiobtn" htmlFor="aboutkanzi">
                     <span>{props.Environment.aboutkanzi}</span>
                  </label>
                  <input
                     type="radio"
                     id="account"
                     name="filter"
                     value="account"
                     onChange={e => selectedvalue(e.target.value, e.target.checked)}
                  />
                  <label className="radiobtn" htmlFor="account">
                     <span>{props.Environment.account}</span>
                  </label>
                  <input
                     type="radio"
                     id="products"
                     name="filter"
                     value="products"
                     onChange={e => selectedvalue(e.target.value, e.target.checked)}
                  />
                  <label className="radiobtn" htmlFor="products">
                     <span>{props.Environment.products}</span>
                  </label>
                  <input
                     type="radio"
                     id="prizedraws"
                     name="filter"
                     value="prizedraws"
                     onChange={e => selectedvalue(e.target.value, e.target.checked)}
                  />
                  <label className="radiobtn" htmlFor="prizedraws">
                     <span>{props.Environment.prizedraws}</span>
                  </label>
                  <input
                     type="radio"
                     id="koinz"
                     name="filter"
                     value="koinz"
                     onChange={e => selectedvalue(e.target.value, e.target.checked)}
                  />
                  <label className="radiobtn" htmlFor="koinz">
                     <span>{props.Environment.hkoinztitle}</span>
                  </label>
               </div>
               {radiobtnvalue === 'aboutkanzi' && radiobtnchecked === true ? (
                  <QuestionComponent
                     {...props}
                     questions={props.Environment.AboutApp}
                     title={props.Environment.aboutkanzi}
                  />
               ) : null}
               {radiobtnvalue === 'account' && radiobtnchecked === true ? (
                  <QuestionComponent
                     {...props}
                     questions={props.Environment.Account_faq}
                     title={props.Environment.account}
                  />
               ) : null}
               {radiobtnvalue === 'products' && radiobtnchecked === true ? (
                  <QuestionComponent
                     {...props}
                     questions={props.Environment.products_faq}
                     title={props.Environment.product}
                  />
               ) : null}
               {radiobtnvalue === 'prizedraws' && radiobtnchecked === true ? (
                  <QuestionComponent
                     {...props}
                     questions={props.Environment.prizedraws_faq}
                     title={props.Environment.prizedraws}
                  />
               ) : null}
               {radiobtnvalue === 'koinz' && radiobtnchecked === true ? (
                  <QuestionComponent
                     {...props}
                     questions={props.Environment.koinz_faq}
                     title={props.Environment.hkoinztitle}
                  />
               ) : null}
               <div className="bot-con-faq">
                  <div className="getintouch-container getintouch-containertr">
                     {props.Environment.havequestionsterms}
                     <br />
                     {/* <Link to="/contactus">
                      */}
                     <span
                        onClick={() => {
                           history.push('/drawtnc');
                        }}
                     >
                        {props.Environment.havequestionstermstounch}
                     </span>
                     {/* </Link> */}
                  </div>

                  <div className="getintouch-container getintouch-containertr">
                     {props.Environment.havequestions}
                     <br />
                     {/* <Link to="/contactus">
                      */}
                     <span
                        onClick={() => {
                           history.push('/contactus');
                        }}
                     >
                        {props.Environment.getintouch}
                     </span>
                     {/* </Link> */}
                  </div>
               </div>
            </div>
            {/* </Tween> */}
         </div>
      </div>
   );
};
export default FAQs;
