import React from 'react';

const DesktopBanner = () => {
   return (
      <div>
      <div className="desktop-home-bannersds site-e" 
      >
         
         <img className= "imgvd" src="images/andesktop.jpg" alt="" />
         <img className="imgvm" src="images/andmobile.jpg" alt="" />
         <div className="right-section">
            <div className="image-block">
               <a href="https://apps.apple.com/bh/app/kanzi/id1563930820" target="_blank">  <img className= "imgvd33" src="makeicons/iOS.svg" alt="" /></a>
               <a href="https://play.google.com/store/apps/details?id=com.kanziapp" target="_blank">  <img src="makeicons/Android.svg" alt="" /></a>
            </div>
         </div>
         <div className="right-sectionss imgvm">
            <div className="image-block">
               <a href="https://apps.apple.com/bh/app/kanzi/id1563930820" target="_blank">  <img className="imgvd33" src="makeicons/iOS.svg" alt="" /></a>
               <a href="https://play.google.com/store/apps/details?id=com.kanziapp" target="_blank">  <img src="makeicons/Android.svg" alt="" /></a>
            </div>
         </div>
      </div>
      <div className="desktop-home-bannersds site-a"
      >

            <img className="imgvd" src="images/barabic.png" alt="" />
            <img className="imgvm" src="images/barabicr.png" alt="" />
         <div className="right-section">
            <div className="image-block">
               <a href="https://apps.apple.com/bh/app/kanzi/id1563930820" target="_blank">  <img className="imgvd33" src="makeicons/iOS.svg" alt="" /></a>
               <a href="https://play.google.com/store/apps/details?id=com.kanziapp" target="_blank">  <img src="makeicons/Android.svg" alt="" /></a>
            </div>
         </div>
         <div className="right-sectionss imgvm">
            <div className="image-block">
               <a href="https://apps.apple.com/bh/app/kanzi/id1563930820" target="_blank">  <img className="imgvd33" src="makeicons/iOS.svg" alt="" /></a>
               <a href="https://play.google.com/store/apps/details?id=com.kanziapp" target="_blank">  <img src="makeicons/Android.svg" alt="" /></a>
            </div>
         </div>
      </div>
      </div>
   );
};

export default DesktopBanner;
