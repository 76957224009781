import React, { useState, useRef, useEffect } from 'react';
import { Environment } from './../../env';
import Button from '../Button/Button';
import { Link, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { TweenMax } from 'gsap';
import NavBar from '../../components/NavBar/NavBar';
import { Tween } from 'react-gsap';
import { GetNotifications, MarkRead } from '../../redux/actions/auth/actions';
import { get } from 'lodash';
import ReactTimeAgo from 'react-time-ago';
import $ from 'jquery';
const script = document.createElement('script');
script.src =
   'https://i.ctnsnet.com/int/integration?pixel=67834349&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}';
script.async = true;
const Notifications = () => {
   const [redirect, setredirect] = useState(false);
   const store = useSelector(store => store);
   const [noti, setnoti] = useState([]);
   let [nullnoti, setnullnoti] = useState(false);
   const dispatch = useDispatch();

   useEffect(() => {
      if (document.getElementById('notification-page-wrapper'))
         document.getElementById('notification-page-wrapper').appendChild(script);

      console.log(get(store, 'auth.token', null));
      if (get(store, 'auth.token', null) != null) {
         //GetNoti()
      }
      return () => {
         if (document.getElementById('notification-page-wrapper'))
            document.getElementById('notification-page-wrapper').removeChild(script);
      };
   }, []);

   console.log(get(store, 'home.notificationlisting', []));

   const GetNoti = async () => {
      let data = await dispatch(GetNotifications(get(store, 'auth.token', null)));

      setnoti(JSON.parse(data));
   };

   const OnNotiClick = async id => {
      await MarkRead(get(store, 'auth.token', null), id);
      dispatch(GetNotifications(get(store, 'auth.token', null)));
   };
   console.log('data' + noti.length);
   /* if (noti.length == 0){
    nullnoti = true;
    $('n-notif').addClass('introd');
   // $('#n-notif').css('display', 'block');

  }
  else{
    nullnoti = false;
    $('n-notif').removeClass('introd');
   // $('#n-notif').css('display', 'block');
  } */
   return (
      <div id="notification-page-wrapper">
         <NavBar notifications="Notifications" />
         <div className="Notifications-container">
            {redirect ? <Redirect to="/" /> : null}
            {nullnoti ? (
               <div className="n-notif" id="n-notif">
                  No <span>Notification</span>
               </div>
            ) : (
               <div className="Notifications-main">
                  <Tween from={{ x: '100%', opacity: 0, duration: 0.5 }}>
                     {get(store, 'home.notificationlisting', []).map(item => {
                        let date = new Date(item.created_at);

                        return (
                           <div
                              className={`list-cards ${item.is_read == 0 ? 'unreadnoti' : ''}`}
                              onClick={() => {
                                 OnNotiClick(item.id);
                              }}
                           >
                              <ul>
                                 <li>
                                    <Link to="/">
                                       <div className="list-txt">{item.notification_text}</div>
                                       <div className="link-blue">{Environment.getitbefore}</div>
                                       {/* <div className="noti-time">
                                         <ReactTimeAgo date={date} locale="en-US" />
                                      </div> */}
                                    </Link>
                                 </li>
                              </ul>
                           </div>
                        );
                     })}
                  </Tween>
               </div>
            )}
         </div>
      </div>
   );
};

export default Notifications;
