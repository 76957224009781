import React, { useState, useRef } from "react";
import { Environment } from "./../../env";
import Button from "../Button/Button";
import { Redirect } from "react-router-dom";
import { TweenMax } from "gsap"
import { Tween } from "react-gsap";


const CartEmpty = (props) => {

  const [redirect, setredirect] = useState(false)

  return (
    <div className="cartempty-container">
      {redirect ? <Redirect to="/" /> : null}
      <div className="cartempty-main">
        <Tween from={{ y: "-100%", opacity: 0, duration: 0.5, }}>
          <div className="ic-cart-empty">
            <img src={`${props.Environment.img_path}makeicons/cart-empty.svg`} alt="" />
          </div>
        </Tween>
        <h4>{props.Environment.emptycart_title}</h4>
        <p>{props.Environment.emptycart_text}</p>
        <Button className="button-red" data={props.Environment.gshopping} onClick={() => { setredirect(true) }}></Button>
      </div>
    </div>
  );
};

export default CartEmpty;
