import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom";
import NavBar from "../../components/NavBar/NavBar";
import { Environment } from "../../env";
import { Tween } from "react-gsap";
import Loader from "../../components/Loader/Loader";
import { DeleteCustomerAddress } from "../../redux/actions/cart/actions"

const SavedAddress = () => {
  const [isRendered, setRendered] = useState(false);
  const isViewCartLoaded = useSelector(state => state.cart.isViewCartLoaded);
  const addresses = useSelector(state => state.cart.customerData);
  const dispatch = useDispatch()

  useEffect(() => {
    if (isViewCartLoaded) {
      setRendered(true);
    }
  }, [isViewCartLoaded]);

  const DeleteAddress = (id) => {
    dispatch(DeleteCustomerAddress(id))
  }

  return (
    <Fragment>
      <NavBar savedaddress="savedaddress" />
      <div className="Saved-container">
        {
          !isRendered && <Loader style={{ margin: '10px auto 60px' }} />
        }
        {
          isRendered &&
          <Tween from={{ x: "100%", opacity: 1, duration: 0.5 }}>
            <div className="Saved-list-address">
              {
                addresses.map((address, i) => (
                  <div className="list-cards" key={i}>
                    <div className="saved-adresses-list">
                      <div className="list-icon">
                        <img src={`${Environment.img_path}icons/ic_saved_address.svg`} alt="" />
                      </div>
                      <div className="list-txt">
                        {
                          address.custom_attributes ? address.custom_attributes.find(cur => cur.attribute_code === 'address_nickname').value : ''}
                      </div>
                      <div className="delete-icon" onClick={() => { DeleteAddress(address.id) }}>
                        <img src={`${Environment.img_path}icons/deleteaddress.svg`} />
                      </div>
                    </div>
                    <ul>
                      <li>
                        <Link to={{
                          pathname: "/addaddress_savedAddress",
                          query: {
                            address: address
                          }
                        }}>
                          <div className="address-list">
                            <span>{address.street.join(', ')}</span>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                ))
              }
            </div>
            <Link to="/addaddress_savedAddress" className="Saved-list-address add-list-address">
              <div className="list-cards add-address-btn">
                <img src={`${Environment.img_path}icons/plus.png`} alt="" />
              </div>
            </Link>
          </Tween>
        }
      </div>
    </Fragment>
  );
};

export default SavedAddress;
