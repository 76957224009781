import React, { useState, useEffect } from "react"
import { Route, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { useDispatch } from 'react-redux';
import { get, isNil } from 'lodash';

import { Environment as en } from "../env"
import { Environment as ar } from "../env_ar"
import { SetLang } from "../redux/actions/home/actions"


const LanguageWrapper = ({ component: Component, isRendered, ...rest }) => {

   const dispatch = useDispatch();

   const lang = useSelector(store => store.home.language)
   let languageget = localStorage.getItem('langage');
   let onboardingvalue = localStorage.getItem('onboardingvalue');
   const location = useLocation();


   useEffect(() => {
      if (languageget == "ar") {
         setlangfile(ar)
      }
      else {
         setlangfile(en)
      }
         // alert("ds")
         if (languageget == "ar") {
         
            document.getElementById('body-id').classList.add('site-ar');
            document.getElementById('body-id').classList.remove('site-en');
            if (location.pathname.includes('orderdetails') || location.pathname.includes('ob')) { }
            else {
               setlangfile(ar)
               console.log("Inside acc")
               console.log("Inside")
               dispatch(SetLang("ar"));
            }
         }
         else {
            
            setlangfile(en)
            dispatch(SetLang("en"));
            
            // if (location.pathname.includes('orderdetails') || location.pathname.includes('ob')) { }
            // else {
            //    setlangfile(en)
            //    dispatch(SetLang("en"));
            // }
            document.getElementById('body-id').classList.add('site-en');
            document.getElementById('body-id').classList.remove('site-ar');

         }
      
   }, [lang])



   // useEffect(() => {
   //     if (onboardingvalue == "true"){
   //         // alert("ds")
   //         if (languageget == "en") {
   //             dispatch(SetLang("en"));
   //             setlangfile(en)
   //             document.getElementById('body-id').classList.remove('site-ar');
   //             document.getElementById('body-id').classList.add('site-en');

   //         }
   //         else {
   //             console.log("dsds")
   //             if (location.pathname.includes('orderdetails') || location.pathname.includes('ob')) { }
   //             else {
   //                 console.log("Inside acc")
   //                 console.log("Inside")
   //                 dispatch(SetLang("ar"));
   //             }
   //             setlangfile(ar)
   //             document.getElementById('body-id').classList.remove('site-en');
   //             document.getElementById('body-id').classList.add('site-ar');

   //         }
   //     }

   // }, [])

   const [langfile, setlangfile] = useState(en)

   return <Route {...rest} render={(props) => <Component {...props} Environment={langfile} />} />
}

export default LanguageWrapper
