import React, { useRef, useState, useEffect } from 'react';
import { Environment } from '../../env';
import Button from '../Button/Button';
import { TweenMax } from 'gsap';
import { Swipeable } from 'react-swipeable';
import { useHistory } from 'react-router-dom';
import { Tween } from 'react-gsap';
import { isObjEmpty } from '../../utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import { AddAddress, EditAddress } from '../../redux/actions/account/actions';
import { SetLang } from '../../redux/actions/account/actions';
import {
   FacebookShareButton,
   InstapaperShareButton,
   LinkedinShareButton,
   WhatsappShareButton,
   EmailShareButton,
} from 'react-share';
import { FacebookIcon, InstapaperIcon, LinkedinIcon, WhatsappIcon, EmailIcon } from 'react-share';

const ShareIconSheet = props => {
   const [error, setError] = useState({});
   const [active, setActive] = useState(false);
   const SheetAddress = useRef(null);
   const history = useHistory();

   const handleGoBack = () => {
      setActive(false);
      TweenMax.fromTo(SheetAddress.current, Environment.popupBackground.duration, { y: 0 }, { y: 1000 });
      setTimeout(() => {
         props.history.goBack();
      }, 700);
   };

   useEffect(() => {
      document.body.style.overflow = 'hidden';
      TweenMax.fromTo(SheetAddress.current, Environment.popupBackground.duration, { y: 600 }, { y: 0 });
      setTimeout(() => {
         setActive(true);
      }, 700);
      return () => (document.body.style.overflow = 'visible');
   }, []);

   useEffect(() => {
      document.addEventListener('click', evt => {
         const flyoutElement = document.querySelector('.action-container');
         let targetElement = evt.target; // clicked element

         if (targetElement == flyoutElement) {
            handleGoBack();
            return;
         }
      });
   }, []);

   return (
      <Swipeable onSwipedDown={handleGoBack}>
         <div
            className="action-container"
            ref={SheetAddress}
            style={{ backgroundColor: active ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0)' }}
         >
            <Tween
               from={{
                  y: '100%',
                  opacity: 1,
                  delay: Environment.popupContainer.delay,
                  duration: Environment.popupContainer.duration,
               }}
            >
               <div className="action-cards share">
                  <div className="icon-row">
                     <EmailShareButton>
                        <EmailIcon borderRadius={50} />
                     </EmailShareButton>
                     <FacebookShareButton url="www.facebook.com">
                        <FacebookIcon borderRadius={50} />
                     </FacebookShareButton>
                     <LinkedinShareButton url="www.linkedin.com">
                        <LinkedinIcon borderRadius={50} />
                     </LinkedinShareButton>
                     <WhatsappShareButton url="www.whatsapp.com">
                        <WhatsappIcon borderRadius={50} />
                     </WhatsappShareButton>
                  </div>
               </div>
            </Tween>
         </div>
      </Swipeable>
   );
};

export default ShareIconSheet;
