import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux"
import { Environment as en } from '../../env';
import { Environment as ar } from '../../env_ar';
import $ from "jquery";
import { get } from 'lodash';
import { SetPrevTab } from '../../redux/actions/home/actions';
import { Tabs } from './../../constants/tabs';


import Button from "../Button/Button"
import SideMenu from "../SideMenu/SideMenu"


const DesktopHeader = (props) => {
   const cartState = useSelector(state => state.cart.cart);
   const cartQty = cartState.reduce((acc, val) => acc + val.qty, 0);
   const [show, setShow] = useState(false);
   const [ham_type, sethamtype] = useState(false);
   const ham = useRef(null);
   const location = useLocation();
   const store = useSelector(store => store);
   const prevTab = useSelector(store => store.home.prevTab);
   const history = useHistory();
   const dispatch = useDispatch();
   const [notcount, setnotcount] = useState(0)
   const [file, setfile] = useState(en)

   useEffect(() => {
      if (get(store, 'home.language', 'en') == "en") {
         setfile(en)
      }
      else {
         setfile(ar)
      }
   }, [get(store, 'home.language', 'en')])


   const OnHamClick = () => {
      if (ham_type) {
         ham.current.style.height = 0
      }
      else {
         ham.current.style.height = "100vh"
      }
      sethamtype(!ham_type)
   }

   useEffect(() => {
      var URL1 = window.location.href;
      var tabname1 = URL1.split("/").pop();
      if (tabname1 == '') {
         //  alert("tabname1");
         $("#mydv").addClass("error-messagess");
      }
      else {
         $("#mydv").removeClass("error-messagess");
      }
      var header = document.getElementById("mydv");
      var btns = header.getElementsByClassName("fdg");
      // for (var i = 0; i < btns.length; i++) {
      //    // btns[i].addEventListener("click", function () {

      //       var current = document.getElementsByClassName("active");
      //       current[0].className = current[0].className.replace(" active", "");
      //       this.className += " active";
      //    // });
      // }
      if (ham_type) {
         OnHamClick()
      }
      if (show) {
         setShow(false)
      }
   }, [location.pathname])

   useEffect(() => {

      if (store.auth.token == null) {
         setShow(false)
      }

   }, [store.auth.token])

   useEffect(() => {

      let c = 0;
      get(store, 'home.notificationlisting', []).forEach(item => {
         console.log(typeof item.is_read)
         if (item.is_read == "0") {
            c++;
         }
      })

      setnotcount(c)

   }, [get(store, 'home.notificationlisting', [])])


   const onLogout = () => {
      localStorage.removeItem('cartid');
      localStorage.removeItem('token');
      localStorage.removeItem('info');
      dispatch({ type: "LOGOUT" });
      history.push('/');
   }
   const setPrevTab = tabName => {
      if (prevTab !== tabName && !location.isNaviagtedByCheckout) dispatch(SetPrevTab(tabName));
   };
   console.log(prevTab)
   if (prevTab == null || prevTab == "null") {
      setPrevTab(Tabs.HOME);
   }
   else {
      // history.push(prevTab ? `/${prevTab}` : '/');
   }
   return (
      <div className="desktop-header-container">
         {show ? <SideMenu show={show} setShow={() => { setShow(false) }}></SideMenu> : null}
         <div className="container deskr">
            <div className="left-section">
               <div className="logo">
                  <Link to="/">
                     <img src="makeicons/desktop-logo.svg" alt="" />
                  </Link>
               </div>
               <nav id="mydv">

                  <Link to="/aboutkanzi" onClick={() => {
                     setPrevTab(Tabs.HOME);
                  }} id="fdgid" className={`hides fdg ${location.pathname.includes("") ? "active" : ""}`}>
                     Homepage
             </Link>
                  <Link to="/aboutkanzi" id="fdgid" className={`fdg ${location.pathname.includes("aboutkanzi") ? "active" : ""}`}>
                     {props.Environment.aboutheader}
             </Link>
                  {/* <Link to="/howitworks" id="fdgid" className={`fdg ${location.pathname.includes("howitworks") ? "active" : ""}`}>
                     How It Works
             </Link> */}
                  <Link onClick={() => {
                     setPrevTab(Tabs.TICKETS);
                  }} to="/tickets" id="fdgid" className={`fdg ${location.pathname.includes("tickets") ? "active" : ""}`}>
                     {props?.Environment?.ticketheaders}
             </Link>
                  <Link to="/watchlist" id="fdgid" className={`fdg ${location.pathname.includes("watchlist") ? "active" : ""}`}>
                     {props?.Environment?.favheader}
             </Link>
                  <Link to="/thismonthwinner" className={` fdg ${location.pathname.includes("thismonthwinner") ? "active" : ""}`} id="fdgid">
                     {props?.Environment?.winnerheader}
             </Link>
                  <Link to="/howitworks" id="fdgid" className={`fdg ${location.pathname.includes("howitworks") ? "active" : ""}`}>
                     {props?.Environment?.howitheader}
             </Link>
               </nav>
               <div
                  id="nav-icon"
                  className={`${ham_type ? "open" : ""}`}
                  onClick={OnHamClick}
               >
                  <span></span>
                  <span></span>
                  <span></span>
               </div>
            </div>
            <div className="right-section">
               <div className="login-button">
                  {store.auth.token !== null ? <Link onClick={() => { setShow(true) }}>{props?.Environment?.myaccounts}</Link> : <Link to="/account/login">{props?.Environment?.login}</Link>}
               </div>
               <div className="icons">
                  {/* <span style={{ position: "relative" }} onClick={() => { history.push('/cart') }}>
                     <img src="makeicons/webcart.svg" alt=""  />
                     <span className="counter">{cartQty}</span>
                  </span> */}
                  <Link to="/cart" onClick={() => {
                     setPrevTab(Tabs.CART);
                  }} 
                     style={{ position: "relative" }} >
                     <img src="makeicons/webcart.svg" alt="" />
                     <span className="counter">{cartQty}</span>
             </Link>
                  
                  <Link to="/accountsettings">  <img src="makeicons/ic_appsetting.svg" alt="" /></Link>
                  {/* <Link to="/currencyselection">  <img src="makeicons/webdollar.svg" alt="" /></Link> */}

               </div>
               <span className="notigf" onClick={() => {
                  if (store.auth.token == null) {
                     return history.push("/account/login")
                  }
                  else {
                     return history.push("/notifications")
                  }


               }}>

                  {(store.auth.token != null && notcount != 0) ? <span className="count">
                     {notcount}
                  </span> : null}
                  <img src={`makeicons/ic-notifications.svg`} alt="" />
               </span>
            </div>
            <div className="ham-dropdown" ref={ham}>
               <div className="left-section ham">

                  <h3>General</h3>
                  <nav>
                     <Link to="/aboutkanzi">About</Link>
                     <Link to="/howitworks">How It Works</Link>
                     <Link to="/">Campaigns</Link>
                     <Link to="/tickets">Tickets</Link>
                     <Link className="hda" to="/mykoinz">Koinz</Link>
                  </nav>

                  <h3>Settings</h3>
                  <nav>
                     <Link to="/accountsettings"><img src={`makeicons/dollarnav.svg`} />Currency</Link>
                     <Link to="/cart"><img src={`makeicons/cartnav.svg`} />My Cart</Link>
                  </nav>

                  <h3>Account</h3>
                  {store.auth.token == null ? <nav>
                     <Button className="button-red" onClick={() => { history.push("/account/login") }}>Login</Button>
                     <Button className="button-outline-red" onClick={() => { history.push("/account/login") }}>Sign Up</Button>
                  </nav> : <nav>
                        <Button className="button-red" onClick={onLogout}>Log Out</Button>
                     </nav>}
               </div>
               <div className="right-section">
                  {/* <div className="login-button">
                  <Link>Login</Link>
               </div> */}
               </div>
            </div>
         </div>
      </div>
   );
};

export default DesktopHeader;
