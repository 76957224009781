import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar';
import { useHistory } from 'react-router-dom';

import { Environment } from '../../env';
import Button from '../../components/Button/Button';
import { Tween } from 'react-gsap';
import { ContactUS } from '../../redux/actions/auth/actions';
import { isObjEmpty } from '../../utils/utils';
import PopUps from '../../components/PopUps/PopUps';
import $ from 'jquery';
import CircularLoader from '../../components/Loader/CircularLoader';
import { getStoreId } from './../../utils/utils';
import { useSelector } from 'react-redux';
const script = document.createElement('script');
script.src =
   'https://i.ctnsnet.com/int/integration?pixel=67834349&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}';
script.async = true;
const ContactUs = props => {
   const [email, setemail] = useState('');
   const history = useHistory();
   const [name, setname] = useState('');
   const [message, setmessage] = useState('');
   const [error, seterror] = useState({ name: null, email: null, message: null });
   const [disabled, setdisabled] = useState(false);
   const [errorPopup, setErrorPopup] = useState('none');
   const [displayLoader, setDisplayLoader] = useState(false);
   const store = useSelector(store => store);

   useEffect(() => {
      if (document.getElementById('contact-us-page-wrapper'))
         document.getElementById('contact-us-page-wrapper').appendChild(script);
      return () => {
         if (document.getElementById('contact-us-page-wrapper'))
            document.getElementById('contact-us-page-wrapper').removeChild(script);
      };
   }, []);

   const onContactUs = async () => {
      if (disabled) {
         return;
      }
      setdisabled(true);
      var errorT = {};
      let donef = true;
      if (name.trim().length === 0) {
         errorT.name = 'Please enter a valid name';
         donef = false;
      }
      if (!/\S+@\S+\.\S+/.test(email.trim())) {
         errorT.email = 'Please enter a valid email';
         donef = false;
      }
      if (message.trim().length === 0) {
         errorT.message = 'Please enter a valid name';
         donef = false;
      }
      console.log(donef);
      if (donef == true) {
         $('#button-redtemp').addClass('contactFormdis');
      } else {
         $('#button-redtemp').removeClass('contactFormdis');
      }
      if (isObjEmpty(errorT)) {
         seterror({ name: null, email: null, message: null });
         setDisplayLoader(true);
         let store_id = getStoreId(store);
         var res = await ContactUS(name, email, message, store_id);
         if (res) {
            setDisplayLoader(false);
            setname('');
            setemail('');
            setmessage('');
            setErrorPopup('contactus');

            // alert(props.Environment.contsucc);
         }
      } else {
         setErrorPopup('none');

         seterror({ name: null, email: null, message: null, ...errorT });
      }
      setdisabled(false);
   };

   return (
      <div id="contact-us-page-wrapper">
         {errorPopup != 'none' && (
            <PopUps
               type="contactus"
               errorType={errorPopup}
               onSubmit={() => {
                  setErrorPopup('none');
                  history.push('/resetpassword');
               }}
               onClose={() => {
                  setErrorPopup('none');
                  history.push('/resetpassword');
               }}
            />
         )}
         <CircularLoader show={displayLoader}></CircularLoader>
         <NavBar contactus="Contact Us" />
         <div className="contact-container">
            {props.token === null ? <Redirect to="/signup" /> : null}
            {/* <Tween from={{ x: '100%', opacity: 1, duration: 0.5 }}> */}
            <h1 className="desktop-header-title">{props.Environment.getintouch}</h1>
            <div className="contact-list-cards">
               <div className="list-cards">
                  <ul>
                     <li>
                        <a href={'mailto:' + props.Environment.emailkanzi}>
                           <div className="icontxt">
                              <div className="list-icon">
                                 <img
                                    src={`${props.Environment.img_path}icons/ic_maillist.svg`}
                                    alt=""
                                    // style={{ marginLeft: '-5px', marginRight: '-5px' }}
                                 />
                              </div>
                              <div className="list-txt">{props.Environment.emailkanzi}</div>
                           </div>
                        </a>
                     </li>
                     <li>
                        <a href={'tel:' + props.Environment.numburkanzi}>
                           <div className="icontxt">
                              <div className="list-icon" style={{ textAlign: 'center' }}>
                                 <img src={`${props.Environment.img_path}icons/ic_phone.svg`} alt="" />
                              </div>
                              <div className="list-txt">{props.Environment.numburkanzi}</div>
                           </div>
                        </a>
                     </li>

                     <li>
                        <a href="https://maps.google.com/?q=25.08582,55.14167">
                           <div className="icontxt">
                              <div className="list-icon" style={{ textAlign: 'center' }}>
                                 <img src={`${props.Environment.img_path}icons/ic_marker.svg`} alt="" />
                              </div>
                              <div className="list-txt">{props.Environment.addresskanzi}</div>
                           </div>
                        </a>
                     </li>
                  </ul>
                  <div className="social-icons">
                     {/* <h1>{props.Environment.social}</h1> */}

                     <div className="cont1">
                        <span className="social">
                           <a target="_blank" href="https://twitter.com/kanziapp">
                              <img src={`${props.Environment.img_path}icons/ic_ttr.svg`} alt="" />
                           </a>
                        </span>
                        <span className="social">
                           <a target="_blank" href="https://www.facebook.com/Kanziapp-105361704989016">
                              <img src={`${props.Environment.img_path}icons/ic_fb.svg`} alt="" />
                           </a>
                        </span>
                        <span className="social">
                           <a target="_blank" href="https://www.instagram.com/kanziapp/">
                              <img src={`${props.Environment.img_path}icons/ic_inst.svg`} alt="" />
                           </a>
                        </span>
                     </div>
                  </div>
               </div>

               <div className="kanzi-contact-form">
                  <h3>{props.Environment.kanzicontactformttl}</h3>
                  <div>
                     <input
                        value={name}
                        style={{ border: error.name !== null ? '1px solid red' : null }}
                        type="text"
                        placeholder={props.Environment.name}
                        onChange={e => {
                           setname(e.currentTarget.value);
                        }}
                     />
                  </div>
                  <div>
                     <input
                        value={email}
                        style={{ border: error.email !== null ? '1px solid red' : null }}
                        type="text"
                        placeholder={props.Environment.email}
                        onChange={e => {
                           setemail(e.currentTarget.value);
                        }}
                     />
                  </div>
                  <div>
                     <textarea
                        value={message}
                        style={{ border: error.message !== null ? '1px solid red' : null }}
                        type="text"
                        placeholder={props.Environment.message}
                        onChange={e => {
                           setmessage(e.currentTarget.value);
                        }}
                     ></textarea>
                  </div>
                  <div>
                     <Button
                        id="button-redtemp"
                        className="button-red"
                        data={props.Environment.sendmessage}
                        onClick={onContactUs}
                     />
                  </div>
               </div>
            </div>
            {/* </Tween> */}
         </div>
      </div>
   );
};

export default ContactUs;
