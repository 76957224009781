import React, { useState, useEffect, useRef } from 'react';
import { useStore, useDispatch, useSelector } from 'react-redux';
import NavBar from '../../components/NavBar/NavBar';
import Button from '../../components/Button/Button';
import {
   ViewCart,
   AddShippingInfo,
   EstimateShippingPrice,
   PaymentInfo,
   CreatePointsActivity,
   ParticipateInCampaign,
   ViewMamboProfile,
   GetOrderDetails,
   GetOrderData,
   GetAdminToken,
   EmptyKoinzProduct,
   isKoinz,
   GetPreviousOrders,
   ReferAFriend,
   PayByCard,
   GetCampaignCount,
   CreatePointsParticipation,
   koinzAmount,
   SetCustProfile,
   GetSavedCards,
   handlePaymentFail,
   SetOrderAmount,
   applePay,
   sumsuangPay,
   ApplePayResponse,
   SumsungPayResponse,
   ApplyPromoCode,
   GetAppliedPromoCodeCodeInfo,
   DeletePromoCode,
} from '../../redux/actions/cart/actions';
import { Tween } from 'react-gsap';
import { get } from 'lodash';
import { ORDERID } from '../../redux/actions/cart/actionTypes';
import { UserData } from '../../redux/actions/auth/actions';
import { SetRewards } from '../../redux/actions/home/actions';
import CheckoutCart from '../../components/CheckoutCart/CheckoutCart';
import CircularLoader from '../../components/Loader/CircularLoader';
import Loader from '../../components/Loader/Loader';
import ActionSheetPayment from '../../components/ActionSheetPayment/ActionSheetPayment';
import { comify } from '../../utils/Function';
import { currencyconvertrate } from '../../utils/Function';
import { SetError } from './../../redux/actions/auth/actions';
import PopUps from '../../components/PopUps/PopUps';
import $ from 'jquery';
import { ADD_ADDRESS } from './../../redux/actions/account/actionTypes';
import { useLockBodyScroll, isOrderSuccessfull } from '../../utils/Function';
import { isNil } from 'lodash';
import ReactPixel from 'react-facebook-pixel';
import TagManager from "react-gtm-module";

let filteredCart=[];
const script = document.createElement('script');
script.src =
   'https://i.ctnsnet.com/int/integration?pixel=67834349&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}';
script.async = true;
let triggerGTAG = false
const CheckoutDetails = props => {
   const store = useSelector(store => store);
   const dispatch = useDispatch();
   const [data, setData] = useState({
      country_id: '',
      street: [],
      city: '',
      firstname: '',
      lastname: '',
      email: '',
      telephone: '',
      selectedid: '',
   });
   const [selected1, setselected1] = useState(false);
   const [selected2, setselected2] = useState(false);
   const [displayLoader, setDisplayLoader] = useState(true);

   const [applepaybutton, setapplepaybutton] = useState(false);
   const [samplebutton, setsamplebutton] = useState(false);
   const [selectedadd, setadd] = useState(null);
   const [locaton, setlocaton] = useState('');
   const [address, setaddress] = useState([]);
   const koinz_replacement = useSelector(store => store.cart.koinz_replacement);
   const cart = useSelector(store => store.cart.cart);
   const cartItemsForGTAG = useSelector(store => store.cart.cartItemsForGTAG);
   //payreview addition
   const [totalAmount, setTotalAmount] = useState(0);
   const campaign = useSelector(store => store.home.campaign_manager);
   const useKoinz = useSelector(store => store.cart.useKoinz);
   const all_products = useSelector(store => store.home.allproducts);
   const koinz = useSelector(store => store.mambo.data);
   const promoCodeInfo = useSelector(store => store.cart.promocode);
   // const orderAmount = useSelector(store => store.cart.orderAmount);
   const [Gtotal, setGtotal] = useState(0);
   const [show, setshow] = useState(false);
   const [koinzprod, setkoinzprod] = useState([]);
   const [showdate, setshowdate] = useState(false);
   const [pickupa, setpickupa] = useState(false);
   const [iosdevice, setIosdevice] = useState('');

   const [showurl, setshowurl] = useState({
      show: false,
      url: null,
   });
   const [delivery_amount, setdm] = useState(15);
   const [showpayment, setshowpayment] = useState(false);
   const [nickname, setnickname] = useState('Office');
   const savedCards = useSelector(store => store.cart.savedCards);
   const [selectedCard, setSelectedCard] = useState(null);
   const [isApplpayeSelected, setIsApplpayeSelected] = useState(false);
   const [isPayNowDisabled, setIsPayNowDisabled] = useState(true);
   const userinfo = useSelector(store => store.auth.userinfo);

   //ends here
   const error_redux = useSelector(state => state.auth.error);
   const [errorPopup, setErrorPopup] = useState('none');
   const [cardError, setCardError] = useState('');
   const [promoCodeDetails, setPromoCodeDetails] = useState({
      isApplied: false,
      amount: 0,
      promoCode: '',
   });
   const pickup_address = [
      {
         // street: ["Level 21, Al Habtoor Business Tower"],
         street: ['The Onyx 1 Tower - office 703,The Greens, Dubai. UAE'],
         city: 'Dubai. UAE',
         firstname: 'Kanzi',
         lastname: 'Warehouse',
         telephone: '+971 4 275 6419',
         id: 100,
      },
   ];

   useEffect(() => {

     if (cart.length > 0 && triggerGTAG === false) {
        triggerGTAG = true;
        filteredCart = cart.map((data, index) => ({
          item_name: data.name,
          item_id: data.item_id,
          price: data.price,
          ...(data.extension_attributes.configurable_item_options
            ? {
                item_variant:
                  data.extension_attributes.configurable_item_options[0]
                    .option_value
              }
            : {}),
          item_list_name: data.product_type,
          item_list_id: data.sku,
          index: index + 1,
          quantity: data.qty,
        }));


        const clearDataLater = { dataLayer: { ecommerce: null } };
        const tagManagerArgs = {
          dataLayer: {
            event: "begin_checkout",
            ecommerce: {
              items: filteredCart
            }
          }
        };

        TagManager.dataLayer(clearDataLater);
        TagManager.dataLayer(tagManagerArgs);
      }


      const cartItemsForGtag = {
         items:filteredCart,
         value: getFinalOrderAmount(),
         shipping:currencyconvertrate(delivery_amount, store),
         currency:store.account.currency,
         transaction_id: "",
         affiliation:"web",
      }

      localStorage.setItem("cartItemsForGtag",JSON.stringify(cartItemsForGtag))
      // dispatch({type:"CART_FOR_GTAG",payload:cartItemsForGtag})
   }, [cart,delivery_amount])

   useEffect(() => {
      if (document.getElementById('drawtnc-page-wrapper'))
         document.getElementById('drawtnc-page-wrapper').appendChild(script);
      return () => {
         if (document.getElementById('drawtnc-page-wrapper')) {
            document.getElementById('drawtnc-page-wrapper').removeChild(script);
         }
         dispatch(DeletePromoCode());
      };
   }, []);

   const applepaymentod = async userinfo => {
      //alert("Payment")
      let totalAmount_new = Math.round(totalAmount);
      const orderAmount = totalAmount_new + delivery_amount;
      console.log(' totalAmount_new ', totalAmount_new);
      console.log(' orderAmount ', orderAmount);
      if (
         totalAmount_new + delivery_amount == 0 ||
         (isApplpayeSelected || koinz_replacement.length == cart.length) & (selected1 !== false)
      ) {
         if (store.auth.token !== null) {
            // props.setLoader(true);
            // setDisplayLoader(true);
            setshow(true);
            let value =
               JSON.stringify(
                  currencyconvertrate(localStorage.getItem('subtotoal'), store) +
                     currencyconvertrate(delivery_amount, store)
               ) - currencyconvertrate(localStorage.getItem('orderAmount'), store);
            let base64ToString = await dispatch(applePay(value, store.account.currency, Date.now()));
            // setDisplayLoader(false);
            setshow(false);
            console.log(JSON.stringify(base64ToString));

            window.ReactNativeWebView.postMessage(
               JSON.stringify({
                  type: 'apple-pay',
                  message: base64ToString,
               })
            );

            //dispatch(ApplePayResponse({status:"failed"}));
            // alert("data api")

            // props.setLoader(false);
         }
      }
   };

   const samsungpaymentod = async userinfo => {
      //alert("Payment")
      if (store.auth.token !== null) {
         // props.setLoader(true);
         // setDisplayLoader(true);
         setshow(true);
         let value =
            JSON.stringify(
               currencyconvertrate(localStorage.getItem('subtotoal'), store) +
                  currencyconvertrate(delivery_amount, store)
            ) - currencyconvertrate(localStorage.getItem('orderAmount'), store);
         let base64ToString = await dispatch(sumsuangPay(value, store.account.currency, Date.now()));
         // setDisplayLoader(false);
         setshow(false);
         console.log(JSON.stringify(base64ToString));

         window.ReactNativeWebView.postMessage(
            JSON.stringify({
               type: 'sumsung-pay',
               message: base64ToString,
            })
         );

         // alert("data api")

         // props.setLoader(false);
      }
   };

   const sumsungpaymentod = async userinfo => {
      if (store.auth.token !== null) {
         // props.setLoader(true);
         let value =
            JSON.stringify(
               currencyconvertrate(localStorage.getItem('subtotoal'), store) +
                  currencyconvertrate(delivery_amount, store)
            ) - currencyconvertrate(localStorage.getItem('orderAmount'), store);
         await dispatch(sumsuangPay(value, store.account.currency, Date.now()));
         console.log('hdsds');
         // props.setLoader(false);
      }
   };

   const [threeDCardData, setThreeDCardData] = useState({
      TermUrl: '',
      acsMd: '',
      acsPaReq: '',
      acsUrl: '',
      orderId: '',
   });
   const [showThreeDAuth, setShowThreeDAuth] = useState(false);

   // useLockBodyScroll();
   //payreview addition
   useEffect(() => {
      // $('.dsdadd').removeClass('dsdaddnoe');
      let isIOS =
         /iPad|iPhone|iPod/.test(navigator.platform) ||
         (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
      setIosdevice(isIOS);

      //dispatch(GetConversionRates())

      if (navigator.appVersion.includes('Android')) {
         document.addEventListener('message', OnMessageRecieved);
      } else {
         window.addEventListener('message', OnMessageRecieved);
      }

      return () => {
         if (navigator.appVersion.includes('Android')) {
            document.removeEventListener('message', OnMessageRecieved);
         } else {
            window.removeEventListener('message', OnMessageRecieved);
         }
      };
   }, []);

   useEffect(() => {
      if (koinz_replacement.length !== get(store, 'cart.cart', []).length) {
         setshowdate(true);
      } else {
         setdm(0);
      }

      //
   }, []);

   useEffect(() => {
      if (error_redux !== null) {
         // if (error_redux === props.Environment.cardError) {
         setErrorPopup('error');
         setshow(false);
         setshowpayment(false);
         // } else {
         //    setErrorPopup('error');
         //    setshow(false);
         //    setshowpayment(false);
         // }
      }
   }, [error_redux]);

   useEffect(
      () => {
         let amount = 0;

         store.cart.cart.forEach(element => {
            amount = amount + element.qty * element.price;
         });

         // if (useKoinz) {
         //    if (koinz.points[0].balance / 5 > amount) {
         //       setTotalAmount(0);
         //    } else {
         //    setTotalAmount(amount - koinz.points[0].balance / 5);
         //    }
         // } else {
         //    setTotalAmount(amount);
         // }
         setTotalAmount(amount);
         setGtotal(amount);

         //
      },
      [
         /* store.cart.cart */
      ]
   );

   //ends here
   const OnMessageRecieved = async message => {
      //  alert(message.data)
      if (message.data === 'true') {
         //alert("here")
         //setshowob(true);
      } else {
         let data;
         if (typeof message.data === 'string') {
            data = JSON.parse(message.data);
         } else {
            data = message.data;
         }
         // alert(data.type)
         // alert(data.data)
         if (data.type === 'pay') {
            setshow(true);

            // dispatch(ApplePayResponse());
            // alert("react js alert" + data.data);
            let fg = data.data;
            // if(fg.status != true){
            //    setErrorPopup('error');
            // }
            let paymentmeething = await dispatch(ApplePayResponse(data.data));
            console.log(JSON.parse(paymentmeething));
            let f = JSON.parse(paymentmeething);

            if (f.order_id != null || f.order_id != undefined) {
               localStorage.setItem('orderidvalue', f.order_id);
               await OrderPlaced1(f.order_id);
            }
         }
         if (data.type === 'sumsungpay') {
            setshow(true);

            // dispatch(ApplePayResponse());
            // alert("react js alert" + data.data);
            let paymentmeething = await dispatch(SumsungPayResponse(data.data));
            console.log(JSON.parse(paymentmeething));
            let f = JSON.parse(paymentmeething);
            console.log(f.order_id);
            if (f.order_id != null || f.order_id != undefined) {
               localStorage.setItem('orderidvalue', f.order_id);
               await OrderPlaced1(f.order_id);
            }
         }
      }
   };
   const chooseErrorPopup = type => {
      if (type === 'error') {
         return {
            onSubmit: () => {
               setErrorPopup('none');
               // history.push('/account');
            },
            onClose: () => {
               setErrorPopup('none');
               dispatch(SetError(null));
            },
         };
      }
      return false;
   };

   useEffect(() => {
      if (props.location.props !== undefined) {
         setlocaton(props.location.props.type);
      }
   }, []);

   useEffect(() => {
      (async () => {
         if (store.auth.token !== null) {
            await dispatch(ViewCart());
            await dispatch(GetSavedCards());
         }
      })();
   }, [store.auth.token]);

   useEffect(() => {
      if (props.location.props != undefined ? props?.location?.props?.type == 'self' : false || locaton == 'self') {
         // if (store.cart.customerData.length !== 0) {
         setnickname('Warehouse');
         setpickupa(true);
         setaddress(pickup_address);
         //storedAddress(100)
         //setselected1(true);
         storedAddress(store?.auth?.userinfo?.id, pickup_address);
         // }
      } else {
         if (store.cart.customerData.length !== 0) {
            //setnickname(atore.cart.customerData[0].custom_attributes!==undefined)
            setaddress(store.cart.customerData);
            setselected1(true);
            setadd(0);

            let defaultAddress = store.cart.customerData.find(item => item.default_shipping);
            if (defaultAddress) {
               storedAddress(defaultAddress?.id, store.cart.customerData);
            } else {
               storedAddress(store.cart.customerData[0].id, store.cart.customerData);
            }
         } else if (koinz_replacement.length == cart.length) {
            setaddress(/* pickup_address */ []);
            //storedAddress(100, pickup_address)
            //setselected1(true)
         }
      }
   }, [store.cart.customerData.length]);

   useEffect(() => {
      if (
         (store.cart.customerData.length !== 0) & (props.location.props != undefined)
            ? props.location.props.type != 'self'
            : false
      ) {
         setnickname(
            store.cart.customerData[0].custom_attributes != undefined
               ? store.cart.customerData[0].custom_attributes[0].value
               : 'Office'
         );
      }
   }, []);

   useEffect(() => {
      if ((data.street.length != 0) & (data?.selectedid != undefined)) {
         SetDeliveryAmount();
      }
   }, [data]);

   const SetDeliveryAmount = async () => {
      setshow(true);
      if (error_redux) setshow(false);
      if (locaton == 'self' || koinz_replacement.length == cart.length) {
         await dispatch(AddShippingInfo(data, 'freeshipping', 'freeshipping'));
         // await dispatch(AddShippingInfo(data, "flatrate", "flatrate"));
      } else {
         let shipping_data = await EstimateShippingPrice(data.selectedid, store.auth.token);
         // let info = await dispatch(AddShippingInfo(data, "flatrate", "flatrate"));
         let info = await dispatch(AddShippingInfo(data, shipping_data.carrier, shipping_data.method));
         if (info != false) {
            setdm(info);
         }
      }
      setshow(false);
   };

   const storedAddress = (id, add = null) => {
      if (props?.location?.props?.type == 'self') {
         let address = {
            country_id: 'AE',
            street: add[0]?.street,
            city: add[0]?.city,
            firstname: store.auth.userinfo.firstname,
            lastname: store.auth.userinfo.lastname,
            email: store.auth.userinfo.email,
            telephone: store.auth.userinfo.phone_number,
            selectedid: store.auth.userinfo.id,
         };
         setData(address);
         dispatch(SetCustProfile(address));
      } else if (store.cart.customerData.length !== 0 || id == 100) {
         setselected1(true);

         let address_saved = address;

         if (add != null) {
            address_saved = add;
         }

         address_saved.forEach(item => {
            if (item.id == id) {
               let address = {
                  country_id: 'AE',
                  street: item.street,
                  city: item.city,
                  firstname: item.firstname,
                  lastname: item.lastname,
                  email: store.auth.userinfo.email,
                  telephone: item.telephone,
                  selectedid: item.id,
               };
               setData(address);
               dispatch(SetCustProfile(address));
            }
         });
      }
   };

   const AddShippingAddress = async () => {
      console.log(data);
      //setselected1(true)
      if (store.auth.token !== null) {
         if (data.street.length !== 0) {
            console.log('heyeey');
            console.log(data.selectedid);
            let shipping_data = null;
            if (/* data.selectedid == 100 */ locaton == 'self' || koinz_replacement.length == cart.length) {
               //shipping_data = await EstimateShippingPrice(store.cart.customerData[0].id, store.auth.token)
               //await dispatch(AddShippingInfo(data, "flatrate", "flatrate"));
            } else {
               //shipping_data = await EstimateShippingPrice(data.selectedid, store.auth.token)
               //await dispatch(AddShippingInfo(data, shipping_data.carrier, shipping_data.method));
            }

            // await OrderPlaced();
            //alert(selectedCard ?.entity_id)
            // alert("placng 1")
            OrderPlaced('payment', selectedCard?.entity_id);

            /* props.history.push({
               pathname: '/payreview',
               id: data.selectedid,
               from: 'checkoutdetails',
            }); */
         } else {
            /* let info = {
               country_id: '101',
               street: ['11','11','11'],
               city: 'dubai',
               firstname: 'john',
               lastname: 'doe',
               email: '101@gmail.com',
               telephone: '1212121212',
               selectedid: '121',
            }
            //sanchit
            await dispatch(AddShippingInfo(info));
            props.history.push({
               pathname: '/payreview',
               id: null,
               from: 'checkoutdetails',
            }); */
         }
         //sanchit
         if (store.cart.customerData.length != 0) {
            //check this
            /* await dispatch(AddShippingInfo(data));
            console.log("hryrgbrjcbrbc")
            console.log(data.selectedid)
            props.history.push({
               pathname: '/payreview',
               id: data.selectedid,
               from: 'checkoutdetails',
            }); */
         }
      }
   };

   //payreview functions starts here

   const HandleReplaceWithKoinz = async (koinzdetail = null) => {
      var koinzforprod = [];
      var koinzforparticipation = [];
      var total_koinz = 0;
      var conversion_rate = get(store, 'cart.config', []).find(item => item.name == 'earning_conversion_rate');
      var product_conversion_rate = get(store, 'cart.config', []).find(
         item => item.name == 'product_cost_conversion_rate'
      );
      var customer_level = get(store, 'mambo.data', null);
      if (customer_level.level != null) {
         customer_level = customer_level.level.reward.customFields;
         customer_level = customer_level.find(fields => fields.name == 'loyalty_level_bonus');
      }
      var details = [];

      koinz_replacement.forEach(item => {
         cart.forEach(prod => {
            if (item == prod.item_id) {
               koinzforprod.push(prod);
            }
         });
      });

      koinzforparticipation = cart.filter(el => !koinzforprod.includes(el));

      koinzforprod.forEach(prod => {
         console.log(prod);
         var koinz =
            prod.qty * prod.price * (conversion_rate == undefined ? 0.2 : parseFloat(conversion_rate.value)) +
            prod.qty *
               prod.extension_attributes.cost *
               (product_conversion_rate == undefined ? 1 : parseFloat(product_conversion_rate.value));
         total_koinz = total_koinz + koinz;
         details.push({ sku: prod.sku, koinz: koinz });
      });

      koinzforparticipation.forEach(prod => {
         let koin = prod.qty * prod.price * (conversion_rate == undefined ? 0.2 : parseFloat(conversion_rate.value));
         total_koinz = total_koinz + koin;
         console.log(total_koinz);
         details.push({ sku: prod.sku, koinz: koin });
      });

      total_koinz =
         total_koinz +
         total_koinz * (customer_level.value == undefined ? 0 * 0.01 : parseFloat(customer_level.value) * 0.01);

      console.log(details);

      if (koinzdetail != null) {
         return details;
      }

      const cp = await dispatch(CreatePointsActivity(Math.round(total_koinz)));
      if (cp) {
         //dispatch(ViewMamboProfile());
      }
   };

   const ParticipateinCampaign = async res => {
      const token = await GetAdminToken();
      const items = await GetOrderDetails(token, res);
      const ids = get(store, 'home.categories', []);

      let awarded = [];

      items.forEach(async (prod, index) => {
         var product = all_products.find(all_prod => {
            return all_prod.id == prod.product_id;
         });

         if (product === undefined) {
            return;
         }

         var campaign_id = campaign.find(camp => {
            return camp.campaign_product_id == product.id;
         });

         if (campaign_id === undefined) {
            return;
         }

         var id = product.custom_attributes.find(attr => {
            return attr.attribute_code == 'category_ids';
         });
         //Error here

         if (id === undefined) {
            return;
         }

         console.log(id);

         let temp_var = null;

         id.value.forEach((temp, index) => {
            if (index == 0) {
               return;
            }
            ids.forEach(cat_id => {
               if (cat_id.id == temp) {
                  temp_var = cat_id;
                  return;
               }
            });
         });

         temp_var = ids.find(cat_id => {
            // return cat_id.id == id.value[1]
            return cat_id.id == id.value[0];
         });

         console.log('id', temp_var);

         console.log(prod);
         let count = await GetCampaignCount(prod.product_id, store.auth.token);

         if (count == false) {
            return;
         }

         let res = await dispatch(ParticipateInCampaign(temp_var.name.toLowerCase(), campaign_id.campaign_id, count));

         if (res.awarded.length !== 0) {
            res.awarded.forEach(award => {
               awarded.push({ message: award.message, img: award.reward.imageUrl });
            });
         }

         if (index === items.length - 1) {
            console.log(awarded);
            dispatch(SetRewards(awarded));
            //await dispatch(ViewMamboProfile())
         }
      });

      await dispatch(CreatePointsParticipation());
   };

   const HandleReferer = async () => {
      var orders = await GetPreviousOrders(get(store, 'auth.token', null));

      if (orders.items.length - 1 == 0) {
         var refer_id = await UserData(get(store, 'auth.token'), null);
         if (refer_id.custom_attributes !== undefined) {
            refer_id = refer_id.custom_attributes.find(attr => attr.attribute_code == 'refer_id');
            console.log(refer_id);
            if (refer_id == null) {
               return;
            } else {
               await ReferAFriend(store.auth.userinfo.id, refer_id.value);
            }
         }
      }
   };

   const orderAddressSaved = () => {
      // alert(currencyconvertrate(localStorage.getItem('subtotoal'), store))
      // alert(delivery_amount)
      console.log(delivery_amount);
      let valueamout =
         currencyconvertrate(localStorage.getItem('subtotoal'), store) +
            delivery_amount -
            currencyconvertrate(localStorage.getItem('orderAmount'), store) <=
         0;
      // alert(Math.round(totalAmount) +
      //    delivery_amount -
      //    currencyconvertrate(localStorage.getItem('orderAmount'), store) <=
      //    0)
      let methpod = 'ngeniusonline';
      if (valueamout == true || valueamout == 'true') {
         methpod = 'free';
      }
      // alert(methpod)
      let address = null;
      console.log(data.selectedid);
      console.log(store.cart.customerData);

      if (locaton === 'self') {
         address = {
            street: ['The Onyx 1 Tower - office 703,The Greens'],
            city: 'Dubai, UAE',
            // firstname: store.cart.customerData[0]['firstname'],
            // lastname: store.cart.customerData[0]['lastname'],
            // telephone: store.cart.customerData[0]['telephone'],
            firstname: store.auth.userinfo?.firstname,
            lastname: store.auth.userinfo?.lastname,
            telephone: store.auth.userinfo?.phone_number,
         };
      } else if (data.selectedid != null) {
         //
         if (data.selectedid == 100) {
            address = store.cart.customerData[0];
         } else {
            address = store.cart.customerData.find(item => item.id === data.selectedid);
         }
         console.log(address);
      } else {
         address = {
            street: ['', '', ''],
            city: '',
            firstname: '',
            lastname: '',
            email: '',
            telephone: '',
         };
      }

      return {
         paymentmethod: methpod,
         country_id: 'AE',
         street: address?.street,
         city: address?.city,
         firstname: address?.firstname,
         lastname: address?.lastname,
         email: store.auth.userinfo?.email,
         telephone: address?.telephone,
      };
   };

   const orderNewAddress = () => {
      return {
         paymentmethod: totalAmount + delivery_amount === 0 ? 'free' : 'ngeniusonline',
         country_id: 'AE',
         street: props.location?.data?.street,
         city: props.location?.data?.city,
         firstname: props.location?.data?.firstname,
         lastname: props.location?.data?.lastname,
         email: store.auth?.userinfo?.email,
         telephone: props.location?.data?.telephone,
      };
   };

   //alert(window.frames['live-agent-container'].location)
   const OrderPlaced1 = async paymentmeething => {
      console.log(paymentmeething);
      var koinzforprod = [];

      koinz_replacement.forEach(item => {
         var prod = cart.find(prod => {
            return item == prod.item_id;
         });
         koinzforprod.push(prod.sku);
      });

      if (koinzforprod.length !== 0) {
         await isKoinz(koinzforprod, paymentmeething);
      }

      HandleReplaceWithKoinz(true)
         .then(response => {
            // koinzAmount(response, paymentmeething);
            GetOrderData('zs667jaikfh4rb34vfqv49k86vb7xcq7', paymentmeething).then(async data => {
               console.log('items  GetOrderDetails', data);

               let isMamboSuccess = 0;
               let email_send = 0;
               const isOrderSuccess = isOrderSuccessfull(data?.status);
               if (isOrderSuccess) {
                  isMamboSuccess = 1;
                  email_send = 1;
                  if (useKoinz) {
                     //Fix Required
                     if (koinz.points[0].balance / 5 > Gtotal) {
                        await dispatch(CreatePointsActivity(-(Gtotal * 5)));
                     } else {
                        await dispatch(CreatePointsActivity(-koinz.points[0].balance));
                     }
                  }

                  await HandleReferer();

                  await HandleReplaceWithKoinz();

                  await ParticipateinCampaign(paymentmeething);

                  dispatch(EmptyKoinzProduct());
               }
               koinzAmount(response, paymentmeething, koinz?.points[0]?.pointId, isMamboSuccess, email_send);
            });
         })
         .catch(error => {
            console.log(error);
         });

      // if (useKoinz) {
      //    //Fix Required
      //    if (koinz.points[0].balance / 5 > Gtotal) {
      //       await dispatch(CreatePointsActivity(-(Gtotal * 5)));
      //    } else {
      //       await dispatch(CreatePointsActivity(-koinz.points[0].balance));
      //    }
      // }

      // await HandleReferer();

      // await HandleReplaceWithKoinz();

      // await ParticipateinCampaign(paymentmeething);

      // dispatch(EmptyKoinzProduct());
      //setshow(false);
      setshow(false);
      console.log('Normal flow');
      props.history.push({
         pathname: '/orderdetails',

         props: { showdate: showdate, pickupa: pickupa },
      });

      dispatch({ type: ORDERID, payload: paymentmeething });
   };
   const OrderPlaced = async (type = 'payment', cardEntityId = '') => {
      // alert("placing")
      setshow(true);
      // if (type == 'payment') {
      //    var url = await dispatch(PayByCard())
      //    setshow(false)
      //    if (url != false) {
      //       setshowurl({
      //          show: true,
      //          url: url
      //       })
      //    }
      //    return
      // }
      // setshow(true)
      // const address = true //props.location.from === "checkoutdetails"
      //    ? orderAddressSaved()
      //    : orderNewAddress();
      const address = orderAddressSaved();
      setData(address);
      if (address.street.length !== 0) {
         let sessionId,
            entity_id,
            saveCardDetails = false;
         //  alert(cardEntityId)
         if (cardEntityId) {
            entity_id = cardEntityId;
         }
         let res;
         let orderAmount = localStorage.getItem('orderAmount');
         let base64ToString = await dispatch(applePay(orderAmount, store.account.currency, Date.now()));
         // const res  = await dispatch(PaymentInfo(address, { sessionId, entity_id }));
         // let [response, error] = await dispatch(PaymentInfo(address, { sessionId, entity_id, saveCardDetails }));
         ReactPixel.track('InitiateCheckout');
         let paymentRes = await dispatch(
            PaymentInfo(address, { sessionId, entity_id, saveCardDetails, orderAmount, pgOrderId: base64ToString._id })
         );
         let { TermUrl, acsMd, acsPaReq, acsUrl, error, order_id } = JSON.parse(paymentRes);
         console.log(TermUrl);

         if (TermUrl && acsMd && acsPaReq && acsUrl && order_id) {
            setThreeDCardData({
               // TermUrl: props.Environment.app_domain_url + '/' + TermUrl + '?orderId=' + order_id,
               TermUrl: props.Environment.app_domain_url + '/' + TermUrl,
               acsMd,
               acsPaReq,
               acsUrl,
               order_id: order_id,
            });
            setShowThreeDAuth(true);
            // setshow(false);
            document.getElementById('3d_form').submit();
            // return;
         }
         console.log(error);
         if (error) {
            // setDisplayLoader(false);
            //dispatch(handlePaymentFail())
            setshow(false);
            setCardError(error);
            return;
         } else {
            localStorage.setItem('orderidvalue', order_id);
            res = order_id;
         }
         // const res = await dispatch(PaymentInfo(address,cardEntityId));

         //call mambo method after successfull order placement
         if (res !== false) {
            var koinzforprod = [];

            koinz_replacement.forEach(item => {
               var prod = cart.find(prod => {
                  return item == prod.item_id;
               });
               koinzforprod.push(prod.sku);
            });

            if (koinzforprod.length !== 0) {
               await isKoinz(koinzforprod, res);
            }

            HandleReplaceWithKoinz(true)
               .then(response => {
                  // koinzAmount(response, res);
                  GetOrderData('zs667jaikfh4rb34vfqv49k86vb7xcq7', order_id).then(async data => {
                     console.log('items  GetOrderDetails', data);

                     let isMamboSuccess = 0;
                     let email_send = 0;
                     const isOrderSuccess = isOrderSuccessfull(data?.status);
                     if (isOrderSuccess) {
                        isMamboSuccess = 1;
                        email_send = 1;
                        if (useKoinz) {
                           //Fix Required
                           if (koinz.points[0].balance / 5 > Gtotal) {
                              await dispatch(CreatePointsActivity(-(Gtotal * 5)));
                           } else {
                              await dispatch(CreatePointsActivity(-koinz.points[0].balance));
                           }
                        }

                        await HandleReferer();

                        await HandleReplaceWithKoinz();

                        await ParticipateinCampaign(res);

                        dispatch(EmptyKoinzProduct());
                     }
                     koinzAmount(response, order_id, koinz?.points[0]?.pointId, isMamboSuccess, email_send);
                     setshow(false);
                  });
               })
               .catch(error => {
                  console.log(error);
               });

            // if (useKoinz) {
            //    //Fix Required
            //    if (koinz.points[0].balance / 5 > Gtotal) {
            //       await dispatch(CreatePointsActivity(-(Gtotal * 5)));
            //    } else {
            //       await dispatch(CreatePointsActivity(-koinz.points[0].balance));
            //    }
            // }

            // await HandleReferer();

            // await HandleReplaceWithKoinz();

            // await ParticipateinCampaign(res);

            // dispatch(EmptyKoinzProduct());

            //props.history.push('/orderdetails');

            // props.history.push({
            //    pathname: '/orderdetails',
            //    props: { showdate: showdate, pickupa: pickupa },
            // });
            if (TermUrl && acsMd && acsPaReq && acsUrl && order_id) {
               // setshow(false);
               document.getElementById('3d_form').submit();
               return;
            } else {
               console.log('Normal flow');
               props.history.push({
                  pathname: '/orderdetails',

                  props: { showdate: showdate, pickupa: pickupa },
               });
            }

            dispatch({ type: ORDERID, payload: res });
         }
         setshow(false);
         // props.history.push('/orderdetails');
      }
      setshow(false);
   };

   const payNowHandler = async event => {
      event.stopPropagation();
      // totalAmount = Math.round(totalAmount);
      // alert(delivery_amount)

      let totalAmount_new = Math.round(totalAmount);
      const orderAmount = totalAmount_new + delivery_amount;
      console.log(' totalAmount_new ', totalAmount_new);
      console.log(' orderAmount ', orderAmount);

      dispatch(SetOrderAmount(totalAmount_new + delivery_amount));
      await AddShippingAddress();

      if (
         totalAmount_new + delivery_amount == 0 ||
         (isApplpayeSelected || koinz_replacement.length == cart.length) & (selected2 !== false)
      ) {
         console.log('here');
         dispatch(SetOrderAmount(0));
         // localStorage.setItem('orderAmount', 0);
         await AddShippingAddress();
      }
   };

   const onPopupClose = () => {
      dispatch(handlePaymentFail());
      chooseErrorPopup(errorPopup).onClose();
      props.history.push('/');
   };

   const applyPromoCode = async () => {
      const promoCode = promoCodeDetails?.promoCode;
      if (promoCode.length >= 7) {
         const { success, data, error } = await dispatch(ApplyPromoCode(promoCode));
         // if (!res) {
         //    setErrorPopup('error');
         // }
         if (success) {
            // let address = mapCustAddressDetails(userDetail, checkoutState?.deliveryAddress);

            const address = orderAddressSaved();
            const postData = {
               paymentMethod: { method: 'ngeniusonline' },
               billing_address: address,
               // promoCode,
            };
            console.log('address in promoCodeApplyHandler =>', address);
            dispatch(GetAppliedPromoCodeCodeInfo(postData));
            // GetAppliedPromoCodeCodeInfo()
         } else {
            setErrorPopup('error');
         }
         // await ApplyPromoCode(promoCode);
      }
   };

   const getFinalOrderAmount = () => {
      let amount = 0;
      const subTotal = currencyconvertrate(localStorage.getItem('subtotoal'), store);
      const orderAmount = currencyconvertrate(localStorage.getItem('orderAmount'), store);
      const delivery = currencyconvertrate(delivery_amount, store);
      const promoCodeDiscount = isNaN(promoCodeInfo?.base_discount_amount)
         ? 0
         : Math.abs(promoCodeInfo?.base_discount_amount);

      amount =
         subTotal + delivery - promoCodeDiscount - orderAmount > 0
            ? subTotal + delivery - promoCodeDiscount - orderAmount
            : 0;
      return amount;
   };

   //ends here
   // console.log(show)
   // console.log(currencyconvertrate(localStorage.getItem('subtotoal'), store));
   // console.log(currencyconvertrate(delivery_amount, store));
   // console.log(currencyconvertrate(localStorage.getItem('orderAmount'), store));
   return (
      <div id="drawtnc-page-wrapper" className="checkout-details-container">
         {errorPopup !== 'none' && (
            <PopUps
               type="error"
               errorType={errorPopup}
               errorMsg={error_redux}
               onSubmit={() => chooseErrorPopup(errorPopup).onSubmit()}
               onClose={onPopupClose}
            />
         )}
         <NavBar checkout={true} headertitle="Checkout" />
         <div class="desktop-header">{props.Environment.chec}</div>
         <CircularLoader show={show}></CircularLoader>
         {showpayment ? (
            <ActionSheetPayment
               {...props}
               threeDCardData={threeDCardData}
               showThreeDAuth={showThreeDAuth}
               cardError={cardError}
               selectedCardhandler={card => OrderPlaced('payment', card?.entity_id)}
               merchantOrderReference={Date.now()}
               setLoader={bool => {
                  console.log('hi');
                  console.log(show);
                  setshow(bool);
               }}
               setShow={bool => {
                  setshowpayment(bool);
               }}
               setSelected={bool => {
                  setselected2(bool);
               }}
               showdate={showdate}
               totalamout={currencyconvertrate(delivery_amount + totalAmount, store)}
               currency_code={store.account.currency}
               pickupa={pickupa}
            />
         ) : null}
         <div className="two-column-container">
            <div className="yourdetails-container">
               {showurl.show ? (
                  <iframe
                     src={showurl.url}
                     onLoad={async () => {
                        if (document.getElementById('myframe').contentDocument != null) {
                           if (
                              document.getElementById('myframe').contentDocument.querySelector('.order-cancel') != null
                           ) {
                              props.history.push('/ordercancel');
                           } else if (
                              document.getElementById('myframe').contentDocument.querySelector('.order-cancel') != null
                           ) {
                              props.history.push('/ordercancel');
                           } else {
                              setshowurl({
                                 show: false,
                                 url: null,
                              });
                              await OrderPlaced('success');
                              //await OrderPlaced('payment', savedCards ?.entity_id)
                           }
                        }
                     }}
                     title="Order Payment"
                     height="100%"
                     width="100%"
                     name="live-agent-container"
                     id="myframe"
                     className="frame"
                  />
               ) : null}
               <div className="forms-main">
                  {/* {koinz_replacement.length !== cart.length || store.cart.customerData.length == 0 ? ( */}
                  <div className="form-screen-details">
                     <h4>{props.Environment.Shipping}</h4>
                     <h6>{props.Environment.delivertxt}</h6>
                  </div>
                  {/* ) : null} */}

                  <div className={`forms-cards ${!selected1 ? 'required-box' : ''}`}>
                     <div className="floating-form">
                        <div className={`address-label ${selected1 ? 'active-txt' : ''}`}>{props.Environment.cda}</div>
                        {store.cart.customerData.map((data, i) => {
                           return (
                              <div
                                 className="saved-address-list-container"
                                 key={i}
                                 onClick={() => {
                                    setadd(i);
                                 }}
                              >
                                 <input
                                    type="radio"
                                    name="addresslist"
                                    className="saved-cards-list"
                                    checked={selectedadd == i ? true : false}
                                 />
                                 <label
                                    className="card-number-label"
                                    htmlFor={data.id}
                                    onClick={() => {
                                       //console.log(data)
                                       storedAddress(data.id);
                                    }}
                                 >
                                    <div className="address-list">
                                       <img src={`${props.Environment.img_path}icons/ic-location.svg`} alt="" />
                                       <div className="address-list-details">
                                          <span className="address-type-label">{props.Environment.office}</span>
                                          <br />
                                          <span className="address-deatils">{data.street.join(', ')}</span>
                                       </div>
                                       <div className="checked-img">
                                          <div></div>
                                       </div>
                                       {/* <span className="circle"></span> */}
                                    </div>
                                 </label>
                              </div>
                           );
                        })}
                        <div
                           className="add-new-address"
                           onClick={() => {
                              props.history.push('/addaddress_checkout');
                           }}
                        >
                           <img src={`${props.Environment.img_path}icons/ic_plus.svg`} alt="" />
                           <span className="add-address">{props.Environment.add_address}</span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="second-srecenn yourdetails-container">
               {currencyconvertrate(localStorage.getItem('subtotoal'), store) +
                  delivery_amount -
                  currencyconvertrate(localStorage.getItem('orderAmount'), store) >
                  0 && (
                  <div className="paynow-sectionsds">
                     <>
                        <div className="form-screen-details">
                           <h4>{props.Environment.payment}</h4>
                           <h6>{props.Environment.paytxt}</h6>
                        </div>
                        <div className={`forms-cards ${!selected2 ? 'required-box htfs' : ''}`}>
                           <div className={`address-label ${selected2 ? 'active-txt' : ''}`}>
                              {props.Environment.saved_cards}
                           </div>
                           <div style={{}}>
                              {/* {promoCodeDetails?.promoCode} */}

                              {/* {promoCodeInfo ? (
                                 <label>{promoCodeDetails?.promoCode}</label>
                              ) : ( */}
                              <input
                                 disabled={promoCodeInfo}
                                 type="text"
                                 name="cardlist"
                                 className="promoCodeInput"
                                 placeholder="Promo code"
                                 maxLength="7"
                                 value={promoCodeDetails?.promoCode}
                                 onChange={e => {
                                    setPromoCodeDetails({ ...promoCodeDetails, promoCode: e.target.value });
                                 }}
                              />
                              {/* )} */}
                              {promoCodeInfo ? (
                                 <button className=" promocodeBtn disabled" id="apply" type="button" disabled={true}>
                                    {'Applied'}
                                 </button>
                              ) : (
                                 <button
                                    className={`promocodeBtn ${
                                       promoCodeDetails?.promoCode.length < 7 ? 'disabled' : ''
                                    }`}
                                    id="applied"
                                    type="button"
                                    disabled={promoCodeDetails?.promoCode.length < 7}
                                    onClick={applyPromoCode}
                                 >
                                    {'Apply'}
                                 </button>
                              )}
                           </div>

                           {savedCards.map(card => (
                              <div key={card.entity_id} className="saved-cards-list-container savd-ca">
                                 <input
                                    type="radio"
                                    name="cardlist"
                                    id={`newcard-${card.entity_id}`}
                                    value={card.entity_id}
                                    onChange={() => {
                                       setSelectedCard(card);
                                       setIsApplpayeSelected(false);
                                       setapplepaybutton(false);
                                       setsamplebutton(true);
                                       setIsPayNowDisabled(false);
                                       ReactPixel.track('AddPaymentInfo');
                                       // setselected2(false);
                                       // setselected1(false);
                                       // setshowpayment(true);
                                    }}
                                 />
                                 <label className="card-number-label" htmlFor={`newcard-${card.entity_id}`}>
                                    <div className="card-container-main">
                                       <div className="card-container">
                                          <span className="card-number card-number-masked">{card?.masked_pan}</span>
                                          <span className="card-scheme">{card?.scheme}</span>
                                       </div>

                                       <span
                                          className={`circle  ${
                                             card.entity_id === selectedCard?.entity_id ? 'active' : ''
                                          } ${isNil(selectedCard) ? 'required-circle' : ''}`}
                                       >
                                          <div></div>
                                       </span>
                                    </div>
                                 </label>
                              </div>
                           ))}
                           {/* <div className="saved-cards-list-container">
                        <input
                           type="radio"
                           name="cardlist"
                           className="saved-cards-list"
                           id="newcard"
                           onClick={() => {
                              setselected2('1');
                              setSelectedCard(null);
                           }}
                        />
                        <label className="card-number-label" htmlFor="newcard">
                           <div className="card-container-main">
                              <div className="card-container">
                                 <img src={`${props.Environment.img_path}icons/ic-visa.svg`} alt="" />
                                 <span className="card-number">{props.Environment.card_number}</span>
                              </div>
                              <span
                                 className={`circle required-circle ${selected2 === '1' ? 'active' : ''} ${
                                    selected2 === false ? 'required-circle' : ''
                                 }`}
                              >
                                 <div></div>
                              </span>
                           </div>
                        </label>
                     </div> */}
                           {/* {iosdevice == true && (
                                    <div className="saved-cards-list-container">
                                       <input
                                          type="radio"
                                          name="cardlist"
                                          className="saved-cards-list"
                                          id="newcarda"
                                          onClick={() => {
                                             setIsApplpayeSelected(true);
                                             setSelectedCard(null);
                                             setapplepaybutton(true);
                                             setsamplebutton(false);
                                             setIsPayNowDisabled(false);
                                          }}
                                       />
                                       <label className="card-number-label" htmlFor="newcarda">
                                          <div className="card-container-main">
                                             <img src={`${props.Environment.img_path}icons/ic_apple_pay.png`} alt="" />

                                             <span
                                                className={`circle  ${isApplpayeSelected ? 'active' : ''} ${
                                                   isApplpayeSelected === false ? 'required-circle' : ''
                                                   }`}
                                             >
                                                <div></div>
                                             </span>
                                          </div>
                                       </label>
                                    </div>
                                 )} */}
                           {/* {iosdevice == false && (
                                 <div className="saved-cards-list-container">
                                    <input
                                       type="radio"
                                       name="cardlist"
                                       className="saved-cards-list"
                                       id="newcarda"
                                       onClick={() => {
                                          setIsApplpayeSelected(true);
                                          setSelectedCard(null);
                                          setapplepaybutton(true);
                                          setsamplebutton(false);
                                          setIsPayNowDisabled(false);
                                       }}
                                    />
                                    <label className="card-number-label" htmlFor="newcarda">
                                       <div className="card-container-main">
                                          <img
                                             className="samsungpady"
                                             src={`${props.Environment.img_path}icons/sumsungpay.png`}
                                             alt=""
                                          />

                                          <span
                                             className={`circle  ${isApplpayeSelected ? 'active' : ''} ${
                                                isApplpayeSelected === false ? 'required-circle' : ''
                                                }`}
                                          >
                                             <div></div>
                                          </span>
                                       </div>
                                    </label>
                                 </div>
                              )} */}

                           <div
                              // className="add-new-address"
                              className={`add-new-address ${selected1 ? false : true ? 'disabledcardclick' : ''}`}
                              //  onClick={() => props.history.push('/addcards_checkout')}

                              onClick={() => {
                                 // let orderAmount = localStorage.getItem('orderAmount');
                                 // let finalOrderAmount = localStorage.getItem('finalOrderAmount');
                                 // console.log('Order Amount =', orderAmount, finalOrderAmount);
                                 // localStorage.setItem('orderAmount', finalOrderAmount);

                                 let finalOrderAmount =
                                    JSON.stringify(
                                       currencyconvertrate(localStorage.getItem('subtotoal'), store) +
                                          currencyconvertrate(delivery_amount, store)
                                    ) - currencyconvertrate(localStorage.getItem('orderAmount'), store);
                                 localStorage.setItem('finalOrderAmount', finalOrderAmount);
                                 props.history.push({
                                    pathname: '/addcards_checkout',
                                    props: { showdate: showdate, pickupa: pickupa },
                                 });
                              }}
                           >
                              <img src={`${props.Environment.img_path}icons/ic_plus.svg`} alt="" />
                              <span className="add-address">{props.Environment.add_card}</span>
                           </div>
                        </div>
                     </>
                  </div>
               )}

               {/* <p className="your-d">{props.Environment.yorders}</p> */}
               {/* {koinz_replacement.length !== cart.length || store.cart.customerData.length == 0 ? (
                     <div className="payreview-ttext">
                        <h4>{locaton == 'self' ? null : props.Environment.pyourorder}</h4>
                        <h6>{locaton == 'self' ? null : props.Environment.wheresdeliver}</h6>
                     </div>
                  ) : null} */}
               <div className="pay-review pay-eev">
                  <div className="cart-container">
                     {store.cart.cart.map(item => {
                        return (
                           <CheckoutCart
                              {...props}
                              num={true}
                              type="payreview"
                              item={item}
                              setAmount={amount => {
                                 setTotalAmount(totalAmount + amount);
                              }}
                           />
                        );
                     })}
                  </div>
               </div>
               {/* ends here -------> */}
               {/* <Button
                     className={`iosfit button-red`}
                     data={'Apple Pay'}
                     onClick={applePay}
                  /> */}

               <div className="po0now">
                  <div className="cart-amount-container">
                     <div className="cart-subtotal-container spce11 checkout-cart-subtitle">
                        <div className="cart-subtotal">
                           {props.Environment.subtotal}
                           <span>
                              {currencyconvertrate(localStorage.getItem('subtotoal'), store)} {store.account.currency}
                           </span>
                        </div>
                        <div className="cart-subtotal">
                           {props.Environment.delivery}
                           <span>
                              {currencyconvertrate(delivery_amount, store)} {store.account.currency}
                           </span>
                        </div>
                        {promoCodeInfo ? (
                           <div className="cart-subtotal">
                              Promo Code Discount
                              <span>{Math.abs(promoCodeInfo?.base_discount_amount)} AED</span>
                           </div>
                        ) : null}
                        <div className="cart-subtotal">
                           {props.Environment.koinz}
                           <span>
                              - {currencyconvertrate(localStorage.getItem('orderAmount'), store)}{' '}
                              {store.account.currency}
                           </span>
                        </div>
                        <div className="cart-subtotal vatamoun vatamoun1">
                           <span className="vatr1">{props.Environment.vate1} </span>
                           <span>
                              {getFinalOrderAmount()} {store.account.currency}
                           </span>
                        </div>
                     </div>
                  </div>

                  {applepaybutton ? (
                     <div>
                        {/* {iosdevice == true && (
                              <Button
                                 className={`apple-pays iosfit button-red dsds ${
                                    (selected1) ? false : true ? 'disabled' : ''
                                    }`}
                                 data={'Pay Now'}

                                 onClick={() => {
                                    applepaymentod(userinfo);
                                 }}
                              />
                           )} */}
                        {/* {iosdevice == false && (
                           <Button
                              // disabled={selected1 ? false : true}
                              className={`iosfit button-red ${
                                 (selected1) ? false : true ? 'disabled' : ''
                                 }`}
                              // className={`apple-pays iosfit button-red dsds`}
                              data={'Pay Now'}
                              onClick={() => {
                                 samsungpaymentod(userinfo);
                              }}
                           />
                        )} */}
                     </div>
                  ) : (
                     <div>
                        {selected1 ? (
                           <Button
                              className={`iosfit button-red dss1 ${
                                 JSON.stringify(
                                    currencyconvertrate(localStorage.getItem('subtotoal'), store) +
                                       currencyconvertrate(delivery_amount, store)
                                 ) -
                                    currencyconvertrate(localStorage.getItem('orderAmount'), store) ==
                                 0
                                    ? false
                                    : isPayNowDisabled
                                    ? 'disabled'
                                    : ''
                              }`}
                              data={
                                 isApplpayeSelected ||
                                 Math.round(totalAmount) +
                                    delivery_amount -
                                    currencyconvertrate(localStorage.getItem('orderAmount'), store) <=
                                    0
                                    ? props.Environment.porder
                                    : props.Environment.pnow
                              }
                              onClick={payNowHandler}
                              disabled={ JSON.stringify(
                                    currencyconvertrate(localStorage.getItem('subtotoal'), store) +
                                       currencyconvertrate(delivery_amount, store)
                                 ) -
                                    currencyconvertrate(localStorage.getItem('orderAmount'), store) ==
                                 0
                                    ? false
                                    : isPayNowDisabled
                                    ? true
                                    : false}
                           />
                        ) : (
                           <Button
                              className={`iosfit button-red dss2 ${selected1 ? false : true ? 'disabled' : ''}`}
                              data={
                                 isApplpayeSelected ||
                                 Math.round(totalAmount) +
                                    delivery_amount -
                                    currencyconvertrate(localStorage.getItem('orderAmount'), store) <=
                                    0
                                    ? props.Environment.porder
                                    : props.Environment.pnow
                              }
                              onClick={payNowHandler}
                           />
                        )}
                     </div>

                     // <Button
                     //    // disabled={selected1 ? false : true}
                     //    // disabled={
                     //    //    Math.round(totalAmount) +
                     //    //       delivery_amount -
                     //    //       currencyconvertrate(localStorage.getItem('orderAmount'), store) <=
                     //    //       0
                     //    //       ? false
                     //    //       : isPayNowDisabled
                     //    // }
                     //    className={`iosfit button-red ${
                     //       Math.round(totalAmount) +
                     //          delivery_amount -
                     //          currencyconvertrate(localStorage.getItem('orderAmount'), store) <=
                     //          0
                     //          ? false
                     //          : isPayNowDisabled
                     //             ? 'disabled'
                     //             : ''
                     //       }`}
                     //    data={
                     //       // Math.round(totalAmount) + delivery_amount == 0 ?
                     //       isApplpayeSelected ||
                     //          Math.round(totalAmount) +
                     //          delivery_amount -
                     //          currencyconvertrate(localStorage.getItem('orderAmount'), store) <=
                     //          0
                     //          ? props.Environment.porder
                     //          : props.Environment.pnow
                     //    }
                     //    onClick={payNowHandler}
                     // />
                  )}
                  <form
                     style={{ display: 'none' }}
                     class="form-inline needs-validation"
                     id="3d_form"
                     action={threeDCardData?.acsUrl}
                     method="POST"
                     enctype="application/x-www-form-urlencoded"
                  >
                     <input
                        class="form-control"
                        type="hidden"
                        id="PaReq"
                        name="PaReq"
                        value={threeDCardData?.acsPaReq}
                     />
                     <input
                        class="form-control"
                        type="hidden"
                        id="TermUrl"
                        name="TermUrl"
                        // value={threeDCardData?.TermUrl}
                        value={
                           props.Environment.app_domain_url +
                           '/pay.php?orderId=' +
                           threeDCardData?.order_id +
                           '&saveCard=false' +
                           '&viewMode=desktop'
                        }
                        // value={props.Environment.app_domain_url + '/orderdetails/' + threeDCardData?.order_id}
                     />
                     <input class="form-control" type="hidden" id="MD" name="MD" value={threeDCardData?.acsMd} />

                     <div className="">
                        {/* <Button
           className="button-red"
           disabled="Authorize"
           data={props.Environment.makePayment}
           type="submit"
        /> */}
                        <div style={{ display: 'none' }} className="button-container">
                           <div>
                              <button className="button-red" id="authorize-three-d" type="submit">
                                 {props.Environment.authenticateCard}
                              </button>
                           </div>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   );
};

export default CheckoutDetails;
