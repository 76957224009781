import React, { useEffect, useState } from 'react';
import { Environment } from "../../env"
import { Environment as en } from '../../env';
import { Environment as ar } from '../../env_ar';
// import Button from "../Button/Button";
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';


const NavBar = props => {
   var URL1 = window.location.href;
   var tabname1 = URL1.split("/").pop();

   const history = useHistory();
   const store = useSelector(store => store);
   const [file, setfile] = useState(en)
   const [needback, setneedback] = useState(false);
   const [count, setcount] = useState(0)

   useEffect(() => {

      let c = 0;
      get(store, 'home.notificationlisting', []).forEach(item => {
         if (item.is_read == "0") {
            c++;
         }
      })

      setcount(c)

   }, [get(store, 'home.notificationlisting', [])])


   useEffect(() => {
      console.log("setneedback" + needback);
      console.log("URL1" + URL1);
      // if (tabname1 == "checkoutdetails"){
      //    props.history.push('/cart');
      // }
      if (tabname1 == "watchlist" || tabname1 == "tickets" || tabname1 == "cart"
         || tabname1 == "signup" || tabname1 == "account" || tabname1 == "mykoinz" || tabname1 == "mykoinz") {
         console.log("Inside")
         setneedback(true);
      }
      else {
         setneedback(false);
      }
      if (get(store, 'home.language', 'en') == "en") {
         setfile(en)
      }
      else {
         setfile(ar)
      }
   }, [get(store, 'home.language', 'en')])

   // useEffect(() => {
   //    var scrollPosition = window.scrollY;
   //    var logoContainer = document.getElementsByClassName('navbar-container')[0];

   //    window.addEventListener('scroll', function () {
   //       scrollPosition = window.scrollY;
   //       if (tabname1.includes("myhistory")) {
   //          if (scrollPosition >= 30) {
   //             logoContainer.classList.add('header-bg-added');
   //             logoContainer.classList.add('header-bg-added1');
   //          } else {
   //             logoContainer.classList.remove('header-bg-added');
   //             logoContainer.classList.remove('header-bg-added1');
   //          }
   //       }
   //       else {
   //          if (scrollPosition >= 30) {
   //             logoContainer.classList.add('header-bg-added');
   //          } else {
   //             logoContainer.classList.remove('header-bg-added');
   //          }
   //       }

   //    });

   //    (function () {
   //       var doc = document.documentElement;
   //       var w = window;

   //       var prevScroll = w.scrollY || doc.scrollTop;
   //       var curScroll;
   //       var direction = 0;
   //       var prevDirection = 0;

   //       var header = document.getElementById('app-header');

   //       var checkScroll = function () {
   //          /*
   //           ** Find the direction of scroll
   //           ** 0 - initial, 1 - up, 2 - down
   //           */

   //          curScroll = w.scrollY || doc.scrollTop;
   //          if (curScroll > prevScroll) {
   //             //scrolled up
   //             direction = 2;
   //          } else if (curScroll < prevScroll) {
   //             //scrolled down
   //             direction = 1;
   //          }
   //          if (direction !== prevDirection) {
   //             toggleHeader(direction, curScroll);
   //          }
   //          prevScroll = curScroll;
   //       };

   //       var toggleHeader = function (direction, curScroll) {
   //          if (tabname1.includes('myhistory')) {
   //             if (direction === 2 && curScroll > 52) {
   //                //replace 52 with the height of your header in px

   //                //header.classList.add('hide');

   //                prevDirection = direction;
   //             } else if (direction === 1) {
   //                // header.classList.remove('hide');
   //                prevDirection = direction;
   //             }
   //          } else {
   //             if (direction === 2 && curScroll > 52) {
   //                //replace 52 with the height of your header in px

   //                header.classList.add('hide');
   //                prevDirection = direction;
   //             } else if (direction === 1) {
   //                header.classList.remove('hide');
   //                prevDirection = direction;
   //             }
   //          }

   //       };

   //       window.addEventListener('scroll', checkScroll);
   //    })();
   // });

   return (
      // id = "app-header"
      <div className="navbar-container" id={props.homeheader} >
      

         {needback && (
            <div>
               <span className="needback site-e" onClick={() => (history.goBack())}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </span>
               <span className="needback site-a dds" onClick={() => (history.goBack())}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </span>
            </div>
         )}

         {props.signup && (
            <div className="fickfix">
               <div className="navbar-title">{props.signup}</div>
            </div>
         )}
         {/* <div className="navbar-title">{props.headertitle}</div> */}

         {/* home header */}
         {props.homeheader && (
            <div className="home-header">
               <div className="home-logo">
                  <img src={`${file.img_path}images/kanzi-logo-white.svg`} alt="kanzi-logo" />
               </div>
               <div className="home-ic-right">
                  {(store.auth.token != null && count != 0) ? <span className="count">
                     {count}
                  </span> : null}
                  {/* <Link to={'/notifications'}>
                     <img src={`${file.img_path}makeicons/ic-notifications.svg`} alt="" />
                  </Link> */}
                  <span onClick={() => {
                     if (store.auth.token == null) {
                        return history.push("/account/login")
                     }
                     else {
                        return history.push("/notifications")
                     }


                  }}>
                     <img src={`${file.img_path}makeicons/ic-notifications.svg`} alt="" />
                  </span>
               </div>
            </div>
         )}


         {props.carthader && (
            <div className="fickfix">
               <div className="navbar-title site-e">Cart</div>
               <div className="navbar-title site-a">العربة</div>
               <div className="navbar-subtitle site-e">You have {props.qty} item(s) on your cart</div>
               <div className="navbar-subtitle site-a">لديك {props.qty} منتج في عربة التسوق الخاصة بك</div>
            </div>
         )}

         {props.resetpass && (
            <div className="payreview-header">
               <Link to={'/menu'}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </Link>
               <div className="navbar-title">Reset password</div>
            </div>
         )}



         {props.watchlist && (
            <div className="fickfix">
               <div className="navbar-title site-e">Favorites</div>
               <div className="navbar-title site-a">قائمتي المفضلة</div>
               <div className="navbar-subtitle site-e">Track your favorite campaigns!</div>
               <div className="navbar-subtitle site-a">تتبع حملاتك المفضلة!</div>
            </div>
         )}

         {props.heropath && (
            <div className="payreview-header">
               <span onClick={() => history.goBack()}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </span>
               <div className="navbar-title site-e">Hero's Path</div>
               <div className="navbar-title site-a">طريق الأبطال</div>
            </div>
         )}

         {props.badgeinfo && (
            <div className="payreview-header">
               <span onClick={() => history.goBack()}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </span>
               <div className="navbar-title">{props.title}</div>
            </div>
         )}

         {props.koinzdetails && (
            <div className="payreview-header">
               <span onClick={() => history.goBack()}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </span>
               <div className="navbar-title site-e">My Koinz Details</div>
               <div className="navbar-title site-a">تفاصيل الكوينز الخاصة بي</div>
            </div>
         )}

         {props.payreview && (
            <div className="payreview-header">
               <span onClick={() => history.goBack()}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </span>
               <div className="navbar-title">Pay &amp; Review</div>
            </div>
         )}

         {props.ticketheader && (
            <div className="fickfix">
               <div className="navbar-title site-e">Tickets</div>
               <div className="navbar-title site-a">التذاكر</div>
               <div className="navbar-subtitle site-e">You have {props.qty} active tickets! Good luck!</div>
               <div className="navbar-subtitle site-a">لديك {props.qty} تذاكر نشطة! حظاً سعيداً </div>
            </div>
         )}

         {props.histoty && (
            <div className="navbar-title">
               <Link onClick={() => history.goBack()}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </Link>
               <div className="navbar-title">{file.myhistory}</div>
            </div>
         )}

         {props.freeKoinz && (
            <div className="navbar-title">
               <Link onClick={() => history.goBack()}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </Link>
               <div className="navbar-title">Want free Koinz?</div>
            </div>
         )}

         {props.account && (
            <div className="navbar-title">
               <Link to={'/'}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </Link>
               <div className="navbar-title">{file.account}</div>
            </div>
         )}

         {/* {props.account2 && (
            <div className="fickfix">
               <div className="navbar-title site-e">
                  {store.auth.token != null ? 'Marhaba ' : 'Account'}
                  <span className="cname-gr"> {store.auth.token != null ? get(store, 'auth.userinfo.firstname', ' ') : ''}</span>
               </div>
               <div className="navbar-title site-a">
                  {store.auth.token != null ? 'Marhaba ' : 'الحساب'}
                  <span className="cname-gr"> {store.auth.token != null ? get(store, 'auth.userinfo.firstname', ' ') : ''}</span>
               </div>
               <div className="navbar-subtitle">{store.auth.token != null ? store.auth.userinfo.email : file.yourprofileheader_txt}</div>
            </div>
         )} */}

         {props.accountdetails && (
            <div className="navbar-title">
               <Link onClick={() => history.push('/menu')}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </Link>
               <div className="navbar-title">{file.accountdetails}</div>
            </div>
         )}

         {props.savedaddress && (
            <div className="navbar-title">
               <Link onClick={() => history.push('/menudetails')}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </Link>
               <div className="navbar-title">{file.savedaddress}</div>
            </div>
         )}

         {props.paymentmethods && (
            <div className="navbar-title">
               <Link onClick={() => history.goBack()}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </Link>
               <div className="navbar-title">{file.paymentmethod}</div>
            </div>
         )}

         {props.mykoinz && (
            <div className="fickfix">
               <div className="navbar-title">   {file.mykoinz}</div>
               {/* <div className="navbar-subtitle site-e">The campaigns you like the most</div>
               <div className="navbar-subtitle site-a">الحملات التي تعجبك</div> */}
            </div>

         )}

         {props.personalDetails && (
            <div className="navbar-title">
               <Link onClick={() => history.goBack()}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </Link>
               <div className="navbar-title">{file.personaldetail}</div>
            </div>
         )}

         {props.accountsettings && (
            <div className="navbar-title">
               <Link to={'/menu'}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </Link>
               <div className="navbar-title">{file.accountsettings}</div>
            </div>
         )}

         {props.notifications && (
            <div className="navbar-title">
               <Link to={'/'}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </Link>
               <div className="navbar-title">{file.notifications}</div>
            </div>
         )}

         {props.howitworks && (
            <div className="navbar-title">
               <Link onClick={() => history.goBack()}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </Link>
               <div className="navbar-title">{file.howitwork}</div>
            </div>
         )}

         {props.checkout && (
            <div className="navbar-title">
               <Link onClick={() => history.push("/cart")}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </Link>
               <div className="navbar-title">{file.chec}</div>
            </div>
         )}

         {props.faqs && (
            <div className="navbar-title">
               <Link onClick={() => history.goBack()}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </Link>
               <div className="navbar-title">{file.faqs}</div>
            </div>
         )}

         {props.drawtnc && (
            <div className="navbar-title">
               <Link onClick={() => history.goBack()}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </Link>
               <div className="navbar-title">{file.drawtnc}</div>
            </div>
         )}

         {props.contactus && (
            <div className="navbar-title">
               <span onClick={() => history.goBack()}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </span>
               <div className="navbar-title">{file.contactus}</div>
            </div>
         )}

         {props.thismonthwinner && (
            <div className="navbar-title">
               <span onClick={() => history.goBack()}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </span>
               <div className="navbar-title">{file.winners}</div>
            </div>
         )}

         {props.aboutapp && (
            <div className="navbar-title">
               <span onClick={() => history.goBack()}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </span>

               <div className="navbar-title">{file.aboutapp}</div>
            </div>
         )}

         {props.tnc && (
            <div className="navbar-title">
               <Link onClick={() => history.goBack()}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </Link>
               <div className="navbar-title">{file.tnc}</div>
            </div>
         )}

         {props.privacypolicy && (
            <div className="navbar-title">
               <Link onClick={() => history.goBack()}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </Link>
               <div className="navbar-title">{file.privacypolicy}</div>
            </div>
         )}

         {props.referfriend && (
            <div className="navbar-title">
               <Link onClick={() => history.goBack()}>
                  <img src={`${file.img_path}makeicons/ic-back-arrow.svg`} alt="" />
               </Link>
               <div className="navbar-title">{file.refertfrd}</div>
            </div>
         )}
      </div>
   );
};

export default NavBar;
