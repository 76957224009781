import React from "react";

const Loader = props => {
  return (
    <div className="loading-container" style={props.containerStyle}>
      <div className="loader" style={props.style}/>
    </div>
  );
}

export default Loader;
