// import SomethingWrong from './components/SomethingWrong/SomethingWrong';

export const Environment = {
   // base_url: 'https://kanziapp.org/rest/V1',  //for production - enable this
   base_url: 'https://kanziapp.org/rest/ar/V1', //for sit - enable this
   app_domain_url: 'https://kanziapp.org',
   trackpae: 'تابع تقدمك في قسم التذاكر',
   trackpae1: ' تابع تقدمك',
   trackpae2: 'تابع مكتسباتك! تحقق من',
   trackpae3: 'My Koinz',
   trackpae5: 'جوائز أخرى ',
   //base_path: "/", //route path in local
   //base_path: "/kanzi-app/", //path for live
   membo_url: 'https://kanziapp.net',
   koinznew: '؟Koinz',
   img_path: './', // images variable in live
   //img_path: "", // images variable in local
   img_url: 'https://kanziapp.org/pub/media/magedad/banners/',
   productUrl: 'https://kanziapp.org/pub/media/catalog/product',
   Product_description: 'وصف المنتج',
   vate: 'تتضمن ضريبة القيمة المضافة',
   Proize_description: 'وصف الجائزة',
   text_card_desc: 'سيتم إنشاء حسابك بعد تقديم طلبك بنجاح. توفير الخاص بك.',
   havequestionsterms: 'يرجى مراجعة',
   havequestionstermstounch: 'شروط وأحكام السحب',
   chec: 'إتمام الشراء',
   otp_cards_title: 'رمز التحقق',
   next: 'التالي',
   winnerexplore: 'تعرف على الفائزين',
   otp_cards_subtitle: 'سنرسل رمز التحقق إلى رقم الهاتف <strong>رمز التحقق</strong> <br/> إلى رقم الهاتف',
   otp_verification_cards_subtitle: 'يُرجى إدخال <strong>رمز التحقق</strong> <br/>الذي أرسلناه إلى رقم الهاتف ',
   otp_card_text1: 'لم يصلك رمز التحقق؟',
   resend_code: 'إعادة إرسال الرمز',
   verify: 'تأكيد',
   phone_number: 'رقم الهاتف ',
   firstname: 'الإسم الأول',
   lastname: 'إسم العائلة',
   email: 'البريد الالكتروني',
   nationality: 'الجنسية',
   gender: 'الجنس',
   male: 'ذكر',
   female: 'أنثى',

   //singuptxt

   singup: 'التسجيل',
   singuptxt: 'أدخل بياناتك الشخصية',
   youtdetails: 'الملف الشخصي',

   buycollectKoinz: 'اجمع أكثر. تسوق أكثر',
   endingsoon: 'ستغلق الحملة قريباُ!!',
   notifymewhen1: 'عليك تسجيل الدخول لتتمكن من إدارة إعدادات الإشعار',
   // levelofluxury: 'ما هي الرفاهية التي تحلم بها؟',
   // luxury: '',
   levelofluxury: ' ماذا تريد أن ',
   luxury: 'تربح اليوم؟ ',
   // levelofluxury: ' أن تربح اليوم؟',
   // luxury: ' ماذا تريد',
   all: 'كافة الجوائز',
   jewellery: 'مجوهرات',
   motors: 'سيارات',
   experiences: 'نشاطات',
   electronics: 'إلكترونيات',
   prizes_next_month: 'الجوائز القادمة!',
   who_celebrating: 'تعرف على الفائزين الذين نحتفل بهم هذا الشهر!',
   winnerlist_title: 'الفائزون في هذا الشهر!',
   emptycart_title: 'يبدو أنّ عربتك فارغة!',
   emptycart_text: 'لدينا الآن حملات رائعة! لنبدأ التسوق!',
   ct: 'العربة',

   addressline1: 'العنوان',
   addressline2: 'العنوان',
   city: 'المدينة',
   country: 'البلد',
   cardname: 'الاسم على البطاقة',
   cardnumber: 'رقم البطاقة',
   expirydate: 'تاريخ',
   cvv: 'رمز الأمان',

   applepay: 'أو استخدم Apple pay',
   Samsungpay: 'Samsung Pay',
   paymenthod: 'تعيين كطريقة دفع تلقائية',
   apaymenthod: 'إدخال تفاصيل بطاقة الدفع',
   paytxt: 'كيف تريد الدفع؟',
   p1: 'الاسم 	 على البطاقة',
   nonefindouttxt: 'هل أنتم مستعدّون لإعلان سعيد الحظ الفائز معنا؟',
   p2: 'رقم البطاقة المكوّن من ١٦ رقمًا',
   p3: '٣ أرقام',
   payment: 'عملية الدفع',
   addressttl: 'تعيين كعنوان تلقائي للتوصيل ',
   agreetxt: 'أوافق على',
   agreetxt1: 'الشروط والأحكام',
   whatswipe: 'إستبدل منتجك',
   vate1: 'الإجمالي (شاملاً ضريبة القيمة المضافة)',
   whatswipesecdesc: 'بكوينز',
   whatswipedescription: 'هل تعلم أن تبديل منتجك بكوينز هو أحد أسهل الطرق للحصول على المزيد.',
   whatswipdeconfdesctpion: 'يمكنك القيام بذلك عن طريق النقر فوق الشريط الأخضر تحت',
   delivertxt: 'إلى أي عنوان تريد أن تتم عملية التوصيل ؟',
   Shipping: 'خطوات التوصيل',

   koinzbanertext:
      'كوينز هي نقاط مكافآت كنزي التي يمكنك استخدامها للتسوق والفوز. ستحصل على كوينز مع كل شراء لاستخدامها كوسيلة دفع عند تسوقك ومشاركتك في حملاتنا الرائعة. كلما زاد رصيد  الكوينز لديك، أصبح بإمكانك التسوق أكثر وأكثر. ',

   // onboarding
   obaordigdra: '    يبدأ السحب عندما يتم',
   obaordigdraspan: 'بيع جميع العناصر ',
   obaordigchnage: 'للحصول على فرصة',
   obaordigdrachangewin: 'للفوز بهذا',
   obaordigdrachangethis: '',
   obaordigdraskip: 'تخطى',
   obaordigdragetachnace: 'احصل على فرصة',
   obaordigdraskipwin: 'للفوز',
   obaordigdrasaedvalue: 'بذهب بقيمة 5000 درهم',
   obaordigdrabuykoinz: 'قم بشراء قميص كنزي',
   obaordigdraawsvaluek: 'درهم 150',
   obaordigdraawsaddcard: 'أضف إلى العربة',
   obaordigdrabuydv: 'محل',
   obaordigdrabuythis: 'هذا المنتج',
   obaordigdrakoinzcollect: 'اربح',
   obaordigdraskipcardkoinz: 'كوينز',
   obaordigdraviewdeatilds: 'عرض التفاصيل',
   obaordigdrakoinzdetails: 'زد فرصك في الفوز من خلال جمع الكوينز واستبدالها مقابل المزيد من الحملات الرائعة',
   obaordigdDoneBtn: 'تابع',
   obaordigdNextBtn: 'التالي',

   //for pay & review pages

   subtotal: 'المجموع الفرعي',
   subtotal_amt_aed: '200 درهم',
   vat: 'الضريبة',
   vat_percentage: '5%',
   total_amt: 'المجموع',
   total_amt_aed: '210 درهم ',

   // woohoo

   woohootxt: 'رائع!!',
   woohsubtxt: 'أنت على بعد خطوة واحدة من الفوز بهذه الجائزة!',
   deliverytimetxt: 'سيتم توصيل طلبك في غضون 2-3 أيام عمل',
   refnumber: 'رقم المرجع:',

   //for shipping and payments details
   saved_address: 'العناوين المسجلة ',
   office: 'مكان العمل ',
   home: 'المنزل  ',
   address: 'JLT  Indigo Icon Tower  1504',
   add_address: 'أضف عنواناً جديداً',
   saved_cards: 'اختر وسيلة الدفع',
   card_number: 'أدخل تفاصيل بطاقتك الإئتمانية ',
   add_card: 'أضف بطاقة جديدة',

   //for tickets page
   ticketnumbertxt: 'رقم التذكرة',
   ticketnumber: '26551-FT',
   recentlypurchasedtxt: 'آخر المشتريات',
   closedcampaigntxt: 'الحملات المنتهية',
   viewtxt: 'عرض',
   orderhistory: 'المشتريات',
   winnertxt: 'الفائز',
   winnername: 'جون دو',
   closedate: '15 مارس 2020',
   //empty ticket
   zeroactivetckt: 'ليس لديك أي تذاكر ناشطة!',
   nxtwinnertxt: 'هل تريد أن تكون الفائز التالي؟ تسوق من منتجاتنا واحصل على تذكرتك',

   //no history ticket
   orderwillappear: 'ستظهر مشترياتك هنا',
   recentorderhistory: 'شاهد أحدث مشترياتك مع تفاصيل الطلب والمرجع.',
   order: 'المشتريات ',

   tipoftheday: 'نصيحة اليوم',
   optout: 'إلغاء الاشتراك',
   of_future_tips: 'من النصائح المستقبلية',
   of_future_votes: 'من التصويت في المستقبل',
   voteforfavorite: 'صوت لجائزتك المفضلة!',
   notifytxt: 'يُرجى إعلامي حين تكون هذه الجائزة متاحة',
   oopstxt: 'عذراً! ',
   somethingWrongtxt: 'حدث خطأ هنا ...',
   somethingwrongbodytxt: 'لم نستطع العثور على الرابط الذي تبحث عنه! يرجى العودة إلى الصفحة الرئيسية والمحاولة مجدداً',

   techtext: 'نعتذر عن حدوث <br/> خطأ تقني!',
   techbodytxt: 'يرجى إعادة تشغيل التطبيق.',
   oohnotxt: 'آسف!',
   oohnobodytxt: 'يبدو أن جميع المنتجات الموجودة في عربة التسوق قد نفدت! يُرجى اختيار حملة أخرى!',
   nointernet: 'لا يوجد اتصال بالإنترنت',
   nointernetbodytxt: 'تأكّد أنّ جهازك ليس على وضع الطيران، ثمّ حاول الاتصال بشبكة الـواي فاي مرة أخرى.',

   awkward: 'عفواً! يبدو أنك كتبت...',
   awkwardbodytxt:
      'هذا الرقم مسجل لدينا مسبقاً. إذا كان هذا رقمك، يرجى الذهاب إلى صفحة تسجيل الدخول، أو اختر رقماً مختلفاً.',
   awkwardbodytxtforemail:
      'هذا البريد الإلكتروني مسجل لدينا مسبقاً، يرجى تسجيل الدخول. إذا كان هذا بريدك، يرجى الذهاب إلى صفحة تسجيل الدخول، أو أدخل بريداً إلكترونياً مختلفاً.',
   awkwardbodytxtforphone:
      'هذا الرقم مسجل لدينا مسبقاً. إذا كان هذا رقمك، يُرجى الذهاب إلى صفحة تسجيل الدخول، أو اختر رقماً مختلفاً.',
   contacttxt: 'تواصل مع',
   creatingacc: 'قم بإنشاء حساب',
   customercarehere: 'خدمة العملاء هنا.',
   gmaplty: 'احصل على التسوق',
   contactusucess: 'شكراً لتواصلك معنا. سيقوم أحد ممثلي خدمة العملاء لدينا بالرد عليك بأقرب وقت ممكن.',
   AddTCartn: 'تمّت الإضافة',
   //Account page

   notanaccount_title: 'عرفنا أكثر عن نفسك',
   notanaccountbodytxt: 'أنشئ حساباً جديداً، تسوّق، إلعب واربح!',
   alreadyan: 'هل لديك حساب؟',
   login: 'سجل الدخول',
   account: 'الحساب',
   yourprofileheader_txt: 'ملفي الشخصي',
   myhistory: 'تاريخ المشتريات',
   mykoinz: ' الكوينز والمكافآت',
   refertfrd: 'قم بدعوة صديق',
   appsetting: 'الإعدادات',
   winners: 'الفائزون',
   howitwork: 'فكرة التطبيق',
   forgetp: 'هل نسيت كلمة المرور؟ ',
   contactus: 'تواصل معنا ',
   aboutapp: 'لمحة عن كنزي',
   accountdetails: 'تفاصيل الحساب ',

   personaldetail: 'الملف الشخصي  ',
   paymentmethod: 'طرق الدفع',
   savedaddress: 'العناوين المسجلة ',
   password: 'كلمة المرور ',
   optional: '(اختياري)',
   accepttnc: 'الرجاء قبول الشروط والأحكام',
   gotp: 'إنشاء رمز التحقق',
   iotp: 'رمز التحقق غير صحيح',
   passagreetxt1: '1 حرف كبير',
   passagreetxt2: '1 حرف صغير',
   spanpasstry: 'الرجاء استخدام على الأقل',
   errorsignup: '<li>يجب أن يحتوي على أحرف صغيرة</li> <li>يجب أن يحتوي على أحرف كبيرة</li>',
   resetpass: 'إعادة تعيين كلمة المرور',
   npass: 'كلمة مرور جديدة',
   cpass: 'كلمة المرور الحالية',
   rnpass: 'أعد كتابة كلمة المرور الجديدة',

   donthaveaccount: 'ليس لديك حساب؟ ',

   //Saved Address
   Newaddress: 'عنوان جديد',
   emirate: 'الإماراة',
   area: 'المنطقة',
   apartmentVilla: 'رقم الشقة / الفيلا',
   Additionalinstructions: 'تعليمات إضافية',
   Addressnickname: 'العنوان ',
   egbusinessbay: 'خليج الأعمال',
   buildingnamestreet: 'اسم المبنى/ الشارع',
   egapartment: 'شقة 1030',
   optional: 'اختياري',
   egoffice: 'مكان العمل ',

   currentbalance: 'الرصيد الحالي',
   koinz: 'كوينز',
   whatare: 'ما هي الكوينز?',
   koinzq: 'كوينز؟',
   shopnow: 'تسوق الآن',
   worth: 'القيمة',
   wallet: 'المحفظة',
   bought: 'المشتريات',
   cmap: 'عرض التفاصيل',
   awkward11: 'مهلاً! يبدو أنه لديك حساب مسجل لدينا.',
   earned: 'المكتسبات',
   referrals: 'دعوات الأصدقاء',
   tellyourfriends: 'أخبر أصدقاءك',
   koinzpopupbodytxt:
      'هل تعلم أن هناك طرقاً عديدة لجمع واكتساب وشراء كوينز؟ تابع رصيد كوينز في محفظتك، واستخدم كوينز للانضمام إلى إحدى حملاتنا لفرصة الفوز بجائزة فاخرة.',

   okthanks: 'حسنًا، شكرًا!',
   youhave: 'لديك',
   // product: 'منتجات',
   product: 'المنتجات',
   done: 'تم',
   learnmorein: 'اعرف المزيد',
   faqs: 'الأسئلة الشائعة',
   accountsettings: 'إعدادات التطبيق',
   language: 'اللغة',
   currency: 'العملة',
   notificationsettings: 'إعدادات الإشعار',
   contsucc: 'تم الارسال. سيتصل بك الفريق قريبًا :)',
   sounds: 'صوت',
   icanvotforaprize: 'إمكاني التصويت على جائزة',
   winnersareannounced: 'تم الإعلان عن الفائزين',
   acampaignonmywatchlist: 'قريباً ستنتهي حملة ضمن قائمة المتابعة لدي',
   save: 'حفظ',
   notifymewhen: 'اعلمني عندما',
   notifications: 'الإشعارات',
   isnowups: 'تتوفر لديك جائزة في قائمة المتابعة',
   getitbefore: 'احصل عليها قبل فوات الأوان ',
   hoursago: 'منذ ساعات',
   clossingsoon: 'سيتم إغلاق حملة موجودة في قائمة المتابعة لديك',
   purchasetktnow: 'اشتر تذكرتك الآن!',
   announced: 'تم الإعلان عن فائز جديد!',
   whattheywon: 'اكتشف جائزة الفائز',
   drawtnc: 'شروط وأحكام السحب',

   v2: 'درهم إماراتي - AED',
   v1: 'دولار الولايات المتحدة - USD',

   //for FAQs
   aboutkanzi: 'لمحة عن كنزي',
   campaigns: 'الحملات',
   products: 'المنتجات',
   prizedraws: 'جوائز السحوبات',
   topquestions: 'أهم الأسئلة',
   whatarekoinz: ' ما هي كنزي؟',
   havequestions: 'لديك المزيد من الأسئلة؟ لدينا إجابات',
   getintouch: ' ابق على تواصل',
   answersfaqs:
      'في كنزي، هدفنا أن نجعل فرصة الربح متاحة للجميع. أهلاً بك في تجربة تسوق رقمية جديدة ومبتكرة! في كل مرة تشتري أحد منتجاتنا الرائعة، سيكون لديك فرصة للفوز بإحدى جوائزنا الفاخرة. هذه فكرة كنزي بكل بساطة! ',
   // answersfaqs:
   //    'تحب الفخامة؟ ونحن أيضاً! في كنزي، هدفنا أن نجعل الحياة الفاخرة متاحة للجميع. أهلاً بك في تجربة تسوق رقمية جديدة ومبتكرة! في كل مرة تشتري أحد منتجاتنا الرائعة، سيكون لديك فرصة للفوز بإحدى جوائزنا الفاخرة. هذه فكرة كنزي بكل بساطة!',
   question2: 'ما هي الحملات التي أشاهدها على كنزي؟',
   answer2:
      'الحملات هي الاسم الذي نطلقه على سحوبات الجوائز، وسوف تكتشف أن لدينا الكثير منها! تنطلق دائماً حملات جديدة عند إغلاق حملة حالية. لذا لا تقلق! لديك دائماً فرص جديدة للفوز! ',
   // answer2:
   //    'الحملات هي الاسم الذي نطلقه على سحوبات الجوائز، وسوف تكتشف أن لدينا الكثير منها! تنطلق دائماً حملات جديدة عند إغلاق حملة حالية. لذا لا تقلق! لديك دائماً فرص جديدة للفوز!',

   question3: 'كيف يمكنني التواصل',
   // question3: 'كيف يمكنني التواصل؟',
   answer3:
      'نحن دائمًا هنا للمساعدة! إذا كان لديك أي أسئلة أو تعليقات حول تجربتك مع كنزي، يسعدنا أن نسمع منك. تواصل معنا عبر البريد الإلكتروني أو الهاتف أو أرسل  لنا رسالة مباشرة عبر تطبيقنا! ',
   // quesAcc1: 'كيف أنشئ حساباً؟',
   quesAcc1: 'كيف أنشئ حساباً؟ ',
   quesAcc2: 'كيف أقوم بتغيير كلمة المرور؟',
   quesAcc3: 'كيف يمكنني حذف حسابي؟',

   //for draw t n c
   tnccontent1:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of  using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for ‘lorem ipsum’ will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).',
   tnccontent2:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of  using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for ‘lorem ipsum’ will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).',
   tnccontent3:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of  using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for ‘lorem ipsum’ will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).',

   //contactus
   emailkanzi: 'Support@kanziapp.com',
   numburkanzi: '97142756419+',
   addresskanzi: 'طابق أونكس 1، المكتب 703،  شارع الشيخ زايد، ذا غرينز، دبي - الإمارات العربية المتحدة',
   kanzicontactformttl: 'نود أن نسمع منك!',
   name: 'الإسم',
   message: 'الرسالة',
   sendmessage: 'أرسل',

   //for winner page
   winnerfindouttxt: 'كيف يتم اختيار الفائزين.',
   findouttxt: 'اكتشف هنا.',
   winnername1: 'جون دو',
   french: 'فرنسي',

   //about app
   //  aboutapp: 'المسائل القانونية',
   tnc: 'الشروط والأحكام',
   privacypolicy: 'سياسة الخصوصية',
   kanzilicencetxt:
      'كنزي مرخصة ومعتمدة من دائرة التنمية الاقتصادية في دبي، ويتم إجراء جميع السحوبات بواسطة ممثل عن دائرة التنمية الاقتصادية.',

   //tnc & privacy policy
   tnc: 'الشروط والأحكام',
   privacypolicy: 'سياسة الخصوصية',

   //refer friend
   earnreward: 'ادع صديقاً للإنضمام واكسب مكافآت!',
   getsharing: 'شارك',
   generateLink: 'أنشئ رابطاً!',

   //language and currency
   selectlanguage: 'اختر اللغة',
   selectcurrency: 'اختر العملة',

   //newly added
   hlearnmore: 'اعرف المزيد',
   hendtingtxt1: 'هذه فرصتك',
   hendtingtxt2: 'الأخيرة',
   hkoinztitle: 'كوينز',
   hupcomingtxt1: 'الجوائز',
   hupcomingtxt2: 'القادمة!',
   hwinnertxt1: 'تعرف على',
   hwinnertxt2: ' الفائزين!',
   emptyticket: 'ليس لديك تذاكر نشطة!',
   emptytickettxt1: 'هل تريد أن تكون الفائز التالي؟ تسوّق من منتجاتنا واحصل على تذكرتك',
   cartdeliver: 'التسليم ما بين ٢ إلى ٣ أيام عمل',
   carthomed: 'خدمة التوصيل إلى المنزل',
   pickupmyself: 'اذهب واستلم بنفسك - مجاناً',
   selfpicktxt: 'يمكنك الاستلام اعتباراً من اليوم',
   usemykoinz: ' استخدم الكوينز الخاصة بي',
   koinzinb: 'الكوينز في رصيدي ',
   whatr: ' ما هي ',
   uwillearn: 'ستحصل على',
   koinzpurchase: 'كوينز من هذا الشراء',
   urticketfor: 'تذكرتك لـ',
   replacewith: 'استبدل ب',
   gotit: 'تلقيتها!',
   replacewithkoinz: 'استبدل هذا المنتج بكوينز قابلة للاسترداد',
   blipradar: 'ليس لديك أي منتجات في قائمتك',
   dontforget: 'لا تنس',
   keepeyeout: 'ترقب الجائزة! ',
   createaccount: 'أنشئ حسابًا لتراقب عن كثب حملاتك المفضلة وتزيد من فرص فوزك!',
   donthvaccount: 'ليس لديك حساب؟',
   singupp: 'التسجيل',
   check: 'الكوينز والمكافآت',
   koinzandrewards: 'الكوينز والمكافآت',
   logout: 'تسجيل الخروج',
   ticketid: 'رقم التذكرة ',
   tk: 'التذاكر',
   referfriend: 'ادع أصدقائك إلى الانضمام واحصل على أروع المكافآت. إنها طريقتنا لشكرك!.',
   social: 'قنوات التواصل الاجتماعي',
   copyrightkanzi: '.2021 Kanzi, Inc',
   rightreserved: 'جميع الحقوق محفوظة',
   kanzversion: 'الإصدار 1.3 ',
   wrnge: 'البريد الإلكتروني أو كلمة المرور غير صحيحة. يرجى المحاولة مرة أخرى.',
   weromga: 'الحساب غير موجود. يُرجى إنشاء حساب',
   AEDTETX: 'درهم',
   SOLDTETX: 'الحملة',
   OUTSNT: 'بيعها من',
   ddds: 'تمّ بيعها من',
   // AddTCart: 'أضف إلى عربة التسوق',
   AddTCart: 'أضف إلى العربة',
   emailabel: 'البريد الإلكتروني',
   passabel: 'كلمة المرور',
   signabel: 'سجل الدخول',
   savabel: 'حفظ التغييرات',
   mmail: 'ذكر',
   fmail: 'أنثى',
   uaev: 'الإمارات العربية المتحدة',
   y: 'الخاص بك',
   ts: ' تذاكر ل',
   t: ' تذكرة ل',
   messenger: 'السفير',
   doer: 'الفاعل',
   collector: 'الجامع',
   themoreshop: 'كلما شاركت في حملة تزداد فرصتك لكسب المزيد من الكوينز',
   participate: 'مشاركة',
   times: 'مرات',
   samecamp: 'في نفس الحملة',
   earn: 'كسب',
   yourac: 'حملاتك النشطة',
   sagain: 'تسوق مرة أخرى',
   wina: 'فوز أ',
   moreparticipate: 'كلما شاركت في حملة جديدة زادت فرصتك لكسب كوينز إضافية',
   yearn: '',
   mreward: 'مكافآتي وشاراتي',
   pyourorder: 'تحقق من مشترياتك',
   wheresdeliver: 'إلى أي موقع تريد أن تتم عملية التوصيل ؟',
   yorders: 'مشترياتك',
   delivery: 'التوصيل',
   adpickup: 'عنوان موقع الاستلام',
   pnow: 'إتمام عملية الدفع',
   porder: 'إتمام الطلب ',
   ypickup: 'موقع الاستلام الخاص بك',
   cda: 'اختر عنوان التسليم',

   AboutApp: [
      {
         question: 'ما هي فكرة كنزي؟',
         answer:
            'في كنزي، هدفنا أن نجعل فرصة الربح متاحة للجميع. أهلاً بك في تجربة تسوق رقمية جديدة ومبتكرة! في كل مرة تشتري أحد منتجاتنا الرائعة، سيكون لديك فرصة للفوز بإحدى جوائزنا الفاخرة. هذه فكرة كنزي بكل بساطة! ',
         // answer:
         //    'تحب الفخامة؟ ونحن أيضاً! في كنزي، هدفنا أن نجعل الحياة الفاخرة متاحة للجميع. أهلاً بك في تجربة تسوق رقمية جديدة ومبتكرة! في كل مرة تشتري أحد منتجاتنا الرائعة، سيكون لديك فرصة للفوز بإحدى جوائزنا الفاخرة. هذه فكرة كنزي بكل بساطة!',
      },
      {
         question: 'فكرة التطبيق   ',
         answer:
            'نطلق "حملات" عديدة لمنتجات وجوائز مختلفة. عند شرائك منتجاً من حملة معيّنة، تحصل تلقائياً على تذكرة لدخول السحب على جائزة فاخرة. بمجرد بيع كافة منتجات الحملة، يتم إجراء السحب والإعلان عن الفائز!​​',
      },
      {
         question: 'كيف يمكنني التواصل؟',
         answer:
            'نحن دائمًا هنا للمساعدة! إذا كان لديك أي أسئلة أو تعليقات حول تجربتك مع كنزي، يسعدنا أن نسمع منك. تواصل معنا عبر البريد الإلكتروني أو الهاتف أو أرسل  لنا رسالة مباشرة عبر تطبيقنا!',
      },
   ],

   Account_faq: [
      {
         question: 'كيف أقوم بإنشاء حساب؟',
         answer:
            ' للانضمام إلى كنزي، قم بإنشاء حساب عن طريق إدخال اسمك ورقم هاتفك والبريد الإلكتروني واختيار كلمة مرور. سنرسل لك بعد ذلك رمز لتأكيد المعلومات. ',
      },
      {
         question: 'كيف أسجل الدخول؟',
         answer:
            'سجل دخولك بإدخال البريد الإلكتروني وكلمة المرور اللذين استخدمتهما عند التسجيل. سنرسل لك بعد ذلك رمزاً للتحقق من المعلومات.',
      },
      {
         question: 'كيف أقوم بتغيير كلمة المرور؟',
         answer:
            "لا تقلق إذا نسيت كلمة المرور! بكل بساطة، اضغط على زر 'نسيت كلمة المرور' في صفحة تسجيل الدخول وسنرسل لك رابط إعادة تعيين إلى عنوان البريد الإلكتروني المسجل.",
         // answer:
         //    "لا تقلق إذا نسيت كلمة المرور! بكل بساطة، اضغط على زر 'نسيت كلمة المرور' في صفحة تسجيل الدخول وسنرسل لك رمز تحقق على رقم هاتفك المحمول المسجّل لإعادة تعيين كلمة مرور جديدة.",
      },
   ],

   products_faq: [
      {
         question: 'هل جميع المنتجات من صنع كنزي؟',
         answer: ' نعم، كافة منتجاتنا مصممة في دبي ومختارة بعناية من قبل فريق كنزي.',
      },
      {
         question: 'كيف أحصل على المنتج الذي اشتريته؟',
         answer: ' سوف نقوم بتسليم المنتج إليك مقابل رسم إضافي رمزي',
      },
   ],

   prizedraws_faq: [
      {
         question: 'كيف يمكنني تتبع التذاكر الخاصة بي؟',
         answer: 'لتتبع التذاكر النشطة الخاصة بك، تحقق من قسم التذاكر في أي وقت.',
      },
      // {},
      {
         question: 'كيف أزيد من فرص فوزي؟',
         answer:
            'لزيادة احتمالات الفوز، قم بشراء أكبر عدد من المنتجات من حملة واحدة! لا يعني هذا فقط أنك ساعدت في تقريب الحملة بأكملها من السحب، بل يعني أيضًا أنه سيكون لديك المزيد من فرص المشاركة في المنافسة. حظا سعيدا!',
      },
      {
         question: 'كيف يمكنني معرفة الفائزين في الحملة؟',
         answer:
            'لمعرفة الفائزين في الحملة التي شاركت فيها، يمكنك إما التحقق من قسم "الفائزون"، أو ستتلقى إشعاراً فورياً من خلال تطبيقنا عند إعلان الفائز. إذا فزت (نأمل ذلك)، سنتواصل معك مباشرة من خلال بياناتك المسجلة.',
      },
      {
         question: 'كيف أحصل على جائزتي إذا فزت؟',
         answer:
            ' ستحفظ جائزتك بأمان في مكتبنا بدبي، وستكون جاهزة للاستلام. يرجى الاطلاع على الشروط والأحكام لمعرفة المزيد من التفاصيل.*',
      },
   ],

   koinz_faq: [
      {
         question: 'ما هي كوينز؟',
         answer:
            'كوينز هي نقاط مكافآت كنزي التي يمكنك استخدامها لتسوق منتجاتنا والفوز بجوائزنا. كلما جمعت كوينز أكثر، ستتمكن من شراء المزيد لاحقاً!',
      },
      {
         question: 'كيف أكسب كوينز؟',
         answer: `في كل مرة تتسوق فيها مع كنزي، ستكسب المزيد من كوينز! وإذا أردت كسب كوينز أكثر، فلدينا الكثير من الطرق الرائعة: 
         \n
         ١. استبدل المنتجات بـ كوينز:
        عند التسوق عبر كنزي، يمكنك دائماً اختيار الحصول على كوينز بدلاً من المنتج وبنفس القيمة بالضبط.
        \n
        ٢. ادع صديقاً للانضمام:
         اربح كوينز كلما دعوت أصدقاءك للانضمام إلى كنزي! وكلما حصلت على عدد محدد من المدعوين، ستفتح لك شارة. إذا قمت بفتح جميع الشارات، تكون مهمتك قد اكتملت وستربح المزيد من كوينز.
         \n
        ٣. برنامج الولاء:
         يتكون برنامج الولاء الخاص بنا من ٤ مستويات. كلما اشتريت أكثر، أصبح من الأسهل الوصول إلى المستوى التالي! وكلما تقدمت في المستوى ستحصل على كوينز أكثر بنسبة 2,5% مع كل عملية شراء!
         \n
        ٤. التسوق من جميع الفئات:
         تسوق من فئات الرفاهية واحصد المزيد من المكافآت. ما عليك سوى شراء منتجين من كل فئة للحصول على مكافأة ضخمة من الكوينز!
         \n
        ٥. عمليات الشراء المتكررة:
         اربح كوينز مجاناً في كل مرة تفتح فيها شارة عند شراء نفس المنتج ٥ أو١٠ أو ٢٥ مرة. هل هناك سبب أفضل للتسوق لجميع أصدقائك وعائلتك دفعة واحدة؟`,
         // answer: `في كل مرة تتسوق فيها مع كنزي، ستكسب المزيد من كوينز! وإذا أردت كسب كوينز أكثر، فلدينا الكثير من الطرق الرائعة: (١) استبدل المنتجات بـ كوينز: عند التسوق عبر كنزي، يمكنك دائماً اختيار الحصول على كوينز بدلاً من المنتج وبنفس القيمة بالضبط. (٢) ادع صديقاً للانضمام: اربح كوينز كلما دعوت أصدقاءك للانضمام إلى كنزي! وكلما حصلت على عدد محدد من المدعوين، ستفتح لك شارة. إذا قمت بفتح جميع الشارات، تكون مهمتك قد اكتملت وستربح المزيد من كوينز. (٣) برنامج الولاء: يتكون برنامج الولاء الخاص بنا من ٤ مستويات. كلما اشتريت أكثر، أصبح من الأسهل الوصول إلى المستوى التالي! وكلما تقدمت في المستوى ستحصل على كوينز أكثر بنسبة 2,5% مع كل عملية شراء! (٤) التسوق من جميع الفئات: تسوق من فئات الرفاهية واحصد المزيد من المكافآت. ما عليك سوى شراء منتجين من كل فئة للحصول على مكافأة ضخمة من الكوينز! (٥) عمليات الشراء المتكررة: اربح كوينز مجاناً في كل مرة تفتح فيها شارة عند شراء نفس المنتج ٥ أو ١٠ أو ٢٥ مرة. هل هناك سبب أفضل للتسوق لجميع أصدقائك وعائلتك دفعة واحدة؟`,
      },
      {
         question: 'كيف أتحقق من رصيد الكوينز الخاص بي؟',
         answer: 'تابع رصيدك المتبقي من كوينز في قسم "الكوينز والمكافآت"',
      },
      {
         question: 'هل يمكنني استخدام النقود وكوينز معاً لشراء المنتجات',

         answer: 'نعم! يمكنك استخدام النقود وكوينز معاً لشراء المنتجات. ',
      },
   ],
   fav: 'قائمتي المفضلة ',
   ctunlock: 'فئات للفتح',
   date: 'التاريخ',
   trans: 'المعاملات',
   heropathtxt: 'تسلّق طريق الأبطال واحصل على المكافآت لبلوغ قمة الفخامة!',
   level: 'المستوى',
   morepart: '  مشاركة أخرى لتصبح  ',
   moreshop: 'تسوق أكثر لتتقدّم في طريق الأبطال! يمنحك كل مستوى جديد مكافآت كوينز إضافية.',
   gshopping: 'تسوق الآن ',
   msent: 'تم الارسال',

   popupBackground: {
      duration: 0.3,
      delay: 0.2,
   },
   popupContainer: {
      duration: 0.1,
      delay: 0.1,
   },
   paymentGateway: {
      hostedApiKey:
         'YTVmZGVmZTktNDkwOS00OTdhLTkzNmQtODc3YTk2YTcxN2YwOjFlYjdhZjkyLTg0NGQtNGRiNi1hZTE0LTQ3ODFlYzA3YzQzMA==',
      outletRef: '74a44d37-151d-4a64-b8d5-f255f35f84ca',
      apikey: 'YTljNGRmOWEtNDc0My00OTBhLWFkNzQtYzliMmJkY2RmN2Q3OmM1M2JkM2I2LTk1MjktNDEyYi1iOTZkLTBiMDQyNzMyYzEzMw==',
   }, //sandbox
   // paymentGateway: {
   //    hostedApiKey:
   //       'ZjE0NTJjOTAtN2NhYi00NjVjLTg1MWYtODcyMmVjYzQ4N2QzOjU4OTQ1NmVmLTZmNjQtNDg4Yy05YWI2LWQ2NDRhNDczNjYwZA==',
   //    outletRef: 'b9777379-9946-4489-8972-2ebcfc8939a5',
   //    apikey: 'ZGMxMmZjZWQtODYyZC00ODFkLWIwNzQtMTc5ZTQ1OGNjZjJmOmU2ODIwOGRmLTdhMjQtNGE4ZS05ZGZjLTQ1NmU5MDAyMzljYg==',
   // }, //production
   sdk: 'https://paypage.sandbox.ngenius-payments.com/hosted-sessions/sdk.js',
   makePayment: 'قم بالدفع',
   cardError: 'يرجى التحقق من تفاصيل بطاقتك وحاول مرة أخرى.',
   addCardDetails: 'الرجاء إدخال تفاصيل بطاقة الدفع',
   saveCardConsentTxt: 'حفظ تفاصيل بطاقتي',
   authenticateCard: 'المصادقة',
   Overview: 'لمحة عامة',
   y: 'Your',
   ts: ' tickets for',
   t: ' ticket for',

   //About us
   aboytheading: 'لمحة عن كنزي ',
   aboytheadingdesc:
      'في Kanzi ، نعتقد أن المعيشة الفاخرة يجب أن تكون في متناول الجميع وقد أنشأنا طريقة لجعل ذلك ممكنًا. Kanzi هي عبارة عن منصة تجارة إلكترونية مرحة تتمحور حول العملاء ، وسهلة الاستخدام ، وتوفر لك فرصة التسوق واللعب والفوز!',
   aboytsecondheading: 'كيف؟',
   aboytseconddesc:
      'نحن ندير "الحملات" ، وهو المصطلح الذي نستخدمه في عمليات السحب على الجوائز. في Kanzi ، سترى كل حملة معروضة بوضوح كمنتج / جائزة. عندما تشتري منتجًا ، تحصل على تذكرة لدخول السحب للفوز بجائزة فاخرة معينة. بمجرد بيع العدد المستهدف من المنتجات ، يتم إجراء السحب والإعلان عن الفائز.',
   aboytthirdheading: 'أين؟',
   aboytthirddesc:
      'حريص على بدء الفوز؟ يمكنك الوصول إلى Kanzi من خلال موقعنا على الإنترنت أو تنزيل تطبيق Kanzi المتوفر على Apple Store و Google Play.',

   //Header
   homeheader: 'الصفحة الرئيسية',
   aboutheader: 'لمحة عن كنزي ',
   favheader: 'قائمة الرغبات',
   winnerheader: 'الفائزون',
   howitheader: 'فكرة التطبيق',
   ticketheaders: 'التذاكر',
   yourtickrs: 'التذاكر',
   emptyyourtickrs: 'قائمة الرغبات',
   emptyyourwinner: 'الفائزون',
   cartemty: 'عربة التسوق',
   myaccounts: 'الحساب',

   //Sign in

   SignIn: 'تسجيل الدخول',
   SignUp: 'اشتراك',
   Signinaccount: 'Account',
   Signdetail: 'Your details',
   emailr: 'إلكتروني',
   passr: 'كلمه السر',
   winneraccouned: 'هل أنتم مستعدوّن لإعلان سعيد الحظ الفائز معنا؟',
   accountsettimn: 'الإعدادات',
   accountsettings2: 'الملف الشخصي',
   //footer
   QuickLinks: 'روابط سريعة',
   PrivacyPolicy: 'سياسة الخصوصية',
   DeliveryShipment: 'التسليم والشحن',
   DownloadApp: 'تحميل التطبيق',
   TermsAndConditions: 'شروط وأحكام السحب',
   AboutKanzi: 'لمحة عن كنزي',
   TermsConditions: 'الاحكام والشروط',
   RefundCancellation: 'الاسترداد والالغاء',
   Supports: 'الدعم',
   ContactUs: 'تواصل معنا',
   FAQs: 'الاسئلة الشائعة',
   Social: 'مواقع التواصل الاجتماعي',
   WeAccept: 'طرق الدفع',
   FequentlyAskedQuestions: 'الاسئلة الشائعة',
   Ends_In: 'ينتهي بـ',
   Days: 'أيام',
};
