import React from "react";
import { Link } from "react-router-dom";
import { Environment } from "./../../env";
import { get } from "lodash";

const WinnerList = props => {
  return (
    <div className="" id="winners-page-wrapper">
      {props.homepagewinnerlist &&
        <div className="ein this-month-winner monthwinner-main-conatiner">
          {get(props, "winnerlist", []).map((data, index) => {
            if (data.status !== "active") return;

            return (
              <div className="monthwinner-cards" key={index}>
                <div className="upper-block">
                  <div className="winner-user-block">
                    <div className="winner-user-img">
                      <img src={get(data, "winner_image[0].url", "")} alt="" />
                    </div>
                    <span className="winner-badge">
                      <img
                        src={`${Environment.img_path}makeicons/winner-star-badges.svg`}
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="winner-detail">
                    <div className="winner-date">
                      {get(data, "created_at", "")}
                    </div>
                    <div className="winner-name">
                      {get(data, "winner_name", "")}
                    </div>
                  </div>

                  <div className="winner-prize-img">
                    <img src={get(data, "prize_image", "")} alt="" />
                  </div>
                </div>
                <div className="ticketnumber-block">
                  {Environment.ticketnumbertxt}
                  <span>
                    {get(data, "winner_ticket_number", "")}
                  </span>
                </div>
              </div>
            );
          })}

          <div className="howtowiner">
            {props.Environment.winnerfindouttxt}
            <Link to="/faqs">
              {props.Environment.findouttxt}
            </Link>
          </div>
        </div>}
    </div>
  );
};

export default WinnerList;
