import React from 'react';
import { Link } from 'react-router-dom';

const DesktopFooter = props => {
   return (
      <div className="desktop-footer-container">
         <div className="container">
            <div className="section-one">
               <img src="makeicons/desktop-logo.svg" alt="" />
               {/* <p>KANZI ENTERPRISES GENERAL TRADING L.L.C</p> */}
            </div>
            <div className="section-two">
               <div className="header"> {props?.Environment?.QuickLinks}</div>
               <Link to="/aboutkanzi"> {props?.Environment?.AboutKanzi}</Link>
               {/* <Link to="/howitworks">How It Works</Link> */}
               <Link to="/desktop-terms"> {props?.Environment?.TermsConditions}</Link>
               <Link to="/privacypolicy">{props?.Environment?.PrivacyPolicy}</Link>
               <Link to="/refund-cancelation">{props?.Environment?.RefundCancellation}</Link>
               <Link to="/delivery-shipment">{props?.Environment?.DeliveryShipment}</Link>
            </div>
            <div className="section-three">
               <div className="header">{props?.Environment?.Supports}</div>
               <Link to="/contactus">{props?.Environment?.ContactUs}</Link>
               <Link to="/faqs">{props?.Environment?.FAQs}</Link>
            </div>
            <div className="section-four">
               <div className="header">{props?.Environment?.DownloadApp}</div>
               <a href="https://apps.apple.com/bh/app/kanzi/id1563930820" target="_blank">
                  {' '}
                  <img src="makeicons/image-3.png" alt="" />
               </a>
               <a href="https://play.google.com/store/apps/details?id=com.kanziapp" target="_blank">
                  {' '}
                  <img src="makeicons/image-4.png" alt="" />
               </a>
               {/* <img src="makeicons/image-4.png" alt="" /> */}
            </div>
            <div className="section-five">
               <div className="header">{props?.Environment?.Social}</div>
               <a target="_blank" href="https://www.linkedin.com/company/kanzi-app/">
                  <img src="makeicons/link.svg" alt="" />
               </a>
               <a target="_blank" href="https://twitter.com/kanziapp">
                  <img src="makeicons/twit.svg" alt="" />
               </a>
               <a target="_blank" href="https://www.facebook.com/Kanziapp-105361704989016">
                  <img src="makeicons/face.svg" alt="" />
               </a>
               <a target="_blank" href="https://www.instagram.com/kanziapp/">
                  {' '}
                  <img src="makeicons/inst.svg" alt="" />
               </a>
            </div>
            <div className="section-five logo-payemnt">
               <div className="header">{props?.Environment?.WeAccept}</div>
               <img src="makeicons/ApplePay.svg" alt="" />
               <img src="makeicons/MasteCard.svg" alt="" />
               <img src="makeicons/AmericanExpress.svg" alt="" />
               <img src="makeicons/Visa.svg" alt="" />
            </div>
         </div>
      </div>
   );
};

export default DesktopFooter;
