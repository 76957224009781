import React, { useRef, useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Tween } from 'react-gsap';
import { Swipeable } from 'react-swipeable';
import axios from 'axios';
import { Environment } from '../../env';
import Button from '../../components/Button/Button';
import {
   GenerateOtp,
   VerifyROTP,
   VerifyLOTP,
   VerifyNewOTP,
   SetError,
   VerifyResetOtp,
} from '../../redux/actions/auth/actions';
import PopUps from '../PopUps/PopUps';
import OTPInput1 from 'react-otp-input';

import CircularLoader from '../Loader/CircularLoader';
import { useLockBodyScroll } from '../../utils/Function';
import ReactPixel from 'react-facebook-pixel';

const ActionSheet = props => {
   const ref = useRef(null);
   const input1 = useRef(null);
   const input2 = useRef(null);
   const input3 = useRef(null);
   const input4 = useRef(null);
   const [type, setType] = useState('phone');
   const [countryCode, setCountryCode] = useState('+91');
   const [phoneNumber, setPhoneNumber] = useState('');
   const [otp, setotp] = useState('');
   const [poperrs, setpoperrs] = useState(false);

   const [errorPopup, setErrorPopup] = useState('none');
   const history = useHistory();
   const dispatch = useDispatch();
   const [otp_error, setotperror] = useState(null);
   const [animate, setanimate] = useState(false);
   const refer_id = useSelector(state => state.home.refer_id);
   const device_token = useSelector(state => state.home.device_token);
   const device_type = useSelector(state => state.home.device_type);
   const prevTab = useSelector(store => store.home.prevTab);

   useLockBodyScroll();

   useEffect(() => {
      function handleClickOutside(event) {
         if (ref.current && !ref.current.contains(event.target)) {
            // for removing login
         }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
         document.removeEventListener('mousedown', handleClickOutside);
      };
   }, [ref]);

   useEffect(() => {
      if (props.type === 'otp') {
         setType('otp');
      }
   }, []);

   useEffect(() => {
      (async () => {
         const res = await axios({
            method: 'GET',
            url: 'https://ipapi.co/json',
         });
         setCountryCode(res.data.country_calling_code);
      })();
   }, []);

   useEffect(() => {
      if (props.error !== null) {
         if (props.error === "Account doesn't exist. Please create an account.") {
            setErrorPopup('login_no_account');
            dispatch(SetError(null));
         } else if (props.error === 'A customer with the same email address already exists in an associated website.') {
            setErrorPopup('register_email');
            dispatch(SetError(null));
         } else if (props.error === 'Account with phone number is already exist') {
            setErrorPopup('register_phone');
            dispatch(SetError(null));
         } else if (props.error === 'A customer with the same email address already exists in an associated website.') {
            setErrorPopup('register_email');
            dispatch(SetError(null));
         }
         console.log(props.error);
         if (props.error == 'Invalid otp' || props.error == 'Invalid OTP') {
            console.log('invalid');
            setpoperrs(true);
         } else {
            setpoperrs(false);
         }
      }
   }, [props.error]);

   useEffect(() => {
      document.addEventListener('click', evt => {
         const flyoutElement = document.querySelector('.action-container');
         let targetElement = evt.target; // clicked element

         if (targetElement == flyoutElement) {
            if (props.type === 'signup') {
               props.callback();
               console.log('helloe from the other side');
            }
            return;
         }
      });
   }, []);

   const hanfchnage = otp => {
      // alert("d"+otp);

      setotp(otp);
      // setTimeout(() => {
      //   props.history.push("/cart");
      // }, 700);
   };
   const chooseErrorPopup = type => {
      if (type === 'login_no_account') {
         return {
            onSubmit: () => {
               setErrorPopup('none');
               history.push('/signup');
            },
            onClose: () => setErrorPopup('none'),
         };
      } else if (type === 'register_email') {
         return {
            onSubmit: () => {
               setErrorPopup('none');
               history.push('/account');
            },
            onClose: () => {
               setErrorPopup('none');
               history.push('/account');
            },
         };
      } else if (type === 'register_phone') {
         return {
            onSubmit: () => {
               setErrorPopup('none');
               history.push('/account');
            },
            onClose: () => {
               setErrorPopup('none');
               history.push('/account');
            },
         };
      }
      return false;
   };

   const handleGoBack = () => {
      // setTimeout(() => {
      //   props.history.push("/cart");
      // }, 700);
   };

   const generateOTP = async () => {
      console.log('hey there');

      setanimate(true);

      if (props.type === 'verify') {
         if (props.countrycode.trim().length === 0) {
            alert('please enter country code');
         } else if (props.phonenumber.trim().length === 0) {
            alert('Please enter phone number');
         } else {
            const res = await dispatch(GenerateOtp(props.countrycode.replace('+', '') + props.phonenumber, 'register'));
            if (res === "Account doesn't exist. Please create an account.") {
               setErrorPopup('login_no_account');
            } else if (res === 'Account with phone number is already exist') {
               setErrorPopup('register_phone');
            } else if (res === 'A customer with the same email address already exists in an associated website.') {
               setErrorPopup('register_email');
            } else if (typeof res === 'boolean') {
               setType('otp');
            } else {
               console.log('ERROR', res);
            }
         }
         //type login
      } else if (props.type === 'login') {
         if (countryCode.trim().length === 0) {
            alert('please enter country code');
         } else {
            if (phoneNumber.trim().length === 0) {
               alert('Please enter phone number');
            } else {
               const res = await dispatch(GenerateOtp(props.countrycode.replace('+', '') + phoneNumber, 'login'));
               if (res === "Account doesn't exist. Please create an account.") {
                  setErrorPopup('login_no_account');
               } else if (typeof res === 'boolean') {
                  setType('otp');
               } else {
                  console.log('ERROR', res);
               }
            }
         }
      } else if (props.type === 'signup') {
         const res = await dispatch(GenerateOtp(props.countrycode.replace('+', '') + props.phonenumber, 'register'));
         if (res) {
            setType('otp');
         } else {
            console.log('ERROR', res);
         }
      }

      setanimate(false);
   };

   const verifyOTP = async () => {
      setanimate(true);
      // const otp =
      //   input1.current.value +
      //   input2.current.value +
      //   input3.current.value +
      //   input4.current.value;
      var myElement = document.getElementById('partitioned').value;
      console.log('myElement' + myElement);
      if (myElement.length < 4) {
         setanimate(false);
         return alert('please enter a valid otp');
      }

      //starts here ---->

      if (props.type === 'verify') {
         await dispatch(VerifyNewOTP(props.countrycode.replace('+', ''), myElement, props.data));
         //setanimate(false)
         history.push('/accountdetails');
      } else if (props.type === 'login') {
         const res = await dispatch(
            VerifyLOTP(props.countrycode.replace('+', '') + phoneNumber, myElement, props.cartid, props.cart)
         );
         // setanimate(false)
         if (res) history.push(props.location.backTo ? props.location.backTo : '/');
      } else if (props.type === 'signup') {
         const res = await dispatch(
            VerifyROTP(
               props.countrycode.replace('+', ''),
               myElement,
               props.data,
               props.cartid,
               props.cart,
               device_type,
               device_token,
               refer_id
            )
         );
         //setanimate(false)
         // if (res) { history.push(props.backTo ? props.backTo : '/'); }
         // else {
         //   setotperror(true)
         //   setanimate(false)
         //   return dispatch(SetError(null))
         // }
         if (res) {
            ReactPixel.track('CompleteRegistration');
            // history.push(props.backTo ? props.backTo : '/');
            history.push(prevTab ? `/${prevTab}` : '/');
         } else {
            setotperror(true);
            setanimate(false);
            return dispatch(SetError(null));
         }
      } else if (props.type === 'otp') {
         const res = await dispatch(VerifyResetOtp(props.phonenumber, myElement));

         if (res === false) {
            setotperror(true);
            setanimate(false);
            return dispatch(SetError(null));
         } else {
            history.push({
               pathname: '/resetpass',
               state: {
                  token: res.token,
                  email: res.email,
                  type: 'forget',
               },
            });
         }
      }

      //ends here---->
      setanimate(false);
   };

   const Otp = props => (
      <div className="action-cards action-cards123" ref={ref}>
         <div className="cards-icon">
            <img src={`${props.Environment.img_path}makeicons/mail-send.svg`} alt="" />
         </div>
         <div className="cards-title">
            {props.Environment.otp_cards_title}
            <p
               dangerouslySetInnerHTML={{
                  __html: props.Environment.otp_verification_cards_subtitle + `<strong>${props.phonenumber}</strong>`,
               }}
            />
         </div>
         <div className="floating-form">
            <div className="actions-forms">
               <div className="code-input-container">
                  <div className="otpcontaine">
                     <div id="divOuter">
                        <div id="divInner">
                           <input
                              error={otp_error}
                              id="partitioned"
                              type="password"
                              maxlength="4"
                              placeholer="Enter OTP"
                           />

                           {poperrs === true ? (
                              <div className="invalidspanf">
                                 <span class="invalidspan">Invalid Otp</span>
                              </div>
                           ) : (
                              <div></div>
                           )}
                        </div>
                     </div>
                     {/*             
              <OTPInput1
                value={otp}
                onChange={hanfchnage}
                autoFocus
                OTPLength={4}
                otpType="number"
                disabled={false}
                secure
              /> */}
                  </div>
                  {/* <OTPInput
              error={otp_error}
              reference={input1}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (!re.test(e.target.value) & (e.target.value !== ""))
                  return (input1.current.value = "");
                else if (e.target.value) input2.current.focus();
              }}
            />
            <OTPInput
              error={otp_error}
              reference={input2}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (!re.test(e.target.value) & (e.target.value !== ""))
                  return (input2.current.value = "");
                else if (e.target.value) input3.current.focus();
                else if (e.target.value === "") input1.current.focus();
              }}
            />
            <OTPInput
              error={otp_error}
              reference={input3}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (!re.test(e.target.value) & (e.target.value !== ""))
                  return (input3.current.value = "");
                else if (e.target.value) input4.current.focus();
                else if (e.target.value === "") input2.current.focus();
              }}
            />
            <OTPInput
              error={otp_error}
              reference={input4}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (!re.test(e.target.value) & (e.target.value !== ""))
                  return (input4.current.value = "");
                else if (e.target.value === "") input3.current.focus();
              }}
            /> */}
               </div>
               <div className="text-container">
                  <div className="card_text1">{props.Environment.otp_card_text1}</div>
                  <div className="resend_code_container">
                     <div
                        className="card_text2"
                        onClick={() => {
                           generateOTP();
                        }}
                     >
                        {props.Environment.resend_code}
                     </div>
                     <span>
                        <img src={`${props.Environment.img_path}makeicons/arrow-to-right.svg`} alt="" />
                     </span>
                  </div>
               </div>
               <div className="">
                  <Button className="button-red" data={props.Environment.verify} onClick={verifyOTP} />
               </div>
            </div>
         </div>
      </div>
   );

   return (
      <div className="action-container currency-contadd">
         <CircularLoader show={animate}></CircularLoader>
         {errorPopup !== 'none' && (
            <PopUps
               type="login"
               errorType={errorPopup}
               onSubmit={() => chooseErrorPopup(errorPopup).onSubmit()}
               onClose={() => chooseErrorPopup(errorPopup).onClose()}
            />
         )}
         {type === 'otp' ? (
            <Otp {...props} />
         ) : (
            <Swipeable onSwipedDown={handleGoBack}>
               <Tween from={{ y: '100%', opacity: 1, delay: 0.9, duration: 0.8 }}>
                  <div className="action-cards action-cards123">
                     <div className="cards-icon">
                        <img src={`${props.Environment.img_path}makeicons/mail-send.svg`} alt="" />
                     </div>
                     <div className="cards-title">
                        {props.Environment.otp_cards_title}
                        <p
                           dangerouslySetInnerHTML={{
                              __html: props.Environment.otp_cards_subtitle,
                           }}
                        />
                     </div>
                     <div className="floating-form">
                        <div className="actions-forms">
                           <div className="floating-label">
                              <input
                                 key="input1"
                                 className="floating-input code-input"
                                 type="text"
                                 inputMode="numeric"
                                 pattern="[0-9]*"
                                 value={props.countrycode !== undefined ? props.countrycode : countryCode}
                                 contentEditable={props.countrycode === undefined}
                                 onChange={e => setCountryCode(e.currentTarget.value)}
                              />
                              <input
                                 key="input2"
                                 className="floating-input mobile-input"
                                 type="text"
                                 inputMode="numeric"
                                 pattern="[0-9]*"
                                 value={props.phonenumber !== undefined ? props.phonenumber : phoneNumber}
                                 contentEditable={props.phonenumber === undefined}
                                 onChange={e => setPhoneNumber(e.currentTarget.value)}
                              />
                              <span className="highlight" />
                              <label>{props.Environment.phone_number}</label>
                           </div>
                           <div className="">
                              <Button className="button-red" data="Generate OTP" onClick={() => generateOTP()} />
                           </div>
                           {/* {
                      props.phonenumber === undefined ? (
                        <div className="alreadyaccount">
                          {props.Environment.donthaveaccount}
                          <Link onClick={() => history.push({
                            pathname: '/signup',
                            backTo: props.location.backTo ? props.location.backTo : '/'
                          })}>{props.Environment.singup}</Link>
                        </div>
                      ) : null
                    } */}
                        </div>
                     </div>
                  </div>
               </Tween>
            </Swipeable>
         )}
      </div>
   );
};

const mapStateToProps = state => {
   return {
      token: state.auth.token,
      error: state.auth.error,
      userinfo: state.auth.userinfo,
      cart: state.cart.cart,
      cartid: state.auth.cartid,
   };
};

export default connect(mapStateToProps)(ActionSheet);
