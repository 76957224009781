import React from 'react';
import { Environment } from '../../env';
import { Tween } from 'react-gsap';
import { get } from 'lodash';

const MonthWinner = props => {
   return (
      <Tween from={{ x: '100%', opacity: 1, duration: 0.5 }}>
         <div className={`monthwinner-main-conatiner ${props.winnerlist.status != 'active' ? 'disabled' : ''}`}>
            <div className="monthwinner-cards">
               <div className="upper-block">
                  <div className="winner-user-block">
                     <div className="winner-user-img">
                        <img src={get(props, 'winnerlist.winner_image[0].url', '')} alt="" />
                     </div>
                     <span className="winner-badge">
                        <img src={`${Environment.img_path}makeicons/winner-star-badges.svg`} alt="" />
                     </span>
                  </div>
                  <div className="winner-detail">
                     <div className="winner-date">{get(props, 'winnerlist.created_at', '')}</div>
                     <div className="winner-name">{get(props, 'winnerlist.winner_name', '')}</div>
                     {/* <div className="winner-flag">
                        <img src={`${Environment.img_path}images/France_Flag.svg`} alt="" />
                        {Environment.french}
                     </div> */}
                  </div>

                  <div className="winner-prize-img">
                     <img src={get(props, 'winnerlist.prize_image', '')} alt="" />
                  </div>
               </div>
               <div className="ticketnumber-block">
                  {Environment.ticketnumbertxt}
                  <span>{get(props, 'winnerlist.winner_ticket_number', '')}</span>
               </div>
            </div>
         </div>
      </Tween>
   );
};

export default MonthWinner;
