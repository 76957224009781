import React, { useRef, useEffect, useState } from "react"
import Account from "../../pages/Account/Account"
import { Environment as en } from '../../env';
import { Environment as ar } from '../../env_ar';
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { get } from "lodash"

const SideMenu = (props) => {
    const ref_container = useRef(null)
    const ref_bg = useRef(null)
    const [file, setfile] = useState(en)
    const history = useHistory()
    const store = useSelector(store => store)
    let user_info = get(store, 'auth.userinfo', { firstname: "John", email: "john@kanzi.com" })

    useEffect(() => {
        if (true) {
            ref_container.current.style.marginRight = "0px"
            //ref_bg.current.style.display = "flex"
            ref_bg.current.style.backgroundColor = "rgba(0,0,0,0.75)"
        }
    }, [/* props.show */])

    useEffect(() => {
        if (get(store, 'home.language', 'en') == "en") {
            setfile(en)
        }
        else {
            setfile(ar)
        }
    }, [get(store, 'home.language', 'en')])

    const OnCross = () => {
        ref_container.current.style.marginRight = "-434px"
        ref_bg.current.style.backgroundColor = "rgba(0,0,0,0)"
        setTimeout(() => {
            props.setShow()
            //ref_bg.current.style.display = "none"
        }, 1700)
    }

    return (
        <div className="sidemenu-parent" ref={ref_bg}>
            <div className="container-side" ref={ref_container}>
                <p className="cross" onClick={OnCross}>
                    <img src={`${file.img_path}makeicons/cross.svg`} />
                </p>
                {user_info != null ? <p className="title">Marhaba <span>{user_info.firstname}</span></p> : null}
                {user_info != null ? <p className="email">{user_info.email}</p> : null}
                <Account account={true} Environment={file} />
            </div>
        </div>
    )
}

export default SideMenu