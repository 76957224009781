import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SetisFav } from '../../redux/actions/favourites/actions';
import { SetPrevTab } from '../../redux/actions/home/actions';

import { useDispatch } from 'react-redux';
import { TweenMax } from 'gsap';
import { get } from 'lodash';
import $ from 'jquery';
import { Tabs } from './../../constants/tabs';

const Footer = () => {
   const cartState = useSelector(state => state.cart.cart);
   const cartQty = cartState.reduce((acc, val) => acc + val.qty, 0);
   const [show, setShow] = useState(true);
   const [line, setLine] = useState(false);
   const location = useLocation();
   const isFav = useSelector(store => store.favourite.isfavourite);
   const dispatch = useDispatch();
   const [selected, setselected] = useState(null);
   const store = useSelector(store => store);
   const prevTab = useSelector(store => store.home.prevTab);

   useEffect(() => {
      var iOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/i) ? true : false;
      //iOS = true;
      if (iOS) {
         setLine(false);
      }
      var ua = navigator.userAgent.toLowerCase();
      var isAndroid = ua.indexOf('android') > -1; //&& ua.indexOf("mobile");
      if (isAndroid) {
         setLine(true);
      }

      if (
         /signup|login|checkoutyourdetails|addcards_payment|addcards_checkout|orderdetails|checkoutdetails|myhistory|somethingwrong|notanaccount|accountdetails|personaldetails|savedaddress|payreview|addaddress|paymentmethod|mykoinz|accountsettings|notificationsettings|notifications|howitworks|faqs|drawtnc|contactus|thismonthwinner|aboutapp|tnc|privacypolicy|referfriend|resetpassword|resetpass|language|currency|heropath|koinzdetails|badgeinfo/.test(
            location.pathname
         )
      ) {
         setShow(false);
      } else {
         setShow(true);
      }
   }, [location]);

   useEffect(() => {
      if (get(store, 'home.language', null) == 'ar' && !location.pathname.includes('language')) {
         document.getElementById('home-menu').click();
      }
   }, [get(store, 'home.language', null)]);

   useEffect(() => {
      let timeout;
      if (isFav) {
         TweenMax.fromTo('.active-heart', 1, { scale: 0, opacity: 0 }, { scale: 1.2, opacity: 1 });

         timeout = setTimeout(() => {
            dispatch(SetisFav());
            TweenMax.fromTo('.active-heart', 1, { scale: 1.2, opacity: 1 }, { scale: 0, opacity: 0 });
         }, 1500);
      }

      return () => {
         clearTimeout(timeout);
      };
   }, [isFav]);

   // useEffect(() => {
   //    // Update the document title using the browser API
   //    const menu_bar = document.querySelector('.footer-bottom-bar');
   //    const menu_item = document.querySelectorAll('.footer-menu-item');
   //    const menu_indicator = document.querySelector('.footer-nav-indicator');
   //    // const menu_current_item = document.querySelector(".footer-current");
   //    let menu_position;
   //    // menu_position = menu_current_item.offsetLeft - 15;
   //    // menu_indicator.style.left = menu_position + "px";
   //    // menu_bar.style.backgroundPosition = menu_position - 8 + "px";
   //    menu_item.forEach(select_menu_item => {
   //       select_menu_item.addEventListener('click', function (e) {
   //          document.querySelector('.footer-nav-indicator').style.display = 'block';
   //          if (e.target.id !== 'cart-menu') {
   //             menu_position = this.offsetLeft - 16;
   //             // menu_indicator.style.left = menu_position + "px";
   //             // menu_bar.style.backgroundPosition = menu_position - 8 + "px";
   //             menu_indicator.style.left = this.offsetLeft !== 0 ? menu_position + 'px' : '20px';
   //             menu_bar.style.backgroundPosition = this.offsetLeft !== 0 ? menu_position - 8 + 'px' : '12px';
   //             [...select_menu_item.parentElement.children].forEach(sibling => {
   //                sibling.classList.remove('footer-current');
   //             });
   //             select_menu_item.classList.add('footer-current');
   //          } else {
   //             if (e.target.children[1].classList.contains('badge') === true) {
   //                document.querySelector('.footer-nav-indicator').style.display = 'none';
   //             }
   //          }
   //       });
   //    });
   //    if (cartQty === 0) {
   //       document.querySelector('.footer-nav-indicator').style.display = 'block';
   //    } else {
   //       document.querySelector('.footer-nav-indicator').style.display = 'none !important';
   //    }
   // }, [cartQty, location]);

   // useEffect(() => {
   //    if (location.pathname === '/cart') {
   //       document.getElementById('cart-menu').click();
   //    } else if (location.pathname === '/watchlist') {
   //       document.getElementById('watchlist-menu').click();
   //    } else if (location.pathname === '/tickets') {
   //       document.getElementById('tickets-menu').click();
   //    } else if (location.pathname === '/') {
   //       document.getElementById('home-menu').click();
   //    }
   //    if (location.pathname === '/account') {
   //       document.getElementById('account-menu').click();
   //    }
   // }, [location, cartQty]);

   const scroold = () => {
      $('html, body').animate(
         {
            scrollTop: $('.fds').offset().top,
         },
         700
      );
   };

   const setPrevTab = tabName => {
      if (prevTab !== tabName && !location.isNaviagtedByCheckout) dispatch(SetPrevTab(tabName));
   };
   return (
      <div className={`footer-container ${show ? '' : 'none'}`}>
         {line == true && <div className="bottom-line"></div>}
         {/* <div className="footer-bottom-bar footer-animation"> */}
         {/* <div className="footer-bottom-bar"> */}
         <div
            className={`footer-bottom-bar ${cartQty >= 1 ? 'footer-animation' : ''} ${
               location.pathname === '/cart' && cartQty >= 1 ? 'footer-qty-available' : 'footer-qty-not-available'
            }`}
         >
            <p className="footer-nav-indicator" />
            <div className="footer-nav-indicator-cart-block">
               {/* {location.pathname !== '/cart' && <Link to="/" className="footer-nav-indicator-cart" />} */}
               <Link to="/" className="footer-nav-indicator-cart" />
            </div>

            <Link
               to="/"
               id="home-menu"
               className="footer-menu-item footer-current"
               onClick={() => {
                  setselected(null);
               }}
            >
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  onClick={() => {
                     scroold();
                  }}
               >
                  <g id="ic-prizes" transform="translate(74 -767)">
                     <rect
                        id="Rectangle_3"
                        data-name="Rectangle 3"
                        width="26"
                        height="26"
                        transform="translate(-74 767)"
                        fill="none"
                     />
                     <g id="ic-account" transform="translate(-73 767.991)">
                        <path
                           id="Path_3081"
                           data-name="Path 3081"
                           d="M18.75,24H5.25A2.253,2.253,0,0,1,3,21.75V13.5H1.25A1.252,1.252,0,0,1,0,12.25a1.274,1.274,0,0,1,.367-.888L11.469.221a.772.772,0,0,1,1.063,0l11.1,11.14A1.275,1.275,0,0,1,24,12.25a1.252,1.252,0,0,1-1.25,1.25H21v8.25A2.253,2.253,0,0,1,18.75,24ZM1.849,12h1.9a.75.75,0,0,1,.75.75v9a.751.751,0,0,0,.75.75h13.5a.751.751,0,0,0,.75-.75v-9a.75.75,0,0,1,.75-.75h1.9L12,1.813Zm20.718.417h.01Z"
                           fill="#464646"
                        />
                        <path
                           id="Path_3082"
                           data-name="Path 3082"
                           d="M12.75,20H8.25A1.752,1.752,0,0,1,6.5,18.25v-6.5A1.752,1.752,0,0,1,8.25,10h4.5a1.752,1.752,0,0,1,1.75,1.75v6.5A1.752,1.752,0,0,1,12.75,20Zm-4.5-8.5a.25.25,0,0,0-.25.25v6.5a.25.25,0,0,0,.25.25h4.5a.25.25,0,0,0,.25-.25v-6.5a.25.25,0,0,0-.25-.25Z"
                           transform="translate(1.5 4)"
                           fill="#464646"
                        />
                     </g>
                  </g>
               </svg>

               <span className="nav-text site-e">Home</span>
               <span className="nav-text site-a">الرئيسية</span>
            </Link>

            <Link
               to="/tickets"
               id="tickets-menu"
               className="footer-menu-item"
               onClick={() => {
                  setselected(null);
               }}
            >
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26.3"
                  height="25"
                  viewBox="0 0 26.3 25"
                  onClick={() => {
                     scroold();
                  }}
               >
                  <g id="ic-ticket" transform="translate(40 -767.161)">
                     <rect
                        id="Rectangle_4"
                        data-name="Rectangle 4"
                        width="25"
                        height="25"
                        transform="translate(-40 767.161)"
                        fill="none"
                     />
                     <g id="ic-ticket-2" data-name="ic-ticket" transform="translate(-39.032 768.935)">
                        <g id="ticket" transform="translate(0 0)">
                           <path
                              id="Path_2411"
                              data-name="Path 2411"
                              d="M331.41,131.37l.581-.581.581.581-.581.581Zm0,0"
                              transform="translate(-314.289 -124.043)"
                              fill="#464646"
                              stroke="#464646"
                              strokeWidth="0.5"
                           />
                           <path
                              id="Path_2412"
                              data-name="Path 2412"
                              d="M308.773,108.733l.581-.581.581.581-.581.581Zm0,0"
                              transform="translate(-292.821 -102.576)"
                              fill="#464646"
                              stroke="#464646"
                              strokeWidth="0.5"
                           />
                           <path
                              id="Path_2413"
                              data-name="Path 2413"
                              d="M286.148,86.116l.581-.581.581.581-.581.581Zm0,0"
                              transform="translate(-271.365 -81.127)"
                              fill="#464646"
                              stroke="#464646"
                              strokeWidth="0.5"
                           />
                           <path
                              id="Path_2414"
                              data-name="Path 2414"
                              d="M376.664,176.62l.581-.581.581.581-.581.581Zm0,0"
                              transform="translate(-357.205 -166.954)"
                              fill="#464646"
                              stroke="#464646"
                              strokeWidth="0.5"
                           />
                           <path
                              id="Path_2415"
                              data-name="Path 2415"
                              d="M354.035,153.987l.581-.581.581.581-.581.581Zm0,0"
                              transform="translate(-335.745 -145.491)"
                              fill="#464646"
                              stroke="#464646"
                              strokeWidth="0.5"
                           />
                           <path
                              id="Path_2416"
                              data-name="Path 2416"
                              d="M200.27,185.166a.413.413,0,0,1-.368-.225l-.746-1.464-1.623.256a.413.413,0,0,1-.357-.7l1.161-1.161-.744-1.464a.413.413,0,0,1,.555-.555l1.464.744,1.161-1.161a.413.413,0,0,1,.7.357l-.262,1.627,1.464.746a.413.413,0,0,1-.124.774l-1.623.257-.253,1.623a.413.413,0,0,1-.344.344.385.385,0,0,1-.066.006Zm-.882-2.56a.414.414,0,0,1,.369.225l.3.6.105-.661a.413.413,0,0,1,.344-.344l.661-.105-.6-.3a.413.413,0,0,1-.221-.433l.1-.663-.473.474a.413.413,0,0,1-.484.075l-.6-.3.3.6a.413.413,0,0,1-.076.484l-.475.475.663-.1A.388.388,0,0,1,199.388,182.606Zm0,0"
                              transform="translate(-186.872 -170.057)"
                              fill="#464646"
                              stroke="#464646"
                              strokeWidth="0.5"
                           />
                           <path
                              id="Path_2417"
                              data-name="Path 2417"
                              d="M24.669,6.477,22.623,4.431l-.29.29A1.24,1.24,0,1,1,20.578,2.97l.29-.29L18.823.633a1.24,1.24,0,0,0-1.754,0L15.609,2.094l-.877-.877a1.24,1.24,0,0,0-1.754,0L.363,13.833a1.24,1.24,0,0,0,0,1.754l2.046,2.046.29-.29a1.27,1.27,0,0,1,1.754,0,1.24,1.24,0,0,1,0,1.754l-.29.29,2.046,2.046a1.24,1.24,0,0,0,1.753,0L9.422,19.97l.877.877a1.24,1.24,0,0,0,1.754,0L24.669,8.234a1.24,1.24,0,0,0,0-1.754ZM7.377,20.847a.413.413,0,0,1-.581,0l-1.49-1.49a2.066,2.066,0,0,0-2.865-2.865L.951,15a.413.413,0,0,1,0-.581L13.564,1.8a.413.413,0,0,1,.581,0l.877.877L4.452,13.249a1.24,1.24,0,0,0,0,1.753L6.5,17.048l.29-.29a1.241,1.241,0,0,1,1.757,1.752l-.29.29.581.581Zm16.707-13.2L11.468,20.262a.413.413,0,0,1-.581,0L9.4,18.772a2.067,2.067,0,0,0-2.867-2.864l-1.49-1.49a.413.413,0,0,1,0-.581L17.654,1.217a.413.413,0,0,1,.581,0L19.725,2.7a2.066,2.066,0,0,0,2.864,2.865L24.08,7.061a.413.413,0,0,1,0,.581Zm0,0"
                              transform="translate(0 -0.27)"
                              fill="#464646"
                              stroke="#464646"
                              strokeWidth="0.6"
                           />
                        </g>
                     </g>
                  </g>
               </svg>

               <span className="nav-text site-e">Tickets</span>
               <span className="nav-text site-a">التذاكر</span>
            </Link>

            <Link
               to="/cart"
               id="cart-menu"
               className="footer-menu-item"
               onClick={() => {
                  setselected(null);
               }}
            >
               <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                  <g id="ic-cart" transform="translate(108 -767)">
                     <rect
                        id="Rectangle_2"
                        data-name="Rectangle 2"
                        width="26"
                        height="26"
                        transform="translate(-108 767)"
                        fill="none"
                     />
                     <g id="ic-cart-2" data-name="ic-cart" transform="translate(-106.998 768.999)">
                        <path
                           id="Path_2272"
                           data-name="Path 2272"
                           d="M.294,884.2a3.036,3.036,0,1,0,3.036,3.036A3.037,3.037,0,0,0,.294,884.2Zm1.512,3.036a1.512,1.512,0,1,1-1.512-1.511,1.514,1.514,0,0,1,1.512,1.511Z"
                           transform="translate(8.587 -867.71)"
                           fill="#464646"
                        />
                        <path
                           id="Path_2273"
                           data-name="Path 2273"
                           d="M15.166,872.816a.763.763,0,0,0-.608-.308H-3.324l-.332-1.86a3.339,3.339,0,0,0-2.7-2.691l-1.335-.235a.768.768,0,0,0-.886.618.765.765,0,0,0,.619.887l1.341.236a1.806,1.806,0,0,1,1.458,1.458l1.813,10.228A2.8,2.8,0,0,0-.58,883.473H10.187a2.824,2.824,0,0,0,2.687-2l2.409-7.985a.789.789,0,0,0-.117-.672ZM13.53,874.03l-2.113,6.995a1.284,1.284,0,0,1-1.225.912H-.576a1.275,1.275,0,0,1-1.261-1.064l-1.214-6.844Z"
                           transform="translate(8.587 -867.71)"
                           fill="#464646"
                        />
                        <path
                           id="Path_2274"
                           data-name="Path 2274"
                           d="M10.125,884.2a3.036,3.036,0,1,0,3.037,3.036A3.036,3.036,0,0,0,10.125,884.2Zm1.512,3.036a1.512,1.512,0,1,1-1.512-1.511,1.512,1.512,0,0,1,1.512,1.511Z"
                           transform="translate(8.587 -867.71)"
                           fill="#464646"
                        />
                     </g>
                  </g>
               </svg>
               {cartQty !== 0 && <span className="badge">{cartQty}</span>}
               <span className="nav-text site-e">Cart</span>
               <span className="nav-text site-a">العربة</span>
            </Link>

            <Link
               to="/watchlist"
               id="watchlist-menu"
               className="footer-menu-item"
               onClick={() => {
                  setselected('fav');
               }}
            >
               (
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  onClick={() => {
                     scroold();
                  }}
               >
                  <g id="ic-watchlist" transform="translate(4 -766)">
                     <rect
                        id="Rectangle_5"
                        data-name="Rectangle 5"
                        width="26"
                        height="26"
                        transform="translate(-4 766)"
                        fill="none"
                     />
                     <g id="Icon_Watchlist_Inactive" data-name="Icon Watchlist Inactive" transform="translate(-2 769)">
                        <g id="heart_1_" data-name="heart (1)" transform="translate(0 -29.836)">
                           <g id="Group_4911" data-name="Group 4911" transform="translate(0 29.836)">
                              <path
                                 id="Path_3080"
                                 data-name="Path 3080"
                                 d="M20.707,31.711a5.741,5.741,0,0,0-8.983.625,8.24,8.24,0,0,0-.556.872,8.233,8.233,0,0,0-.556-.872,5.741,5.741,0,0,0-8.983-.625A6.421,6.421,0,0,0,0,36.06a7.883,7.883,0,0,0,2.284,5.261A50.2,50.2,0,0,0,8,46.485c.864.712,1.758,1.449,2.71,2.254l.029.024a.671.671,0,0,0,.862,0l.029-.024c.952-.805,1.845-1.542,2.71-2.254a50.191,50.191,0,0,0,5.715-5.164,7.883,7.883,0,0,0,2.284-5.261A6.421,6.421,0,0,0,20.707,31.711ZM13.489,45.522c-.745.614-1.512,1.246-2.321,1.926-.809-.68-1.575-1.312-2.321-1.926-4.54-3.741-7.539-6.213-7.539-9.462a5.181,5.181,0,0,1,1.3-3.512,4.406,4.406,0,0,1,6.933.52,7.4,7.4,0,0,1,1,1.926.662.662,0,0,0,1.244,0,7.4,7.4,0,0,1,1-1.926,4.406,4.406,0,0,1,6.933-.52,5.181,5.181,0,0,1,1.3,3.512c.01,3.249-2.989,5.721-7.529,9.462Z"
                                 transform="translate(0 -29.836)"
                                 fill={
                                    get(store, 'favourite.favourites', []).length === 0 || selected === 'fav'
                                       ? '#464646'
                                       : '#e03a3f'
                                 }
                                 stroke={
                                    get(store, 'favourite.favourites', []).length === 0 || selected === 'fav'
                                       ? '#464646'
                                       : '#e03a3f'
                                 }
                                 strokeWidth="0.4"
                              />
                           </g>
                        </g>
                     </g>
                  </g>
               </svg>
               )<span className="nav-text site-e">Favorites</span>
               <span className="nav-text site-a">قائمتي المفضلة</span>
            </Link>

            <Link
               to="/menu"
               id="account-menu"
               className="footer-menu-item"
               onClick={() => {
                  setselected(null);
               }}
            >
               <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                  <g id="ic-account" transform="translate(143 -766)">
                     <rect
                        id="Rectangle_1"
                        data-name="Rectangle 1"
                        width="26"
                        height="26"
                        transform="translate(-143 766)"
                        fill="none"
                     />
                     <g id="ic-account-2" data-name="ic-account" transform="translate(-139.001 768.999)">
                        <path
                           id="Path_2270"
                           data-name="Path 2270"
                           d="M54.684,878.979h.137a5.149,5.149,0,1,0-.137,0Zm-2.466-7.847a3.584,3.584,0,0,1,4.991-.071v.144h.147a3.585,3.585,0,0,1-5.071,5.064l-.077-.077a3.572,3.572,0,0,1,.005-5.056Z"
                           transform="translate(-45.858 -868.353)"
                           fill="#464646"
                        />
                        <path
                           id="Path_2271"
                           data-name="Path 2271"
                           d="M63.861,884.752a12.786,12.786,0,0,0-.173-1.354,10.921,10.921,0,0,0-.338-1.368,6.848,6.848,0,0,0-.569-1.292,4.97,4.97,0,0,0-.875-1.139,3.879,3.879,0,0,0-1.27-.8,4.713,4.713,0,0,0-1.634-.29,1.751,1.751,0,0,0-.934.388c-.246.161-.534.346-.848.547a4.663,4.663,0,0,1-1.059.467,4.039,4.039,0,0,1-2.564,0,4.61,4.61,0,0,1-1.049-.462c-.272-.17-.523-.334-.744-.478l-.086-.054a1.794,1.794,0,0,0-.98-.407,4.26,4.26,0,0,0-1.611.291,3.878,3.878,0,0,0-1.274.8,4.944,4.944,0,0,0-.871,1.134,6.908,6.908,0,0,0-.571,1.295,10.639,10.639,0,0,0-.333,1.367,12.29,12.29,0,0,0-.173,1.356c-.028.4-.043.823-.043,1.249a3.756,3.756,0,0,0,1.117,2.836,3.96,3.96,0,0,0,2.679,1.044H59.935a3.99,3.99,0,0,0,2.858-1.048,3.738,3.738,0,0,0,1.111-2.819c0-.434-.015-.857-.043-1.263Zm-13.283-4.516c.041.024.1.058.17.106.254.166.546.353.875.564a6.351,6.351,0,0,0,1.444.646,5.756,5.756,0,0,0,3.623,0,6.376,6.376,0,0,0,1.455-.652c.331-.212.616-.394.87-.56.073-.048.128-.082.169-.106a2.6,2.6,0,0,1,.82.169,2.179,2.179,0,0,1,.7.436,3.281,3.281,0,0,1,.567.747,5.073,5.073,0,0,1,.425.964,8.679,8.679,0,0,1,.279,1.147,11.219,11.219,0,0,1,.152,1.173c.025.366.038.749.039,1.155a2.039,2.039,0,0,1-.575,1.552,2.232,2.232,0,0,1-1.649.566H49.807a2.238,2.238,0,0,1-1.63-.561,2.037,2.037,0,0,1-.582-1.573c0-.389.012-.772.038-1.138a11.311,11.311,0,0,1,.151-1.176,8.674,8.674,0,0,1,.279-1.144,5.148,5.148,0,0,1,.427-.968,3.224,3.224,0,0,1,.56-.738,2.166,2.166,0,0,1,.711-.442,2.571,2.571,0,0,1,.817-.167Z"
                           transform="translate(-45.858 -868.353)"
                           fill="#464646"
                        />
                     </g>
                  </g>
               </svg>

               <span className="nav-text site-e">Account</span>
               <span className="nav-tex site-a">حسابي</span>
            </Link>
         </div>
      </div>
   );
};

export default Footer;
