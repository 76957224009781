import React, { useEffect, useState, useRef } from "react";
import NavBar from "../../components/NavBar/NavBar";
import CartEmpty from "../../components/CartEmpty/CartEmpty";
import CheckoutCart from "../../components/CheckoutCart/CheckoutCart";
import Button from "../../components/Button/Button";
import { Link, useLocation } from "react-router-dom";
import {
  ViewCart,
  ViewMamboProfile,
  SetUseKoinz,
  EmptyKoinzProduct,
  GetConversionRates,
  LoyaltyData,
  BadgeData
} from "../../redux/actions/cart/actions";
import { GuestViewCart } from "../../redux/actions/guestcart/actions";
import { unuseLockBodyScroll } from "../../utils/Function";
import PopUps from "../../components/PopUps/PopUps";

import { useStore, useDispatch, useSelector } from "react-redux";

import Loader from "../../components/Loader/Loader";
import { useSwipeable, Swipeable } from "react-swipeable";
import { isObjEmpty, decimalUptoTwoDigits } from "../../utils/utils";
import { get, isNil } from "lodash";
import { comify } from "../../utils/Function";
import { currencyconvertrate } from "../../utils/Function";
import TagManager from "react-gtm-module";

const script = document.createElement("script");
script.src =
  "https://i.ctnsnet.com/int/integration?pixel=67834349&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}";
script.async = true;
let triggerGTAG = false;
const Cart = props => {
  unuseLockBodyScroll();
  const [toggleState, setToggleState] = useState("off");
  const [popup, setPopup] = useState("none");
  const [totalAmount, setTotalAmount] = useState(0);
  const [cartPopup, setCartPopup] = useState(false);
  const [cartPopup1, setCartPopup1] = useState(true);
  const [isRendered, setRendered] = useState(false);
  const store = useSelector(store => store);
  const [, setKoinzModeFlag] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const card = useRef(null);
  const cart_items = useSelector(store => store.cart.cart);
  const koinz = useSelector(store => store.mambo.data);
  const useKoinz = useSelector(store => store.cart.useKoinz);
  const [cv_rate, setcvrate] = useState(0.2);
  const [d_type, setdtype] = useState("home");
  const koinz_replacement = useSelector(store => store.cart.koinz_replacement);
  const [koinzc, setkoinz] = useState(0);
  const [delivery_amount, setdm] = useState(15);
  const [ntotal, setNtotal] = useState(0);
  const [needkoinspop, setNeedkoinspop] = useState(false);

  const token = useSelector(store => get(store, "auth.token", ""));

  // For google tag manager
  useEffect(
    () => {
      if (cart_items.length > 0 && triggerGTAG === false) {
        triggerGTAG = true;
        const filteredCart = cart_items.map((data, index) => ({
          item_name: data.name,
          item_id: data.item_id,
          price: data.price,
          ...(data?.extension_attributes?.configurable_item_options
            ? {
                item_variant:
                  data?.extension_attributes?.configurable_item_options?.[0]
                    ?.option_value
              }
            : {}),
          item_list_name: data.product_type,
          item_list_id: data.sku,
          index: index + 1,
          quantity: data.qty
        }));

        const clearDataLater = { dataLayer: { ecommerce: null } };
        const tagManagerArgs = {
          dataLayer: {
            event: "add_to_cart",
            ecommerce: {
              items: filteredCart
            }
          }
        };

        TagManager.dataLayer(clearDataLater);
        TagManager.dataLayer(tagManagerArgs);
      }
    },
    [cart_items]
  );

  useEffect(() => {
    if (document.getElementById("cart-page-wrapper"))
      document.getElementById("cart-page-wrapper").appendChild(script);
    return () => {
      if (document.getElementById("cart-page-wrapper"))
        document.getElementById("cart-page-wrapper").removeChild(script);
    };
  }, []);
  //console.log(cart_items)

  useEffect(
    () => {
      // alert("ds");
      (async () => {
        //setRendered(false);//Demo
        setRendered(true); //Demo
        if (store.auth.token !== null) {
          // localStorage.setItem('orderAmount', 0);
          // alert(dsd)
          await dispatch(ViewCart());
          //await dispatch(BadgeData(store.auth.userinfo.id))
        }
        setRendered(true);
      })();

      //LoyaltyData(store.auth.userinfo.id)
    },
    [store.auth.token]
  );

  useEffect(
    () => {
      localStorage.setItem("order_id", "");
      localStorage.setItem("realorder_id", "");

      CalcKoinz();
    },
    [store.cart.cart, store.cart.koinz_replacement]
  );

  useEffect(
    () => {
      if (isObjEmpty(get(store, "mambo.data", {}))) {
      } else {
        var conversion_rate = get(store, "cart.config", []).find(
          item => item.name == "earning_conversion_rate"
        );
        var level = get(store, "mambo.data", null);
        if (level.level != null) {
          level = level.level.reward.customFields;
        } else {
          return;
        }
        level = level.find(fields => fields.name == "loyalty_level_bonus");
        if (level != undefined) {
          setcvrate(
            (conversion_rate == undefined
              ? 0.2
              : parseFloat(conversion_rate.value)) +
              (level.value == undefined
                ? 0 * 0.01
                : parseFloat(level.value) * 0.01)
          );
        }
      }
    },
    [get(store, "mambo.data", {})]
  );

  useEffect(() => {
    if (get(store, "cart.useKoinz", false)) {
      setToggleState("on");
    } else {
      setToggleState("off");
    }
    localStorage.setItem("pickupa", false);
    // dispatch(EmptyKoinzProduct())
  }, []);

  useEffect(
    () => {
      if (location.pathname === "/cart") {
        setTimeout(() => {
          if (document.getElementById("cart-menu"))
            document.getElementById("cart-menu").click();
        }, 0);
      }
    },
    [location.pathname]
  );

  useEffect(
    () => {
      (async () => {
        if (store.auth.token === null) {
          if (store.auth.cartid !== null) {
            setRendered(false);
            await dispatch(GuestViewCart(store.auth.cartid));
            setRendered(true);
          }
        }
      })();
    },
    [store.auth.cartid]
  );

  const calcTotalAmount = () => {
    let amount = 0;

    store.cart.cart.forEach(element => {
      amount = amount + element.qty * element.price;
    });

    // setTotalAmount(amount);
    setNtotal(Math.round(amount));
    localStorage.setItem("subtotoal", Math.round(amount));
    let totoal = Math.round(amount) + delivery_amount;
    localStorage.setItem("totoal", totoal);
    // alert(totoal)
    setTotalAmount(totoal);
  };

  const handleCheckout = () => {
    if (toggleState == "off") {
      localStorage.setItem("orderAmount", 0);
    }
    if (toggleState == "on") {
      // if (doiscount == -15 || doiscount == -4){
      //    doiscount = 0
      // }
      localStorage.setItem(
        "orderAmount",
        currencyconvertrate(doiscount, store)
      );
    }

    // const ordervalue = useKoinz
    //    ? comify(
    //         decimalUptoTwoDigits(
    //            handleTotalAmount(currencyconvertrate(totalAmount, store) - HandleKoinzSelection()) + ''
    //         )
    //      )
    //    : comify(decimalUptoTwoDigits(handleTotalAmount(currencyconvertrate(totalAmount, store)) + ''));

    // localStorage.setItem('finalOrderAmount', JSON.stringify(Number(ordervalue)));

    if (store.auth.token !== null) {
      // if (store.cart.customerData !== null && store.cart.customerData.length > 0) {
      //    props.history.replace('/checkoutdetails');
      // } else {
      //    props.history.replace('/checkoutyourdetails');
      // } thanveer

      props.history.push({
        pathname: "/checkoutdetails",
        props: { type: d_type }
      });
    } else {
      /* props.history.replace({
            pathname: '/login',
            backTo: '/cart',
         }); */
      props.history.push({
        pathname: "/account/login",
        isNaviagtedByCheckout: true
      });
    }
  };

  useEffect(
    () => {
      calcTotalAmount();
    },
    [store.cart.cart]
  );

  const toggle = () => {
    setToggleState(
      useKoinz ? "off" : "on" /* toggleState === 'off' ? 'on' : 'off' */
    );
    dispatch(SetUseKoinz());
  };
  let dtotle = 0;
  let kionzdtotle = 0;
  let doiscount = 0;
  let totlamoutp = 0;
  const handleTotalAmount = (total, vat = 0) => {
    dtotle = total + total * vat / 100;

    if (dtotle != 0) {
      totlamoutp = dtotle;
    }
    kionzdtotle = Math.round(
      (isObjEmpty(koinz) && isNil(koinz.points)
        ? "0"
        : koinz.points[0].balance) *
        (get(store, "cart.config", []).find(
          item => item.name == "burning_conversion_rate"
        ) == undefined
          ? 0.2
          : parseFloat(
              get(store, "cart.config", []).find(
                item => item.name == "burning_conversion_rate"
              ).value
            ))
    );

    //  console.log(total + total * vat / 100);

    if (dtotle == 0 || dtotle < 0) {
      doiscount = totlamoutp;
    } else {
      doiscount = kionzdtotle;
    }

    return total + total * vat / 100;
  };

  const HandleKoinzSelection = () => {
    var conversion_rate = get(store, "cart.config", []).find(
      item => item.name == "burning_conversion_rate"
    );

    if (useKoinz) {
      if (isObjEmpty(koinz)) {
        if (store.auth.token != null) {
          dispatch(ViewMamboProfile());
        }
        return 0;
      } else if (koinz.points[0].balance == 0) {
        return 0;
      } else {
        if (koinz_replacement.length !== get(store, "cart.cart", []).length) {
          if (
            koinz.points[0].balance *
              (conversion_rate == undefined
                ? 0.2
                : parseFloat(conversion_rate.value)) >
            handleTotalAmount(totalAmount)
          ) {
            return handleTotalAmount(totalAmount);
          } else {
            return (
              koinz.points[0].balance *
              (conversion_rate == undefined
                ? 0.2
                : parseFloat(conversion_rate.value))
            );
          }
        } else {
          if (
            koinz.points[0].balance *
              (conversion_rate == undefined
                ? 0.2
                : parseFloat(conversion_rate.value)) >
            // handleTotalAmount(totalAmount)
            handleTotalAmount(currencyconvertrate(totalAmount - 15, store))
          ) {
            return handleTotalAmount(
              currencyconvertrate(totalAmount - 15, store)
            );
          } else {
            return (
              koinz.points[0].balance *
              (conversion_rate == undefined
                ? 0.2
                : parseFloat(conversion_rate.value))
            );
          }
        }
      }
    } else {
      return 0;
    }
  };

  const CalcKoinz = () => {
    var koinzforprod = [];
    var koinzforparticipation = [];
    var total_koinz = 0;
    var conversion_rate = get(store, "cart.config", []).find(
      item => item.name == "earning_conversion_rate"
    );
    var product_conversion_rate = get(store, "cart.config", []).find(
      item => item.name == "product_cost_conversion_rate"
    );
    var customer_level = get(store, "mambo.data", null);
    if (customer_level.level != null) {
      customer_level = customer_level.level.reward.customFields;
      customer_level = customer_level.find(
        fields => fields.name == "loyalty_level_bonus"
      );
    }

    koinz_replacement.forEach(item => {
      get(store, "cart.cart", []).forEach(prod => {
        if (item == prod.item_id) {
          koinzforprod.push(prod);
        }
      });
    });

    koinzforparticipation = get(store, "cart.cart", []).filter(
      el => !koinzforprod.includes(el)
    );

    koinzforprod.forEach(prod => {
      var koinz =
        prod.qty *
          prod.price *
          (conversion_rate == undefined
            ? 0.2
            : parseFloat(conversion_rate.value)) +
        prod.qty *
          prod.extension_attributes.cost *
          (product_conversion_rate == undefined
            ? 1
            : parseFloat(product_conversion_rate.value));
      total_koinz = total_koinz + koinz;
    });

    koinzforparticipation.forEach(prod => {
      total_koinz =
        total_koinz +
        prod.qty *
          prod.price *
          (conversion_rate == undefined
            ? 0.2
            : parseFloat(conversion_rate.value));
      //console.log(total_koinz)
    });

    total_koinz =
      total_koinz +
      total_koinz *
        (customer_level.value == undefined
          ? 0 * 0.01
          : parseFloat(customer_level.value) * 0.01);

    setkoinz(Math.round(total_koinz));
  };

  return (
    <div id="cart-page-wrapper">
      {cartPopup &&
        <PopUps
          type="stock"
          onSubmit={() => {
            setCartPopup(false);
            props.history.push("/");
          }}
          onClose={() => setCartPopup(false)}
        />}

      <NavBar cart="Cart" carthader="true" qty={store.cart.cart.length} />
      <div className="cart-container paddingbtm">
        <h1 className="cart-heading">
          {props.Environment.cartemty}
        </h1>
        {popup === "koinz" &&
          <PopUps
            type="whatarekoinz"
            onSubmit={() => setPopup("none")}
            onClose={() => setPopup("none")}
          />}
        {!isRendered && <Loader />}
        {isRendered &&
          (cart_items.length === 0
            ? <CartEmpty {...props} />
            : <div>
                <div className="box">
                  <div className="cont1">
                    {cartPopup1 &&
                      (localStorage.getItem("needkoinz") === null ||
                        localStorage.getItem("needkoinz") === "null") &&
                      <PopUps
                        type="whatarekoinz1"
                        onSubmit={() => {
                          setPopup("none");
                          setCartPopup1(false);
                          localStorage.setItem("needkoinz", false);
                          // alert("dsdsd")
                        }}
                        onClose={() => {
                          setPopup("none");
                          setCartPopup1(false);
                          localStorage.setItem("needkoinz", false);
                        }}
                      />}
                    {store.cart.cart.map((item, i) => {
                      return (
                        <CheckoutCart
                          {...props}
                          type="cart"
                          key={item.item_id}
                          item={item}
                          setAmount={amount => {
                            setTotalAmount(totalAmount + amount);
                          }}
                          setKoinzModeFlag={setKoinzModeFlag}
                        />
                      );
                    })}
                  </div>
                  <div className="cart-amount-container martcart">
                    <div className="whatarecoinz">
                      <span>
                        {" "}{props.Environment.whatr}{" "}
                      </span>
                      <span
                        className="spanunderlinekoiz"
                        onClick={() => setPopup("koinz")}
                      >
                        {props.Environment.koinznew}{" "}
                      </span>
                    </div>
                    {/* <div className="koinz-info">
                                 <p>
                                    {props.Environment.whatr} <Link to="/mykoinz">{props.Environment.koinz}</Link>
                                 </p>
                              </div> */}
                    <div className="delivery-container">
                      <div className="section">
                        <img src="images/money.svg" alt="" />
                        <span>
                          {props.Environment.uwillearn} {koinzc}{" "}
                          {props.Environment.koinzpurchase}{" "}
                        </span>
                      </div>
                      {koinz_replacement.length !==
                      get(store, "cart.cart", []).length
                        ? <div className="section">
                            <img
                              src={`${props.Environment
                                .img_path}icons/ic-truck.svg`}
                              alt="kanzi-logo"
                            />
                            {/* <img src="images/money.svg" alt="" /> */}
                            <span>
                              {props.Environment.cartdeliver}
                            </span>
                          </div>
                        : null}
                      {/* <div className="cart-subtotal-container">
                                 <div className="cart-subtotal">
                                    {props.Environment.subtotal}
                                    <span>
                                       {comify(currencyconvertrate(totalAmount, store))} {store.account.currency} 
                                    </span>
                                 </div>
                                 {koinz_replacement.length !== get(store, 'cart.cart', []).length ? (
                                 <div className="cart-subtotal">
                                    {props.Environment.delivery}
                                    <span>
                                       15 {store.account.currency}  
                                    </span>
                                 </div>
                                 ) : <div className="cart-subtotal">
                                       {props.Environment.delivery}
                                       <span>
                                          0 {store.account.currency}
                                       </span>
                                    </div>}
                                 
                              </div> */}
                      {koinz_replacement.length !==
                      get(store, "cart.cart", []).length
                        ? <div className="section delivery-type">
                            <div
                              className="type border"
                              onClick={() => {
                                setdtype("home");
                                localStorage.setItem("pickupa", false);
                              }}
                            >
                              <div className="left">
                                {/* <p className="bold">Home delivery - 10 AED</p> */}
                                <p className="bold">
                                  {props.Environment.carthomed}
                                </p>
                                <p>
                                  {props.Environment.cartdeliver}
                                </p>
                              </div>
                              <div className="right">
                                <span
                                  className={`circle ${d_type == "home"
                                    ? "active"
                                    : ""}`}
                                >
                                  <div />
                                </span>
                              </div>
                            </div>

                            {/* <div
                                       className="type"
                                       onClick={() => {
                                          setdtype('self');
                                          localStorage.setItem('pickupa', true);
                                       }}
                                    >
                                       <div className="left">
                                          <p className="bold">{props.Environment.pickupmyself}</p>
                                          <p>{props.Environment.selfpicktxt}</p>
                                       </div>
                                       <div className="right">
                                          <span className={`circle ${d_type != 'home' ? 'active' : ''}`}>
                                             <div></div>
                                          </span>
                                       </div>
                                    </div> */}
                          </div>
                        : null}
                    </div>

                    {/* <div className="cart-subtotal-container">
                                 <div className="cart-subtotal">
                                    {props.Environment.total_amt}
                                    <span>AED {Math.round(totalAmount)}</span>
                                 </div>
                                 
                              </div> */}
                    <div className="cart-amount-container dcardf">
                      <div className="cart-subtotal-container spce11">
                        <div className="cart-subtotal">
                          {props.Environment.subtotal}
                          <span>
                            {currencyconvertrate(
                              localStorage.getItem("subtotoal"),
                              store
                            )}{" "}
                            {store.account.currency}
                          </span>
                        </div>
                        <div className="cart-subtotal">
                          {props.Environment.delivery}
                          <span>
                            {/* {currencyconvertrate(delivery_amount, store)} {store.account.currency} */}
                            {koinz_replacement.length !==
                            get(store, "cart.cart", []).length
                              ? <span>
                                  {currencyconvertrate(15, store)}{" "}
                                  {store.account.currency}
                                </span>
                              : <span>
                                  {currencyconvertrate(0, store)}{" "}
                                  {store.account.currency}
                                </span>}
                          </span>
                        </div>
                        {/* <div className="cart-subtotal">
                                    {props.Environment.koinz}
                                    <span>
                                       - {currencyconvertrate(localStorage.getItem('orderAmount'), store)} {store.account.currency}
                                    </span>
                                 </div> */}

                        {/* <span className="vatamoun">{props.Environment.vate}</span> */}
                      </div>
                    </div>
                    <div className="koinz-balance-container">
                      <div className="checkoutcart-cards">
                        <div className="koinz-switch-container">
                          <div className="koinz-balance">
                            {props.Environment.usemykoinz}
                            {/* <span> {store.mambo.data.points ? store.mambo.data.points[0].balance : 0} Koinz in balance</span> */}
                            <span>
                              {" "}{isObjEmpty(koinz) && isNil(koinz.points)
                                ? "0"
                                : koinz.points[0].balance}{" "}
                              {props.Environment.koinzinb}
                            </span>
                            {store.auth.token !== null
                              ? <span>
                                  {store.account.currency}{" "}
                                  {Math.round(
                                    (isObjEmpty(koinz) && isNil(koinz.points)
                                      ? "0"
                                      : koinz.points[0].balance) *
                                      (get(store, "cart.config", []).find(
                                        item =>
                                          item.name == "burning_conversion_rate"
                                      ) == undefined
                                        ? 0.2
                                        : parseFloat(
                                            get(store, "cart.config", []).find(
                                              item =>
                                                item.name ==
                                                "burning_conversion_rate"
                                            ).value
                                          ))
                                  )}
                                </span>
                              : null}
                          </div>
                          <div className="koinz-switch">
                            <div
                              className={`switch ${toggleState}`}
                              onClick={() => {
                                let am = 0;
                                if (isObjEmpty(koinz) && isNil(koinz.points)) {
                                  return;
                                } else {
                                  if (koinz.points[0].balance == 0) return;
                                }

                                toggle();
                                // if (toggleState == "off"){

                                //    localStorage.setItem('orderAmount', doiscount);
                                // }
                                // else{
                                //    am = 0;
                                //    localStorage.setItem('orderAmount', 0);

                                // }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="total-cart-amount">
                                    {props.Environment.total_amt}
                                 </div> */}
                    </div>

                    <div className="cart-subtotal-container spce11">
                      <div className="cart-subtotal vatamoun vatamoun1">
                        <span className="vatr1">
                          {props.Environment.vate1}{" "}
                        </span>
                        <span className="an">
                          {" "}{/* {totalAmount} */}
                          {koinz_replacement.length !==
                          get(store, "cart.cart", []).length
                            ? <div>
                                {useKoinz
                                  ? comify(
                                      decimalUptoTwoDigits(
                                        handleTotalAmount(
                                          currencyconvertrate(
                                            totalAmount,
                                            store
                                          ) - HandleKoinzSelection()
                                        ) + ""
                                      )
                                    )
                                  : comify(
                                      decimalUptoTwoDigits(
                                        handleTotalAmount(
                                          currencyconvertrate(
                                            totalAmount,
                                            store
                                          )
                                        ) + ""
                                      )
                                    )}{" "}
                              </div>
                            : <div>
                                {useKoinz
                                  ? comify(
                                      decimalUptoTwoDigits(
                                        handleTotalAmount(
                                          currencyconvertrate(
                                            totalAmount - 15,
                                            store
                                          ) - HandleKoinzSelection()
                                        ) + ""
                                      )
                                    )
                                  : comify(
                                      decimalUptoTwoDigits(
                                        handleTotalAmount(
                                          currencyconvertrate(
                                            totalAmount - 15,
                                            store
                                          )
                                        ) + ""
                                      )
                                    )}{" "}
                              </div>}
                          &nbsp; {store.account.currency}
                          {/* {koinz_replacement.length !== get(store, 'cart.cart', []).length ? (
                                          <div>
                                             {useKoinz
                                                ? comify(
                                                   decimalUptoTwoDigits(
                                                      handleTotalAmount(
                                                         currencyconvertrate(totalAmount, store) - HandleKoinzSelection()
                                                      ) + ''
                                                   )
                                                )
                                                : comify(
                                                   decimalUptoTwoDigits(
                                                      handleTotalAmount(currencyconvertrate(totalAmount, store)) + ''
                                                   )
                                                )}{' '}
                                          </div>
                                       ) : <div>
                                             {useKoinz
                                                ? comify(
                                                   decimalUptoTwoDigits(
                                                      handleTotalAmount(
                                                         currencyconvertrate((totalAmount - 15), store) - HandleKoinzSelection()
                                                      ) + ''
                                                   )
                                                )
                                                : comify(
                                                   decimalUptoTwoDigits(
                                                      handleTotalAmount(currencyconvertrate((totalAmount - 15), store)) + ''
                                                   )
                                                )}{' '}
                                          </div>} */}
                          {/* {(JSON.stringify(currencyconvertrate(localStorage.getItem('subtotoal'), store) + currencyconvertrate(delivery_amount, store)) - currencyconvertrate(localStorage.getItem('orderAmount'), store))}  {store.account.currency} */}
                        </span>
                      </div>
                    </div>

                    <span>
                      {" "}{token != null
                        ? ""
                        : <div className="cart-footer-text">
                            {props.Environment.text_card_desc}
                          </div>}
                    </span>
                    <div className="buy-button-panel">
                      <div className="buy-product-name">
                        <div className="product-title">
                          {props.Environment.total_amt}
                          <span className="product-amount p-disd">
                            {" "}{store.account.currency} &nbsp;
                            {koinz_replacement.length !==
                            get(store, "cart.cart", []).length
                              ? <div>
                                  {useKoinz
                                    ? comify(
                                        decimalUptoTwoDigits(
                                          handleTotalAmount(
                                            currencyconvertrate(
                                              totalAmount,
                                              store
                                            ) - HandleKoinzSelection()
                                          ) + ""
                                        )
                                      )
                                    : comify(
                                        decimalUptoTwoDigits(
                                          handleTotalAmount(
                                            currencyconvertrate(
                                              totalAmount,
                                              store
                                            )
                                          ) + ""
                                        )
                                      )}{" "}
                                </div>
                              : <div>
                                  {useKoinz
                                    ? comify(
                                        decimalUptoTwoDigits(
                                          handleTotalAmount(
                                            currencyconvertrate(
                                              totalAmount - 15,
                                              store
                                            ) - HandleKoinzSelection()
                                          ) + ""
                                        )
                                      )
                                    : comify(
                                        decimalUptoTwoDigits(
                                          handleTotalAmount(
                                            currencyconvertrate(
                                              totalAmount - 15,
                                              store
                                            )
                                          ) + ""
                                        )
                                      )}{" "}
                                </div>}
                          </span>
                        </div>
                      </div>
                      <Button
                        className="button-red"
                        data={props.Environment.chec}
                        onClick={handleCheckout}
                      />
                    </div>
                    {/* <div className="cart-subtotal vatamoun vatamounnew">
                              <span className="psnavat">{props.Environment.vate1}</span>
                              <span>
                                 {comify(currencyconvertrate(totalAmount, store))} {store.account.currency} 
                              </span>
                           </div> */}
                    {/* <span className="vatamoun vatamounnew">{props.Environment.vate1}</span> */}
                  </div>
                </div>
                {/* </div> */}
              </div>)}
      </div>
    </div>
  );
};

export default Cart;
