import React, { useRef, useState, useEffect } from 'react';
// import { Environment } from '../../env';
import Button from '../../components/Button/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { TweenMax } from 'gsap';
import { Swipeable } from 'react-swipeable';
import { useLockBodyScroll, isOrderSuccessfull } from '../../utils/Function';
import { Tween } from 'react-gsap';
import { ORDERID } from '../../redux/actions/cart/actionTypes';
import { UserData, SetError } from '../../redux/actions/auth/actions';
import { SetRewards } from '../../redux/actions/home/actions';
import CircularLoader from '../../components/Loader/CircularLoader';
import $ from 'jquery';
import ReactPixel from 'react-facebook-pixel';
import {
   PaymentInfo,
   CreatePointsActivity,
   ParticipateInCampaign,
   GetOrderDetails,
   GetOrderData,
   GetAdminToken,
   EmptyKoinzProduct,
   isKoinz,
   GetPreviousOrders,
   ReferAFriend,
   GetCampaignCount,
   CreatePointsParticipation,
   koinzAmount,
   CreateSessionId,
   applePay,
} from '../../redux/actions/cart/actions';
import { get } from 'lodash';

import { formatCreditCardNumber, formatCVC, formatExpirationDate } from '../../utils/cardUtils.js';

const script = document.createElement('script');
script.src =
   'https://i.ctnsnet.com/int/integration?pixel=67834349&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}';
script.async = true;
const ActionSheetcards = props => {
   const SheetAddress = useRef(null);
   const history = useHistory();
   const [showdate, setshowdate] = useState(false);
   const [pickupa, setpickupa] = useState(false);
   const location = useLocation();
   const [active, setActive] = useState(false);
   const [displayLoader, setDisplayLoader] = useState(false);

   const EditMode = location?.cardEditable ? true : false;
   const dispatch = useDispatch();
   const [makePaymentdisabled, setMakePaymentdisabled] = useState(true);
   const custProfile = useSelector(store => store.cart.custProfile);
   // const useKoinz = useSelector(store => store.cart.useKoinz)
   // const [totalAmount, setTotalAmount] = useState(0);
   const store = useStore().getState();
   const campaign = useSelector(store => store.home.campaign_manager);
   const koinz_replacement = useSelector(store => store.cart.koinz_replacement);
   const useKoinz = useSelector(store => store.cart.useKoinz);
   const cart = useSelector(store => store.cart.cart);
   const all_products = useSelector(store => store.home.allproducts);
   const koinz = useSelector(store => store.mambo.data);
   // const orderAmount = useSelector(store => store.cart.orderAmount);
   const [Gtotal, setGtotal] = useState(0);
   const [cardError, setCardError] = useState('');
   const [showThreeDAuth, setShowThreeDAuth] = useState(false);

   const [cardDetails, setCardDetails] = useState({
      name: '',
      number: '',
      expiry: '',
      cvc: '',
      saveCardDetails: false,
   });

   const [threeDCardData, setThreeDCardData] = useState({
      TermUrl: '',
      acsMd: '',
      acsPaReq: '',
      acsUrl: '',
      orderId: '',
   });

   console.log(EditMode);
   useLockBodyScroll();

   useEffect(() => {
      if (document.getElementById('add-new-card-page-wrapper'))
         document.getElementById('add-new-card-page-wrapper').appendChild(script);
      return () => {
         if (document.getElementById('add-new-card-page-wrapper'))
            document.getElementById('add-new-card-page-wrapper').removeChild(script);
      };
   }, []);

   const handleGoBack = () => {
      setActive(false);
      // TweenMax.fromTo(SheetAddress.current, props.Environment.popupBackground.duration, { y: 0 }, { y: 1000 });
      setTimeout(() => {
         const url = location.pathname.includes('checkout');
         history.push(url ? '/checkoutdetails' : '/savedaddress');
      }, 700);
   };

   useEffect(() => {
      // loadPaymentGatewayScript()
      // document.body.style.overflow = 'hidden';
      //$('.forms-main').addClass('fds435');
      $('.fds').addClass('fds43new');
      // TweenMax.fromTo(SheetAddress.current, 0.5, { y: 600 }, { y: 0 });
      TweenMax.fromTo(SheetAddress.current, props.Environment.popupBackground.duration, { y: 600 }, { y: 0 });
      setTimeout(() => {
         setActive(true);
      }, 700);
      return () => $('.fds').removeClass('fds43new');
   }, []);

   useEffect(() => {
      // document.addEventListener('click', evt => {
      //    const flyoutElement = document.querySelector('.action-container');
      //    let targetElement = evt.target; // clicked element
      //    if (targetElement == flyoutElement) {
      //       if (!displayLoader) handleGoBack();
      //       return;
      //    }
      // });
   }, []);

   // const loadPaymentGatewayScript = () => {
   //    const script = document.createElement('script');
   //    script.type = 'text/javascript';
   //    script.src = props.Environment?.sdk;
   //    script.async = true;
   //    script.dataset.cfasync = false;
   //    document.body.appendChild(script);
   //    script.addEventListener('load', res => {
   //       mountPaymentGateway();
   //       // resolve();
   //    });
   // };

   const addCard = () => {
      if (!displayLoader) history.goBack();
   };

   // const mountPaymentGateway = () => {
   //    setDisplayLoader(true);

   //    window.NI.mountCardInput('mount-id' /* the mount id*/, {
   //       style: {
   //          main: {
   //             padding: '0px',
   //          },
   //          base: {
   //             backgroundColor: '#FFFFFF',
   //             fontSize: '16px',
   //          },
   //          input: {
   //             borderWidth: '1px',
   //             borderRadius: '5px',
   //             borderStyle: 'solid',
   //             backgroundColor: '#FFFFFF',
   //             borderColor: '#DDDDDD',
   //             color: '#000000',
   //             padding: '5px',
   //          },
   //          invalid: {
   //             borderColor: 'red',
   //          },
   //       }, // Style configuration you can pass to customize the UI
   //       apiKey: props.Environment.paymentGateway.apikey, // API Key for WEB SDK from the portal
   //       outletRef: props.Environment.paymentGateway.outletRef, // outlet reference from the portal
   //       onSuccess: res => {
   //          console.log(res);
   //       }, // Success callback if apiKey validation succeeds
   //       onFail: error => {
   //          console.error(error);
   //       }, // Fail callback if apiKey validation fails
   //       onChangeValidStatus: ({ isCVVValid, isExpiryValid, isNameValid, isPanValid }) => {
   //          setDisplayLoader(false);
   //          // dispatch(CardFormLoaded((true)));
   //          if (isCVVValid && isExpiryValid && isNameValid && isPanValid) {
   //             setMakePaymentdisabled(false);
   //          }
   //          console.log(isCVVValid, isExpiryValid, isNameValid, isPanValid);
   //       },
   //    });
   // };

   const getAddressDetails = profile => {
      return {
         paymentmethod: 'ngeniusonline',
         email: profile?.email,
         region: '',
         region_id: 0,
         region_code: '',
         country_id: profile?.country_id,
         street: profile?.street,
         postcode: '0000',
         city: profile?.city,
         telephone: profile?.telephone,
         firstname: profile?.firstname,
         lastname: profile?.lastname,
      };
   };

   const createUserSessionId = async () => {
      setDisplayLoader(true);
      ReactPixel.track('AddPaymentInfo');
      let res;
      setCardError('');
      try {
         let address = getAddressDetails(custProfile);
         console.log(custProfile);
         const cardData = {
            data: {
               pan: cardDetails.number.split(' ').join(''),
               expiry:
                  new Date().getFullYear().toString().substr(0, 2) +
                  cardDetails.expiry.split('/')[1] +
                  '-' +
                  cardDetails.expiry.split('/')[0],
               cvv: cardDetails.cvc,
               cardholderName: cardDetails.name,
            },
            customerId: custProfile.selectedid,
         };
         // var sessionData = await dispatch(CreateSessionId(cardData));
         var [sessionData, error] = await dispatch(CreateSessionId(cardData));
         // if (sessionData && sessionData.length && sessionData[0] !== '') {
         if (sessionData) {
            // let sessionId = sessionData[0];
            let sessionId = sessionData;
            let entity_id;
            let saveCardDetails = cardDetails.saveCardDetails;

            // res = await dispatch(PaymentInfo(address, { sessionId, entity_id }));
            // setShowThreeDAuth(true);
            // setDisplayLoader(false);
            // return;
            // let [response, error] = await dispatch(PaymentInfo(address, { sessionId, entity_id, saveCardDetails }));
            let orderAmount = localStorage.getItem('orderAmount');
            let orderAmountPayment = localStorage.getItem('finalOrderAmount');
            ReactPixel.track('InitiateCheckout');
            let base64ToString = await dispatch(applePay(orderAmountPayment, store.account.currency, Date.now()));
            console.log('===========base64ToString=============base64ToString========', base64ToString);
            let paymentRes = await dispatch(
               PaymentInfo(address, {
                  sessionId,
                  entity_id,
                  saveCardDetails,
                  orderAmount,
                  pgOrderId: base64ToString._id,
               })
            );
            var { TermUrl, acsMd, acsPaReq, acsUrl, error, order_id } = JSON.parse(paymentRes);
            if (order_id) {
               localStorage.setItem('orderidvalue', order_id);
            }
            if (TermUrl && acsMd && acsPaReq && acsUrl && order_id) {
               localStorage.setItem('orderidvalue', order_id);
               setThreeDCardData({
                  // TermUrl: props.Environment.app_domain_url + '/' + TermUrl + '?orderId=' + order_id,
                  TermUrl: props.Environment.app_domain_url + '/' + TermUrl,
                  acsMd,
                  acsPaReq,
                  acsUrl,
                  order_id: order_id,
               });
               setShowThreeDAuth(true);

               // setDisplayLoader(false);
               // document.getElementById('3d_form').submit();
               // return;
            } else {
               props.history.push({
                  pathname: '/orderdetails',
                  props: { showdate: location.props.showdate, pickupa: location.props.pickupa },
               });
            }

            if (error) {
               setDisplayLoader(false);
               // setCardError(error);
               dispatch(SetError(error));
               history.goBack();
               return;
            } else {
               // res = response;
               res = order_id;
            }
         } else {
            setDisplayLoader(false);
            // setCardError(props.Environment.cardError);
            // dispatch(SetError(props.Environment.cardError));
            dispatch(SetError(props.Environment.cardError));
            history.goBack();
            return;

            // history.goBack();
         }

         // const res = await dispatch(PaymentInfo(address, sessionId));

         //call mambo method after successfull order placement
         if (res) {
            var koinzforprod = [];

            koinz_replacement.forEach(item => {
               var prod = cart.find(prod => {
                  return item == prod.item_id;
               });
               koinzforprod.push(prod.sku);
            });

            if (koinzforprod.length !== 0) {
               await isKoinz(koinzforprod, res);
            }

            HandleReplaceWithKoinz(true)
               .then(response => {
                  GetOrderData('zs667jaikfh4rb34vfqv49k86vb7xcq7', order_id).then(async data => {
                     console.log('items  GetOrderDetails', data);

                     let isMamboSuccess = 0;
                     let email_send = 0;
                     const isOrderSuccess = isOrderSuccessfull(data?.status);
                     if (isOrderSuccess) {
                        isMamboSuccess = 1;
                        email_send = 1;
                        if (useKoinz) {
                           //Fix Required
                           if (koinz.points[0].balance / 5 > Gtotal) {
                              await dispatch(CreatePointsActivity(-(Gtotal * 5)));
                           } else {
                              await dispatch(CreatePointsActivity(-koinz.points[0].balance));
                           }
                        }

                        await HandleReferer();

                        await HandleReplaceWithKoinz();

                        await ParticipateinCampaign(res);

                        dispatch(EmptyKoinzProduct());
                     }
                     koinzAmount(response, order_id, koinz?.points[0]?.pointId, isMamboSuccess, email_send);
                  });
               })
               .catch(error => {
                  console.log(error);
               });

            setDisplayLoader(false);

            // props.history.push('/orderdetails');
            // props.history.push({
            //    pathname: '/orderdetails',
            //    props: { showdate: location.props.showdate, pickupa: location.props.pickupa },
            // });
            if (TermUrl && acsMd && acsPaReq && acsUrl && order_id) {
               // alert("3D valida")
               // setshow(false);
               document.getElementById('3d_form').submit();
               return;
            } else {
               // alert("Normal flow")
               props.history.push({
                  pathname: '/orderdetails',
                  props: { showdate: location.props.showdate, pickupa: location.props.pickupa },
               });
            }

            dispatch({ type: ORDERID, payload: res });
         }
         // props.history.push('/orderdetails');
      } catch (err) {
         setDisplayLoader(false);
         console.error(err);
      }
   };

   const HandleReplaceWithKoinz = async (koinzdetail = null) => {
      var koinzforprod = [];
      var koinzforparticipation = [];
      var total_koinz = 0;
      var conversion_rate = get(store, 'cart.config', []).find(item => item.name == 'earning_conversion_rate');
      var product_conversion_rate = get(store, 'cart.config', []).find(
         item => item.name == 'product_cost_conversion_rate'
      );
      var customer_level = get(store, 'mambo.data', null);
      if (customer_level.level != null) {
         customer_level = customer_level.level.reward.customFields;
         customer_level = customer_level.find(fields => fields.name == 'loyalty_level_bonus');
      }
      var details = [];

      koinz_replacement.forEach(item => {
         cart.forEach(prod => {
            if (item == prod.item_id) {
               koinzforprod.push(prod);
            }
         });
      });

      koinzforparticipation = cart.filter(el => !koinzforprod.includes(el));

      koinzforprod.forEach(prod => {
         console.log(prod);
         var koinz =
            prod.qty * prod.price * (conversion_rate == undefined ? 0.2 : parseFloat(conversion_rate.value)) +
            prod.qty *
               prod.extension_attributes.cost *
               (product_conversion_rate == undefined ? 1 : parseFloat(product_conversion_rate.value));
         total_koinz = total_koinz + koinz;
         details.push({ sku: prod.sku, koinz: koinz });
      });

      koinzforparticipation.forEach(prod => {
         let koin = prod.qty * prod.price * (conversion_rate == undefined ? 0.2 : parseFloat(conversion_rate.value));
         total_koinz = total_koinz + koin;
         console.log(total_koinz);
         details.push({ sku: prod.sku, koinz: koin });
      });

      total_koinz =
         total_koinz +
         total_koinz * (customer_level.value == undefined ? 0 * 0.01 : parseFloat(customer_level.value) * 0.01);

      console.log(details);

      if (koinzdetail != null) {
         return details;
      }

      const cp = await dispatch(CreatePointsActivity(Math.round(total_koinz)));
      if (cp) {
         //dispatch(ViewMamboProfile());
      }
   };

   const ParticipateinCampaign = async res => {
      const token = await GetAdminToken();
      const items = await GetOrderDetails(token, res);
      const ids = get(store, 'home.categories', []);

      let awarded = [];

      items.forEach(async (prod, index) => {
         var product = all_products.find(all_prod => {
            return all_prod.id == prod.product_id;
         });

         if (product === undefined) {
            return;
         }

         var campaign_id = campaign.find(camp => {
            return camp.campaign_product_id == product.id;
         });

         if (campaign_id === undefined) {
            return;
         }

         var id = product.custom_attributes.find(attr => {
            return attr.attribute_code == 'category_ids';
         });

         if (id === undefined) {
            return;
         }

         id = ids.find(cat_id => {
            return cat_id.id == id.value[0];
            //  return cat_id.id == id.value[1]
         });

         console.log('id', id);

         console.log(prod);
         let count = await GetCampaignCount(prod.product_id, store.auth.token);

         if (count == false) {
            return;
         }

         let res = await dispatch(ParticipateInCampaign(id.name.toLowerCase(), campaign_id.campaign_id, count));

         if (res.awarded.length !== 0) {
            res.awarded.forEach(award => {
               awarded.push({ message: award.message, img: award.reward.imageUrl });
            });
         }

         if (index === items.length - 1) {
            console.log(awarded);
            dispatch(SetRewards(awarded));
            //await dispatch(ViewMamboProfile())
         }
      });

      await dispatch(CreatePointsParticipation());
   };

   const HandleReferer = async () => {
      var orders = await GetPreviousOrders(get(store, 'auth.token', null));

      if (orders.items.length - 1 == 0) {
         var refer_id = await UserData(get(store, 'auth.token'), null);
         if (refer_id.custom_attributes !== undefined) {
            refer_id = refer_id.custom_attributes.find(attr => attr.attribute_code == 'refer_id');
            console.log(refer_id);
            if (refer_id == null) {
               return;
            } else {
               await ReferAFriend(store.auth.userinfo.id, refer_id.value);
            }
         }
      }
   };

   // const createSessionId = async () => {
   //    console.log('RESSS', res);
   // };

   const cardInputHandler = (type, event) => {
      let value = event.target.value;

      if (type === 'number') {
         let formattedValue = formatCreditCardNumber(value);
         // formattedValue= formattedValue.split(' ').join('');
         setCardDetails({ ...cardDetails, number: formattedValue });
      } else if (type === 'cvc') {
         let formattedValue = formatCVC(value);
         setCardDetails({ ...cardDetails, cvc: formattedValue });
      } else if (type === 'expiry') {
         let formattedValue = formatExpirationDate(value);
         setCardDetails({ ...cardDetails, expiry: formattedValue });
      } else {
         setCardDetails({ ...cardDetails, name: value });
      }
   };

   useEffect(() => {
      if (koinz_replacement.length !== get(store, 'cart.cart', []).length) {
         setshowdate(true);
      }
   }, []);

   useEffect(() => {
      checkCardValidition();
   }, [cardDetails]);

   const checkCardValidition = () => {
      if (
         cardDetails.number.length === 19 &&
         cardDetails.expiry.length === 5 &&
         cardDetails.cvc.length === 3 &&
         cardDetails.name.length > 1
      ) {
         setMakePaymentdisabled(false);
      }
   };

   return (
      <Swipeable id="add-new-card-page-wrapper" onSwipedDown={handleGoBack}>
         <CircularLoader show={displayLoader}></CircularLoader>
         <div
            className="action-container currency-contadd"
            ref={SheetAddress}
            style={{ backgroundColor: active ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0)' }}
         >
            {/* <Tween from={{ y: '100%', opacity: 1, delay: 0.9, duration: 0.8 }}> */}
            <Tween
               from={{
                  y: '100%',
                  opacity: 1,
                  delay: props.Environment.popupContainer.delay,
                  duration: props.Environment.popupContainer.duration,
               }}
            >
               <div style={showThreeDAuth ? { visibility: 'hidden' } : {}} className="action-cards action-cards121">
                  <button
                     onClick={() => {
                        history.goBack();
                     }}
                     type="button"
                     class="close1231"
                     data-dismiss="modal"
                     aria-label="Close"
                  >
                     <span aria-hidden="true">×</span>
                  </button>
                  <div className="cards-icon">
                     <img src="icons/pay.svg" alt="" />
                  </div>
                  <div className="cards-title">{props.Environment.apaymenthod}</div>
                  {location?.cardEditable ? (
                     <div className="floating-form edit-form">
                        <div className="actions-forms address-forms">
                           <div className="edit-label-section">
                              <div className="header">{props.Environment.cardname}</div>
                              <div className="sub-header">SARA AL HANABALI</div>
                           </div>

                           <div className="edit-label-section">
                              <div className="header">{props.Environment.cardnumber}</div>
                              <div className="sub-header">**** **** **** 5432</div>
                           </div>

                           <div className="edit-label-section">
                              <div className="header">{props.Environment.cvv}</div>
                              <div className="sub-header">03/22</div>
                           </div>

                           <div className="form-checkbx">
                              <input type="checkbox" id="address" name="box" />
                              <label htmlFor="address" className="site-e">
                                 Set as default payment method
                              </label>
                              <label htmlFor="address" className="site-a">
                                 تعيين كطريقة دفع تلقائية
                              </label>
                           </div>

                           <div className="">
                              <Button className="button-red site-e" data="Delete Card" onClick={addCard} />
                              <Button className="button-red site-a" data="حذف البطاقة" onClick={addCard} />
                           </div>
                        </div>
                     </div>
                  ) : (
                     <div className="floating-form">
                        {!showThreeDAuth && (
                           <>
                              <div className="actions-forms address-forms">
                                 <div className="floating-label">
                                    <input
                                       className="floating-input"
                                       type="text"
                                       value={cardDetails.name}
                                       pattern="^[a-zA-Z\s]*$"
                                       onChange={event => cardInputHandler('name', event)}
                                       placeholder={props.Environment.p1}
                                    />
                                    <span className="highlight" />
                                    <label className="label_placeholder">{props.Environment.cardname}</label>
                                 </div>

                                 <div className="floating-label">
                                    <input
                                       className="floating-input"
                                       value={cardDetails.number}
                                       type="text"
                                       pattern="[\d| ]{16}"
                                       onChange={event => cardInputHandler('number', event)}
                                       placeholder={props.Environment.p2}
                                    />
                                    <span className="highlight" />
                                    <label className="label_placeholder">{props.Environment.cardnumber}</label>
                                 </div>

                                 <div className="form-row">
                                    <div className="floating-label">
                                       <input
                                          className="floating-input"
                                          value={cardDetails.expiry}
                                          type="text"
                                          onChange={event => cardInputHandler('expiry', event)}
                                          pattern="\d\d/\d\d"
                                          placeholder="mm/yy"
                                       />
                                       <span className="highlight" />
                                       <label className="label_placeholder">{props.Environment.expirydate}</label>
                                    </div>

                                    <div className="floating-label">
                                       <input
                                          className="floating-input"
                                          value={cardDetails.cvc}
                                          type="password"
                                          onChange={event => cardInputHandler('cvc', event)}
                                          pattern="\d{3,4}"
                                          placeholder={props.Environment.p3}
                                       />
                                       <span className="highlight" />
                                       <label className="label_placeholder">{props.Environment.cvv}</label>
                                    </div>
                                 </div>

                                 <div className="form-checkbx">
                                    <input
                                       type="checkbox"
                                       id="saveCardConsent"
                                       name="box"
                                       onChange={() => {
                                          setCardDetails({
                                             ...cardDetails,
                                             saveCardDetails: !cardDetails.saveCardDetails,
                                          });
                                       }}
                                    />
                                    <label htmlFor="address">{props.Environment.saveCardConsentTxt}</label>
                                 </div>
                              </div>
                              {cardError && <span class="card-error">{cardError}</span>}
                              <div className="">
                                 <Button
                                    className={makePaymentdisabled ? 'disabled button-red ' : 'button-red'}
                                    disabled={makePaymentdisabled}
                                    data={props.Environment.makePayment}
                                    onClick={createUserSessionId}
                                 />
                              </div>
                           </>
                        )}
                        {/* ( */}
                        <form
                           class="form-inline needs-validation"
                           id="3d_form"
                           action={threeDCardData?.acsUrl}
                           method="POST"
                           enctype="application/x-www-form-urlencoded"
                        >
                           <input
                              class="form-control"
                              type="hidden"
                              id="PaReq"
                              name="PaReq"
                              value={threeDCardData?.acsPaReq}
                           />
                           <input
                              class="form-control"
                              type="hidden"
                              id="TermUrl"
                              name="TermUrl"
                              // value={threeDCardData?.TermUrl}
                              value={
                                 props.Environment.app_domain_url +
                                 '/pay.php?orderId=' +
                                 threeDCardData?.order_id +
                                 '&saveCard=' +
                                 '&viewMode=desktop' +
                                 cardDetails.saveCardDetails
                              }
                              // value={props.Environment.app_domain_url + '/orderdetails/' + threeDCardData?.order_id}
                           />
                           <input class="form-control" type="hidden" id="MD" name="MD" value={threeDCardData?.acsMd} />

                           <div className="">
                              {/* <Button
                                    className="button-red"
                                    disabled="Authorize"
                                    data={props.Environment.makePayment}
                                    type="submit"
                                 /> */}
                              <div style={{ display: 'none' }} className="button-container">
                                 <div>
                                    <button className="button-red" id="authorize-three-d" type="submit">
                                       {props.Environment.authenticateCard}
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </form>
                        {/* ) */}
                     </div>
                  )}
               </div>
            </Tween>
         </div>
      </Swipeable>
   );
};

export default ActionSheetcards;
