import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavBar from "../../components/NavBar/NavBar";
import { Environment } from "../../env";
import { Tween } from "react-gsap";

const TermsCondition = props => {
    return (
        <div className="">
            <NavBar tnc="tnc" />
            <div className="drawtnc-main-container">
            <Tween from={{ x: "100%", opacity: 1, duration: 0.5 }}>
                <div className="drawtnc-card">
                    <span className="card-content">

                    This website and or site and or mobile application or portal is owned, regulated, operated, and maintained by “Kanzi Enterprises General Trade LLC” and or its affiliates under the trade name “KANZIAPP.COM” (hereinafter referred to as “Website / We/ Us”). 
        By accessing, using and or purchasing from this Website, the customer (hereinafter referred to as (“Customer/ You / Your”) acknowledges that they have read, understood and agree to be legally bound by these Terms and Conditions. These DRAW Terms and Conditions may be changed from time to time without any prior intimation to the Customer and the Customer shall keep themselves updated by frequently visiting the Terms and Conditions prior to their purchase in this Website. In case, the Customer do not agree to any of the following Draw Terms and Conditions, they are encouraged to immediately refrain from using or purchasing from this Website. 
        
        <br/>
                            <br />
                            <span>What is DRAW:</span>
        <br />
                            
        Any registered Customer or a guest, by purchasing a Product from this Website (hereinafter referred to as the “Product”) shall receive a Complimentary DRAW Ticket with each purchase, which will be automatically entered into a DRAW for the prize listed on the campaign they participated. 
                                                The Customer shall not have the option to choose the number/series of the Complimentary DRAW Ticket, as it is system generated. 
                                                
        <br />
                            <br />

                            <span>Non- Transferable:</span>

                            <br />
                            
                            The Complimentary DRAW Ticket is nontransferable. The Customer shall not be able to exchange or transfer the Complimentary DRAW Ticket for money or other Product or other Complimentary Ticket. 
                            The Customer understands and agrees that the Products once sold could not be exchanged, returned or cancelled for any reason whatsoever, therefore the Customer is encouraged to verify the Products carefully before purchasing. 



        <br />
                            <br />
                            <span>Cancellation of the DRAW:</span>

        <br />
                            
                            This Website reserves its right to cancel the DRAW at any time without any prior intimation to the Customers for whatsoever reason. 
                            
        <br />
                            <br />
        
                            <span>Dates for the DRAW:</span>
        <br />
                            
        Once the Products listed in a particular campaign are sold out, all Customers who have purchased from this campaign will automatically enter into the DRAW with the complimentary DRAW Ticket on the dates that is prescheduled on the Website and the Application. 
                                            Nonetheless, the Website/Application reserves its right to reschedule the dates of the DRAW any time without prior notice, subject to the availability of Dubai Economy Representative.  
                                            
                             <br />
                        <br />


                            <span>Refund to oversold Customers:</span>
                            <br />
                            
                            In case the Products are oversold, We undertake to refund the Product price to the oversold Customers. The refund shall be initiated to the customers based on the series of the Complimentary DRAW Ticket sold excessively. 
                        
                        
                             <br />
                        <br />


                            <span>How a DRAW is conducted:</span>
                            <br />
                            
                            Once the Products are sold, the DRAW will be conducted pursuant to the rules and regulation of the Dubai Economy. A representative of Dubai Economy shall conduct the DRAW and select one winner from each campaign DRAW.
                        Once the winners are selected, they shall be notified in their registered email and through their registered mobile number on the same day of the DRAW. The details of the next steps shall be made known to the Winner in the same email and be informed via phone call. 
                        
                             <br />
                        <br />


                            <span>Usage of Winners’ information by the Website:</span>
                            <br />
                            
                            All Winners consent to the use of their name and photos by the Website in all their social media platforms, local and regional media unless otherwise instructed by the Winners. 
                        
                        
                             <br />
                        <br />


                            <span>Collection of the Prize:</span>
                            
                        <br />
                        


                            <span>For Winners inside UAE:</span>
                            
                            <br />
                            The Winners agree to collect the Prize from us as per the instructions mentioned in the email. The Winner is required to collect the Prize by themselves within a period of 30 (thirty) days from the DRAW date and should possess a valid original ID (Either original Emirates ID or original Passport) while collection. 
                        We reserve the right to refrain from handing over the Prize to the Winner in the event of failure of non-possession of the abovementioned original documents. 
                        
                             <br />
                        <br />


                            <span>For Winners outside UAE:</span>
                            <br />
                            
                            In case the Winners are residing outside UAE, upon request, We can arrange to ship the Prize to the winner’s country of residence, (nearest seaport as selected by Us in Our sole discretion) Collection from the port and clearance through the port and customs shall be the responsibility, and at the cost, of the Winner. Accordingly, all local taxes, custom duties, and any other form of expense beyond shipping and delivery at the port will be paid by the Winner.
                            Immediately upon shipping the prize, the Winner shall be notified of the order tracking number to check the status of the shipment. 
                            We shall insure the shipment until it reaches to your port location and the title, ownership/ risk and responsibility of the Prize shall be immediately transferred to You the minute the shipment is taken out of the port, and We shall not be liable for any kind of damage or destruction or loss to the prize once it is taken out of the port, whatsoever. 
                            In the event of a cash Prize, We shall transfer the said cash Prize to the Winner’s bank account excluding any kind of bank, exchange or other charges. Tax laws shall be applicable as per the regulations existing in the country of the Winner’s residence. We will not be responsible for any bank charges, including but not limited to processing fees from your local bank. 
                            We will not be responsible for arranging insurance, including travel insurance, medical insurance, transport expenses, meal expenses, or any expenses of a personal nature, in the collection of the Prize.
                            
        <br />
                            <br />

                        <br />
                        <br />


                            <span> Ineligibility of a Customer to enter DRAW:</span>
                            
                            <br />
                            If any Customer, as in accordance with the existing laws of the country, is found ineligible to enter into the DRAW conducted by this Website, We reserve our complete right and using our sole discretion can deny handing over or forfeit the Prize at any time and in that case the Customer shall not have any right to claim the same from Us or before any competent court of law or before any jurisdiction whatsoever. 
                            Liability and Indemnification
                            
        <br />
                            <br />

        The Website or its affiliates shall not be liable or held responsible for any claims, damages, costs, expenses of any nature including but not limited to bodily injury, illness, death arising out of the defects in any Prize. 
                            The Customer hereby indemnifies, defends and holds harmless the entity owning and operating the Website, its subsidiaries, affiliates, vendors, agents and their respective directors, officers, employees and agents (hereinafter individually and collectively referred to as "indemnified parties") from and against any and all losses, liabilities, claims, suits, proceedings, penalties, interests, damages, demands, costs and expenses (including legal and other statutory fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by the indemnified parties that arise out of, result from, or in connection with :
                            
        <br />
                            <br />

        The Customers’ breach of these Draw Terms and Conditions, or
                            Any claims made by any third party due to, or arising out of, or in connection with, the Customers’ use of the Website; or
                            Violation of any rights of any third party by the Customer, including any IPR.
        <br />
                            <br />

        We will not be responsible for claims, damages, or expenses of any nature whatsoever for any loss, illness, bodily injury, including death, of or to any Winner and/or any third party during and/or in course of usage of any Prize or due to any defects in any Prize.
        <br />
                            <br />

        
        <span>Governing Law and Dispute Resolution: </span>
        <br />
                            <br />

        These DRAW Terms and Conditions will be governed by and construed in accordance with the laws of the United Arab Emirates. In the event of any dispute arising between the Parties in relation to this Website shall be referred to the jurisdiction of Dubai Courts for resolution. "
        </span>
                        {/* {props.Environment.tnccontent1}
                        <br/>
                        <br/>
                        {props.Environment.tnccontent2}
                        <br/>
                        <br/>
                        {props.Environment.tnccontent3} */}
                    </div>
                
                </Tween>
            </div>
        </div>
    );
}

export default TermsCondition;