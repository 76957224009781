import React, { useState, useEffect, useRef } from 'react';
import Button from '../Button/Button';
import { Environment } from '../../env';
import { useHistory } from 'react-router-dom';
import { useStore, useDispatch, useSelector } from 'react-redux';
import { GenerateProductLink } from '../../redux/actions/home/actions';
import { AddToCart, DeleteCartItem, UpdateCartItem } from '../../redux/actions/cart/actions';
import { AddFavourite, RemoveFavourite, SetisFav } from '../../redux/actions/favourites/actions';
// import { TweenMax } from 'gsap';
import { currencyconvertrate } from '../../utils/Function';
import { calcDaysFromCurrentDate, showTimeBasedCampaigns, prefixZeroIfSingleDigit } from '../../utils/Function';
import {
   CreateQuote,
   GuestAddToCart,
   GuestDeleteCartItem,
   GuestUpdateCartItem,
} from '../../redux/actions/guestcart/actions';
import CustomProgressbar from './CustomProgressbar';
import { Tween } from 'react-gsap';
import { get } from 'lodash';
import PopUps from '../PopUps/PopUps';
import $ from 'jquery';
import TimerCounter from './TimerCounter';
import ReactPixel from 'react-facebook-pixel';
const PrizeList = props => {
   const [type, setType] = useState(1);
   const store = useStore().getState();
   const c_id = useSelector(store => get(store, 'auth.cartid', ''));
   const token = useSelector(store => get(store, 'auth.token', ''));
   const cartItem = store.cart.cart.find(item => item.sku === props.item.product.sku);
   const favourites = useSelector(store => store.favourite.favourites);
   const [count, setCount] = useState(cartItem ? cartItem.qty : 1);
   const dispatch = useDispatch();
   // var sales = 0;
   // var stock = 0;
   // var prizePercentage = 0;
   const [sales, setSales] = useState(0);
   const [stock, setStock] = useState(0);
   const [prizePercentage, setPrizePercentage] = useState(0);
   // const sales =
   //    props.item.product.custom_attributes.find(x => x.attribute_code === 'total_sales') == undefined
   //       ? 1
   //       : props.item.product.custom_attributes.find(x => x.attribute_code === 'total_sales').value;
   // console.log(sales)
   // const stock =
   //    props.item.product.custom_attributes.find(x => x.attribute_code === 'total_stock') == undefined
   //       ? 1
   //       : props.item.product.custom_attributes.find(x => x.attribute_code === 'total_stock').value;
   //const cost = props.item.product.custom_attributes.find(x => x.attribute_code === 'price')
   // const prizePercentage = (sales / stock) * 100;
   const history = useHistory();
   const mainPrize = useRef();
   const hid = useRef(null);
   const prod = props.item.product;
   const [isfav, setfav] = useState(null);
   const [active, setactive] = useState(true);
   const [price, setprice] = useState(null);
   const [campaign, setcampaign] = useState(null);
   const [product, setproduct] = useState(false);
   const [displayTimer, displayTimerTimeBasedCamp] = useState(false);
   useEffect(() => {
      // let camp = store.home.campaign_manager.find(camp => camp.campaign_product_id == props.item.product.id);
      let camp = store.home.campaign_manager.find(camp => camp?.campaign_prize?.sku == props.item?.prize?.sku);

      setcampaign(camp);
      // sales = camp?.campaign_total_sales;
      // stock = camp?.campaign_total_tickets;
      // prizePercentage = (sales / stock) * 100;
      setSales(camp?.campaign_total_sales);
      setStock(camp?.campaign_total_tickets);

      setPrizePercentage((camp?.campaign_total_sales / camp?.campaign_total_tickets) * 100);
      const showTimeBasedCamp = showTimeBasedCampaigns(camp?.campaign_type);
      if (showTimeBasedCamp && camp.status == 'active') {
         showHideTimerTimeBasedCamp(camp?.campaign_end_time);
      } else {
         displayTimerTimeBasedCamp(false);
      }
      //    const days = calcDaysFromCurrentDate(camp?.campaign_end_time);
      // if (days === 0) {
      //    displayTimerTimeBasedCamp(true);
      // } else {
      //    displayTimerTimeBasedCamp(false);
      // }
   }, []);
   const showHideTimerTimeBasedCamp = endingOn => {
      const days = calcDaysFromCurrentDate(endingOn);

      // console.log('Number(new Date(endingOn).getTime()) ========== ', Number(new Date(endingOn).getTime()));
      // console.log(' Number(now) ========== ', Number(now));

      if (days === 0) {
         displayTimerTimeBasedCamp(true);
      } else {
         displayTimerTimeBasedCamp(false);
      }
   };

   useEffect(() => {
      if (props.item.product.type_id == 'configurable') {
         var cartItems = store.cart.cart.filter(item => item.sku.includes(props.item.product.sku));
         var qty = 0;
         cartItems.forEach(element => {
            qty += element.qty;
         });

         var child_prod = store.home.allproducts.find(
            item => item.id == props.item.product.extension_attributes.configurable_product_links[0]
         );
         if (child_prod != undefined) {
            setprice(child_prod.price);
         }

         setType(cartItems.length !== 0 ? 2 : 1);
         setCount(qty !== 0 ? qty : 1);
      } else {
         setType(cartItem ? 2 : 1);
         setCount(cartItem ? cartItem.qty : 1);
      }
   }, [store.cart.cart, props.item]);

   useEffect(() => {
      var fav = favourites.find(item => {
         return item.product_id == props.item.product.id;
      });
      if (fav != null) {
         //TweenMax.fromTo(hid.current, 0.3, { scale: 1.8 }, { scale: 1 });
         setfav(fav);
         //TweenMax.fromTo(hid.current, 0.3, { scale: 1.8 }, { scale: 1 });
      } else {
         // TweenMax.fromTo(hid.current, 0.3, { scale: 1 }, { scale: 1 });
         // TweenMax.fromTo(hid.current, 0.3, { scale: 1.8 }, { scale: 1 });
         setfav(null);
      }
   }, [favourites]);

   const getPrizePercentage = () => {
      let prizePercentage = 0;
      if (showTimeBasedCampaigns(campaign?.campaign_type)) {
         let finished =
            calcDaysFromCurrentDate(campaign?.campaign_end_time, campaign?.activation_date) -
            calcDaysFromCurrentDate(campaign?.campaign_end_time);
         let total = calcDaysFromCurrentDate(campaign?.campaign_end_time, campaign?.activation_date);
         prizePercentage = (finished / total) * 100;
      } else {
         prizePercentage = (sales / stock) * 100;
      }

      return prizePercentage;
   };

   const ShouldOpenPopup = () => {
      props.callback(props.item.product.extension_attributes.configurable_product_links);
   };

   const addToCart = async () => {
      if (stock === sales || props?.status == 'sold') {
         return;
      }

      // fbq('track', 'AddToCart');

      //temp
      setType(3);

      if (props.item.product.type_id == 'configurable') {
         ShouldOpenPopup();
      } else {
         if (!active) {
            return;
         }
         ReactPixel.track('AddToCart');
         setactive(false);

         // AF('pba', 'event', {
         //    eventType: 'EVENT',
         //    eventName: 'af_add_to_cart',
         //    eventValue: { af_content_id: '1' },
         // });

         if (store.auth.token !== null) {
            await dispatch(AddToCart(c_id, props.item.product.sku));
         } else {
            if (store.auth.cartid === null) {
               const quote = await dispatch(CreateQuote());

               await dispatch(GuestAddToCart(quote, props.item.product.sku));
            } else {
               await dispatch(GuestAddToCart(c_id, props.item.product.sku));
            }
         }
         setType(2);
         setactive(true);
      }
   };

   const onPlus = async e => {
      e.preventDefault();
      if (props.item.product.type_id == 'configurable') {
         ShouldOpenPopup();
      } else {
         if (!active) {
            return;
         }
         //setactive(false);
         if (cartItem.qty >= stock - sales) {
            return;
         }
         if (store.auth.token !== null) {
            //await dispatch(UpdateCartItem(c_id, cartItem.sku, count + 1, cartItem.item_id));
            dispatch(UpdateCartItem(c_id, cartItem.sku, count + 1, cartItem.item_id));
         } else {
            await dispatch(GuestUpdateCartItem(c_id, cartItem.sku, count + 1, cartItem.item_id));
         }
         setCount(cartItem.qty);
         setactive(true);
      }
   };

   const OnDelete = (id, product_id) => {
      dispatch(RemoveFavourite(token, id, product_id));
   };

   const onMinus = async e => {
      e.preventDefault();
      if (props.item.product.type_id == 'configurable') {
         ShouldOpenPopup();
      } else {
         if (!active) {
            return;
         }
         //setactive(false);
         if (count === 1) {
            //setType(3)
            //alert for delete item
            if (store.auth.token !== null) {
               await dispatch(DeleteCartItem(cartItem.item_id));
            } else {
               await dispatch(GuestDeleteCartItem(cartItem.item_id, c_id));
            }
            setType(1);
         } else {
            //update it with less number
            if (store.auth.token !== null) {
               //await dispatch(UpdateCartItem(c_id, cartItem.sku, count - 1, cartItem.item_id));
               dispatch(UpdateCartItem(c_id, cartItem.sku, count - 1, cartItem.item_id));
            } else {
               await dispatch(GuestUpdateCartItem(c_id, cartItem.sku, count - 1, cartItem.item_id));
            }
         }
         setactive(true);
         setCount(cartItem.qty);
      }
   };

   const OnHearted = async () => {
      if (sales == stock) {
         return;
      }
      if (token === null) {
         //new additions
         if (isfav !== null) {
            dispatch(RemoveFavourite(null, null, props.item.product.id));
            return;
         } else {
            prod.product_id = prod.id;
            await dispatch(AddFavourite(null, props.item.product.id, null));
            return;
         }
      } else if (isfav !== null) {
         dispatch(RemoveFavourite(token, isfav.wishlist_item_id, props.item.product.id));
      } else {
         prod.product_id = prod.id;
         await dispatch(AddFavourite(store.auth.token, props.item.product.id, prod));
         dispatch(SetisFav());
      }
   };

   const OnShared = async () => {
      let campaign = store.home.campaign_manager.find(camp => camp.campaign_product_id == props.item.product.id);
      let link =
         'Don’t miss this amazing winning opportunity ' +
         ' \n \n' +
         campaign.campaign_name +
         ' \n \n' +
         props.item.product.custom_attributes.find(attr => attr.attribute_code == 'share_link').value;
      //let link = await GenerateProductLink(props.item.product.id)
      // console.log(link)
      // window.ReactNativeWebView.postMessage(link);
      window.ReactNativeWebView.postMessage(
         JSON.stringify({
            type: 'share',
            message: link,
         })
      );
      //history.push("/share")
   };

   var short_description = props.item.prize.custom_attributes.find(x => x.attribute_code === 'short_description');
   // if (short_description.value) {
   //    if (!short_description.value.includes('span')) {
   //       short_description.value = localStorage.getItem('langage') == "en" ? short_description.value.replace('WIN', '&nbsp;<span style="color: #e03a3f;">WIN </span>') : short_description.value.replace('اربح', '<span style="color: #e03a3f;margin-left: 5px;">اربح</span>')
   //    }
   // }

   // var image = props.item.prize.custom_attributes.find(x => x.attribute_code === 'image');

   // var small_image = props.item.product.custom_attributes.find(x => x.attribute_code === 'small_image');

   var small_image = props.item.product.custom_attributes.find(x => x.attribute_code === 'image');

   var image = props.item.prize.custom_attributes.find(x => x.attribute_code === 'small_image');

   var short_description_prod = props.item.product.custom_attributes.find(
      x => x.attribute_code === 'short_description'
   );

   var campaign_close_text = props.item.product.custom_attributes.find(x => x.attribute_code === 'campaign_close_text');

   return (
      <div ref={mainPrize} className="dsste">
         {props.homelisting && (
            <Tween from={{ y: 0 }}>
               <div className={`main-prize-listing ${stock === sales || props?.status == 'sold' ? 'soldout' : ''}`}>
                  {/* <div className="cart-center">
                     <img src="icons/cart-center.svg" alt="" />
                  </div> */}
                  <img src="icons/temp.svg" className="clip-path" alt="" />
                  {/* <div className="left-shape"></div> */}
                  <div className="listing-prize listing-prize11">
                     <div className="semi-circle-progressbar">
                        {showTimeBasedCampaigns(campaign?.campaign_type) ? (
                           <>
                              <div className="dialdots-line">
                                 <img src={`${props.Environment.img_path}icons/dots.svg`} alt="" />
                              </div>
                              <div className="progress-percentage">
                                 <p className="total-value">Ends In</p>
                                 <p className="current-value">
                                    {prefixZeroIfSingleDigit(calcDaysFromCurrentDate(campaign?.campaign_end_time))}
                                 </p>
                                 {/* <p className="sold-out site-e">
                                    {props.Environment.SOLDTETX} <br /> {props.Environment.OUTSNT}
                                 </p> */}
                                 {/* <p className="sold-out site-a">{props.Environment.ddds}</p> */}
                                 <p className="total-value">{'Days'}</p>
                              </div>
                           </>
                        ) : (
                           <>
                              <div className="dialdots-line">
                                 <img src={`${props.Environment.img_path}icons/dots.svg`} alt="" />
                              </div>
                              <div className="progress-percentage">
                                 <p className="current-value">{sales}</p>
                                 <p className="sold-out site-e">
                                    {props.Environment.SOLDTETX} <br /> {props.Environment.OUTSNT}
                                 </p>
                                 <p className="sold-out site-a">{props.Environment.ddds}</p>
                                 <p className="total-value">{stock}</p>
                              </div>
                           </>
                        )}
                        <CustomProgressbar
                           progress={getPrizePercentage()}
                           soldOut={stock === sales || props?.status == 'sold' ? true : false}
                        />
                     </div>
                     <div className="pricehotfix">
                        <div className="listing-prize-icons">
                           {/* {stock !== sales ? (
                              <span className="sic" onClick={() => { OnShared() }}>
                                 <img src={`${props.Environment.img_path}icons/ic-share.svg`} alt="" />
                              </span>
                           ) : null} */}

                           {props.status !== 'sold' && (
                              <span onClick={OnHearted}>
                                 {
                                    stock !== sales ? (
                                       <div>
                                          {isfav == null ? (
                                             <img
                                                id="notidisable"
                                                className="notidisable heart-icon"
                                                ref={hid}
                                                src={`${props.Environment.img_path}icons/${'ic-fav.svg'}`}
                                                alt=""
                                             />
                                          ) : (
                                             <img
                                                id="notienable"
                                                className="notienable heart-icon bb"
                                                ref={hid}
                                                src={`${props.Environment.img_path}icons/${'ic-fav-active.svg'}`}
                                                alt=""
                                             />
                                          )}
                                       </div>
                                    ) : null
                                    // <img src={`${props.Environment.img_path}icons/ic-notification-gray.svg`} alt="" />
                                 }
                              </span>
                           )}
                        </div>
                        <div className="prize-title">
                           <div
                              dangerouslySetInnerHTML={{
                                 __html: short_description == undefined ? '' : short_description.value,
                              }}
                           />
                        </div>
                        <div
                           className="prize-img"
                           onClick={event => {
                              //  alert("ds")

                              event.preventDefault();

                              //setproduct(true);

                              if (stock === sales || props?.status == 'sold') {
                                 return;
                              }
                              history.push({
                                 pathname: '/product',
                                 query: { type: 'prize' },
                                 props: { prize: props.item.prize, product: props.item.product },
                              });

                              // history.push({
                              //    pathname: '/product1',
                              //    query: { type: 'prize' },
                              //    props: { prize: props.item.prize, product: props.item.product },
                              // });
                           }}
                        >
                           <img src={props.Environment.productUrl + (image == undefined ? '' : image.value)} alt="" />
                        </div>

                        <div className="campaign-close-text-title">
                           <div
                              dangerouslySetInnerHTML={{
                                 __html: campaign_close_text == undefined ? '' : campaign_close_text.value,
                              }}
                           />
                        </div>
                     </div>

                     <div className="listing-prize-icons">
                        {/* {stock !== sales ? (
                           <span className="sic" onClick={() => { OnShared() }}>
                              <img src={`${props.Environment.img_path}icons/ic-share.svg`} alt="" />
                           </span>
                        ) : null} */}
                        {displayTimer && <TimerCounter closingOn={campaign?.campaign_end_time}></TimerCounter>}
                        {props.status !== 'sold' && (
                           <span onClick={OnHearted}>
                              {
                                 stock !== sales ? (
                                    <div>
                                       {isfav == null ? (
                                          <img
                                             id="notidisable"
                                             className="notidisable heart-icon"
                                             ref={hid}
                                             src={`${props.Environment.img_path}icons/${'ic-fav.svg'}`}
                                             alt=""
                                          />
                                       ) : (
                                          <img
                                             id="notienable"
                                             className="notienable heart-icon aa"
                                             ref={hid}
                                             src={`${props.Environment.img_path}icons/${'ic-fav-active.svg'}`}
                                             alt=""
                                          />
                                       )}
                                    </div>
                                 ) : null
                                 // <img src={`${props.Environment.img_path}icons/ic-notification-gray.svg`} alt="" />
                              }
                           </span>
                        )}
                     </div>
                     <div className="prize-title">
                        <div
                           dangerouslySetInnerHTML={{
                              __html: short_description == undefined ? '' : short_description.value,
                           }}
                        />
                     </div>
                     <div
                        className="prize-img ds"
                        onClick={event => {
                           // alert("ds")

                           event.preventDefault();
                           // setproduct(true);
                           // event.preventDefault();
                           //setproduct(true);

                           if (stock === sales || props?.status == 'sold') {
                              return;
                           }
                           history.push({
                              pathname: '/product',
                              query: { type: 'prize' },
                              props: { prize: props.item.prize, product: props.item.product },
                           });

                           // return (
                           //    <ProductDetail
                           //       {...props}
                           //       prize={props.item.prize}
                           //       product={props.item.product}
                           //       type='prize'
                           //    />
                           // );
                        }}
                     >
                        <img src={props.Environment.productUrl + (image == undefined ? '' : image.value)} alt="" />
                     </div>

                     <div className="listing-product listing-product11">
                        <div
                           className="product-img po2"
                           onClick={() => {
                              if (stock === sales || props?.status == 'sold') {
                                 return;
                              }
                              history.push({
                                 pathname: '/product',
                                 query: { type: 'product' },
                                 props: { prize: props.item.prize, product: props.item.product },
                              });
                           }}
                        >
                           <img
                              src={props.Environment.productUrl + (small_image == undefined ? '' : small_image.value)}
                              alt=""
                           />
                        </div>
                        <div className="product-name">
                           <div className="product-title">
                              <p
                                 dangerouslySetInnerHTML={{
                                    __html: short_description_prod == undefined ? '' : short_description_prod.value,
                                 }}
                              />
                              <span className="product-amount">
                                 {store.account.currency}{' '}
                                 {price != null
                                    ? currencyconvertrate(price, store)
                                    : currencyconvertrate(props.item.product.price, store)}
                              </span>
                           </div>

                           {type === 1 || type === 3 || sales == stock ? (
                              <Button
                                 className={`button-red ${type == 3 ? 'green' : ''}`}
                                 onClick={addToCart}
                                 type={type}
                                 data={type == 1 ? props.Environment.AddTCart : props.Environment.AddTCartn}
                                 soldout={sales == stock ? true : false}
                                 date={campaign ? campaign.draw_date : null}
                              />
                           ) : (
                              <div className="home-plus-minus">
                                 <Button className="button-red minus" onClick={onMinus}>
                                    -
                                 </Button>
                                 {props.item.product.type_id == 'configurable' ? (
                                    <p>{count !== 0 ? count : 1}</p>
                                 ) : (
                                    <p>{cartItem ? cartItem.qty : 1}</p>
                                 )}
                                 <Button className="button-red plus" onClick={onPlus}>
                                    +
                                 </Button>
                              </div>
                           )}
                        </div>
                     </div>
                  </div>
                  {/* <div className="listing-product listing-product11">
                     <div
                        className="product-img po2"
                        onClick={() => {
                           if (stock === sales || props?.status=="sold") {
                              return;
                           }
                           history.push({
                              pathname: '/product',
                              query: { type: 'product' },
                              props: { prize: props.item.prize, product: props.item.product },
                           });
                        }}
                     >
                        <img
                           src={props.Environment.productUrl + (small_image == undefined ? '' : small_image.value)}
                           alt=""
                        />
                     </div>
                     <div className="product-name">
                        <div className="product-title">
                           <p
                              dangerouslySetInnerHTML={{
                                 __html: short_description_prod == undefined ? '' : short_description_prod.value,
                              }}
                           />
                           <span className="product-amount">

                              {store.account.currency} {price != null ? currencyconvertrate(price, store) : currencyconvertrate(props.item.product.price, store)}
                           </span>
                        </div>

                        {type === 1 || type === 3 || sales == stock ? (
                           <Button
                              className={`button-red ${type == 3 ? 'green' : ''}`}
                              onClick={addToCart}
                              type={type}
                              data={type == 1 ? props.Environment.AddTCart : props.Environment.AddTCartn}
                              soldout={sales == stock ? true : false}
                              date={campaign ? campaign.draw_date : null}
                           />
                        ) : (
                              <div className="home-plus-minus">
                                 <Button className="button-red minus" onClick={onMinus}>
                                    -
                              </Button>
                                 {props.item.product.type_id == 'configurable' ? (
                                    <p>{count !== 0 ? count : 1}</p>
                                 ) : (
                                       <p>{cartItem ? cartItem.qty : 1}</p>
                                    )}
                                 <Button className="button-red plus" onClick={onPlus}>
                                    +
                              </Button>
                              </div>
                           )}
                     </div>
                  </div> */}
                  <div className="campaign-close-text-title">
                     {props.campaign}
                     <div
                        dangerouslySetInnerHTML={{
                           __html:
                              campaign_close_text == undefined
                                 ? ''
                                 : store.home.language === 'en'
                                 ? props?.item?.campaign?.campaign_close_text_en
                                 : props?.item?.campaign?.campaign_close_text_ar,
                        }}
                     />
                  </div>
                  {stock === sales || props?.status == 'sold' ? (
                     <div className="soldout-img">
                        <img src={`${props.Environment.img_path}images/soldout.svg`} alt="" />
                     </div>
                  ) : null}
               </div>
            </Tween>
         )}
      </div>
   );
};

export default PrizeList;
