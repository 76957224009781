import { VIEWMAMBOPROFILE } from "../actions/cart/actionTypes"
import { LOGOUT } from "../actions/auth/actionTypes"

const initialState = {
  data: {}
};

const MamboReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIEWMAMBOPROFILE:
      return {
        ...state,
        data: action.payload
      };
    case LOGOUT:
      return {
          ...state,
          data: {}
      };  
    default:
      return state;
  }
}

export default MamboReducer;
