import React, { useRef, useState, useEffect } from 'react';
import Button from '../../components/Button/Button';
import { TweenMax } from 'gsap';
import { Swipeable } from 'react-swipeable';
import { useHistory } from 'react-router-dom';
import { Tween } from 'react-gsap';
import ReactPixel from 'react-facebook-pixel';
const ActionSheetPayment = props => {
   const [error, setError] = useState({});
   const [active, setActive] = useState(false);
   const SheetAddress = useRef(null);
   const [selected2, setselected2] = useState(false);
   const history = useHistory();

   const handleGoBack = () => {
      setActive(false);
      TweenMax.fromTo(SheetAddress.current, props.Environment.popupBackground.duration, { y: 0 }, { y: 1000 });
      setTimeout(() => {
         props.setShow(false);
      }, 700);
   };

   useEffect(() => {
      if (!props.desktop) {
         document.body.style.overflow = 'hidden';
         TweenMax.fromTo(SheetAddress.current, props.Environment.popupBackground.duration, { y: 600 }, { y: 0 });
         setTimeout(() => {
            setActive(true);
         }, 700);
      }

      return () => (document.body.style.overflow = 'visible');
   }, []);

   return (
      <Swipeable onSwipedDown={handleGoBack}>
         <div
            className="action-container"
            ref={SheetAddress}
            style={{ backgroundColor: active ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0)' }}
         >
            <Tween
               from={{
                  y: '100%',
                  opacity: 1,
                  delay: props.Environment.popupContainer.delay,
                  duration: props.Environment.popupContainer.duration,
               }}
            >
               <div className="action-cards">
                  <div className="form-screen-details">
                     <h4>{props.Environment.payment}</h4>
                     <h6>{props.Environment.paytxt}</h6>
                  </div>
                  <div className={`forms-cards ${!selected2 ? 'required-box' : ''}`}>
                     <div className={`address-label ${selected2 ? 'active-txt' : ''}`}>
                        {props.Environment.saved_cards}
                     </div>
                     <div className="saved-cards-list-container">
                        <input
                           type="radio"
                           name="cardlist"
                           className="saved-cards-list"
                           id="newcard"
                           onClick={() => {
                              setselected2('1');
                              props.setSelected('1');
                              handleGoBack();
                           }}
                        />
                        <label className="card-number-label" htmlFor="newcard">
                           <div className="card-container-main">
                              <div className="card-container">
                                 <img src={`${props.Environment.img_path}makeicons/ic-visa.svg`} alt="" />
                                 <span className="card-number">{props.Environment.card_number}</span>
                              </div>
                              {/* <div className="checked-img">
                      <img
                        src={`${props.Environment.img_path}makeicons/ic-checkedcard.svg`}
                        alt="" />
                    </div> */}
                              <span
                                 className={`circle ${selected2 === '1' ? 'active' : ''} ${
                                    selected2 === false ? 'required-circle' : ''
                                 }`}
                              >
                                 <div></div>
                              </span>
                           </div>
                        </label>
                     </div>
                     <div className="saved-cards-list-container 11">
                        <input
                           type="radio"
                           name="cardlist"
                           className="saved-cards-list"
                           id="newcarda"
                           onClick={() => {
                              setselected2('2');
                              props.setSelected('2');
                              handleGoBack();
                           }}
                        />
                        {/* <label className="card-number-label" htmlFor="newcarda">
                           <div className="card-container-main">
                              <img src={`${props.Environment.img_path}makeicons/ic_apple_pay.png`} alt="" />
                             
                              <span
                                 className={`circle ${selected2 === '2' ? 'active' : ''} ${
                                    selected2 === false ? 'required-circle' : ''
                                 }`}
                              >
                                 <div></div>
                              </span>
                           </div>
                        </label> */}
                     </div>
                     <div
                        className="add-new-address"
                        onClick={() => {
                           handleGoBack();
                           props.setSelected(false);
                           // setTimeout(() => {
                           //    history.push('/addcards_checkout');
                           // }, 700);
                           history.push('/addcards_checkout');
                        }}
                     >
                        <img src={`${props.Environment.img_path}makeicons/ic_plus.svg`} alt="" />
                        <span className="add-address">{props.Environment.add_card}</span>
                     </div>
                  </div>
               </div>
            </Tween>
         </div>
      </Swipeable>
   );
};

export default ActionSheetPayment;
