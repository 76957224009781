import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import KanzApp from './pages/KanzApp/KanzApp';
import LanguageWrapper from './HigherOrderComponent/LanguageWrapper';

export default () => {
   return (
      <BrowserRouter>
         <KanzApp />
      </BrowserRouter>
   );
};
