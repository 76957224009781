import React, { useState, useEffect } from "react"
import NavBar from '../../components/NavBar/NavBar';
import { Environment } from '../../env';
import { KoinzDetails as GetDetails } from "../../redux/actions/cart/actions"
import { get } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import { isObjEmpty } from "../../utils/utils";

const KoinzDetails = (props) => {

    const [details, setdetails] = useState([])
    const store = useSelector(store => store)
    const dispatch = useDispatch()
    const mambo = useSelector(store => store.mambo.data);

    useEffect(() => {
        let date = new Date("2020-11-26T07:12:27.903Z")
        console.log(date.getDate() + "/" + date.getMonth() + 1 + "/" + (date.getFullYear() % 100))
        dispatch(GetDetails(get(store, 'auth.userinfo.id', null)))
    }, [])

    return (
        <div className="koinzdetails-parent">
            <NavBar koinzdetails={Environment.mykoinz} />
            <div className="current-balance">
                <h4>{props.Environment.currentbalance}</h4>
                <div className="koinz-balance">
                    {isObjEmpty(mambo) ? 0 : mambo.points[0].balance}
                    <span>{Environment.koinz}</span>
                </div>
            </div>

            <div className="history-list">
                <div className="head">
                    <span className="row1">Date</span>
                    <span className="row2">Transactions</span>
                    <span className="row3">Koinz</span>
                </div>
                <div className="list">
                    {get(store, 'cart.koinzDetails', []).map((item) => {

                        let date = new Date(item.createdOn)
                        date = date.getDate() + "/" + (date.getMonth() + 1) + "/" + (date.getFullYear() % 100)

                        let koin = 0
                        let desc = ""

                        if (item.attrs.type == "behaviour") {

                            if (item.awarded.length == 0) {
                                return (
                                    <div>
                                        {item.attrs.behaviours.map(behave => {
                                            if (behave.prizes.points == null) {
                                                return
                                            }

                                            koin = behave.prizes.points[0].points + ""

                                            if (behave.prizes.points[0].pointId == "5fe9a201f70b7319514e22aa") {
                                                koin = behave.prizes.points[1].points + ""
                                            }

                                            return (
                                                <div className="child">
                                                    <span className="row1">{date}</span>
                                                    <span className="row2">{behave.name}</span>
                                                    <span className={`row3 ${koin.includes("-") ? "negative" : ""}`}>{koin}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                                //return null
                            } else {
                                item.awarded.forEach(award => {
                                    if (award.prizes.points !== null) {
                                        award.prizes.points.forEach(point => {
                                            koin = koin + point.points
                                        })
                                    }
                                })

                                koin += ""

                                if (item.awarded.length != 0) {
                                    desc = item.awarded[0].name
                                }

                                return (
                                    <div>
                                        {item.awarded.map(award => {
                                            if (award.prizes.points == null) {
                                                return
                                            }


                                            koin = award.prizes.points[0].points + ""

                                            return (
                                                <div className="child">
                                                    <span className="row1">{date}</span>
                                                    <span className="row2">{award.name}</span>
                                                    <span className={`row3 ${koin.includes("-") ? "negative" : ""}`}>{koin}</span>
                                                </div>
                                            )
                                        })}

                                    </div>
                                )

                            }

                            /*  item.points.forEach(item => {
                                 koin = koin + item.points
                             }) */


                        } else {
                            if (item.reason == "participation koinz") return
                            if (item.state == "rejected") return
                            desc = item.reason
                            koin = item.points[0].points + " "
                        }



                        return (
                            <div className="child">
                                <span className="row1">{date}</span>
                                <span className="row2">{desc}</span>
                                <span className={`row3 ${koin.includes("-") ? "negative" : ""}`}>{koin}</span>
                            </div>
                        )
                    })}

                    {/*  <div className="child">
                        <span className="row1">12/11/20</span>
                        <span className="row2">All categories mission</span>
                        <span className="row3">+30</span>
                    </div>
                    <div className="child">
                        <span className="row1">12/11/20</span>
                        <span className="row2">All categories mission</span>
                        <span className="row3">+30</span>
                    </div>
                    <div className="child">
                        <span className="row1">12/11/20</span>
                        <span className="row2">All categories mission</span>
                        <span className="row3">+30</span>
                    </div>
                    <div className="child">
                        <span className="row1">12/11/20</span>
                        <span className="row2">All categories mission</span>
                        <span className="row3">+30</span>
                    </div>
                    <div className="child">
                        <span className="row1">12/11/20</span>
                        <span className="row2">All categories mission</span>
                        <span className="row3">+30</span>
                    </div>
                    <div className="child">
                        <span className="row1">12/11/20</span>
                        <span className="row2">All categories mission</span>
                        <span className="row3">+30</span>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default KoinzDetails