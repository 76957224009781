import {
  LOGIN,
  REGISTER,
  SETERROR,
  AUTOLOGIN,
  SETCARTID,
  CREATE_PLAYER,
  CREATE_PLAYER_ERROR,
  LOGOUT,
  GENERATE_OTP,
} from './actionTypes';
import { SETNOTIFICATIONLISTING } from '../home/actionTypes';
import AuthApi from '../../../api/AuthApi';
import Cookie from 'js-cookie';
import { UpdateCustomer } from '../account/actions';
import MemboApi from '../../../api/MemboApi';
import Axios from 'axios';
import { AddFavourite } from '../favourites/actions';
import TagManager from "react-gtm-module";

const setCookies = data => {
  const { id, email, firstname, lastname, gender, extension_attributes, custom_attributes } = data;

  localStorage.setItem('token', extension_attributes.token);
  localStorage.setItem(
    'info',
    JSON.stringify({
      id,
      email,
      firstname,
      lastname,
      gender,
      phone_number: data.custom_attributes.find(element => {
        return element.attribute_code === 'phone_number';
      }).value,
      nationality: '',
    })
  );
};

const MoveItems = (cart_id, cart, customerid, storeid, token) => async dispatch => {
  if (cart.length === 0 || cart_id === null) return false;

  console.log(cart_id);

  try {
    await AuthApi({
      method: 'PUT',
      url: '/guest-carts/' + cart_id,
      data: {
        customerId: customerid,
        storeId: storeid,
      },
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });

    dispatch({
      type: 'MOVE_ITEMS',
    });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const ResetPass = (currentPass, newPass, customer_id) => async dispatch => {
  try {
    const res = await AuthApi({
      method: 'PUT',
      url: '/customers/me/password?customerId=' + customer_id,
      data: {
        currentPassword: currentPass,
        newPassword: newPass,
      },
      headers: {
        Authorization: 'Bearer ' + '7eyk6eurtm7rfa2blsmscw2ggnxmp2d3',
      },
    });

    if (res.data) {
      return true;
    }

    return false;
  } catch (e) {
    console.log(e);

    if (e.response && e.response.data) {
      dispatch({
        type: SETERROR,
        payload: e.response.data.message,
      });
    }

    return false;
  }
};

export const CreateCart = token => async dispatch => {
  try {
    const res = await AuthApi({
      method: 'POST',
      url: '/carts/mine',
      data: {},
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });

    dispatch({
      type: 'CREATE_CART',
    });
    localStorage.setItem('cartid', res.data);
    return res.data;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const CreateCartId = token => async dispatch => {
  try {
    const res = await dispatch(CreateCart(token));
    dispatch({
      type: 'SETCARTID',
      payload: res,
    });
    localStorage.setItem('cartid', res);
    return res;
  } catch (e) {
    console.log(e);
    return false;
  }
};

const Favourites = token => (dispatch, getState) => {
  let fav = getState().favourite.favourites;

  if (fav.length != 0) {
    fav.forEach(item => {
      dispatch(AddFavourite(token, item.product_id));
    });
  }

  localStorage.setItem('fav', JSON.stringify([]));
};

const Register = (
  countrycode,
  data,
  cart_id,
  cart,
  device_type,
  device_token,
  refer_id,
  store_id
) => async dispatch => {
  //alert(device_token)
  try {
    const res = await AuthApi({
      method: 'POST',
      url: '/customers',
      data: {
        customer: {
          email: data.email.trim(),
          firstname: data.firstname,
          lastname: data.lastname,
          gender: data.gender,
          store_id,
          extension_attributes: {
            // phone_number: countrycode + data.phone_number, //remove counry code
            phone_number: data.phone_number,
            nationality: '',
            dial_code: countrycode,
            device_type,
            device_token,
            refer_id,
          },
        },
        password: data.pass,
      },
    });

    if (res.data.created_at !== undefined) {
      dispatch(Favourites(res.data.extension_attributes.token));
      await dispatch(CreatePlayer(res.data));
      setCookies(res.data);
      await dispatch(MoveItems(cart_id, cart, res.data.id, res.data.store_id, res.data.extension_attributes.token));
      dispatch({
        type: 'SETCARTID',
        payload: null,
      });
      //Temp chnage at 17 jan 2020
      // const cartid = await dispatch(CreateCart(res.data.extension_attributes.token));
      dispatch({
        type: REGISTER,
        payload: {
          data: {
            id: res.data.id,
            email: res.data.email,
            firstname: res.data.firstname,
            lastname: res.data.lastname,
            gender: res.data.gender,
            phone_number: res.data.custom_attributes[1].value,
            nationality: '',
          },
          token: res.data.extension_attributes.token,
        },
      });

      return true;
    }
    return false;
  } catch (e) {
    console.log(e);
    dispatch({
      type: SETERROR,
      payload: e.response.data.message,
    });
    return false;
  }
};

//mambo
export const CreatePlayer = data => async dispatch => {
  //https://kanziapp.net/api/v1/kanziapp.com/users
  try {
    var res = await MemboApi({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      url: '/api/v1/js/d92d7bca75984c979adddd09aa3e4a38/kanziapp.com/users',
      data: {
        uuid: data.id,
        isMember: true,
        details: {
          email: data.email,
          displayName: data.firstname + ' ' + data.lastname,
          firstName: data.firstname,
          lastName: data.lastname,
          gender: data.gender === 1 ? 'M' : 'F',
        },
      },
    });

    console.log(res);

    dispatch({
      type: CREATE_PLAYER,
    });

    //dispatch(ViewMamboProfile())

    return true;
  } catch (e) {
    console.log(e);
    dispatch({
      type: CREATE_PLAYER_ERROR,
      payload: e,
    });
    return false;
  }
};

export const GenerateOtp = (phone_number, type) => async dispatch => {
  //type can be register or login
  try {
    const res = await AuthApi({
      method: 'POST',
      url: '/customer/generateotp',
      data: { phone_number, type },
    });

    dispatch({ type: GENERATE_OTP, OTPmessage: res.data });
    if (res.data.status === 1) {
      dispatch({ type: GENERATE_OTP, OTPmessage: res.data });
      return true;
    } else {
      dispatch({ type: GENERATE_OTP, OTPmessage: res.data });
      return res.data.message;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};
const tagManagerArgs = {
  dataLayer: {
    pageCategory: "signup",
    visitorType: "high-value"
  }
};
//need this for register
export const VerifyROTP = (
  countrycode,
  otp,
  data,
  cart_id,
  cart,
  device_type = undefined,
  device_token = undefined,
  refer_id = undefined,
  store_id
) => async dispatch => {
  try {
    const res = await AuthApi({
      method: 'POST',
      url: '/customer/verifyregisterotp',
      data: {
        phone_number: countrycode + data.phone_number,
        otp: otp,
      },
    });

    if (res.data.status === 0) {
      dispatch({ type: SETERROR, payload: res.data.message });
      return false;
    } else if (res.data.status === 1) {

    TagManager.dataLayer(tagManagerArgs);

      return await dispatch(
        Register(countrycode, data, cart_id, cart, device_type, device_token, refer_id, store_id)
      );
    }
  } catch (e) {
    console.log(e);
    dispatch({ type: SETERROR, payload: 'Something went wrong' });
    return false;
  }
};

//this is for updating user info
export const VerifyNewOTP = (countrycode, otp, data) => async dispatch => {
  try {
    const res = await AuthApi({
      method: 'POST',
      url: '/customer/verifyregisterotp',
      data: {
        phone_number: countrycode + data.phone_number,
        otp: otp,
      },
    });

    if (res.data.status === 0) {
      dispatch({
        type: SETERROR,
        payload: res.data.message,
      });
      return false;
    } else {
      await dispatch(UpdateCustomer(data, true, countrycode));
      return true;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

//new flow, independent don't need to change
export const Login = (email, pass, cart_id, cart, device_token, device_type) => async dispatch => {
  try {
    const res = await AuthApi({
      method: 'POST',
      url: '/integration/customer/token',
      data: {
        username: email,
        password: pass,
      },
    });

    if (res.data === undefined) {
      dispatch({
        type: SETERROR,
        payload: res.data.message,
      });
      return false;
    } else if (res.data !== '') {
      dispatch(Favourites(res.data));
      await dispatch(GetUserData(cart_id, cart, res.data, device_token, device_type));

    }
    return true;
  } catch (e) {
    if (e.response && e.response.data) {
      dispatch({
        type: SETERROR,
        payload: e.response.data,
      });
    }
    console.log(e);
    return false;
  }
};

export const UserData = token => {
  try {
    return new Promise(async (response, rej) => {
      const res = await AuthApi({
        method: 'GET',
        url: '/customers/me',
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });

      console.log(res.data);
      response(res.data);
    });
  } catch (e) {
    console.log(e);
  }
};



//need this only for login, independent so no need to change
export const GetUserData = (cart_id, cart, token, device_token, device_type) => async dispatch => {
  try {
    const res = await AuthApi({
      method: 'GET',
      url: '/customers/me',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });

    /* if (res.token === undefined) {
    dispatch({
      type: SETERROR,
      payload: res.data.message
    });
    return false; */
    /* } else if (res.token !== "") { */
    console.log(res.data);
    setCookies(res.data);
    setTimeout(() => {
      dispatch(GETDEVICEDATA(token, cart_id, cart, res.data, device_token, device_type));

    }, 5000)
    // dispatch(GETDEVICEDATA(token,cart_id, cart, res.data, device_token, device_type));
    await dispatch(MoveItems(cart_id, cart, res.data.id, res.data.store_id, res.data.extension_attributes.token));
    dispatch({
      type: 'SETCARTID',
      payload: null,
    });
    //Temp chnage at 17 jan 2020
    // const cartid = await dispatch(CreateCart(res.data.extension_attributes.token));
    dispatch({
      type: LOGIN,
      payload: {
        data: {
          id: res.data.id,
          email: res.data.email,
          firstname: res.data.firstname,
          lastname: res.data.lastname,
          gender: res.data.gender,
          phone_number: res.data.custom_attributes.find(element => {
            return element.attribute_code === 'phone_number';
          }).value,
          nationality: '',
        },
        token: res.data.extension_attributes.token,
      },
    });
    /* } */
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};


//need for token in login api
export const GETDEVICEDATA = (token, cart_id, cart, customerdata, device_token, device_type) => async dispatch => {
  // alert(device_type)
  // alert(device_token)
  try {
    const res = await AuthApi({
      method: 'PUT',
      url: '/customers/me',
      data: {
        "customer": {
          "email": customerdata.email,
          "firstname": customerdata.firstname,
          "lastname": customerdata.lastname,
          "gender": customerdata.gender,
          "website_id": customerdata.website_id,
          "custom_attributes": [
            {
              "attribute_code": "device_token",
              "value": device_token
            },
            {
              "attribute_code": "device_type",
              "value": device_type
            }
          ]
        }
      },
      headers: {
        // Authorization: 'Bearer ' + '7eyk6eurtm7rfa2blsmscw2ggnxmp2d3',
        Authorization: 'Bearer ' + token,
      },
    });
    // alert(JSON.stringify(res.data));
    if (res.data) {
      return true;
    }

    return false;
  } catch (e) {
    console.log(e);

    // if (e.response && e.response.data) {
    //    dispatch({
    //       type: SETERROR,
    //       payload: e.response.data.message,
    //    });
    // }

    return false;
  }
};

//dont need this anymore
export const VerifyLOTP = (phone_number, otp, cart_id, cart) => async dispatch => {
  try {
    const res = await AuthApi({
      method: 'POST',
      url: '/customer/verifyloginotp',
      data: {
        phone_number: phone_number,
        otp: otp,
      },
    });

    if (res.data.status === 0) {
      dispatch({
        type: SETERROR,
        payload: res.data.message,
      });
      return false;
    } else if (res.data.status === 1) {
      setCookies(res.data.customer);
      await dispatch(
        MoveItems(
          cart_id,
          cart,
          res.data.customer.id,
          res.data.customer.store_id,
          res.data.customer.extension_attributes.token
        )
      );
      const cartid = await dispatch(CreateCart(res.data.customer.extension_attributes.token));
      dispatch({
        type: LOGIN,
        payload: {
          data: {
            id: res.data.customer.id,
            email: res.data.customer.email,
            firstname: res.data.customer.firstname,
            lastname: res.data.customer.lastname,
            gender: res.data.customer.gender,
            phone_number: res.data.customer.custom_attributes[1].value,
            nationality: '',
          },
          token: res.data.customer.extension_attributes.token,
          cartid,
        },
      });
    }
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

//no need to change

export const AutoLogin = () => async dispatch => {
  const token = localStorage.getItem('token');
  const data = localStorage.getItem('info');
  const cartid = localStorage.getItem('cartid');
  if (token === undefined) {
    if (cartid === undefined) {
    } else {
      dispatch({
        type: SETCARTID,
        payload: cartid,
      });
    }
  } else {
    if (data === undefined) {
      dispatch({
        type: AUTOLOGIN,
        payload: {
          token: token,
          data: '',
          cartid: cartid,
        },
      });
    } else {
      dispatch({
        type: AUTOLOGIN,
        payload: {
          token: token,
          data: JSON.parse(data),
          cartid: cartid,
        },
      });
    }
  }
};

export const SetError = error => dispatch => {
  dispatch({ type: SETERROR, payload: error });
};

//these are independent

export const ResetForgetPass = (Email, newPassword, reset_token) => async dispatch => {
  try {
    const res = await AuthApi({
      method: 'POST',
      url: '/customers/resetPassword',
      data: {
        email: Email,
        resetToken: reset_token,
        newPassword: newPassword,
      },
    });

    console.log(res.data);

    if (res.data) {
      return true;
    }

    return false;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const VerifyResetOtp = (phone_number, otp) => async dispatch => {
  try {
    const res = await AuthApi({
      method: 'POST',
      url: '/customer/verifyresetotp',
      data: {
        phone_number: phone_number,
        otp: otp,
      },
    });

    if (res.data.token === null) {
      dispatch({
        type: SETERROR,
        payload: res.data.message,
      });
      return false;
    }

    return { token: res.data.token, email: res.data.customer.email };
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const ForgotPass = phone_number => async dispatch => {
  try {
    const g_otp = await dispatch(GenerateOtp(phone_number, 'reset'));
    if (g_otp) {
      return true;
    }
    dispatch({ type: null });
  } catch (e) {
    console.log(e);
    dispatch({ type: null });
    return false;
  }
};

export const LogOut = () => dispatch => {
  dispatch({ type: LOGOUT });
};

export const ContactUS = async (name, email, message, store_id) => {
  try {
    const res = await AuthApi({
      method: 'POST',
      url: '/contact-us',
      data: {
        contact: {
          name,
          email,
          message,
          store_id,
        },
      },
    });

    if (res.status === 200) {
      return true;
    }
    console.log(res.data);
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const GenerateUniqueLink = async uuid => {
  try {
    const res = await Axios({
      method: 'POST',
      url: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDEa58jyckEbrBXaUy8M5oJ3AFGb5i9A6I',
      data: {
        longDynamicLink: `https://kanzi.page.link/?link=https://kanziapp/refer/${uuid}&apn=com.kanziapp&afl=https://play.google.com/store/apps/details?id=com.kanziapp&ibi=com.kanziapp.KANZI&ifl=https://apps.apple.com/us/app/kanzi/id1563930820`,

      },
    });

    if (res.status === 200) {
      return res.data.shortLink;
    }
    console.log(res.data);
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const GetNotifications = token => async dispatch => {
  try {
    const res = await AuthApi({
      method: 'GET',
      url: '/get/notification',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });

    if (res.status === 200) {
      dispatch({ type: SETNOTIFICATIONLISTING, payload: JSON.parse(res.data) });
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const MarkRead = async (token, id) => {
  try {
    const res = await AuthApi({
      method: 'POST',
      url: '/read/notification',
      data: {
        notificationId: id,
      },
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });

    if (res.status === 200) {
      console.log(res.data);
      return res.data;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};
