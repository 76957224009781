import { SETCURRENCY, SETLANGUAGE, SETNOTIFICATIONS , SETNOTIFICATIONLISTING } from "../actions/account/actionTypes"
import { LOGOUT } from "../actions/auth/actionTypes"

const initialState = {
    language: "English",
    currency: "AED",
    notification: [],
    notification_listing: []
};

const AccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETLANGUAGE: return {
            ...state,
            language: action.payload
        };
        case SETCURRENCY: return {
            ...state,
            currency: action.payload
        };
        case SETNOTIFICATIONS: console.log("I was here")
        return {
            ...state,
            notification: action.payload
        };
        case SETNOTIFICATIONLISTING: return {
            ...state,
            notification_listing: action.payload
        }
        case LOGOUT: return {
            ...state,
            language: "English",
            currency: "AED",
            notification: {
                sounds: false,
                I_can_vote: false,
                winners_are_announced: false,
                campaign_abount_to_end: false
            }
        }
        default: return state

    }
}

export default AccountReducer