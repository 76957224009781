import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar';
import { Environment } from '../../env';
import { Tween } from 'react-gsap';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
const script = document.createElement('script');
script.src =
   'https://i.ctnsnet.com/int/integration?pixel=67834349&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}';
script.async = true;
const HowItWorks = props => {
   const [isPlay, setIsPlay] = useState(true);
   useEffect(() => {
      console.log('isplay', isPlay);
      //document.getElementById("hiwVideo").play()
   }, [isPlay]);

   const playVideo = e => {
      const vid = document.getElementById('hiwVideo');
      if (vid.paused) {
         setIsPlay(false);
         vid.play();
      } else {
         setIsPlay(true);
         vid.pause();
      }
   };
   useEffect(() => {
      if (document.getElementById('howitworks-page-wrapper'))
         document.getElementById('howitworks-page-wrapper').appendChild(script);
      return () => {
         if (document.getElementById('howitworks-page-wrapper'))
            document.getElementById('howitworks-page-wrapper').removeChild(script);
      };
   }, []);

   return (
      <div className="" id="howitworks-page-wrapper">
         <NavBar howitworks="howitworks" />
         <div className="howitworks-main-container site-e">
            <div style={{ marginTop: '30px' }}></div>
            <div>
               <div
                  className="m-how"
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', textAlign: 'right' }}
               >
                  <div>
                     <div className="viedpdes">
                        <video style={{ borderRadius: 10 }} width="600" height="500" controls>
                           <source src={`${props.Environment.img_path}images/Howitworkenglish.mp4`} type="video/mp4" />
                        </video>
                     </div>
                  </div>
                  <div className="account-list-cards" style={{ marginTop: 0 }}>
                     <div className="list-cards">
                        <ul style={{ display: 'flex', flexDirection: 'column' }}>
                           <li>
                              <Link to="/faqs">
                                 <div className="icontxt">
                                    <div className="list-icon">
                                       <img src={`${props.Environment.img_path}icons/ic-faqs.svg`} alt="" />
                                    </div>
                                    <div className="list-txt">{props.Environment.faqs}</div>
                                 </div>
                                 <div>
                                    <img src={`${props.Environment.img_path}icons/ic_list_arrow.svg`} alt="" />
                                 </div>
                              </Link>
                           </li>
                           <li>
                              <Link to={window.innerWidth > 767 ? '/desktop-terms' : '/drawtnc'}>
                                 <div className="icontxt">
                                    <div className="list-icon">
                                       <img src={`${props.Environment.img_path}icons/ic-drawtnc.svg`} alt="" />
                                    </div>
                                    <div className="list-txt">{props.Environment.drawtnc}</div>
                                 </div>
                                 <div>
                                    <img src={`${props.Environment.img_path}icons/ic_list_arrow.svg`} alt="" />
                                 </div>
                              </Link>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="howitworks-main-container site-a">
            <div style={{ marginTop: '30px' }}></div>
            <div>
               <div
                  className="m-how"
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', textAlign: 'right' }}
               >
                  <div>
                     <div className="viedpdes site-a">
                        <video style={{ borderRadius: 10 }} width="600" height="500" controls>
                           <source src={`${props.Environment.img_path}images/Howitworkdarabic.mp4`} type="video/mp4" />
                        </video>
                     </div>
                  </div>
                  <div className="account-list-cards" style={{ marginTop: 0 }}>
                     <div className="list-cards">
                        <ul style={{ display: 'flex', flexDirection: 'column' }}>
                           <li>
                              <Link to="/faqs">
                                 <div className="icontxt">
                                    <div className="list-icon">
                                       <img src={`${props.Environment.img_path}icons/ic-faqs.svg`} alt="" />
                                    </div>
                                    <div className="list-txt">{props.Environment.faqs}</div>
                                 </div>
                                 <div>
                                    <img src={`${props.Environment.img_path}icons/ic_list_arrow.svg`} alt="" />
                                 </div>
                              </Link>
                           </li>
                           <li>
                              <Link to={window.innerWidth > 767 ? '/desktop-terms' : '/drawtnc'}>
                                 <div className="icontxt">
                                    <div className="list-icon">
                                       <img src={`${props.Environment.img_path}icons/ic-drawtnc.svg`} alt="" />
                                    </div>
                                    <div className="list-txt">{props.Environment.drawtnc}</div>
                                 </div>
                                 <div>
                                    <img src={`${props.Environment.img_path}icons/ic_list_arrow.svg`} alt="" />
                                 </div>
                              </Link>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};
export default HowItWorks;
