import React, { useRef, useState, useEffect } from 'react';
import { Environment } from '../../env';
import Button from '../../components/Button/Button';
import { TweenMax } from 'gsap';
import { Swipeable } from 'react-swipeable';
import { useHistory } from 'react-router-dom';
import { Tween } from 'react-gsap';
import AddressInput from './AddressInput';
import { isObjEmpty } from '../../utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import { AddAddress, EditAddress } from '../../redux/actions/account/actions';
import { SetNotification, DeleteNotification } from '../../redux/actions/account/actions';

const ActionSheetNotification = props => {
   const [error, setError] = useState({});
   const [active, setActive] = useState(false);
   const SheetAddress = useRef(null);
   const notification = useSelector(state => state.account.notification);
   const notification_listing = useSelector(state => state.account.notification_listing)
   const dispatch = useDispatch();
   const [toggleState, setToggleState] = useState('off');
   const [toggleState1, setToggleState1] = useState('off');
   const [toggleState2, setToggleState2] = useState('off');
   const [toggleState3, setToggleState3] = useState('off');
   const [toggleState4, setToggleState4] = useState('off');
   const history = useHistory();

   const handleGoBack = () => {
      setActive(false);
      TweenMax.fromTo(SheetAddress.current, Environment.popupBackground.duration, { y: 0 }, { y: 1000 });
      setTimeout(() => {
         props.history.push({
            pathname: '/accountsettings',
            state: {
               data: null
            }
         });
      }, 700);
   };

   useEffect(() => {
      document.body.style.overflow = 'hidden';
      TweenMax.fromTo(SheetAddress.current, Environment.popupBackground.duration, { y: 600 }, { y: 0 });
      setTimeout(() => {
         setActive(true);
      }, 700);
      return () => (document.body.style.overflow = 'visible');
   }, []);

  

   /*   useEffect(() => {
      if (isEditMode) {
        console.log(props.location.query.address);
        const { street, country_id: countryId, custom_attributes, default_shipping } = props.location.query.address;
        const [apartment, addressLine1, area] = street;
        const nickname = custom_attributes ? custom_attributes.find(cur => cur.attribute_code === 'address_nickname').value : '';
        const isDefault = !!default_shipping;
        console.log({
          area, addressLine1, apartment,
          countryId, nickname, isDefault
        });
        setData({
          area, addressLine1, apartment,
          countryId, nickname, isDefault
        });
      }
    }, []);
  
    useEffect(() => {
      document.addEventListener("click", (evt) => {
        const flyoutElement = document.querySelector('.action-container');
        let targetElement = evt.target; // clicked element
  
        if (targetElement == flyoutElement) {
          handleGoBack()
          return;
        }
  
      });
    }, []) */

   /*  const onFormChange = (type, e, direct = false) => {
     const value = direct ? e : e.target.value;
     let formData = { ...data };
     formData = {
       ...formData,
       [type]: value
     }
     setData(formData);
   }
 
   const handleChecks = () => {
     const errorTemplate = {};
     if (data.area.trim().length === 0) {
       errorTemplate.area = "Area cannot be empty";
     }
     if (data.addressLine1.trim().length === 0) {
       errorTemplate.addressLine1 = "Address Line 1 cannot be empty";
     }
     if (data.apartment.trim().length === 0) {
       errorTemplate.apartment = "Apartment cannot be empty";
     }
     if (data.nickname.trim().length === 0) {
       errorTemplate.nickname = "Address nickname cannot be empty";
     }
     if (!isObjEmpty(errorTemplate)) {
       setError(errorTemplate);
       return false;
     } else {
       setError(errorTemplate);
       return true;
     }
   }
 
   const addAddress = async () => {
     const check = handleChecks();
     if (!check) return false;
 
     if (isEditMode) {
       const DBData = {
         ...props.location.query.address,
         ...data,
         firstname: userInfo.firstname,
         lastname: userInfo.lastname,
         telephone: userInfo.phone_number
       }
       await dispatch(EditAddress(DBData));
     } else {
       const DBData = {
         ...data,
         firstname: userInfo.firstname,
         lastname: userInfo.lastname,
         telephone: userInfo.phone_number
       }
       await dispatch(AddAddress(DBData));
     }
     history.push('/accountsettings');
   }; */

   const toggle = () => {
      setToggleState(toggleState === 'off' ? 'on' : 'off');
   };

   return (
      <Swipeable onSwipedDown={handleGoBack}>
         <div
            className="action-container"
            ref={SheetAddress}
            style={{ backgroundColor: active ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0)' }}
         >
            <Tween
               from={{
                  y: '100%',
                  opacity: 1,
                  delay: Environment.popupContainer.delay,
                  duration: Environment.popupContainer.duration,
               }}
            >
               <div className="action-cards">
                  <button onClick={() => {
                     history.goBack();
                  }} type="button" class="close1231" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">×</span>
                  </button>
                  <div className="cards-icon">
                     <img src={`${Environment.img_path}makeicons/ic_notification.svg`} alt="" />
                  </div>
                  <div className="cards-title">{props.Environment.notificationsettings}</div>

                  <div className="notification-list">
                     <div className="notication-list-lable">{props.Environment.sounds}</div>
                     <div className="koinz-switch">
                        <div
                           className={`switch ${toggleState}`}
                           onClick={() => {
                              setToggleState(toggleState === 'off' ? 'on' : 'off');
                           }}
                        />
                     </div>
                  </div>

                  <div className="small-lab">
                     <h6>{props.Environment.notifymewhen}</h6>
                  </div>

                  {notification_listing.map(item => {

                     let IsSet = notification.find(notifi => {
                        return notifi.id == item.notification_id
                     })

                     return (
                        <div className="notification-list">
                           <div className="notication-list-lable">{item.notification_message}</div>
                           <div className="koinz-switch">
                              <div
                                 className={`switch ${IsSet?"on":"off"}`}
                                 onClick={() => {
                                    if (IsSet==undefined) {
                                       dispatch(SetNotification(item.notification_id))
                                    } else {
                                       //let noti = notification.find(noti => {
                                       //return noti.notification_message.includes("vote")
                                       //})

                                       dispatch(DeleteNotification(IsSet.notification_id,item.notification_id))
                                    }
                                 }}
                              />
                           </div>
                        </div>
                     )
                  })}



                 

                  <div className="">
                     <Button
                        className="button-red"
                        data={Environment.save}
                        onClick={() => {
                           handleGoBack();
                        }}
                     />
                  </div>
               </div>
            </Tween>
         </div>
      </Swipeable>
   );
};

export default ActionSheetNotification;
