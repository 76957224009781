import React, { useEffect } from 'react';
import DrawTnC from '../DrawTnC/DrawTnC';
const script = document.createElement('script');
script.src =
   'https://i.ctnsnet.com/int/integration?pixel=67834349&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}';
script.async = true;
const DesktopTerms2 = () => {
   useEffect(() => {
      if (document.getElementById('delivery-shipment-page-wrapper'))
         document.getElementById('delivery-shipment-page-wrapper').appendChild(script);
      return () => {
         if (document.getElementById('delivery-shipment-page-wrapper'))
            document.getElementById('delivery-shipment-page-wrapper').removeChild(script);
      };
   }, []);

   const terms = [
      {
         header: '1. Delivery/Shipping Policy (For Products):',
         desc: 'We deliver our Products to all Countries and mainly UAE. Shipping will be done by a third-party courier. Delivery within UAE cities shall be in 2 – 3 working days and from 4 to 10 working Days to Other Countries. UAE Delivery Fees will be a Flat Rate of AED: 10 and Delivery Fees outside UAE will be mentioned on the checkout page depending on the country and location. Avoid any delivery delay by providing your full address along with your contact details.',
      },
   ];

   return (
      <div id="delivery-shipment-page-wrapper" className="dekstop-terms-container">
         <h1 className="desktop-header">Delivery and Shipment</h1>

         <div className="terms-wrapper">
            {terms.map(data => (
               <section className="content-section">
                  <h1>{data.header}</h1>
                  <p
                     dangerouslySetInnerHTML={{
                        __html: data.desc,
                     }}
                  ></p>
               </section>
            ))}
         </div>
      </div>
   );
};

export default DesktopTerms2;
