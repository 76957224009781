import React, { useState, useEffect, useRef } from "react"
import NavBar from '../../components/NavBar/NavBar';
import Slider from 'react-slick';
import Messenger from "./Components/Messenger"
import Doer from "./Components/Doer"
import Collector from "./Components/Collector"
import { Col } from "react-bootstrap";
import { GetClusteredData } from "../../redux/actions/cart/actions"
import { useSelector } from "react-redux"
import PopUps from "../../components/PopUps/PopUps"
import { get } from "lodash";
import { constants } from "os";

const BadgeInfo = (props) => {

    const [slide, setslide] = useState(0)
    const [slidename, setslidename] = useState(props.Environment.messenger)
    const [data, setdata] = useState([])
    const store = useSelector(store => store)
    const slider_ref = useRef(null)
    const [ShowPopup, setShow] = useState(false);
    const [p_data, setpdata] = useState(null)
    const [ddraw, setddraw] = useState(0)


    useEffect(() => {
        if (props.location.state) {
            let slide_name = props.location.state.detail
            if (slide_name == "messenger") {
                setslidename(props.Environment.messenger)
                slider_ref.current.slickGoTo(0, false)
                setddraw(0);
            }
            else if (slide_name == "doer") {
                setslidename(props.Environment.doer)
                slider_ref.current.slickGoTo(1, false)
                setddraw(1);
            }
            else if (slide_name == "collector") {
                setslidename(props.Environment.collector)
                slider_ref.current.slickGoTo(2, false)
                setddraw(2);
            }
        }
    }, [])

    const options = {
        dots: true,
        infinite: false,
        initialSlide: 0,
        speed: 500,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        beforeChange(s1, s2) {
            setslide(s2);
            let slide_name;
            if (props.location.state != undefined || props.location.state != null) {
                slide_name = props.location.state.detail;

                if (slide_name == "messenger") {
                    setddraw(0)
                }
                if (slide_name == "doer") {
                    setddraw(1)
                }
                if (slide_name == "collector") {
                    setddraw(2)
                }
            }


            if (s2 == 0) {
                setslidename(props.Environment.messenger)
            }
            else if (s2 == 1) {
                setslidename(props.Environment.doer)
            }
            else {
                setslidename(props.Environment.collector)
            }
        }
    }
    const optionsa = {
        dots: true,
        infinite: false,
        initialSlide: 0,
        speed: 500,
        slidesToScroll: -1,
        arrows: false,
        fade: true,
        beforeChange(s1, s2) {
            setslide(s2)
            if (s2 == 0) {
                setslidename(props.Environment.messenger)
            }
            else if (s2 == 1) {
                setslidename(props.Environment.doer)
            }
            else {
                setslidename(props.Environment.collector)
            }
        }
    }

    useEffect(() => {
        /* GetClusteredData(store.auth.token).then(res => {
            setdata(res)
        }).catch(e => console.log(e)) */
    }, [])

    console.log(ddraw)

    return (
        <div className="badgeinfo-parent">
            {ShowPopup && (
                <PopUps
                    data={p_data}
                    type='loyalty-popup2'
                    onClose={() => {
                        setShow(false);
                    }}
                />
            )}
            <NavBar badgeinfo={"badge-info"} title={slidename} />
            
            <div className="navs">
                <span className={`nav ${slide == 0 ? "active" : ""}`}></span>
                <span className={`nav ${slide == 1 ? "active" : ""}`}></span>
                <span className={`nav ${slide == 2 ? "active" : ""}`}></span>
            </div>

            <div className="slider-container">
                {(ddraw == 0) && (
                    <div>
                        <Slider className="site-e" ref={slider_ref} {...options}>
                            <Messenger setData={(data) => { setpdata(data) }} setShow={() => { setShow(true) }} {...props}></Messenger>
                            <Doer {...props}></Doer>
                            <Collector data={get(store, 'cart.clusteredData', [])} {...props}></Collector>
                        </Slider>
                        <Slider className="site-a" ref={slider_ref} {...optionsa}>
                            <Messenger setData={(data) => { setpdata(data) }} setShow={() => { setShow(true) }} {...props}></Messenger>
                            <Doer {...props}></Doer>
                            <Collector data={get(store, 'cart.clusteredData', [])} {...props}></Collector>
                        </Slider>
                    </div>
                )}
                {(ddraw == 1) && (
                    <div>
                        <Slider className="site-e" ref={slider_ref} {...options}>
                            <Doer {...props}></Doer>
                            <Collector data={get(store, 'cart.clusteredData', [])} {...props}></Collector>
                            <Messenger setData={(data) => { setpdata(data) }} setShow={() => { setShow(true) }} {...props}></Messenger>
                        </Slider>
                        <Slider className="site-a" ref={slider_ref} {...optionsa}>
                            <Doer {...props}></Doer>
                            <Collector data={get(store, 'cart.clusteredData', [])} {...props}></Collector>
                            <Messenger setData={(data) => { setpdata(data) }} setShow={() => { setShow(true) }} {...props}></Messenger>
                        </Slider>
                    </div>
                )}
                {(ddraw == 2) && (
                    <div>
                        <Slider className="site-e" ref={slider_ref} {...options}>
                            <Collector data={get(store, 'cart.clusteredData', [])} {...props}></Collector>
                            <Messenger setData={(data) => { setpdata(data) }} setShow={() => { setShow(true) }} {...props}></Messenger>
                            <Doer {...props}></Doer>
                        </Slider>
                        <Slider className="site-a" ref={slider_ref} {...optionsa}>
                            <Collector data={get(store, 'cart.clusteredData', [])} {...props}></Collector>
                            <Messenger setData={(data) => { setpdata(data) }} setShow={() => { setShow(true) }} {...props}></Messenger>
                            <Doer {...props}></Doer>
                        </Slider>
                    </div>
                )}

                {/* <Slider className="site-a" ref={slider_ref} {...optionsa}>
                    <Messenger setData={(data) => { setpdata(data) }} setShow={() => { setShow(true) }} {...props}></Messenger>
                    <Doer {...props}></Doer>
                    <Collector data={get(store, 'cart.clusteredData', [])} {...props}></Collector>
                </Slider> */}
            </div>
        </div>
    )
}

export default BadgeInfo
