import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar';
import { Environment } from '../../env';
import { Tween } from 'react-gsap';
const script = document.createElement('script');
script.src =
   'https://i.ctnsnet.com/int/integration?pixel=67834349&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}';
script.async = true;
const AboutApp = props => {
   useEffect(() => {
      if (document.getElementById('about-app-page-wrapper'))
         document.getElementById('about-app-page-wrapper').appendChild(script);
      return () => {
         if (document.getElementById('about-app-page-wrapper'))
            document.getElementById('about-app-page-wrapper').removeChild(script);
      };
   }, []);

   return (
      <div id="about-app-page-wrapper" className="">
         <NavBar aboutapp="aboutapp" />
         <div className="aboutapp-main-container">
            <Tween from={{ x: '100%', opacity: 1, duration: 0.5 }}>
               <div className="about-app-container">
                  <div className="desktop-header">{props.Environment.aboytheading}</div>
                  <div className="account-list-cards">
                     <div className="list-cards">
                        <ul>
                           <li>
                              <Link to="/desktop-terms">
                                 <div className="icontxt">
                                    <div className="list-icon">
                                       <img src={`${props.Environment.img_path}makeicons/ic-tnc.svg`} alt="" />
                                    </div>
                                    <div className="list-txt">{props.Environment.tnc}</div>
                                 </div>
                                 <div>
                                    <img src={`${props.Environment.img_path}makeicons/ic_list_arrow.svg`} alt="" />
                                 </div>
                              </Link>
                           </li>
                           <li>
                              <Link to="/privacypolicy">
                                 <div className="icontxt">
                                    <div className="list-icon">
                                       <img
                                          src={`${props.Environment.img_path}makeicons/ic-privacypolicy.svg`}
                                          alt=""
                                       />
                                    </div>
                                    <div className="list-txt">{props.Environment.privacypolicy}</div>
                                 </div>
                                 <div>
                                    <img src={`${props.Environment.img_path}makeicons/ic_list_arrow.svg`} alt="" />
                                 </div>
                              </Link>
                           </li>
                        </ul>
                     </div>
                  </div>
                  {/* <div className="kanzi-appversion-container">
                     <div className="k-logo">
                        <img src="makeicons/k-logo.svg" alt="" />
                     </div>
                     <div className="appversion-block">
                        {props.Environment.kanzversion}
                        <div className="sub-section">
                           <div>{props.Environment.copyrightkanzi}</div>
                           <div className="reserved">{props.Environment.rightreserved}</div>
                        </div>
                     </div>
                  </div> */}

                  {/* <div className="kanzi-licence-block">
                            <div className="dubaieconomy-logo">
                                <img src={`${props.Environment.img_path}images/dlogo.png`} alt="" />
                            </div>

                            <div className="appversion-block">
                                {props.Environment.kanzilicencetxt}
                            </div>
                        </div> */}
               </div>
            </Tween>
         </div>
      </div>
   );
};

export default AboutApp;
