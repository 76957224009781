import React, { useRef, useState, useEffect } from 'react';
import { Environment } from '../../env';
import Button from '../../components/Button/Button';
import { TweenMax } from 'gsap';
import { Swipeable } from 'react-swipeable';
import { useHistory, useLocation } from 'react-router-dom';
import { Tween } from 'react-gsap';
import { isObjEmpty } from '../../utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import { AddAddress, EditAddress } from '../../redux/actions/account/actions';
//import { SetLang } from '../../redux/actions/account/actions';
import { SetLang } from '../../redux/actions/home/actions';

const script = document.createElement('script');
script.src =
   'https://i.ctnsnet.com/int/integration?pixel=67834349&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}';
script.async = true;
const ActionSheetLanguage = props => {
   const [error, setError] = useState({});
   const [active, setActive] = useState(false);
   const SheetAddress = useRef(null);
   const history = useHistory();
   const location = useLocation();
   const language = useSelector(state => state.home.language);
   const [lang, setlang] = useState(language);
   const dispatch = useDispatch();
   const isEditMode = props.location.query ? !!props.location.query.address : false;

   const handleGoBack = () => {
      console.log('hey');
      if (lang != language) {
         dispatch({ type: 'SETRENDER' });
      }
      if (lang == 'en') {
         //alert(document.getElementById('body-id').classList)
         dispatch(SetLang('en'));
         localStorage.setItem('langage', 'en');
         document.getElementById('body-id').classList.remove('site-ar');
         //alert(document.getElementById('body-id').classList)
         document.getElementById('body-id').classList.add('site-en');
      } else {
         //alert(document.getElementById('body-id').classList)
         dispatch(SetLang('ar'));
         localStorage.setItem('langage', 'ar');
         document.getElementById('body-id').classList.remove('site-en');
      }

      setActive(false);
      TweenMax.fromTo(SheetAddress.current, Environment.popupBackground.duration, { y: 0 }, { y: 1000 });
      setTimeout(() => {
         props.history.push('/accountsettings');
      }, 700);
   };

   useEffect(() => {
      if (document.getElementById('actionsheetlang-page-wrapper'))
         document.getElementById('actionsheetlang-page-wrapper').appendChild(script);
      return () => {
         if (document.getElementById('actionsheetlang-page-wrapper'))
            document.getElementById('actionsheetlang-page-wrapper').removeChild(script);
      };

      document.body.style.overflow = 'hidden';
      TweenMax.fromTo(SheetAddress.current, Environment.popupBackground.duration, { y: 600 }, { y: 0 });
      setTimeout(() => {
         setActive(true);
      }, 700);
      return () => (document.body.style.overflow = 'visible');
   }, []);

   useEffect(() => {
      window.addEventListener(
         'click',
         evt => {
            const flyoutElement = document.querySelector('.action-container');
            let targetElement = evt.target; // clicked element

            if (targetElement == flyoutElement) {
               //handleGoBack();
               return;
            }
         },
         true
      );
   }, [location]);

   return (
      <Swipeable id="actionsheetlang-page-wrapper" onSwipedDown={handleGoBack}>
         <div
            className="action-container lang-cont"
            ref={SheetAddress}
            style={{ backgroundColor: active ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0)' }}
         >
            <Tween
               from={{
                  y: '100%',
                  opacity: 1,
                  delay: props.Environment.popupContainer.delay,
                  duration: props.Environment.popupContainer.duration,
               }}
            >
               <div className="action-cards action-cards121">
                  <button
                     onClick={() => {
                        history.goBack();
                     }}
                     type="button"
                     class="close1231"
                     data-dismiss="modal"
                     aria-label="Close"
                  >
                     <span aria-hidden="true">×</span>
                  </button>
                  <div className="cards-icon">
                     <img src={`${Environment.img_path}makeicons/ic_lang.svg`} alt="" />
                  </div>
                  <div className="cards-title">{props.Environment.selectlanguage}</div>
                  <div className="main-language-container">
                     <div
                        className="saved-cards-list-container"
                        onClick={() => {
                           setlang('en');
                        }}
                     >
                        <input
                           type="radio"
                           name="cardlist"
                           className="saved-cards-list"
                           id="newcarda"
                           checked={lang === 'en' ? true : false}
                        />
                        <label className="card-number-label" htmlFor="newcarda">
                           <div className="card-container-main">
                              English
                              <div className="checked-img">
                                 <div className="check"></div>
                              </div>
                              <div className="radio"></div>
                           </div>
                        </label>
                     </div>
                     <div
                        className="saved-cards-list-container"
                        onClick={() => {
                           setlang('ar');
                        }}
                     >
                        <input
                           type="radio"
                           name="cardlist"
                           className="saved-cards-list"
                           id="newcard1"
                           checked={lang === 'ar' ? true : false}
                        />
                        <label className="card-number-label" htmlFor="newcard1">
                           <div className="card-container-main">
                              العربية
                              <div className="checked-img">
                                 <div className="check"></div>
                              </div>
                              <div className="radio"></div>
                           </div>
                        </label>
                     </div>
                  </div>
                  <div className="">
                     <Button
                        className="button-red"
                        data={props.Environment.save}
                        onClick={() => {
                           handleGoBack();
                        }}
                     />
                  </div>
               </div>
            </Tween>
         </div>
      </Swipeable>
   );
};

export default ActionSheetLanguage;
