// import { useStore } from "react-redux";
import { Environment as env_en } from '../../../env';
import { Environment as env_ar } from '../../../env_ar';
import { CreateCartId, SetError } from '../auth/actions';
import { isNil } from 'lodash';
import { getStoreId } from './../../../utils/utils';
import {
   ADDTOCART,
   VIEWCART,
   UPDATECART,
   DELETEITEM,
   SHIPPINGINFO,
   ORDERID,
   VIEWMAMBOPROFILE,
   CREATEPOINTS,
   UPDATECARTITEMSELECTION,
   UPDATEKOINZMODE,
   ADDKOINZPROD,
   REMOVEKOINZPROD,
   SETUSEKOINZ,
   EMPTYKOINZPROD,
   ADDCONSTANTS,
   SETLOYALTYDATA,
   ADDKOINZDETAILS,
   SETUSERMISSIONS,
   SETBADGEDATA,
   SETCLUSTEREDDATA,
   SETPREVIOUSORDERS,
   DELETE_ADDRESS,
   REPLACEPRODUCT,
   SETCUSTPROFILE,
   SETSAVEDCARDS,
   EMPTYCART,
   SETORDERAMOUNT,
   SETPROMOCODE,
} from './actionTypes';
import axios from 'axios';

import AuthApi from '../../../api/AuthApi';
import MemboApi from '../../../api/MemboApi';
import GetApi from '../../../api/GetApi';
import jsonp from 'jsonp';
import { devToolsEnhancer } from 'redux-devtools-extension';

export const applePay = (amount, currency_code, merchantOrderReference) => async (dispatch, getState) => {
   //alert(amount)
   const token = getState().auth.token;
   const userinfo = getState().auth.userinfo;
   const carfinfo = getState().cart.customerData;
   let base_url = localStorage.getItem('langage') == 'ar' ? env_ar : env_en;

   try {
      const res = await AuthApi({
         method: 'POST',
         url: '/gpayments/ngenius-createorder',
         data: {
            data: {
               currency_code,
               amount,
               email_address: userinfo.email,
               merchantOrderReference,
               billingAddress: {
                  email: userinfo.email,
                  countryCode: carfinfo[0].country_id,
                  address1: carfinfo[0].street.join(','),
                  city: carfinfo[0].city,
                  first_name: userinfo.firstname,
                  last_name: userinfo.lastname,
               },
            },
            customerId: userinfo.id,
         },

         headers: {
            Authorization: 'Bearer ' + token,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
         },
      });

      // const res = await axios({
      //    method: 'GET',

      //    baseURL: 'https://www.kanziapp.com/order.php'
      // })

      //let datas = JSON.stringify(res.data);
      //let dataes1 = JSON.parse(datas);
      let data = JSON.stringify(res.data);
      let str = data;
      let buff = new Buffer(str, 'base64');
      let base64ToStringNew = buff.toString('ascii');
      let base64ToString = JSON.parse(base64ToStringNew);
      localStorage.setItem('realorder_id', base64ToString._id);
      // var decodedStringAtoB = atob(data);

      return base64ToString;
   } catch (e) {
      return null;
   }
};

export const sumsuangPay = (amount, currency_code, merchantOrderReference) => async (dispatch, getState) => {
   const token = getState().auth.token;
   const userinfo = getState().auth.userinfo;
   const carfinfo = getState().cart.customerData;
   let base_url = localStorage.getItem('langage') == 'ar' ? env_ar : env_en;
   // alert("ds")
   try {
      const res = await AuthApi({
         method: 'POST',
         baseURL: base_url.base_url,
         url: '/gpayments/applepay-createorder',
         data: {
            data: {
               currency_code,
               amount,
               email_address: userinfo.email,
               merchantOrderReference,
               billingAddress: {
                  email: userinfo.email,
                  countryCode: carfinfo[0].country_id,
                  address1: carfinfo[0].street.join(','),
                  city: carfinfo[0].city,
                  first_name: userinfo.firstname,
                  last_name: userinfo.lastname,
               },
            },
            customerId: userinfo.id,
         },

         headers: {
            Authorization: 'Bearer ' + token,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
         },
      });

      // const res = await axios({
      //    method: 'GET',

      //    baseURL: 'https://www.kanziapp.com/order.php'
      // })

      //let datas = JSON.stringify(res.data);
      //let dataes1 = JSON.parse(datas);
      let data = JSON.stringify(res.data);
      let str = data;
      let buff = new Buffer(str, 'base64');
      let base64ToStringNew = buff.toString('ascii');
      let base64ToString = JSON.parse(base64ToStringNew);
      // var decodedStringAtoB = atob(data);

      return base64ToString;
   } catch (e) {
      return null;
   }
};

export const ApplePayResponse = response => async (dispatch, getState) => {
   const token = getState().auth.token;
   const userinfo = getState().auth.userinfo;
   const carfinfo = getState().cart.customerData;
   //Discount
   let orderAmount = localStorage.getItem('orderAmount');
   // alert(orderAmount);
   try {
      const res = await AuthApi({
         method: 'POST',
         url: '/carts/mine/payment-information',
         data: {
            paymentMethod: {
               method: 'ngeniusonline',
               additional_data: {
                  session_id: '',
                  card_id: '',
                  store_id: '1',
                  applepay_id: localStorage.getItem('realorder_id'),
                  applepay_status: JSON.stringify(response.status),
                  order_amount: orderAmount,

                  //applepayresponse: response,
               },
            },
         },
         headers: {
            Authorization: 'Bearer ' + token,
         },
      });
      // alert('Response from apple pay succues to mambo api : PASS' + JSON.stringify(res.data));
      return res.data;
   } catch (e) {
      console.log(e);

      // alert(e);
   }
};
export const SumsungPayResponse = response => async (dispatch, getState) => {
   //alert(JSON.stringify(response));

   const token = getState().auth.token;
   const userinfo = getState().auth.userinfo;
   const carfinfo = getState().cart.customerData;
   try {
      const res = await AuthApi({
         method: 'POST',
         url: '/carts/mine/payment-information',
         data: {
            paymentMethod: {
               method: 'ngeniusonline',
               additional_data: {
                  session_id: '',
                  card_id: '',
                  store_id: '1',
                  samsungpay: {
                     _id: localStorage.getItem('realorder_id'), //RECEIEVED_FROM_CREATE_ORDER,
                     status: response.status, //RECEIVED FROM APPLE PAY RESPONSE
                  },
                  //applepayresponse: response,
               },
            },
         },
         headers: {
            Authorization: 'Bearer ' + token,
         },
      });
      // alert('Response from apple pay succues to mambo api : PASS' + JSON.stringify(res.data));
      return res.data;
   } catch (e) {
      console.log(e);

      alert('Sorry we can handle this request');
   }
};

export const AddToCart =
   (cartid = null, sku, product_type = undefined, option_id = undefined, option_value = undefined, item = undefined) =>
   async (dispatch, getState) => {
      const token = getState().auth.token;

      console.log(typeof getState().auth.cartid);
      if (getState().auth.cartid == null || getState().auth.cartid == 'null') {
         let res = await dispatch(CreateCartId(token));
         cartid = res;
      }
      let data = {};
      if (product_type == 'configurable') {
         data = {
            item_id: null,
            sku: item,
            qty: 1,
            name: '',
            price: 100,
            product_type,
            quote_id: cartid,
         };
      } else {
         data = {
            item_id: null,
            sku,
            qty: 1,
            name: '',
            price: 100,
            product_type,
            quote_id: cartid,
         };
      }

      dispatch({
         type: ADDTOCART,
         payload: data,
      });

      /*  if(getState().cart.cart.length==0){
    setTimeout(()=>{
      dispatch(ViewMamboProfile())
    },3000)
  }
 */
      try {
         const res = await AuthApi({
            method: 'POST',
            url: '/carts/mine/items',
            data: {
               cartItem: {
                  sku: sku,
                  qty: 1,
                  quote_id: cartid,
                  product_type,
                  product_option: {
                     extension_attributes: {
                        configurable_item_options: [
                           {
                              option_id,
                              option_value,
                           },
                        ],
                     },
                  },
               },
            },
            headers: {
               Authorization: 'Bearer ' + token,
               'Access-Control-Allow-Origin': '*',
               'Content-Type': 'application/json',
            },
         });

         dispatch({
            type: REPLACEPRODUCT,
            payload: res.data,
         });

         return true;
      } catch (e) {
         console.log(e);
         return false;
      }
   };

export const handlePaymentFail = () => (dispatch, getState) => {
   const cart = getState().cart.cart;
   cart.forEach(async item => {
      await dispatch(DeleteCartItem(item.item_id));
   });
   dispatch({ type: EMPTYCART });
};

//mambo
// export const ViewMamboProfile = () => async (dispatch, getState) => {
//   const mamboId = getState().auth.userinfo.id;

//   try {
//   /*  let res = await MemboApi({
//       method: 'GET',
//       url: '/api/v1/js/d92d7bca75984c979adddd09aa3e4a38/kanziapp.com/users/' + mamboId,
//     }); */

//   var res = {}

//   jsonp('https://kanziapp.net/api/v1/js/d92d7bca75984c979adddd09aa3e4a38/kanziapp.com/users/' + mamboId, { name: 'callback' }, (error, data) => {
//     if (error) {
//       console.log(error)
//     } else {
//       dispatch({
//         type: VIEWMAMBOPROFILE,
//         payload: data
//       });
//     }
//   })

//   //res = JSON.parse(res);
//   console.log(JSON.parse(res.data));
//   if (res) {
//    /*  dispatch({
//       type: VIEWMAMBOPROFILE,
//       payload: JSON.parse(res.data.substr(9, res.data.length - 10))
//     }); */
//     return true;
//   }
//   return false;
// } catch (e) {
//   console.log(e);
//   return false;
// }
// }
export const ViewMamboProfile = () => async (dispatch, getState) => {
   const mamboId = getState().auth.userinfo.id;

   try {
      /* let res = await MemboApi({
  method: 'GET',
  url: '/api/v1/kanziapp.com/users/' + mamboId,
  });  */

      var res = {};

      return new Promise((resolve, reject) => {
         jsonp(
            'https://kanziapp.net/api/v1/js/d92d7bca75984c979adddd09aa3e4a38/kanziapp.com/users/' + mamboId,
            { name: 'callback' },
            (error, data) => {
               if (error) {
                  console.log(error);
                  resolve();
               } else {
                  console.log(data);
                  if (data.error !== undefined) {
                     resolve();
                     return;
                  }
                  dispatch({
                     type: VIEWMAMBOPROFILE,
                     payload: data,
                  });
                  resolve();
               }
            }
         );
      });

      //res = JSON.parse(res);
      //console.log(JSON.parse(res.data));
      //if (res) {
      /* dispatch({
  type: VIEWMAMBOPROFILE,
  payload: JSON.parse(res.data.substr(9, res.data.length - 10))
  }); */
      // return true;
      // }
   } catch (e) {
      console.log(e);
      return false;
   }
};

export const GetConversionRates = () => dispatch => {
   return new Promise((resolve, reject) => {
      try {
         jsonp(
            'https://kanziapp.net/api/v1/js/d92d7bca75984c979adddd09aa3e4a38/kanziapp.com/rewards/achievements',
            { name: 'callback' },
            (error, data) => {
               if (error) {
                  console.log(error);
                  resolve();
               } else {
                  console.log(data);
                  if (data.error !== undefined) {
                     resolve();
                  }

                  //var configs = data.find(item => item.id == "5f4cf312f70b732473a1fb71")
                  if (Array.isArray(data) && data[0] && data[0].customFields) {
                     dispatch({
                        type: ADDCONSTANTS,
                        payload: data[0].customFields,
                     });
                  }

                  resolve();
               }
            }
         );
      } catch (e) {
         console.log(e);
         resolve();
      }
   });
};

export const ViewCart = () => async (dispatch, getState) => {
   const token = getState().auth.token;
   let base_url = localStorage.getItem('langage') == 'ar' ? env_ar : env_en;
   const cartId = getState().auth.cartId;

   try {
      const res = await AuthApi({
         baseURL: base_url.base_url,
         method: 'GET',
         url: '/carts/mine',
         headers: {
            Authorization: 'Bearer ' + token,
            crossorigin: true,
         },
      });

      if (isNil(cartId)) {
         dispatch({
            type: 'SETCARTID',
            payload: res.data.id,
         });
         localStorage.setItem('cartid', res.data.id);
      }

      if (res.data.items !== undefined) {
         dispatch({
            type: VIEWCART,
            payload: res.data,
         });
         return true;
      }

      return false;
   } catch (e) {
      if (e?.response?.data?.message === 'No such entity with %fieldName = %fieldValue') {
         dispatch(handlePaymentFail());
      }
      return false;
   }
};

export const GetSavedCards = () => async (dispatch, getState) => {
   const token = getState().auth.token;
   // let base_url = localStorage.getItem('langage')localStorage.getItem('langage') == 'ar' ? env_ar : env_en;
   let base_url = localStorage.getItem('langage') == 'ar' ? env_ar : env_en;

   try {
      const res = await AuthApi({
         baseURL: base_url.base_url,
         method: 'GET',
         url: '/gpayments/saved-cards',
         headers: {
            Authorization: 'Bearer ' + token,
            crossorigin: true,
         },
      });

      if (res.data.length) {
         dispatch({
            type: SETSAVEDCARDS,
            payload: res.data,
         });
         return true;
      }
   } catch (e) {
      console.log(e);
      return false;
   }
};

export const DeleteCartItem = item_id => async (dispatch, getState) => {
   const token = getState().auth.token;

   dispatch({
      type: DELETEITEM,
      payload: item_id,
   });

   try {
      const res = await AuthApi({
         method: 'DELETE',
         url: '/carts/mine/items/' + item_id,
         headers: {
            Authorization: 'Bearer ' + token,
            crossorigin: true,
         },
      });

      if (res.data) {
         return true;
      }
      return false;
   } catch (e) {
      console.log(e);
      return false;
   }
};

export const UpdateCartItem =
   (cartid, sku, qty, item_id, product_type = undefined, option_id = undefined, option_value = undefined) =>
   async (dispatch, getState) => {
      const token = getState().auth.token;

      dispatch({
         type: UPDATECART,
         payload: {
            item_id,
            qty,
         },
      });

      try {
         const res = await AuthApi({
            method: 'PUT',
            url: '/carts/mine/items/' + item_id,
            data: {
               cartItem: {
                  sku: sku,
                  qty: qty,
                  quote_id: cartid,
                  product_type,
                  product_option: {
                     extension_attributes: {
                        configurable_item_options: [
                           {
                              option_id,
                              option_value,
                           },
                        ],
                     },
                  },
               },
            },
            headers: {
               Authorization: 'Bearer ' + token,
               crossorigin: true,
            },
         });

         /* dispatch({
    type: UPDATECART,
    payload: res.data
  })  */

         return true;
      } catch (e) {
         console.log(e);
         return false;
      }
   };

export const SetUpdateCartItemSelection = data => async (dispatch, getState) => {
   const token = getState().auth.token;

   try {
      dispatch({
         type: UPDATECARTITEMSELECTION,
         payload: data,
      });

      return true;
   } catch (e) {
      console.log(e);
      return false;
   }
};

//checkout methods

export const AddShippingInfo = (data, code, method) => async (dispatch, getState) => {
   console.log(code);
   const token = getState().auth.token;

   try {
      const res = await AuthApi({
         method: 'POST',
         url: '/carts/mine/shipping-information',
         data: {
            addressInformation: {
               shipping_address: {
                  country_id: data.country_id,
                  street: data.street,
                  city: data.city,
                  firstname: data.firstname,
                  lastname: data.lastname,
                  email: data.email,
                  telephone: data.telephone,
                  save_in_address_book: data.save_in_address_book,
               },
               billing_address: {
                  country_id: data.country_id,
                  street: data.street,
                  city: data.city,
                  firstname: data.firstname,
                  lastname: data.lastname,
                  email: data.email,
                  telephone: data.telephone,
               },
               shipping_carrier_code: code,
               shipping_method_code: method,
            },
         },
         headers: {
            Authorization: 'Bearer ' + token,
         },
      });

      dispatch({
         type: SHIPPINGINFO,
         payload: res.data,
      });

      if (res.data.totals != undefined) {
         return res.data.totals.base_shipping_amount;
      }
   } catch (e) {
      console.log(e);
      return false;
   }
};

//order placed
export const PaymentInfo =
   (data, cardEntity = null) =>
   async (dispatch, getState) => {
      const token = getState().auth.token;

      console.log(data);
      let orderAmount = localStorage.getItem('orderAmount');
      try {
         let additionData = { saveCardDetails: false, card_id: '', payment_id: '', store_id: '1', discount: 0 };
         if (cardEntity?.sessionId) {
            additionData = {
               //  payment_id: cardEntity?.pgOrderId,
               session_id: cardEntity?.sessionId,
               payment_type: 'card',
               card_id: null,
            };
         } else if (cardEntity?.entity_id) {
            additionData = {
               card_id: cardEntity?.entity_id,
               payment_type: 'savedcard',
            };
         }
         if (cardEntity?.saveCardDetails) {
            additionData.saveCardDetails = cardEntity?.saveCardDetails;
         } else {
            additionData.saveCardDetails = false;
         }
         additionData.discount = Number(orderAmount);

         additionData.store_id = getStoreId(getState());

         additionData.payment_status = 'Successs';
         additionData.applepay_id = '';
         additionData.applepay_status = '';

         const res = await AuthApi({
            method: 'POST',
            url: '/carts/mine/payment-information',
            data: {
               paymentMethod: {
                  method: data?.paymentmethod, //data.paymentmethod
                  additional_data: additionData,
               },
               billing_address: {
                  email: data.email,
                  country_id: data.country_id,
                  street: data.street,
                  city: data.city,
                  firstname: data.firstname,
                  lastname: data.lastname,
                  telephone: data.telephone,
               },
            },
            headers: {
               Authorization: 'Bearer ' + token,
            },
         });

         /* dispatch({
    type: ORDERID,
    payload: res.data
  });  */

         dispatch({
            type: null,
         });

         return res.data;
      } catch (e) {
         console.log(e);
         return false;
      }
   };

export const CreateSessionId = data => async (dispatch, getState) => {
   const token = getState().auth.token;
   try {
      const res = await AuthApi({
         method: 'POST',
         url: '/gpayments/session-id',
         data: data,
         headers: {
            Authorization: 'Bearer ' + token,
         },
      });

      console.log('CreateSessionId ', res);

      return res.data;
   } catch (e) {
      console.log(e);
      return false;
   }
};

export const GetPreviousOrders = (token, sort = 'ASC') => {
   return new Promise((response, reject) => {
      AuthApi.get('/orders/mine?sort=' + sort, {
         headers: {
            Authorization: 'Bearer ' + token,
         },
      })
         .then(res => {
            console.log(res.data);
            response(res.data);
         })
         .catch(error => {
            reject(error);
         });
   });
};

export const GetPreviousOrdersRedux =
   (token, sort = 'ASC', callback) =>
   async dispatch => {
      await AuthApi.get('/orders/mine?sort=' + sort, {
         headers: {
            Authorization: 'Bearer ' + token,
         },
      })
         .then(res => {
            if (res.data.items != undefined) {
               dispatch({ type: SETPREVIOUSORDERS, payload: res.data.items });
            }
            console.log(res.data);
            callback();
            return true;
         })
         .catch(error => {
            console.log(error);
            callback();
            return true;
         });
   };

export const GetClusteredData = token => dispatch => {
   return new Promise((response, reject) => {
      AuthApi.get('/get-clustred-order', {
         headers: {
            Authorization: 'Bearer ' + token,
         },
      })
         .then(res => {
            console.log(res.data);
            dispatch({ type: SETCLUSTEREDDATA, payload: res.data });
            response(true);
         })
         .catch(error => {
            reject(error);
         });
   });
};

export const UpdateKoinzMode = data => async dispatch => {
   dispatch({
      type: UPDATEKOINZMODE,
      payload: data,
   });
};

export const ParticipateInCampaign =
   (category_name, campaign_id, campaign_participation_count) => async (dispatch, getState) => {
      const mamboId = getState().auth.userinfo.id;

      try {
         const res = await MemboApi({
            method: 'POST',
            url: '/api/v1/js/d92d7bca75984c979adddd09aa3e4a38/kanziapp.com/activities/sync',
            data: {
               uuid: mamboId,
               url: 'https://kanziapp.com/kanzi-demo/index.html#/',
               attrs: {
                  type: 'behaviour',
                  verb: 'participate',
                  metadata: {
                     category_name: category_name,
                     campaign_id: campaign_id,
                     campaign_participation_count: campaign_participation_count,
                  },
               },
            },
         });

         console.log(res);
         if (res) {
            /* dispatch({
     type: VIEWMAMBOPROFILE,
     payload: res.data
   }); */
            return res.data;
         }
         return false;
      } catch (e) {
         console.log(e);
         return false;
      }
   };

export const CreatePointsActivity = data => async (dispatch, getState) => {
   const token = getState().auth.token;
   const pointId = getState().mambo.data.points[0].pointId;
   const points = Math.round(data);
   const uuid = getState().auth.userinfo.id;

   try {
      const res = await MemboApi({
         method: 'POST',
         url: '/api/v1/js/d92d7bca75984c979adddd09aa3e4a38/kanziapp.com/activities/sync',
         data: {
            uuid: uuid,
            reason: 'create point in kanzi', //msg string ex. you got 50 koinz for participating in campaign
            attrs: {
               type: 'point',
               action: 'increment',
               points: [
                  {
                     pointId: pointId,
                     points: points, // use -ve number if deduct
                  },
               ],
            },
         },
         headers: {
            'Content-Type': 'application/json',
         },
      });

      dispatch({
         type: CREATEPOINTS,
         payload: res.data,
      });

      return true;
   } catch (e) {
      console.log(e);
      return false;
   }
};

export const CreatePointsParticipation = () => async (dispatch, getState) => {
   const token = getState().auth.token;
   let points = 0;

   getState().cart.cart.forEach(item => {
      points += item.qty;
   });
   const uuid = getState().auth.userinfo.id;

   try {
      const res = await MemboApi({
         method: 'POST',
         url: '/api/v1/js/d92d7bca75984c979adddd09aa3e4a38/kanziapp.com/activities/sync',
         data: {
            uuid: uuid,
            reason: 'participation koinz', //msg string ex. you got 50 koinz for participating in campaign
            attrs: {
               type: 'point',
               action: 'increment',
               points: [
                  {
                     pointId: '5f609666f70b732473a1fbc4',
                     points: points, // use -ve number if deduct
                  },
               ],
            },
         },
         headers: {
            'Content-Type': 'application/json',
         },
      });

      dispatch({ type: null });

      return true;
   } catch (e) {
      console.log(e);
      return false;
   }
};

export const UpdateMamboProfile = (email, firstName, lastName) => async (dispatch, getState) => {
   const mamboId = getState().auth.userinfo.id;

   try {
      const res = await MemboApi({
         method: 'PUT',
         url: '/api/v1/js/d92d7bca75984c979adddd09aa3e4a38/kanziapp.com/users/' + mamboId,
         data: {
            uuid: mamboId,
            details: {
               email,
               firstName,
               lastName,
               gender: 'U',
            },
         },
      });

      console.log(res);
      if (res) {
         /* dispatch({
     type: VIEWMAMBOPROFILE,
     payload: res.data
   }); */
         return true;
      }
      return false;
   } catch (e) {
      console.log(e);
      return false;
   }
};

export const ReferAFriend = async (friend_id, uuid) => {
   console.log('Hey from refer');

   try {
      const res = await MemboApi({
         method: 'POST',
         url: '/api/v1/js/d92d7bca75984c979adddd09aa3e4a38/kanziapp.com/activities/sync',
         data: {
            uuid,
            url: 'https://kanziapp.com',
            attrs: {
               type: 'behaviour',
               verb: 'refer',
               metadata: {
                  friend_id,
               },
            },
         },
         headers: {
            'Content-Type': 'application/json',
         },
      });
      console.log(res);

      return true;
   } catch (e) {
      console.log(e);
      return false;
   }
};

export const GetOrderDetails = (token, id) => {
   console.log(token);
   let prodapitoken = 'zs667jaikfh4rb34vfqv49k86vb7xcq7';
   return new Promise((response, reject) => {
      AuthApi.get('/orders/' + id, {
         headers: {
            Authorization: 'Bearer ' + prodapitoken,
         },
      })
         .then(res => {
            response(res.data.items);
            console.log(res.data);
         })
         .catch(error => {
            reject(false);
            console.log(error);
         });
   });
};
export const GetOrderData = (token, id) => {
   console.log(token);
   let prodapitoken = 'zs667jaikfh4rb34vfqv49k86vb7xcq7';
   return new Promise((response, reject) => {
      AuthApi.get('/orders/' + id, {
         headers: {
            Authorization: 'Bearer ' + prodapitoken,
         },
      })
         .then(res => {
            response(res.data);
            console.log(res.data);
         })
         .catch(error => {
            reject(false);
            console.log(error);
         });
   });
};

export const GetAdminToken = () => {
   return new Promise((response, reject) => {
      response('zs667jaikfh4rb34vfqv49k86vb7xcq7');
      // AuthApi.post("/integration/admin/token", {
      //   username: "admin",
      //   password: "admin@1234"
      // }).then((res) => {
      //   response(res.data)
      //   console.log(res.data)
      // }).catch((error) => {
      //   reject(false)
      //   console.log(error)
      // })
   });
   // return "zs667jaikfh4rb34vfqv49k86vb7xcq7";
};

export const isKoinz = (products, id) => {
   return new Promise((response, reject) => {
      AuthApi.post('/order-type', {
         data: {
            sku: products,
            orderId: id,
         },
      })
         .then(res => {
            console.log(res.data);
            response(res.data);
         })
         .catch(error => {
            console.log(error);
            reject(error);
         });
   });
};

export const koinzAmount = (products, id, koinz_points_id, isMamboSuccess = 0, email_send = 0) => {
   return new Promise((response, reject) => {
      AuthApi.post('/order-koinz-amount', {
         data: {
            items: products,
            orderId: id,
            koinz_points_id,
            mambo_success: isMamboSuccess,
            email_send,
         },
      })
         .then(res => {
            console.log(res.data);
            response(res.data);
         })
         .catch(error => {
            console.log(error);
            reject(error);
         });
   });
};

// export const koinzAmount = (products, id) => {
//    return new Promise((response, reject) => {
//       AuthApi.post('/order-koinz-amount', {
//          data: {
//             items: products,
//             orderId: id,

//          },
//       })
//          .then(res => {
//             console.log(res.data);
//             response(res.data);
//          })
//          .catch(error => {
//             console.log(error);
//             reject(error);
//          });
//    });
// };

export const PayByCard = () => async (dispatch, getState) => {
   const token = getState().auth.token;
   let date = new Date();

   try {
      const res = await AuthApi({
         method: 'POST',
         url: '/card-payment',
         data: {
            data: {
               cartId: date.getTime(),
               order_amount: 200,
               auth_url: 'https://kanziapp.com/kanzi-demo/index.html#/ordersuccess',
               decline_url: 'https://kanziapp.com/kanzi-demo/index.html#/ordercancel',
               cancel_url: 'https://kanziapp.com/kanzi-demo/index.html#/ordercancel',
               sku: 'Blue Cotton Hoodie',
               firstname: 'Usama',
               lastname: 'Bluelogic',
               address: 'building 106A dubai design disctrict',
               city: 'Dubai',
               country: 'AE',
               email: 'customer@email.com',
               phone_number: '041234567',
            },
         },
         headers: {
            Authorization: 'Bearer ' + token,
         },
      });

      console.log(res.data[0].url);
      return res.data[0].url;
   } catch (e) {
      console.log(e);
      return false;
   }
};

export const AddKoinzProduct = item_id => dispatch => {
   dispatch({ type: ADDKOINZPROD, payload: item_id });
};

export const RemoveKoinzProduct = item_id => dispatch => {
   dispatch({ type: REMOVEKOINZPROD, payload: item_id });
};

export const SetUseKoinz = () => dispatch => {
   dispatch({ type: SETUSEKOINZ });
};

export const EmptyKoinzProduct = () => dispatch => {
   dispatch({ type: EMPTYKOINZPROD });
};

export const SetCustProfile = profile => dispatch => {
   dispatch({ type: SETCUSTPROFILE, payload: profile });
};

export const SetOrderAmount = amount => dispatch => {
   dispatch({ type: SETORDERAMOUNT, payload: amount });
};

export const LoyaltyData = uuid => dispatch => {
   return new Promise((resolve, reject) => {
      try {
         jsonp(
            'https://kanziapp.net/api/v1/js/d92d7bca75984c979adddd09aa3e4a38/kanziapp.com/users/' +
               uuid +
               '/rewards/levels/all',
            { name: 'callback' },
            (error, data) => {
               if (error) {
                  console.log(error);
                  resolve();
               } else {
                  console.log(data);
                  if (data.error !== undefined) {
                     resolve();
                     return;
                  }

                  dispatch({ type: SETLOYALTYDATA, payload: data });
                  resolve();
               }
            }
         );
      } catch (e) {
         console.log(e);
         resolve();
      }
   });
};

export const BadgeData = uuid => async dispatch => {
   return new Promise((resolve, reject) => {
      try {
         jsonp(
            'https://kanziapp.net/api/v1/js/d92d7bca75984c979adddd09aa3e4a38/kanziapp.com/users/' +
               uuid +
               '/rewards/achievements/all?tags=badge ',
            { name: 'callback' },
            (error, data) => {
               if (error) {
                  console.log(error);
                  resolve();
               } else {
                  console.log(data);
                  if (data.error !== undefined) {
                     resolve();
                     return;
                  }

                  dispatch({ type: SETBADGEDATA, payload: data });

                  resolve();
               }
            }
         );
      } catch (e) {
         console.log(e);
         resolve();
      }
   });
};

export const UserMissions = uuid => async dispatch => {
   return new Promise((resolve, reject) => {
      try {
         jsonp(
            'https://kanziapp.net/api/v1/js/d92d7bca75984c979adddd09aa3e4a38/kanziapp.com/users/' +
               uuid +
               '/rewards/missions/all',
            { name: 'callback' },
            (error, data) => {
               if (error) {
                  console.log(error);
                  resolve();
               } else {
                  console.log(data);
                  if (data.error !== undefined) {
                     resolve();
                     return;
                  }
                  dispatch({ type: SETUSERMISSIONS, payload: data });
                  resolve();
               }
            }
         );
      } catch (e) {
         console.log(e);
         resolve();
      }
   });
};

export const KoinzDetails = uuid => async dispatch => {
   return new Promise((resolve, reject) => {
      try {
         jsonp(
            'https://kanziapp.net/api/v1/js/d92d7bca75984c979adddd09aa3e4a38/kanziapp.com/users/' +
               uuid +
               '/activities',
            { name: 'callback' },
            (error, data) => {
               if (error) {
                  console.log(error);
                  resolve();
               } else {
                  console.log(data);

                  if (data.error !== undefined) {
                     resolve();
                  }

                  dispatch({ type: ADDKOINZDETAILS, payload: data });

                  resolve();
               }
            }
         );
      } catch (e) {
         console.log(e);
         resolve();
      }
   });
};

export const GetCampaignCount = async (product_id, token) => {
   try {
      const res = await GetApi({
         method: 'GET',
         url: '/get-customer-participation?productId=' + product_id,
         headers: {
            Authorization: 'Bearer ' + token,
         },
      });

      if (isNaN(res.data)) {
         return false;
      }
      return res.data;
   } catch (e) {
      console.log(e);
      return false;
   }
};

export const EstimateShippingPrice = async (id, token) => {
   try {
      const res = await AuthApi({
         method: 'POST',
         url: '/carts/mine/estimate-shipping-methods-by-address-id',
         data: {
            addressId: id,
         },
         headers: {
            Authorization: 'Bearer ' + token,
         },
      });

      if (Array.isArray(res.data)) {
         return { carrier: res.data[1].carrier_code, method: res.data[1].method_code };
      }

      return false;
   } catch (e) {
      console.log(e);
      return false;
   }
};

export const DeleteCustomerAddress = id => async (dispatch, getState) => {
   let token = getState().auth.token;

   try {
      const res = await AuthApi({
         method: 'DELETE',
         url: '/addresses/mine/' + id,
         headers: {
            Authorization: 'Bearer ' + token,
         },
      });

      dispatch({ type: DELETE_ADDRESS, payload: id });
   } catch (e) {
      console.log(e);
   }
};

export const ApplyPromoCode = promoCode => async (dispatch, getState) => {
   let token = getState().auth.token;
   const response = { success: true, data: null, error: '' };
   try {
      const res = await AuthApi({
         method: 'PUT',
         url: '/carts/mine/coupons/' + promoCode,
         headers: {
            Authorization: 'Bearer ' + token,
         },
      });
      response.data = res;
      console.log('res =>', res);
      // return res.data;
   } catch (error) {
      console.log('error =>', error);
      response.success = false;
      response.error = error;

      // dispatch(SetError(e));
      // return false;
   }
   return response;
};

// export const DeletePromoCode = () => async (dispatch, getState) => {
//    const response = { success: true, data: null, error: '' };
//    try {
//       // const res = await postDataAPI('/carts/mine/coupons', 'DELETE');
//       const res = await AuthApi({
//          method: 'DELETE',
//          url: '/carts/mine/coupons',
//          data: postData,
//          headers: {
//             Authorization: 'Bearer ' + token,
//          },
//       });
//       response.data = res;
//    } catch (error) {
//       response.error = error;
//    }
//    return response;
// };

export const DeletePromoCode = () => async (dispatch, getState) => {
   let token = getState().auth.token;
   const response = { success: true, data: null, error: '' };
   try {
      const res = await AuthApi({
         method: 'DELETE',
         url: '/carts/mine/coupons',
         headers: {
            Authorization: 'Bearer ' + token,
         },
      });

      // const res = await postDataAPI('/carts/mine/collect-totals', 'PUT', postData);
      response.data = res;
      dispatch({ type: SETPROMOCODE, payload: null });
   } catch (error) {
      response.success = false;
      response.error = error;
   }
   return response;
};

export const GetAppliedPromoCodeCodeInfo = postData => async (dispatch, getState) => {
   let token = getState().auth.token;
   const response = { success: true, data: null, error: '' };
   try {
      const res = await AuthApi({
         method: 'PUT',
         url: '/carts/mine/collect-totals',
         data: postData,
         headers: {
            Authorization: 'Bearer ' + token,
         },
      });

      // const res = await postDataAPI('/carts/mine/collect-totals', 'PUT', postData);
      response.data = res;
      dispatch({ type: SETPROMOCODE, payload: res.data });
   } catch (error) {
      response.success = false;
      response.error = error;
   }
   return response;
};
