import React from "react";
import PopUps from "../PopUps/PopUps";

class ErrorBoundary extends React.Component {
  state = {
    hasError: false
  };

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    alert(error)
  }

  render() {
    if (this.state.hasError) {
      return <PopUps type="issue" onClose={() => {
        this.setState({ hasError: false })
        this.props.history.replace('/')
      }}/>
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
