import React, { useState, useEffect } from "react"
import Account from "../Account/Account"
import SignUp from "../../components/SignUp/SignUp"
import ActionSheet from "../../components/ActionSheet/ActionSheet"
import { useSelector, useDispatch } from "react-redux"
import { Environment as en } from '../../env';
import { Environment as ar } from '../../env_ar';
import { get } from 'lodash';


const WebLogin = (props) => {

    const [type, settype] = useState("login");
    const [data, setData] = useState(null);
    const [countryCode, setCountryCode] = useState(null);
    const store = useSelector(store => store);
    const [file, setfile] = useState(en)

    useEffect(() => {
        if (get(store, 'home.language', 'en') == "en") {
            setfile(en)
        }
        else {
            setfile(ar)
        }
    }, [get(store, 'home.language', 'en')])


    return (
        <div className="weblogin-parent">
            <h3 className="title site-e">{type == 'login' ? 'Account' : 'Sign Up'}</h3>
            <h3 className="title site-a">{type == 'login' ? 'تفاصيلك' : 'اشتراك'}</h3>
            <div className="card-container">
                <div className="bt-container">
                    <span className={`${type == 'login' ? 'active' : ''}`} onClick={() => { settype("login") }}>{file.SignIn}</span>
                    <span className={`${type == 'login' ? '' : 'active'}`} onClick={() => { settype("signup") }}>{file.SignUp}</span>
                </div>
                <div className="page">
                    {type != "otp" ? <div>
                        <p className="txt1 site-e">{type == 'login' ? 'Your details' : 'Account'}</p>
                        <p className="txt2 site-e">{type == 'login' ? 'Enter your personal details below' : 'Log in to enjoy full app benefits!'}</p>

                        <p className="txt1 site-e">{type == 'login' ? '' : ''}</p>
                        <p className="txt2 site-a">{type == 'login' ? 'أدخل التفاصيل الشخصية الخاصة بك أدناه' : 'قم بتسجيل الدخول للاستمتاع بمزايا التطبيق الكاملة!'}</p>


                    </div> : null}
                    {type == 'login' ? <Account {...props} login={true} /> : null}
                    {type == 'signup' ? <SignUp {...props} call={(data, cc) => {
                        console.log(data)
                        console.log(cc)
                        setData(data)
                        setCountryCode(cc)
                        settype('otp')
                    }} /> : null}
                    {type == 'otp' ? <ActionSheet {...props}
                        type="signup"
                        callback={() => { settype('signup') }}
                        phonenumber={data.phone_number}
                        countrycode={countryCode}
                        data={data} /> : null}
                </div>
            </div>

        </div>
    )
}

export default WebLogin