import React, { useEffect } from 'react';
import DrawTnC from '../DrawTnC/DrawTnC';
const script = document.createElement('script');
script.src =
   'https://i.ctnsnet.com/int/integration?pixel=67834349&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}';
script.async = true;
const DesktopTerms1 = () => {
   useEffect(() => {
      if (document.getElementById('desktop-t1-page-wrapper'))
         document.getElementById('desktop-t1-page-wrapper').appendChild(script);
      return () => {
         if (document.getElementById('desktop-t1-page-wrapper'))
            document.getElementById('desktop-t1-page-wrapper').removeChild(script);
      };
   }, []);

   const terms = [
      {
         header: '1. Refund to oversold Customers:',
         desc: 'In case the Products are oversold, We undertake to refund the Product price to the oversold Customers. The refund shall be initiated to the customers based on the series of the Complimentary DRAW Ticket sold excessively.',
      },
      {
         header: '2. Refund Policy:',
         desc: 'In case the Products are oversold, We undertake to refund the Product price to the oversold Customers. The refund shall be initiated to the customers based on the series of the Complimentary DRAW Ticket sold excessively.',
      },
      {
         header: '3. Return and Refund Policy (For Products):',
         desc: 'We accept returns within 7 days of receipt, only if their delivery packaging has not been opened or the products are damaged or wrong product. Please notify us and return the box in its original packaging. In such instances, we will endeavour to send you another or refund the payment. We can only accept returns of products that have not been tampered with, are sealed and remain in the original packaging. If all these conditions are met, please ship your products back to us using a registered courier service and we will issue a full refund. Please note that we reserve the right to refuse any returned shipments if the product has been used or tampered with. Shipping & Handling fees are non-refundable. shipments may result in multiple postings to the cardholder’s monthly statement.',
      },
   ];

   return (
      <div id="desktop-t1-page-wrapper" className="dekstop-terms-container">
         <h1 className="desktop-header">Refund and Cancellation</h1>

         <div className="terms-wrapper">
            {terms.map(data => (
               <section className="content-section">
                  <h1>{data.header}</h1>
                  <p
                     dangerouslySetInnerHTML={{
                        __html: data.desc,
                     }}
                  ></p>
               </section>
            ))}
         </div>
      </div>
   );
};

export default DesktopTerms1;
