import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar';
import { useStore, useDispatch, useSelector } from 'react-redux';
import { Environment } from '../../env';
import { Environment as envar } from '../../env_ar.js';
import { Tween } from 'react-gsap';
import Button from '../../components/Button/Button';
import { ViewMamboProfile, UserMissions, KoinzDetails, GetClusteredData } from '../../redux/actions/cart/actions';
import PopUps from '../../components/PopUps/PopUps';
import { isObjEmpty } from '../../utils/utils';
import { ProgressBar } from 'react-bootstrap';
import EmptyKoinz from './EmptyKoinz';
import { TweenMax } from 'react-gsap';
import { get } from 'lodash';
//import 'bootstrap/dist/css/bootstrap.min.css
//import ProgressBar from "progressbar.js";
const script = document.createElement('script');
script.src =
   'https://i.ctnsnet.com/int/integration?pixel=67834349&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}';
script.async = true;
const MyKoinz = props => {
   console.log(props);
   const [isRendered, setRendered] = useState(false);
   const [popup, setPopup] = useState('none');
   const dispatch = useDispatch();
   const history = useHistory();
   const mambo = useSelector(store => store.mambo.data);
   const store = useSelector(store => store);

   const [messenger, setmessenger] = useState(null);
   const [doer, setdoer] = useState(null);
   const [progress, setprogress] = useState({
      progress: 0,
      required: 0,
   });
   const [collector_data, setcdata] = useState([]);
   const [data, setdata] = useState([]);
   const token = useSelector(store => get(store, 'auth.token', ''));
   const language = localStorage.getItem('langage');

   useEffect(() => {
      if (document.getElementById('my-koinz-page-wrapper'))
         document.getElementById('my-koinz-page-wrapper').appendChild(script);
      return () => {
         if (document.getElementById('my-koinz-page-wrapper'))
            document.getElementById('my-koinz-page-wrapper').removeChild(script);
      };
   }, []);

   useEffect(() => {
      (async () => {
         setRendered(false);
         // if (store.auth.token !== null) {
         //   await dispatch(ViewMamboProfile());
         // }
         setRendered(true);

         //KoinzDetails(store.auth.userinfo.id)
      })();

      /* setTimeout(() => {
         var bar = new ProgressBar.Path('#heart-path', {
            easing: 'easeInOut',
            duration: 1400
         });

         bar.set(0);
         bar.animate(0.5);
      }, 2000) */
   }, []);

   /* useEffect(() => {
      dispatch(GetClusteredData(store.auth.token)).then(res => {
         //setdata(res)
         HandleCollectorData(res)
      }).catch(e => console.log(e))
   }, []) */

   const HandleCollectorData = data => {
      let totalParticipation = [];
      data.forEach(item => {
         let participations = 0;
         item.data[0].items.forEach(campaign => {
            participations += parseInt(campaign.qty_ordered);
         });
         totalParticipation.push(participations);
      });

      setcdata(totalParticipation);
   };

   const GetProgress = () => {
      let progress = get(store, 'mambo.data', null);
      if (!isObjEmpty(progress)) {
         progress = get(store, 'mambo.data.points', []).find(point => point.pointId == '5fe9d0057126540c24a3e0c6');

         return progress.balance;
      }
      return 0;
   };

   useEffect(() => {
      if (get(store, 'cart.userMissions', null) !== null) {
         let messenger = get(store, 'cart.userMissions', null).available.find(item => {
            return item.name == 'The Ambassador';
         });
         let doer = get(store, 'cart.userMissions', null).available.find(item => {
            return item.name == 'The Doer';
         });

         setmessenger(messenger);
         setdoer(doer);

         CalcProgress(messenger);
      }
   }, [get(store, 'cart.userMissions', null)]);

   const CalcProgress = messenger => {
      let completed = messenger.attrs.progress.completed;

      /* if (completed == 0) {
         let required = messenger.attrs.rewards[0].attrs.behaviours[0].times - messenger.attrs.rewards[0].attrs.behaviours[0].timesCompleted
         let message = messenger.attrs.rewards[completed].hint
         setprogress({
            progress: 0,
            required: message
         })
      }
      else { */
      let total = messenger.attrs.rewards[messenger.attrs.rewards.length - 1].attrs.behaviours[0].times;
      let current = GetProgress(); //messenger.attrs.rewards[completed].attrs.behaviours[0].timesCompleted
      let required = messenger.attrs.rewards[completed].attrs.behaviours[0].times - current; //messenger.attrs.rewards[completed].attrs.behaviours[0].timesCompleted

      setprogress({
         progress: Math.round((current / total) * 100),
         required: messenger.attrs.rewards[completed].hint,
      });
      /* } */
   };

   return (
      <Fragment>
         <NavBar mykoinz={props.Environment.mykoinz} />
         {token === null ? (
            <EmptyKoinz {...props} />
         ) : (
            <div id="my-koinz-page-wrapper">
               {popup === 'koinz' && (
                  <PopUps type="whatarekoinz" onSubmit={() => setPopup('none')} onClose={() => setPopup('none')} />
               )}
               {isRendered && (
                  <div className="koinz-container">
                     <Tween from={{ y: '50%', opacity: 1, duration: 0.5 }}>
                        <div className="koinz-list-cards">
                           <div className="list-cards">
                              <div className="current-balance">
                                 <h4>{props.Environment.currentbalance}</h4>
                                 <div className="koinz-balance">
                                    {isObjEmpty(mambo) ? 0 : mambo.points[0].balance}
                                    <span>{props.Environment.koinz}</span>
                                 </div>
                              </div>
                              <div className="bal-shopnow">
                                 <div
                                    className="balance-worth"
                                    onClick={() => {
                                       history.push('/koinzdetails');
                                    }}
                                 >
                                    <span>{props.Environment.cmap}</span>
                                 </div>

                                 <Button
                                    onClick={() => history.push('/')}
                                    className="button-outline-red"
                                    data={props.Environment.shopnow}
                                 />
                              </div>
                           </div>
                           <div className="whatarecoinz">
                              <span onClick={() => setPopup('koinz')}>{props.Environment.whatare}</span>
                           </div>
                        </div>

                        <div className="my-rewards">
                           <p className="title-rewards">{props.Environment.mreward}</p>

                           {messenger != null ? (
                              <div
                                 className="badge-cont"
                                 onClick={() => {
                                    history.push({
                                       pathname: '/badgeinfo',
                                       state: { detail: 'messenger' },
                                    });
                                 }}
                              >
                                 <div className="head">
                                    <img
                                       src={
                                          /* `${props.Environment.img_path}makeicons/badge1.svg` */ messenger.imageUrl
                                       }
                                    />
                                    <p>{messenger.translations.find(trans => trans.languageCode == language).name}</p>
                                    <div className="more">
                                       <p className="site-e">More</p>
                                       <p className="site-a">المزيد</p>
                                       <img src={`${props.Environment.img_path}makeicons/ic-back-arrow.svg`} />
                                    </div>
                                 </div>

                                 <p className="refer">
                                    {/* Refer a friend and earn <span>Koinz!</span> */}
                                    {messenger.translations.find(trans => trans.languageCode == language).hint}
                                 </p>

                                 <div className="container">
                                    <p>{progress.required}</p>
                                    <img src={`${props.Environment.img_path}makeicons/lock.svg`} />
                                 </div>

                                 <div className="progress-cont">
                                    <ProgressBar now={progress.progress} />
                                    <div className="badges">
                                       {messenger.attrs.rewards.map((reward, index) => {
                                          let completed = messenger.attrs.progress.completed >= index + 1;
                                          let img = reward.imageUrl.replace('source', 'grayscale');

                                          if (completed) {
                                             img = reward.imageUrl.replace('grayscale', 'source');
                                          }

                                          console.log(completed);
                                          let count = reward.attrs.behaviours[0].times;
                                          return (
                                             <div className={`badge ${completed ? 'b1-active' : ''}`}>
                                                <img style={{ height: '40px' }} src={img} />
                                             </div>
                                          );
                                       })}
                                    </div>
                                 </div>
                              </div>
                           ) : (
                              <div className="badge-cont loading"></div>
                           )}
                           {doer != null ? (
                              <div
                                 className="badge-cont2"
                                 onClick={() => {
                                    history.push({
                                       pathname: '/badgeinfo',
                                       state: { detail: 'doer' },
                                    });
                                 }}
                              >
                                 <div className="head">
                                    <img
                                       src={/* `${props.Environment.img_path}makeicons/badge2.svg` */ doer.imageUrl}
                                    />
                                    <p>{doer.translations.find(trans => trans.languageCode == language).name}</p>
                                    <div className="more">
                                       <p className="site-e">More</p>
                                       <p className="site-a">المزيد</p>
                                       <img src={`${props.Environment.img_path}makeicons/ic-back-arrow.svg`} />
                                    </div>
                                 </div>

                                 <p className="refer">
                                    {/* Participate <span>2 times</span> to each category to unlock it! */}
                                    {doer.translations.find(trans => trans.languageCode == language).hint}
                                 </p>

                                 <div className="container">
                                    <p className="site-e">
                                       {doer.attrs.rewards.length - doer.attrs.progress.completed} categories to unock
                                    </p>
                                    <p className="site-a">
                                       {doer.attrs.rewards.length - doer.attrs.progress.completed} فئات للفتح
                                    </p>
                                    <img src={`${props.Environment.img_path}makeicons/lock.svg`} />
                                 </div>

                                 <div className="progress-cont">
                                    <div className="badges">
                                       {doer.attrs.rewards.map((reward, index) => {
                                          let isCompleted = doer.attrs.progress.completedIds.find(
                                             id => id == reward.id
                                          );
                                          let img =
                                             isCompleted == null
                                                ? reward.imageUrl.replace('source', 'grayscale')
                                                : reward.imageUrl;

                                          console.log(isCompleted);

                                          return (
                                             <div className={`badge ${isCompleted != null ? 'active-b' : ''}`}>
                                                <img className="badge-img" src={img} />
                                                {/* <img className="icon-img" src={reward.imageUrl} /> */}
                                             </div>
                                          );
                                       })}
                                       {/* <div className="badge active-b">
                                 <img className="badge-img" src={`${props.Environment.img_path}makeicons/doer-badge.svg`} />
                                 <img className="icon-img" src={`${props.Environment.img_path}makeicons/icon1-active.svg`}/>
                              </div>
                              <div className="badge">
                                 <img className="badge-img" src={`${props.Environment.img_path}makeicons/doer-badge.svg`} />
                                 <img className="icon-img" src={`${props.Environment.img_path}makeicons/icon2-inactive.svg`}/>
                              </div>
                              <div className="badge">
                                 <img className="badge-img" src={`${props.Environment.img_path}makeicons/doer-badge.svg`} />
                                 <img className="icon-img" src={`${props.Environment.img_path}makeicons/icon3-inactive.svg`}/>
                              </div>
                              <div className="badge">
                                 <img className="badge-img" src={`${props.Environment.img_path}makeicons/doer-badge.svg`} />
                                 <img className="icon-img" src={`${props.Environment.img_path}makeicons/icon4-inactive.svg`}/>
                              </div>
                              <div className="badge">
                                 <img className="badge-img" src={`${props.Environment.img_path}makeicons/doer-badge.svg`} />
                                 <img className="icon-img" src={`${props.Environment.img_path}makeicons/icon5-inactive.svg`}/>
                              </div>  */}
                                    </div>
                                 </div>
                              </div>
                           ) : (
                              <div className="badge-cont loading"></div>
                           )}
                           {get(store, 'cart.badgeData', null) != null ? (
                              <div
                                 className="badge-cont3"
                                 onClick={() => {
                                    history.push({
                                       pathname: '/badgeinfo',
                                       state: { detail: 'collector' },
                                    });
                                 }}
                              >
                                 <div className="head">
                                    <img src={`${props.Environment.img_path}makeicons/badge3.svg`} />
                                    <p>{props.Environment.collector}</p>
                                    <div className="more">
                                       <p className="site-e">More</p>
                                       <p className="site-a">المزيد</p>
                                       <img src={`${props.Environment.img_path}makeicons/ic-back-arrow.svg`} />
                                    </div>
                                 </div>

                                 <p className="refer">
                                    {props.Environment.moreparticipate}
                                    <span>{props.Environment.koinz}</span> {props.Environment.yearn}
                                 </p>

                                 <div className="progress-cont">
                                    <div className="badges">
                                       {get(store, 'cart.badgeData.available', []).map((item, index) => {
                                          let count = 0;
                                          let img = item.imageUrl;
                                          let completed = get(store, 'cart.badgeData.completed', []).find(item_c => {
                                             console.log(item_c.awarded.rewardId == item.id);
                                             console.log(item.id);
                                             return item_c.awarded.rewardId == item.id;
                                          });

                                          console.log(completed);

                                          if (completed) {
                                             count = completed.awardedCounter;
                                          } else {
                                             img = item.imageUrl.replace('source', 'grayscale');
                                          }

                                          if (get(store, 'cart.badgeData.completed', []).length != 0) {
                                          }

                                          /* collector_data.forEach(data => {
                                             if (data > item.attrs.behaviours[0].behaviour.attrs.metadata.conditions[1].value) {
                                                count++
                                             }
                                          }) */

                                          return (
                                             <div className={`badge ${count > 0 ? 'active' : ''}`}>
                                                <img src={img} />
                                                <span className="green-badge">x{count}</span>
                                             </div>
                                          );
                                       })}
                                       {/* <div className="badge">
                                 <img src={`${props.Environment.img_path}makeicons/collector-badge.svg`} />
                                 <span>5</span>
                                 <span className="green-badge">x1</span>
                              </div>
                              <div className="badge">
                                 <img src={`${props.Environment.img_path}makeicons/collector-badge.svg`} />
                                 <span>10</span>
                                 <span className="green-badge">x2</span>
                              </div>
                              <div className="badge">
                                 <img src={`${props.Environment.img_path}makeicons/collector-badge.svg`} />
                                 <span>15</span>
                                 <span className="green-badge">x0</span>
                              </div> */}
                                    </div>
                                 </div>

                                 {/* <div style={{ marginTop: 100 }}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="229.982" height="192.982" viewBox="0 0 229.982 192.982">
                              <g>
                                 <g>
                                    <path fill="#f6f6f6" stroke="#ddd" stroke-linecap="round" stroke-miterlimit="10" stroke-width="12px" d="M-24.245.5h171.42a46.563 46.563 0 0 1 0 93.125h-55.2a46.563 46.563 0 0 0-46.563 46.563v41.294" transform="translate(30.245 5.794) translate(0 .206) translate(0 -.5)" />
                                 </g>
                                 <path  id="heart-path" fill="transparent" stroke="#3277f3" stroke-linecap="round" stroke-miterlimit="10" stroke-width="12px" d="M-24.245.5h171.42a46.563 46.563 0 0 1 0 93.125h-55.2a46.563 46.563 0 0 0-46.563 46.563v41.294" transform="translate(30.245 5.794) translate(0 .206) translate(0 -.5)" />
                              </g>
                           </svg> 
                        </div> */}
                              </div>
                           ) : (
                              <div className="badge-cont loading"></div>
                           )}
                        </div>
                     </Tween>
                  </div>
               )}
            </div>
         )}
      </Fragment>
   );
};

export default MyKoinz;
