import React, { useRef, useState, useEffect } from 'react';

import Button from '../../components/Button/Button';
import { TweenMax } from 'gsap';
import { Swipeable } from 'react-swipeable';
import { useHistory, useLocation } from 'react-router-dom';
import { Tween } from 'react-gsap';
import AddressInput from './AddressInput';
import { isObjEmpty } from '../../utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import { AddAddress, EditAddress } from '../../redux/actions/account/actions';
const script = document.createElement('script');
script.src =
   'https://i.ctnsnet.com/int/integration?pixel=67834349&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}';
script.async = true;
const ActionSheetAddress = props => {
   const [data, setData] = useState({
      area: '',
      addressLine1: '',
      apartment: '',
      countryId: 'AE',
      nickname: '',
      isDefault: false,
      emirates: 'Dubai',
   });
   const [error, setError] = useState({});
   const [active, setActive] = useState(false);
   const [addres, setaddres] = useState('');
   const SheetAddress = useRef(null);
   const history = useHistory();
   const location = useLocation();
   const userInfo = useSelector(state => state.auth.userinfo);
   const dispatch = useDispatch();
   const isEditMode = props.location.query ? !!props.location.query.address : false;

   useEffect(() => {
      if (document.getElementById('add-address-page-wrapper'))
         document.getElementById('add-address-page-wrapper').appendChild(script);
      return () => {
         if (document.getElementById('add-address-page-wrapper'))
            document.getElementById('add-address-page-wrapper').removeChild(script);
      };
   }, []);

   const handleGoBack = () => {
      setActive(false);
      TweenMax.fromTo(SheetAddress.current, props.Environment.popupBackground.duration, { y: 0 }, { y: 1000 });
      setTimeout(() => {
         const url = location.pathname.includes('checkout');
         history.push(url ? '/checkoutdetails' : '/savedaddress');
      }, 700);
   };

   useEffect(() => {
      document.body.style.overflow = 'hidden';
      TweenMax.fromTo(SheetAddress.current, props.Environment.popupBackground.duration, { y: 600 }, { y: 0 });
      setTimeout(() => {
         setActive(true);
      }, 700);
      return () => (document.body.style.overflow = 'visible');
   }, []);

   useEffect(() => {
      if (isEditMode) {
         console.log(props.location.query.address);
         const { street, country_id: countryId, custom_attributes, default_shipping } = props.location.query.address;
         const [apartment, addressLine1, area] = street;
         const nickname = custom_attributes
            ? custom_attributes.find(cur => cur.attribute_code === 'address_nickname').value
            : '';
         const isDefault = !!default_shipping;
         console.log({
            area,
            addressLine1,
            apartment,
            countryId,
            nickname,
            isDefault,
         });
         setData({
            area,
            addressLine1,
            apartment,
            countryId,
            nickname,
            isDefault,
         });
      }
   }, []);

   const onFormChange = (type, e, direct = false) => {
      const value = direct ? e : e.target.value;
      let formData = { ...data };
      formData = {
         ...formData,
         [type]: value,
      };
      setData(formData);
   };

   const handleChecks = () => {
      const errorTemplate = {};
      if (data.area.trim().length === 0) {
         errorTemplate.area = 'Area cannot be empty';
      }
      if (data.addressLine1.trim().length === 0) {
         errorTemplate.addressLine1 = 'Address Line 1 cannot be empty';
      }
      if (data.apartment.trim().length === 0) {
         errorTemplate.apartment = 'Apartment cannot be empty';
      }
      if (data.nickname.trim().length === 0) {
         errorTemplate.nickname = 'Address nickname cannot be empty';
      }
      if (!isObjEmpty(errorTemplate)) {
         setError(errorTemplate);
         return false;
      } else {
         setError(errorTemplate);
         return true;
      }
   };

   const addAddress = async () => {
      const check = handleChecks();
      if (!check) return false;

      if (isEditMode) {
         const DBData = {
            ...props.location.query.address,
            ...data,
            firstname: userInfo.firstname,
            lastname: userInfo.lastname,
            telephone: userInfo.phone_number,
         };
         await dispatch(EditAddress(DBData));
      } else {
         const DBData = {
            ...data,
            firstname: userInfo.firstname,
            lastname: userInfo.lastname,
            telephone: userInfo.phone_number,
         };
         await dispatch(AddAddress(DBData));
      }
      history.goBack();
   };

   const handleEmirates = event => {
      setData({
         ...data,
         emirates: event.target.value,
      });
      console.log(event.target.value);
   };

   return (
      <Swipeable id="add-address-page-wrapper" onSwipedDown={handleGoBack}>
         <div
            className="action-container currency-contadd"
            ref={SheetAddress}
            style={{ backgroundColor: active ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0)' }}
         >
            <Tween
               from={{
                  y: '100%',
                  opacity: 1,
                  delay: props.Environment.popupContainer.delay,
                  duration: props.Environment.popupContainer.duration,
               }}
            >
               <div className="action-cards action-cards123ad">
                  <button
                     onClick={() => {
                        history.goBack();
                     }}
                     type="button"
                     class="close1231"
                     data-dismiss="modal"
                     aria-label="Close"
                  >
                     <span aria-hidden="true">×</span>
                  </button>
                  <div className="cards-icon">
                     <img src={`${props.Environment.img_path}makeicons/ic_new_address.svg`} alt="" />
                  </div>
                  <div className="cards-title">{isEditMode ? 'Address details' : props.Environment.Newaddress}</div>
                  <div className="floating-form">
                     <div className="actions-forms address-forms">
                        <div className="label-emirate">{props.Environment.emirate}</div>
                        <div className="addresssradiob"></div>
                        <select
                           name="emirates"
                           value={data.emirates}
                           className="emirates-dropdown site-e"
                           onChange={handleEmirates}
                        >
                           <option value="Abu Dhabi">Abu Dhabi</option>
                           <option value="Al Ain">Al Ain</option>
                           <option value="Ajman">Ajman</option>
                           <option value="Dubai">Dubai</option>
                           <option value="Fujeira">Fujeira</option>
                           <option value="Sharjah">Sharjah</option>
                           <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                        </select>
                        <select
                           name="emirates"
                           value={data.emirates}
                           className="emirates-dropdown site-a"
                           onChange={handleEmirates}
                        >
                           <option value="دبي">دبي</option>
                           <option value="الشارقة">الشارقة</option>
                           <option value="عجمان">عجمان</option>
                           <option value="الفجيرة">الفجيرة</option>
                           <option value="رأس الخيمة">رأس الخيمة</option>
                           <option value="أم القيوين">أم القيوين</option>
                        </select>
                        <AddressInput
                           placeholder={props.Environment.egbusinessbay}
                           label={props.Environment.area}
                           value={data.area || ''}
                           onChange={e => onFormChange('area', e)}
                           error={error.area}
                        />
                        <AddressInput
                           placeholder={props.Environment.buildingnamestreet}
                           label={props.Environment.addressline1}
                           value={data.addressLine1 || ''}
                           onChange={e => onFormChange('addressLine1', e)}
                           error={error.addressLine1}
                        />
                        <AddressInput
                           placeholder={props.Environment.egapartment}
                           label={props.Environment.apartmentVilla}
                           value={data.apartment || ''}
                           onChange={e => onFormChange('apartment', e)}
                           error={error.apartment}
                        />
                        <AddressInput
                           placeholder={props.Environment.egoffice}
                           label={props.Environment.Addressnickname}
                           value={data.nickname || ''}
                           onChange={e => onFormChange('nickname', e)}
                           error={error.nickname}
                        />
                        {/*<AddressInput*/}
                        {/*  placeholder={props.Environment.optional}*/}
                        {/*  label={props.Environment.Additionalinstructions}*/}
                        {/*  value={data}*/}
                        {/*  onChange={e => onFormChange('', e)}*/}
                        {/*  error={error}*/}
                        {/*/>*/}
                        {/*<div className="floating-label">*/}
                        {/*  <select*/}
                        {/*    className="floating-select">*/}
                        {/*    <option value="">Dubai</option>*/}
                        {/*  </select>*/}
                        {/*  <span className="highlight"/>*/}
                        {/*  <label className="label_placeholder">{props.Environment.emirate}</label>*/}
                        {/*</div>*/}
                        <div className="floating-label">
                           <input
                              className={`floating-input`}
                              type="text"
                              defaultValue={props.Environment.uaev}
                              readOnly
                           />
                           <span className="highlight" />
                           <label className="label_placeholder">{props.Environment.country}</label>
                        </div>
                        <div className="form-checkbx">
                           <input
                              type="checkbox"
                              id="address"
                              name="box"
                              checked={data.isDefault}
                              onChange={e => onFormChange('isDefault', e.target.checked, true)}
                           />
                           <label htmlFor="address">{props.Environment.addressttl}</label>
                        </div>
                        <div className="">
                           <Button
                              className="button-red site-e"
                              data={isEditMode ? 'Edit address' : 'Add address'}
                              onClick={addAddress}
                           />
                           <Button
                              className="button-red site-a"
                              data={isEditMode ? ' تعديل العنوان' : 'اضف العنوان'}
                              onClick={addAddress}
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </Tween>
         </div>
      </Swipeable>
   );
};

export default ActionSheetAddress;
