import React from 'react';
import { Environment } from '../../env';
import Button from '../Button/Button';
import { Tween } from 'react-gsap';
import useRouter from '../../customHooks/useRouter';

const MyHistoryEmpty = (props) => {
   const { history } = useRouter();
   return (
      <div className="MyHistoryEmpty-container">
         <div className="MyHistoryEmpty-main">
            <Tween from={{ y: '-100%', opacity: 0, duration: 0.5 }}>
               <div className="ic-cart-empty">
                  <img src={`${props.Environment.img_path}makeicons/ic_no_history.svg`} alt="" />
               </div>
            </Tween>
            <h4>{props.Environment.orderwillappear}</h4>
            <p>{props.Environment.recentorderhistory}</p>
            <Button onClick={() => history.push('/')} className="button-red" data={props.Environment.shopnow} />
         </div>
      </div>
   );
};

export default MyHistoryEmpty;
