import React, { useState, useRef } from 'react';
import { Environment } from './../../env';
import Button from '../../components/Button/Button';
import { TweenMax } from 'gsap';
import { Tween } from 'react-gsap';
import { useStore } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';

const EmptyKoinz = (props) => {
    const history = useHistory();
    const [redirect, setredirect] = useState(false);
    const store = useStore().getState();

    return (
        <div className="cartempty-container empty-ticket-container watchlist-container">
            {redirect ? <Redirect to="/" /> : null}
            <div className="cartempty-main">
                <Tween from={{ y: '-100%', opacity: 0, duration: 0.5 }}>
                    <div className="ic-cart-empty">
                        <img className="wtemd" src="makeicons/ic-money1.svg" alt="" />
                    </div>
                </Tween>
                <h4>{props.Environment.kblipradar}</h4>
                <p>{props.Environment.dontforget} <br /> {props.Environment.keepeyeout}</p>
                {store.auth.token === null && (
                    <div className="guest-view">
                        <p>
                            {props.Environment.createaccount}
                        </p>
                        <Button
                            className="button-red"
                            data="Create an account"
                            onClick={() => {
                                history.push('/account/login');
                            }}
                        ></Button>
                        <div className="alreadyaccount">
                            {props.Environment.alreadyan}
                            <Link to="/account/login">{props.Environment.login}</Link>
                        </div>
                    </div>
                )}
                {/* <Button
                    className="button-red"
                    data={props.Environment.shopnow}
                    onClick={() => {
                        setredirect(true);
                    }}
                ></Button> */}
            </div>
        </div>
    );
};

export default EmptyKoinz;
