import React from "react";
import { Environment } from "../../env";
import Button from "../Button/Button";

const SomethingWrong = props => {
  return (
    <div className="somethingwrong-container">
      <div className="somethingwrong-main">
        <h2>{Environment.oopstxt}</h2>
        <p>{Environment.somethingWrongtxt}</p>
        <p className="somethingwrong-body">{Environment.somethingwrongbodytxt}</p>
        <Button className="button-red" data="Go home" onClick={() => props.history.replace('/')}/>
      </div>
    </div>
  );
};

export default SomethingWrong;
