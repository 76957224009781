import React, { useState, useRef, useEffect } from 'react';
import { Environment } from '../../env';
import { UpdateCartItem, DeleteCartItem, AddKoinzProduct, RemoveKoinzProduct } from '../../redux/actions/cart/actions';
import { GuestUpdateCartItem, GuestDeleteCartItem } from '../../redux/actions/guestcart/actions';
import { useStore, useDispatch, useSelector } from 'react-redux';
import { Tween } from 'react-gsap';
import { Swipeable } from 'react-swipeable';
import { TweenMax } from 'gsap';

import { ADDKOINZPROD } from '../../redux/actions/cart/actionTypes';
import SwipeToDelete from 'react-swipe-to-delete-ios';
import { get } from "lodash"
import { isObjEmpty } from '../../utils/utils';
import $ from 'jquery';
import { currencyconvertrate } from '../../utils/Function';
const CheckoutCart = props => {
   const [count, setCount] = useState(props.item.qty);
   const store = useStore().getState();
   const card = useRef(null);
   const cart = useSelector(state => state.cart.cart);
   const dispatch = useDispatch();
   const [prod_type, setprodtype] = useState("product")
   const [prize, setprize] = useState(undefined)
   const [offset, setoffset] = useState(0)
   const [cv_rate, setcvrate] = useState(0.2)


   useEffect(() => {
      card.current.addEventListener('touchend', Listener);
      //card.current.addEventListener('click', ClickListener);

      return () => {
         card.current.removeEventListener('touchend', Listener)
         //card.current.removeEventListener('click', ClickListener)
      }
   }, [props.item]);

   useEffect(() => {

      if (props.item.product_type == "configurable") {
         var product = get(store, 'home.allproducts', []).find(item => item.sku == props.item.sku.split("-")[0])
         // var product = get(store, 'home.allproducts', []).find(item => item.sku == props.item.sku)         
         if (product == undefined) return
         var campaign = get(store, 'home.campaign_manager', []).find(item => item.campaign_product_id == product.id)
         if (campaign == undefined) return
         var prize = get(store, 'home.allproducts', []).find(item => item.id == campaign.campaign_prize_id)
         if (prize == undefined) return

         if (campaign.campaign_total_tickets == campaign.campaign_total_sales) {
            OnDelete()
         }
      }
      else {
         var product = get(store, 'home.allproducts', []).find(item => item.sku == props.item.sku)
         if (product == undefined) return
         var campaign = get(store, 'home.campaign_manager', []).find(item => item.campaign_product_id == product.id)
         if (campaign == undefined) return
         var prize = get(store, 'home.allproducts', []).find(item => item.id == campaign.campaign_prize_id)
         if (prize == undefined) return

         if (campaign.campaign_total_tickets == campaign.campaign_total_sales) {
            OnDelete()
         }
      }
      setprize(prize)

   }, [])

   useEffect(() => {

      if (isObjEmpty(get(store, 'mambo.data', {}))) {

      } else {
         var conversion_rate = get(store, 'cart.config', []).find(item => item.name == "earning_conversion_rate")
         var product_conversion_rate = get(store, 'cart.config', []).find(item => item.name == "product_cost_conversion_rate")

         var level = get(store, 'mambo.data', null)
         if (level.level != null) {
            level = level.level.reward.customFields
         } else {
            return
         }
         level = level.find(fields => fields.name == "loyalty_level_bonus")
         if (level != undefined) {
            setcvrate(((conversion_rate == undefined ? 0.2 : parseFloat(conversion_rate.value)) + (level.value == undefined ? 0 * 0.01 : parseFloat(level.value) * 0.01)))
         }
      }

   }, [get(store, 'mambo.data', {})])

   


   const Listener = () => {
      if (18 > card.current.getBoundingClientRect().x && card.current.getBoundingClientRect().x > -80) {
         setoffset(0)
         TweenMax.to(card.current, 0.5, { x: 0 });
      }
      if (-150 < card.current.getBoundingClientRect().x && card.current.getBoundingClientRect().x < -80) {
         setoffset(80)
         TweenMax.fromTo(card.current, 0.2, { x: card.current.getBoundingClientRect().x }, { x: -80 });
      }
      if (18 > card.current.getBoundingClientRect().x && card.current.getBoundingClientRect().x < -150) {
         TweenMax.fromTo(card.current, 0.2, { x: card.current.getBoundingClientRect().x }, { x: -400 });
         // TweenMax.to(card.current.previousSibling, 0.2, {opacity: 0});
         OnDelete()
      }
   }

   const ClickListener = () => {
      if (card.current.getBoundingClientRect().x < -60) {
         TweenMax.to(card.current, 0.5, { x: 0 });
      }
   }

   const OnPlus = async () => {
      
      const config = props.item.extension_attributes.configurable_item_options;
      

      if (store.auth.token !== null) {
         await dispatch(UpdateCartItem(store.auth.cartid, props.item.sku, count + 1, props.item.item_id, config == undefined ? undefined : "configurable", config == undefined ? undefined : config[0].option_id, config == undefined ? undefined : config[0].option_value));
      } else {
         await dispatch(GuestUpdateCartItem(store.auth.cartid, props.item.sku, count + 1, props.item.item_id, config == undefined ? undefined : "configurable", config == undefined ? undefined : config[0].option_id, config == undefined ? undefined : config[0].option_value));
      }
      setCount(props.item.qty);
      //props.setAmount(props.item.price); cartchange
   };

   const OnDelete = async () => {
      //TweenMax.to(card.current, 0.1, { x: 0 })
      //alert for delete item
      if (store.auth.token !== null) {
         await dispatch(DeleteCartItem(props.item.item_id));
      } else {
         await dispatch(GuestDeleteCartItem(props.item.item_id, store.auth.cartid));
      }
   };

   useEffect(() => {
      setCount(props.item.qty);
      TweenMax.to(card.current, 0, { x: 0 });
   }, [cart]);

   const OnMinus = async () => {

      const config = props.item.extension_attributes.configurable_item_options;

      if (count === 1) {
         //alert for delete item
         if (store.auth.token !== null) {
            await dispatch(DeleteCartItem(props.item.item_id));
         } else {
            await dispatch(GuestDeleteCartItem(props.item.item_id, store.auth.cartid));
         }
      } else {
         //update it with less number
         if (store.auth.token !== null) {
            await dispatch(UpdateCartItem(store.auth.cartid, props.item.sku, count - 1, props.item.item_id, config == undefined ? undefined : "configurable", config == undefined ? undefined : config[0].option_id, config == undefined ? undefined : config[0].option_value));
         } else {
            await dispatch(GuestUpdateCartItem(store.auth.cartid, props.item.sku, count - 1, props.item.item_id, config == undefined ? undefined : "configurable", config == undefined ? undefined : config[0].option_id, config == undefined ? undefined : config[0].option_value));
         }
      }
      setCount(props.item.qty);
      //props.setAmount(props.item.price);
   };

   useEffect(() => {
      var replace = get(store, 'cart.koinz_replacement', []).find(item => item == props.item.item_id)
      if (replace != undefined) setprodtype("koinz")
   }, [])

   const setKoinzMode = async () => {

      if (prod_type === "product") {
         setprodtype("koinz")
         $('accimag').addClass('accimag');

         
         const config = props.item.extension_attributes.configurable_item_options;
         

         // if (store.auth.token !== null) {
         //    await dispatch(UpdateCartItem(store.auth.cartid, "Loyalty-Points", count, props.item.item_id, config == undefined ? undefined : "configurable", config == undefined ? undefined : config[0].option_id, config == undefined ? undefined : config[0].option_value));
         // } else {
         //    await dispatch(GuestUpdateCartItem(store.auth.cartid, "Loyalty-Points", count, props.item.item_id, config == undefined ? undefined : "configurable", config == undefined ? undefined : config[0].option_id, config == undefined ? undefined : config[0].option_value));
         // }
         dispatch(AddKoinzProduct(props.item.item_id))
      }
      else {
         setprodtype("product")

         $('accimag').removeClass('accimag');
         
         const config = props.item.extension_attributes.configurable_item_options;
         

         if (store.auth.token !== null) {
            await dispatch(UpdateCartItem(store.auth.cartid, props.item.sku, count, props.item.item_id, config == undefined ? undefined : "configurable", config == undefined ? undefined : config[0].option_id, config == undefined ? undefined : config[0].option_value));
         } else {
            await dispatch(GuestUpdateCartItem(store.auth.cartid, props.item.sku, count, props.item.item_id, config == undefined ? undefined : "configurable", config == undefined ? undefined : config[0].option_id, config == undefined ? undefined : config[0].option_value));
         }
         dispatch(RemoveKoinzProduct(props.item.item_id))
      }
   };

   const html = () => {
      return props.item.extension_attributes.prize_text
         .replace(/<\/p>/g, '|')
         .replace(/<p>/g, '')
         .split('|')
         .filter(cur => cur.trim())
         .pop();
   };


   const OnSwiping = e => {
      if (props.type !== 'cart') {
         return;
      }
      if (e.dir === 'Left') {
         card.current.style.transform = 'translateX(-' + (e.absX + offset) + 'px)';
         /*  if (store.auth.token !== null) {
             dispatch(DeleteCartItem(props.item.item_id));
          } else {
             dispatch(GuestDeleteCartItem(props.item.item_id, store.auth.cartid));
          } */
      }
      if (e.dir === 'Right') {
         TweenMax.to(card.current, 0.5, { x: 0 });
      }

   }

   const CalcKoinz = () => {
      var conversion_rate = get(store, 'cart.config', []).find(item => item.name == "earning_conversion_rate")
      var product_conversion_rate = get(store, 'cart.config', []).find(item => item.name == "product_cost_conversion_rate")

      var customer_level = get(store, 'mambo.data', null)
      if (customer_level.level != null) {
         customer_level = customer_level.level.reward.customFields
         customer_level = customer_level.find(fields => fields.name == "loyalty_level_bonus")
      }

      var total_koinz = ((props.item.qty * props.item.price) * ((conversion_rate == undefined ? 0.2 : parseFloat(conversion_rate.value)))) + ((props.item.qty * props.item.extension_attributes.cost) * ((product_conversion_rate == undefined ? 1 : parseFloat(product_conversion_rate.value))))
      total_koinz = total_koinz + (total_koinz * (customer_level.value == undefined ? 0 * 0.01 : parseFloat(customer_level.value) * 0.01))

      return Math.round(total_koinz)

   }

   return (
      <div className="checkoutcart-container">
         <Tween from={{ x: '100%', opacity: 1, duration: 0.5 }}>
            <Swipeable
               onSwiping={e => {
                  OnSwiping(e);
               }}
            >
               {props.type === 'cart' ? (
                  <div
                     className="deletecard"
                     onClick={() => {
                        OnDelete();
                     }}
                  >
                     <img src={`${props.Environment.img_path}images/delete.svg`} />
                  </div>
               ) : null}
               {/* <SwipeToDelete
               onDelete={OnDelete} // required
               height={100} // required
               // optional
               transitionDuration={250} // default
               deleteWidth={80} // default
               deleteColor="red" // default
               deleteText="Delete" // default
               disabled={false} // default
            > */}
               <div className="checkoutcart-main" ref={card}>
                  <div className="checkoutcart-cards">
                     <div className="checkoutcart-prize">
                        <div className="checkoutcart-name">
                           {props.type === 'cart' && (
                              <div
                                 className="money-img"
                                 style={{
                                    width: prod_type === 'product' ? '55px' : '80px',
                                 }}
                              >
                                 {prod_type === 'koinz' ? (
                                    <img id="accimag" src={`${props.Environment.img_path}icons/ic-money.svg`} alt="" />
                                 ) : (
                                       <img
                                          src={
                                             props.item.extension_attributes !== undefined
                                                ? props.Environment.productUrl + props.item.extension_attributes.image
                                                : ''
                                          }
                                          alt=""
                                       />
                                    )}
                              </div>
                           )}
                           {props.type === 'payreview' && (
                              <div className="prize-img">
                                 {prod_type == 'product' ? (
                                    <img
                                       src={
                                          props.item.extension_attributes !== undefined
                                             ? props.Environment.productUrl + props.item.extension_attributes.image
                                             : ''
                                       }
                                       alt=""
                                    />
                                 ) : (
                                       <img src={`${props.Environment.img_path}icons/ic-money.svg`} alt="" />
                                    )}
                              </div>
                           )}
                           <div className="checkoutcart-koinz">
                              {prod_type === 'koinz' ? (
                                 <span>
                                    {/* Math.round(props.item.price * cv_rate) */ CalcKoinz()} {props.Environment.koinz}
                                 </span>
                              ) : (
                                    <span>{props.item.name}</span>
                                 )}
                              <span> {store.account.currency}  {currencyconvertrate(props.item.price, store)}  </span>
                           </div>
                        </div>
                        <div className="checkout-product">
                           <img
                              src={
                                 props.item.extension_attributes !== undefined
                                    ? props.Environment.productUrl +
                                    (prize != undefined
                                       ? prize.custom_attributes.find(item => item.attribute_code == 'thumbnail')
                                          .value
                                       : '')
                                    : ''
                              }
                              alt=""
                           />
                        </div>
                     </div>
                     <div className="checkout-ticket-count">
                        <div className="product-name">
                           {props.Environment.y}
                           {props.num ? <span> {count}</span> : null}&nbsp;
                           {count > 1 ? props.Environment.ts : props.Environment.t}
                           <span> {prize != undefined ? prize.name : ''}</span>
                        </div>
                        {props.type === 'cart' && (
                           <div className="ticket-plus-minus">
                              <img src={`${props.Environment.img_path}icons/ic_minus.svg`} alt="" onClick={OnMinus} />
                              <p>{count}</p>
                              <img src={`${props.Environment.img_path}icons/ic_plus.svg`} alt="" onClick={OnPlus} />
                           </div>
                        )}
                     </div>
                  </div>
                  {props.type === 'cart' && (
                     <div className="checkoutcart-cards bdr-green" onClick={() => setKoinzMode()}>
                        <div className="checkoutcart-replace-item">

                           {prod_type === 'koinz' ? (

                              <span>
                                 {props.Environment.replacewith} {props.item.name}
                              </span>

                           ) : (
                                 <div className="notif-koinz">
                                    <img src="./icons/koinzselect.svg" alt="kanzi-logo" />
                                    <span>{props.Environment.replacewithkoinz}</span>
                                 </div>
                              )}
                           {/* <span className="cart-arrow koinz-flipss">
                              <img src="images/right-green-arrow.svg" alt="" />
                           </span> */}
                        </div>
                     </div>
                  )}
               </div>
            </Swipeable>
         </Tween>
      </div>
   );
};

export default CheckoutCart;

/* const func = onSwiping= {(e) => {
   console.log(e.absX)
   console.log(card.current.getBoundingClientRect().x)
   if (Math.floor(card.current.getBoundingClientRect().x) == 0) {
      return
   }
   if (e.dir === "Right" && card.current.getBoundingClientRect().x <= 120) {
      card.current.style.transform = "translateX(" + e.absX + "px)"
      /* console.log(card.current.style)

      console.log(card.current.style.transform) */
//console.log(card.current.getBoundingClientRect().x) */
// }
// if (e.dir === "Left" && card.current.getBoundingClientRect().x <= 120 && card.current.getBoundingClientRect().x !== 19) {
//    card.current.style.transform = "translateX(" + (card.current.getBoundingClientRect().x - e.absX) + "px)"
// }

//}}
