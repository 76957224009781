import React, { useState, useEffect, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar';
import { Environment } from '../../env';
import { TweenMax } from 'gsap';
import { Tween } from 'react-gsap';
import { useSelector, useDispatch } from 'react-redux';
import { GetNotificationListing, GetNotificationStatus } from '../../redux/actions/account/actions';
import $ from 'jquery';

const script = document.createElement('script');
script.src =
   'https://i.ctnsnet.com/int/integration?pixel=67834349&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}';
script.async = true;
const AccountSettings = props => {
   const dispatch = useDispatch();
   const store = useSelector(store => store);

   useEffect(() => {
      if (document.getElementById('accountsettings-page-wrapper'))
         document.getElementById('accountsettings-page-wrapper').appendChild(script);
      return () => {
         if (document.getElementById('accountsettings-page-wrapper'))
            document.getElementById('accountsettings-page-wrapper').removeChild(script);
      };
      if (store.auth.token != null) {
         dispatch(GetNotificationListing());
         dispatch(GetNotificationStatus());
      }
   }, []);

   const language = useSelector(state => {
      return state.home.language;
   });
   console.log('language' + language);

   console.log(props);

   if (props.location.state == undefined) {
      console.log('hey');
      if (language == 'ar') {
         $('#body-id').addClass('site-ar');
      } else {
         $('#body-id').removeClass('site-ar');
      }
   }

   const currency = useSelector(state => {
      return state.account.currency;
   });

   return (
      <div id="accountsettings-page-wrapper">
         <NavBar accountsettings="Account Details" />
         <div className="account-container">
            <div className="desktop-header">{props.Environment.accountsettimn}</div>
            {props.token === null ? <Redirect to="/signup" /> : null}
            <Tween from={{ x: '100%', opacity: 1, duration: 0.5 }}>
               <div className="account-list-cards">
                  <div className="list-cards">
                     <ul>
                        <li>
                           <Link to="/language">
                              <div className="icontxt">
                                 <div className="list-icon">
                                    <img src={`${props.Environment.img_path}makeicons/ic_lang.svg`} alt="" />
                                 </div>
                                 <div className="list-txt">{props.Environment.language}</div>
                              </div>
                              <div className="list-txt-selected" id="lanhus">
                                 {language == 'en' ? 'English' : 'العربية'}
                              </div>
                              <div>
                                 <img src={`${props.Environment.img_path}makeicons/ic_list_arrow.svg`} alt="" />
                              </div>
                           </Link>
                        </li>
                        <li>
                           <Link to="/currency">
                              <div className="icontxt">
                                 <div className="list-icon">
                                    <img src={`${props.Environment.img_path}makeicons/ic_currency.png`} alt="" />
                                 </div>
                                 <div className="list-txt">{props.Environment.currency}</div>
                              </div>
                              <div className="list-txt-selected">{currency}</div>
                              <div>
                                 <img src={`${props.Environment.img_path}makeicons/ic_list_arrow.svg`} alt="" />
                              </div>
                           </Link>
                        </li>
                        {/* <li>
                  <Link to="/notificationsettings">
                    <div className="icontxt">
                      <div className="list-icon">
                        <img src={`${props.Environment.img_path}makeicons/ic_noti.svg`} alt="" />
                      </div>
                      <div className="list-txt">
                        {props.Environment.notificationsettings}
                      </div>
                    </div>
                    <div>
                      <img src={`${props.Environment.img_path}makeicons/ic_list_arrow.svg`} alt="" />
                    </div>
                  </Link>
                </li> */}
                        {/* <li>
                  <Link to="/">
                    <div className="icontxt">
                      <div className="list-icon">
                        <img src={`${props.Environment.img_path}makeicons/ic_password.svg`} alt="" />
                      </div>
                      <div className="list-txt">
                        {props.Environment.password}
                      </div>
                    </div>
                    <div>
                      <img src={`${props.Environment.img_path}makeicons/ic_list_arrow.svg`} alt="" />
                    </div>
                  </Link>
                </li> */}
                     </ul>
                  </div>
               </div>
            </Tween>
         </div>
      </div>
   );
};

export default AccountSettings;
