import React, { useEffect, useState } from "react"
import NavBar from "../../components/NavBar/NavBar";
import { ProgressBar } from "react-bootstrap"
import { Environment } from '../../env';
import { useSelector } from "react-redux"
//import 'bootstrap/dist/css/bootstrap.min.css';
import PopUps from '../../components/PopUps/PopUps';
import { TweenMax } from "gsap"
import { get } from "lodash"


const HeroPath = () => {

   const [ShowPopup, setShow] = useState(false);
   
   const [pptype, setpptype] = useState("loyalty-popup")
   const store = useSelector(store => store)
   
   const [level_info, setlevelinfo] = useState({
      current: "Walker",
      next: "Walker",
      required: 0,
      progress: 50,
      current_count: 0
   })
   const [p_data, setpdata] = useState(null)
   const Animate = () => {
      TweenMax.to(".p-container", 0.8, { x: "0%" })
   }

   const language = localStorage.getItem('langage')

   /* const GetCurrentLevel = () => {
      let data = get(store, 'cart.loyaltyData', null)
      console.log("data" + data);

      if (data) {

         let add_progress = 0
         let completed = data.completed.length

         if (completed == 0) {
            completed = 1
         }
         if (completed == 1) {
            // bar_ref.current.style.width = "32%"
         }
         if (completed == 2) {
            add_progress = 30
            // bar_ref.current.style.width = "57%"
         } else if (completed == 3) {
            add_progress = 60
            //bar_ref.current.style.width = "83%"
         }

         let current = data.available[completed - 1].name.split(" ")[1]
         let next = data.available[completed].name.split(" ")[1]
         let required = data.available[completed].attrs.progress.criteriaProgress[0].required - data.available[completed].attrs.progress.criteriaProgress[0].completed
         let progress = ((data.available[data.available.length - 1].attrs.progress.criteriaProgress[0].completed - data.available[completed - 1].attrs.progress.criteriaProgress[0].required) / (data.available[data.available.length - 1].attrs.progress.criteriaProgress[0].required - data.available[completed - 1].attrs.progress.criteriaProgress[0].required)) * 100
         let current_count = data.available[data.available.length - 1].attrs.progress.criteriaProgress[0].completed
         

         setlevelinfo({
            current,
            next,
            required,
            progress: Math.round(progress + add_progress),
            current_count
         })
      }
   } */


   const GetCurrentLevel = () => {
      let data = get(store, 'cart.loyaltyData', null)
      console.log("data" + data);

      if (data) {

         let add_progress = 0
         let completed = data.completed.length

         if (completed == 0) {
            completed = 1
         }
         if (completed == 1) {
            // bar_ref.current.style.width = "32%"
         }
         if (completed == 2) {
            add_progress = 30
            // bar_ref.current.style.width = "57%"
         } else if (completed == 3) {
            add_progress = 60
            //bar_ref.current.style.width = "83%"
         }

         
         let current = data.available[completed - 1].name
         
         if (completed == 4) {
            completed = 3;
         }
         let next = data.available[completed].name
         let required = data.available[completed].attrs.progress.criteriaProgress[0].required - data.available[completed].attrs.progress.criteriaProgress[0].completed
         let progress = ((data.available[data.available.length - 1].attrs.progress.criteriaProgress[0].completed - data.available[completed - 1].attrs.progress.criteriaProgress[0].required) / (data.available[data.available.length - 1].attrs.progress.criteriaProgress[0].required - data.available[completed - 1].attrs.progress.criteriaProgress[0].required)) * 100
         let current_count = data.available[data.available.length - 1].attrs.progress.criteriaProgress[0].completed

         if (completed == 1) {
            progress =
               (data.available[completed].attrs.progress.criteriaProgress[0].completed /
                  data.available[completed].attrs.progress.criteriaProgress[0].required) *
               100;
            progress = (progress / 100) * 36;
         }
         if (completed == 2) {
            if (data.available[completed].attrs.progress.criteriaProgress[0].completed - data.available[completed - 1].attrs.progress.criteriaProgress[0].required == 0) {
               progress = 36
            } else {
               progress =
                  ((data.available[completed].attrs.progress.criteriaProgress[0].completed - data.available[completed - 1].attrs.progress.criteriaProgress[0].required) /
                     data.available[completed].attrs.progress.criteriaProgress[0].required) * 100;
               progress = 35 + ((progress / 100) * 35);
            }

            // bar_ref.current.style.width = "57%"
         } else if (completed == 3) {
            if (data.available[completed].attrs.progress.criteriaProgress[0].completed - data.available[completed - 1].attrs.progress.criteriaProgress[0].required == 0) {
               progress = 70
            } else {
               progress =
                  ((data.available[completed].attrs.progress.criteriaProgress[0].completed - data.available[completed - 1].attrs.progress.criteriaProgress[0].required) /
                     data.available[completed].attrs.progress.criteriaProgress[0].required) * 100;
               progress = 70 + ((progress / 100) * 30);
            }
            //bar_ref.current.style.width = "83%"
         }

         if (completed != 4) {
            /*  progress =
            (data.available[completed].attrs.progress.criteriaProgress[0].completed /
               data.available[completed].attrs.progress.criteriaProgress[0].required) *
            100;
            progress = (progress/100)*36; */
         }

         setlevelinfo({
            current,
            next,
            required,
            progress: Math.round(progress),
            current_count
         })
      }
   }

   

   useEffect(() => {
      if (ShowPopup) {
         Animate()
      }
   }, [ShowPopup])

   useEffect(() => {
      if (get(store, 'cart.loyaltyData', null) != null) {
         GetCurrentLevel()
         Showpopup()
      }
   }, [get(store, 'cart.loyaltyData', null)])

   const Showpopup = () => {
      let data = get(store, 'cart.loyaltyData', null)
      let completed = data.completed.length
      let badge = get(store, 'cart.loyaltyData.available', [])

      console.log(badge)

      if (completed == 0) {
         completed = 1
      }

      let isShown = parseInt(localStorage.getItem("loyalty-popup1"))

      console.log(isShown)

      if (completed > 1 & isShown != completed) {
         setpdata({
            level: badge[completed - 1].name,
            reward: badge[completed - 1].customFields[0].value,
            url: badge[completed - 1].imageUrl,
            message: badge[completed - 1].message
         })
         setpptype("loyalty-popup")
         setShow(true)
         localStorage.setItem("loyalty-popup1", completed)
      }

   }

   return (
      <div className="heropath-container">
         {ShowPopup && (
            <div className="p-container">
               <PopUps
                  data={p_data}
                  type={pptype}
                  onClose={() => {
                     setShow(false);
                  }}
               />
            </div>
         )}
         <NavBar heropath="Hero's path" />
         

         <div className="top top32">
            <p className="txt1">Climb the hero’s path to unlock rewards and reach new prestiges!</p>
            <div className="container">
               <p>
                  {level_info.required} more participations to become a {level_info.next}
               </p>
            </div>
            {/* <p className="level-txt">Level:</p> */}
         </div>
         <div className="section1">
            <ProgressBar striped={true} animated now={level_info.progress} className="use-bootstrap" />
            {/* <div className="arrow-container">
               <div className="arrow-progress" ref={bar_ref}>
                  <div className="arrow">
                     <span className="circle"></span>
                     <span className="line"></span>
                     <div className="box">
                        {level_info.current_count}
                     </div>
                  </div>
               </div>
            </div> */}
            {/* <ProgressBar now={50} /> */}
            <div className="levels">
               {get(store, 'cart.loyaltyData.available', []).map((level, index) => {

                  let name = level.translations.find(trans => trans.languageCode == language).name
                  let data = get(store, 'cart.loyaltyData', null)
                  let completed = 0
                  let img = level.imageUrl

                  if (data) {
                     completed = data.completed.length
                  }

                  if (completed == 0) {
                     completed = 1
                  }

                  if (index + 1 > completed) {
                     img = img.replace('source', 'grayscale')
                  }

                  return (
                     <div className="level">
                        <span className={`${index == 0 ? "disable" : ""}`}>
                           <img src={`${Environment.img_path}makeicons/money.svg`} />
                            x{level.customFields[0].value}%
                        </span>
                        <img src={img} />
                        <p className={`p-count ${index == 0 ? "disable" : ""}`}>{level.attrs.progress.criteriaProgress[0].required}</p>
                        <p className="l-name">
                           {name}
                        </p>
                     </div>
                  )
               })}
               {/* to here */}
               {/* <div className="level" onClick={() => {
                  setpptype("loyalty-popup1")
                  setShow(true)
                  Animate()
               }}>
                  <span className="disable">
                     <img src={`${Environment.img_path}makeicons/money.svg`} />
                      x2.5%
                   </span>
                  <img src={`${Environment.img_path}makeicons/level1.svg`} />
                  <p className="p-count disable">25</p>
                  <p className="l-name">
                     The
                     <br />
                     Walker
                  </p>
               </div>
               <div
                  className="level"
                  onClick={() => {
                     setpptype('loyalty-popup1');
                     setShow(true);
                     Animate();
                  }}
               >
                  <span>
                     <img src={`${Environment.img_path}makeicons/money.svg`} />
                     x2.5%
                  </span>
                  <img src={`${Environment.img_path}makeicons/level2.svg`} />
                  <p className="p-count">25</p>
                  <p className="l-name">
                     The
                     <br />
                     Runner
                  </p>
               </div>
               <div
                  className="level"
                  onClick={() => {
                     setpptype('loyalty-popup2');
                     setShow(true);
                     Animate();
                  }}
               >
                  <span>
                     <img src={`${Environment.img_path}makeicons/money.svg`} />
                     x5%
                  </span>
                  <img src={`${Environment.img_path}makeicons/level3.svg`} />
                  <p className="p-count">100</p>
                  <p className="l-name">
                     The
                     <br />
                     Climber
                  </p>
               </div>
               <div className="level">
                  <span>
                     <img src={`${Environment.img_path}makeicons/money.svg`} />
                     x7.5%
                  </span>
                  <img src={`${Environment.img_path}makeicons/level4.svg`} />
                  <p className="p-count">250</p>
                  <p className="l-name">
                     The
                      <br />
                      Flyer
                   </p>
               </div> */} {/* from here */}
            </div>
         </div>
         <div className="bottom">
            <p className="txt1">The more you shop, the higher you climb, each new level gives you more bonus Koinz.</p>
            {/* <p className="level-txt">Badges:</p>

            <div className="bottom-badge">
               <div className="img-cont">
                  <img src={`${Environment.img_path}makeicons/badge1.svg`}></img>
               </div>
               <div className="img-cont">
                  <img src={`${Environment.img_path}makeicons/placeholder2.svg`}></img>
               </div>
            </div>

            <p className="txt1">Complete missions to earn badges and get free Koinz.</p> */}
         </div>
         {/* <Button
            className="button-red"
            onClick={() => { history.push("/mykoinz") }}
            data={"My Koinz and Rewards"}
         /> */}
         {/* <div className="bt-txt" onClick={() => {
            history.push("/mykoinz")
         }}>
            Check <span>My Koinz and Rewards.</span>
         </div> */}
      </div >
   );
}

export default HeroPath