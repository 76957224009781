export const SETALLPRODUCTS = 'SETALLPRODUCTS';
export const SETBANNERS = 'SETBANNERS';
export const SETCATEGORIES = 'SETCATEGORIES';
export const SETPRODBYCAT = 'SETPRODBYCAT';
export const SETPRIZESNEXTMONTH = 'SETPRIZESNEXTMONTH';
export const SETCATEGORY = 'SETCATEGORY';
export const GETTIP = 'GETTIP';
export const GETVOTE = 'GETVOTE';
export const GETCURRENCYDATA = 'GETCURRENCYDATA';
export const SAVEVOTE = 'SAVEVOTE';
export const SUBSCRIBE_TIP = 'SUBSCRIBE_TIP';
export const UNSUBSCRIBE_TIP = 'UNSUBSCRIBE_TIP';
export const FETCH_WINNER = 'FETCH_WINNER';
export const SETPREVROUTE = 'SETPREVROUTE';
export const SETPREVTAB = 'SETPREVTAB';
export const SETDEVICEIFO = 'SETDEVICEINFO';
export const SETCAMPAIGNMANAGER = 'SETCAMPAIGNMANAGER';
export const SETENDINGSOONPRODUCTS = 'SETENDINGSOONPRODUCTS';
export const SETREFERID = "SETREFERID"
export const SETLANG = "SETLANG"
export const SETDESKTOPPRODUCTS = "SETDESKTOPPRODUCTS"
export const SETRENDER = "SETRENDER"
export const SETPOPUPDATA = "SETPOPUPDATA";
export const SETREWARDS = "SETREWARDS"
export const SETRECALL = "SETRECALL"
export const SETNOTIFICATION = "SETNOTIFICATION"
export const ADDNOTIFICATION = "ADDNOTIFICATION"
export const REMOVENOTIFICATION = "REMOVENOTIFICATION"
export const SETMASTERDATA = "SETMASTERDATA"
export const SETNOTIFICATIONLISTING = "SETNOTIFICATIONLISTING"
export const SETCAMPAIGNBYCATEGORY = "SETCAMPAIGNBYCATEGORY"
