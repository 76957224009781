export const LOGIN = "LOGIN"
export const REGISTER = "REGISTER"
export const LOGOUT = "LOGOUT"
export const SETERROR = "SETERROR"
export const CREATE_PLAYER = "CREATE_PLAYER"
export const CREATE_PLAYER_ERROR = "CREATE_PLAYER_ERROR"
export const VERIFY = "VERIFY"
export const AUTOLOGIN = "AUTOLOGIN"
export const SETCARTID = "SETCARTID"
export const NEWCARTID = "NEWCARTID"
export const CUSTOMERUPDATE = "CUSTOMERUPDATE"
export const GENERATE_OTP = "GENERATE_OTP"
