import React, { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { Environment } from '../../env';
import { useDispatch } from 'react-redux';
import MyHistoryEmpty from '../../components/MyHistoryEmpty/MyHistoryEmpty';
import OrderHistoryList from '../../components/OrderHistoryList/OrderHistoryList';
import NavBar from '../../components/NavBar/NavBar';
import Loader from '../../components/Loader/Loader';
import { GetPreviousOrders, GetPreviousOrdersRedux } from '../../redux/actions/cart/actions';
import { Tween } from 'react-gsap';
const script = document.createElement('script');
script.src =
   'https://i.ctnsnet.com/int/integration?pixel=67834349&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}';
script.async = true;
const MyHistory = props => {
   const [isRendered, setRendered] = useState(false);
   const [orders, setOrders] = useState([]);
   const store = useStore().getState();
   const dispatch = useDispatch();
   const history = useHistory();

   useEffect(() => {
      if (document.getElementById('myhistory-page-wrapper'))
         document.getElementById('myhistory-page-wrapper').appendChild(script);
      return () => {
         if (document.getElementById('myhistory-page-wrapper'))
            document.getElementById('myhistory-page-wrapper').removeChild(script);
      };
   }, []);
   useEffect(() => {
      if (store.auth.token !== null) {
         //setRendered(true);
         ApiCall();
      } else {
         setRendered(true);
      }
   }, []);

   const ApiCall = async () => {
      if (store.cart.previousorders.length != 0) {
         setRendered(true);
      }

      let res = await dispatch(
         GetPreviousOrdersRedux(store.auth.token, 'DESC', () => {
            setRendered(true);
         })
      );

      console.log(res);

      /* if (res == true) {
         setRendered(true)
      } */
   };

   console.log(store.cart.previousorders);

   return (
      <div id="myhistory-page-wrapper" className="">
         <NavBar histoty="true" />
         {!isRendered && (
            <div className="history-container">
               <div className="history-details" style={{ minHeight: 'auto' }}>
                  <Loader style={{ margin: '10px auto 60px' }} />
               </div>
            </div>
         )}
         {isRendered && store.cart.previousorders.length === 0 && <MyHistoryEmpty {...props} />}
         {isRendered && store.cart.previousorders.length !== 0 && (
            <div className="history-container">
               <div className="desktop-header">Your Orders</div>
               <div className="history-details">
                  <Tween from={{ x: '100%', opacity: 1, duration: 0.5 }}>
                     <div>
                        {/* <h6>{props.Environment.order}</h6> */}
                        <OrderHistoryList orders={store.cart.previousorders} {...props} />
                        <Link to="/">
                           <Button
                              className="button-outline-gray"
                              data={props.Environment.shopnow}
                              onClick={() => {
                                 history.push({
                                    pathname: '/',
                                    props: { top: true },
                                 });
                              }}
                           />
                        </Link>
                     </div>
                  </Tween>
               </div>
            </div>
         )}
      </div>
   );
};

export default MyHistory;
