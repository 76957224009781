import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useStore, useDispatch } from 'react-redux';
import { Environment } from '../../env';
import { CreateCartId } from '../../redux/actions/auth/actions';
import Button from '../../components/Button/Button';
import TicketList from '../../components/TicketList/TicketList';
import { Tween } from 'react-gsap';
import { unuseLockBodyScroll, isOrderSuccessfull } from '../../utils/Function';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import { SetRecall } from '../../redux/actions/home/actions';
import { useLocation } from 'react-router-dom';
import CircularLoader from '../../components/Loader/CircularLoader';
// import { Ordermail } from '../../redux/actions/cart/actions';

import {
   GetCategories,
   GetAllProducts,
   GetProdByCat,
   GetPrizesNextMonth,
   GetBanners,
   SetCategory,
   GetTip,
   GetVote,
   GetCurrencyData,
   SaveVote,
   UpdateTipAndVotePromotion,
   GetWinnerList,
   SetDeviceInfo,
   GetCampaignManager,
   Ordermail,
   Getendingsoonproducts,
   ShowTip,
   SetTipRead,
   SetLang,
   GetDesktopProducts,
   MasterApiData,
} from '../../redux/actions/home/actions';

import {
   PaymentInfo,
   CreatePointsActivity,
   ParticipateInCampaign,
   GetOrderDetails,
   GetOrderData,
   GetAdminToken,
   EmptyKoinzProduct,
   isKoinz,
   GetPreviousOrders,
   ReferAFriend,
   GetCampaignCount,
   CreatePointsParticipation,
   koinzAmount,
   CreateSessionId,
   applePay,
} from '../../redux/actions/cart/actions';
import { get } from 'lodash';
import { SetRewards } from '../../redux/actions/home/actions';
import { UserData, SetError } from '../../redux/actions/auth/actions';
import ReactPixel from 'react-facebook-pixel';
import TagManager from "react-gtm-module";

const script = document.createElement('script');
script.src =
   'https://i.ctnsnet.com/int/integration?pixel=67834351&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}';
script.async = true;

const OrderDetails = props => {
   const [hasOrderId, setOrderId] = useState(false);
   // const [showdate, setshowdate] = useState(false);
   const [pickupa, setpickupa] = useState(false);

   const location = useLocation();

   const store = useStore().getState();

   const dispatch = useDispatch();
   const history = useHistory();
   const koinz_replacement = useSelector(store => store.cart.koinz_replacement);
   const [displayLoader, setDisplayLoader] = useState(false);
   const [orderNumber, setOrderNumber] = useState(0);
   const [coutorderNumber, setcoutorderNumber] = useState(0);
   const useKoinz = useSelector(store => store.cart.useKoinz);
   const koinz = useSelector(store => store.mambo.data);
   const cart = useSelector(store => store.cart.cart);
   const cartItemsForGTAG = useSelector(store => store.cart.cartItemsForGTAG);
   const all_products = useSelector(store => store.home.allproducts);
   const campaign = useSelector(store => store.home.campaign_manager);
   const [Gtotal, setGtotal] = useState(0);
   let params = useParams();
   let orderidvalu = localStorage.getItem('orderidvalue');
   
   useEffect(() => {
        const cartItemsForGtag = JSON.parse(localStorage.getItem("cartItemsForGtag"))         
        const clearDataLater = { dataLayer: { ecommerce: null } };
        const tagManagerArgs = {
          dataLayer: {
            event: "purchase",
            ecommerce: { ...cartItemsForGtag, transaction_id:orderidvalu}            
          }
        };

        TagManager.dataLayer(clearDataLater);
        TagManager.dataLayer(tagManagerArgs);
   }, [])

   useEffect(() => {
      if (orderidvalu && koinz.isMember) {         
         const order_id = orderidvalu;
         HandleReplaceWithKoinz(true)
            .then(response => {
               GetOrderData('zs667jaikfh4rb34vfqv49k86vb7xcq7', order_id).then(async data => {
                  let isMamboSuccess = 0;
                  let email_send = 0;
                  const isOrderSuccess = isOrderSuccessfull(data?.status);
                  if (isOrderSuccess) {
                     isMamboSuccess = 1;
                     email_send = 1;
                     if (useKoinz) {
                        //Fix Required
                        if (koinz.points[0].balance / 5 > Gtotal) {
                           await dispatch(CreatePointsActivity(-(Gtotal * 5)));
                        } else {
                           await dispatch(CreatePointsActivity(-koinz.points[0].balance));
                        }
                     }

                     await HandleReferer();

                     await HandleReplaceWithKoinz();

                     await ParticipateinCampaign(order_id);
                     await dispatch(Ordermail(params, 'url'));
                     dispatch(EmptyKoinzProduct());
                  }
                  koinzAmount(response, order_id, koinz?.points[0]?.pointId, isMamboSuccess, email_send);
               });
            })
            .catch(error => {
               console.log(error);
            });
      }
   }, [koinz]);

   const HandleReplaceWithKoinz = async (koinzdetail = null) => {
      var koinzforprod = [];
      var koinzforparticipation = [];
      var total_koinz = 0;
      var conversion_rate = get(store, 'cart.config', []).find(item => item.name == 'earning_conversion_rate');
      var product_conversion_rate = get(store, 'cart.config', []).find(
         item => item.name == 'product_cost_conversion_rate'
      );
      var customer_level = get(store, 'mambo.data', null);
      if (customer_level.level != null) {
         customer_level = customer_level.level.reward.customFields;
         customer_level = customer_level.find(fields => fields.name == 'loyalty_level_bonus');
      }
      var details = [];

      koinz_replacement.forEach(item => {
         cart.forEach(prod => {
            if (item == prod.item_id) {
               koinzforprod.push(prod);
            }
         });
      });

      koinzforparticipation = cart.filter(el => !koinzforprod.includes(el));

      koinzforprod.forEach(prod => {
         console.log(prod);
         var koinz =
            prod.qty * prod.price * (conversion_rate == undefined ? 0.2 : parseFloat(conversion_rate.value)) +
            prod.qty *
               prod.extension_attributes.cost *
               (product_conversion_rate == undefined ? 1 : parseFloat(product_conversion_rate.value));
         total_koinz = total_koinz + koinz;
         details.push({ sku: prod.sku, koinz: koinz });
      });

      koinzforparticipation.forEach(prod => {
         let koin = prod.qty * prod.price * (conversion_rate == undefined ? 0.2 : parseFloat(conversion_rate.value));
         total_koinz = total_koinz + koin;
         console.log(total_koinz);
         details.push({ sku: prod.sku, koinz: koin });
      });

      total_koinz =
         total_koinz +
         total_koinz * (customer_level.value == undefined ? 0 * 0.01 : parseFloat(customer_level.value) * 0.01);

      console.log(details);

      if (koinzdetail != null) {
         return details;
      }

      const cp = await dispatch(CreatePointsActivity(Math.round(total_koinz)));
      if (cp) {
         //dispatch(ViewMamboProfile());
      }
   };

   const ParticipateinCampaign = async res => {
      const token = await GetAdminToken();
      const items = await GetOrderDetails(token, res);
      const ids = get(store, 'home.categories', []);

      let awarded = [];

      items.forEach(async (prod, index) => {
         var product = all_products.find(all_prod => {
            return all_prod.id == prod.product_id;
         });

         if (product === undefined) {
            return;
         }

         var campaign_id = campaign.find(camp => {
            return camp.campaign_product_id == product.id;
         });

         if (campaign_id === undefined) {
            return;
         }

         var id = product.custom_attributes.find(attr => {
            return attr.attribute_code == 'category_ids';
         });

         if (id === undefined) {
            return;
         }

         id = ids.find(cat_id => {
            return cat_id.id == id.value[0];
            //  return cat_id.id == id.value[1]
         });

         console.log('id', id);

         console.log(prod);
         let count = await GetCampaignCount(prod.product_id, store.auth.token);

         if (count == false) {
            return;
         }

         let res = await dispatch(ParticipateInCampaign(id.name.toLowerCase(), campaign_id.campaign_id, count));

         if (res.awarded.length !== 0) {
            res.awarded.forEach(award => {
               awarded.push({ message: award.message, img: award.reward.imageUrl });
            });
         }

         if (index === items.length - 1) {
            console.log(awarded);
            dispatch(SetRewards(awarded));
            //await dispatch(ViewMamboProfile())
         }
      });

      await dispatch(CreatePointsParticipation());
   };

   const HandleReferer = async () => {
      var orders = await GetPreviousOrders(get(store, 'auth.token', null));

      if (orders.items.length - 1 == 0) {
         var refer_id = await UserData(get(store, 'auth.token'), null);
         if (refer_id.custom_attributes !== undefined) {
            refer_id = refer_id.custom_attributes.find(attr => attr.attribute_code == 'refer_id');
            console.log(refer_id);
            if (refer_id == null) {
               return;
            } else {
               await ReferAFriend(store.auth.userinfo.id, refer_id.value);
            }
         }
      }
   };

   useEffect(() => {
      let finalOrderAmount = localStorage.getItem('finalOrderAmount');
      ReactPixel.track('Purchase', {
         value: finalOrderAmount,
         currency: store.account.currency ? store.account.currency : 'AED',
      });
      if (document.getElementById('order-details-wrapper')) {
         document.getElementById('order-details-wrapper').appendChild(script);
      }
      $('.fds').addClass('ordervsiablescroll');
      // unuseLockBodyScroll();
      // loadPaymentGatewayScript()
      setpickupa(localStorage.getItem('pickupa'));
      document.body.style.overflow = 'visible';
      setTimeout(() => {
         // setActive(true)
      }, 700);
      return () => {
         document.body.style.overflow = 'visible';
         if (document.getElementById('order-details-wrapper'))
            document.getElementById('order-details-wrapper').removeChild(script);
      };
   }, []);

   // useEffect(() => {
   //    console.log(location);
   //    if (location?.props && location?.props?.showdate) {
   //       setshowdate(location?.props?.showdate);
   //    }
   //    if (location?.props?.pickupa) {
   //       setpickupa(location?.props?.pickupa);
   //    }
   // }, [location]);

   useEffect(() => {
      (async () => {
         // console.log("Order id" + store.cart.orderid);
         if (store.auth.token !== null) {
            if (store.auth.cartid === null) {
               setDisplayLoader(true);
               await dispatch(CreateCartId(store.auth.token));
               //  await dispatch(Ordermail(params));
               setOrderId(true);
               setDisplayLoader(false);
            }
         }
      })();
   }, [store.auth.cartid]);

   useEffect(() => {
      if (store.cart && store.cart.orderid) {
         setOrderId(true);
      }
      setTimeout(() => {
         dispatch(SetRecall());
      }, 5000);

      if (params?.orderId) {
         getCampProductData();
      } else {
         // let localStorage.getItem('order_id');
         let orderidvalu = localStorage.getItem('orderidvalue');
         getnormalCampProductData(orderidvalu);
      }
   }, []);

   console.log(props);

   const getCampProductData = async () => {
      // console.log(store.cart.orderid)

      const apiCallArray = [
         await dispatch(GetCampaignManager()),
         await dispatch(GetCategories()),
         await dispatch(GetPrizesNextMonth()),
         await dispatch(Getendingsoonproducts()),
         await dispatch(GetBanners()),
         await dispatch(GetAllProducts()),
         await dispatch(GetWinnerList()),
         await dispatch(MasterApiData()),
         await dispatch(GetCurrencyData()),
         await dispatch(Ordermail(params, 'url')),
      ];

      // const [campaigns, category] = await Promise.all(apiCallArray);
      const [campaigns, category, , , ,] = await Promise.all(apiCallArray);
      console.log(category.id);
      await dispatch(GetProdByCat(category.id, campaigns));

      setOrderId(true);
   };
   const getnormalCampProductData = async orderidvalu => {
      // console.log(store.cart.orderid)

      const apiCallArray = [
         await dispatch(GetCampaignManager()),
         await dispatch(GetCategories()),
         await dispatch(GetPrizesNextMonth()),
         await dispatch(Getendingsoonproducts()),
         await dispatch(GetBanners()),
         await dispatch(GetAllProducts()),
         await dispatch(GetWinnerList()),
         await dispatch(MasterApiData()),
         await dispatch(GetCurrencyData()),
         dispatch(Ordermail(orderidvalu, 'normal')),
      ];

      // const [campaigns, category] = await Promise.all(apiCallArray);
      const [campaigns, category, , , ,] = await Promise.all(apiCallArray);
      console.log(category.id);
      await dispatch(GetProdByCat(category.id, campaigns));

      setOrderId(true);
   };
   // setcoutorderNumber(1);
   // console.log(coutorderNumber)

   return (
      <div className="" id="order-details-wrapper">
         {displayLoader ? (
            <CircularLoader show={displayLoader}></CircularLoader>
         ) : (
            <div className="order-container">
               <div className="order-details order-extra-details">
                  <Tween from={{ y: '-100%', opacity: 0, duration: 0.5 }}>
                     <div className="koinz-logo">
                        <img src={`${Environment.img_path}images/woho1.gif`} alt="kanzi-logo" className="ohooimahe" />
                        {/* <video id="video" width="100%" height="100%"  autoPlay muted src={`${Environment.img_path}images/woho.mp4`} type="video/mp4" webkit-playsinline="true" playsinline="true" /> */}
                     </div>
                  </Tween>
                  {/* <h2>{Environment.woohootxt}</h2> */}
                  {/* <p>{props.Environment.woohsubtxt}</p> */}
                  <p>{props?.Environment?.woohsubtxt}</p>
                  {hasOrderId && (
                     <React.Fragment>
                        <TicketList
                           {...props}
                           replace={true}
                           type="openTickets"
                           hasQty={false}
                           orderid={Number(localStorage.getItem('orderidvalue'))}
                           // orderid={324}
                           order="ASC"
                        />
                     </React.Fragment>
                  )}
                  {pickupa ? null : (
                     <div className="orderdeliverydays">
                        <span class="vehicle-logo">
                           <img src={`${Environment.img_path}icons/ic-truck.svg`} alt="kanzi-logo" />
                        </span>
                        <span class="extd">{props?.Environment.deliverytimetxt}</span>
                     </div>
                  )}

                  <div
                     onClick={() => {
                        history.push('/tickets');
                     }}
                     className="tktsection"
                  >
                     {props?.Environment?.trackpae}
                     {/* <Link to="/"> */}

                     {/* </Link> */}
                  </div>
                  <span className="tspan">
                     <img src={`${Environment.img_path}icons/ic-ticketred.svg`} alt="kanzi-logo" />
                  </span>
                  <p className="pspan">{props?.Environment?.trackpae1}</p>
               </div>
               <Button
                  className="button-red"
                  data={props?.Environment?.trackpae5}
                  onClick={() => {
                     history.push('/');
                  }}
               />
               <div className="tktsection" style={{ paddingBottom: 68 }}>
                  {props?.Environment?.trackpae2}
                  <Link to="/mykoinz">{props?.Environment?.trackpae3}</Link>
               </div>
            </div>
         )}
      </div>
   );
};

export default OrderDetails;
