import React from "react";

const AddressInput = ({ label, placeholder, value, onChange, error }) => {
  return (
    <div className="floating-label">
      <input
        className={`floating-input ${error ? "error" : ""}`}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      <span className="highlight"/>
      <label className="label_placeholder">{label}</label>
    </div>
  )
}

export default AddressInput;
