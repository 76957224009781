import React, { useState, useRef } from 'react';
import { Environment } from './../../env';
import Button from '../../components/Button/Button';
import { Redirect } from 'react-router-dom';
import { TweenMax } from 'gsap';
import { Tween } from 'react-gsap';

const EmptyWatchlist = (props) => {
   const [redirect, setredirect] = useState(false);

   return (
      <div className="cartempty-container empty-ticket-container watchlist-container">
         {redirect ? <Redirect to="/" /> : null}
         <div className="cartempty-main">
            <Tween from={{ y: '-100%', opacity: 0, duration: 0.5 }}>
               <div className="ic-cart-empty">
                  <img className="wtemd" src="images/watchlist.svg" alt="" />
               </div>
            </Tween>
            <h4>{props.Environment.blipradar}</h4>
            <p>{props.Environment.dontforget} <br /> {props.Environment.keepeyeout}</p>
            <Button
               className="button-red"
               data={props.Environment.gshopping}
               onClick={() => {
                  setredirect(true);
               }}
            ></Button>
         </div>
      </div>
   );
};

export default EmptyWatchlist;
