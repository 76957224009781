import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Cookie from 'js-cookie';
import NavBar from '../../components/NavBar/NavBar';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Environment } from '../../env';
import FloatingInput from '../../pages/PersonalDetails/FloatingInput';
import { Tween } from 'react-gsap';
import Button from '../../components/Button/Button';
import { Login, SetError } from '../../redux/actions/auth/actions';
import PopUps from '../../components/PopUps/PopUps';
import { isObjEmpty } from '../../utils/utils';
import { LOGOUT } from '../../redux/actions/auth/actionTypes';
import CircularLoader from '../../components/Loader/CircularLoader';
import { ProgressBar } from 'react-bootstrap';
import { get } from 'lodash';
import { ViewMamboProfile } from '../../redux/actions/cart/actions';
import { ViewCart } from './../../redux/actions/cart/actions';
const script = document.createElement('script');
script.src =
   'https://i.ctnsnet.com/int/integration?pixel=67834349&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}';
script.async = true;

const Account = props => {
   const [email, setemail] = useState('');
   const [form_error, setformerror] = useState({
      email: null,
      pass: null,
   });
   const store = useSelector(store => store);
   const [pass, setpass] = useState('');
   const history = useHistory();
   const cart = useSelector(state => state.cart.cart);
   const cart_id = useSelector(state => state.auth.cartid);
   const error = useSelector(state => state.auth.error);
   const dispatch = useDispatch();
   const [errorPopup, setErrorPopup] = useState('none');
   const [animate, setanimate] = useState(false);
   const [level_info, setlevelinfo] = useState({
      progress: 0,
      current_level: `${props.Environment.img_path}makeicons/loyalty-img1.svg`,
      next_level: `${props.Environment.img_path}makeicons/loyalty-img2.svg`,
      current_level_name: 'The Walker',
      points: '0',
   });
   const language = localStorage.getItem('langage');
   const prevTab = useSelector(store => store.home.prevTab);

   useEffect(() => {
      if (document.getElementById('login-page-wrapper'))
         document.getElementById('login-page-wrapper').appendChild(script);
      return () => {
         if (document.getElementById('login-page-wrapper'))
            document.getElementById('login-page-wrapper').removeChild(script);
      };
   }, []);

   useEffect(() => {
      if (get(store, 'cart.loyaltyData', null) !== null) {
         GetCurrentLevel();
      }
   }, [get(store, 'cart.loyaltyData', null)]);

   console.log(props.login);

   //alert(get(store, 'home.refer_id', null))

   useEffect(() => {
      if (error !== null) {
         setErrorPopup('login_no_account');
         dispatch(SetError(null));
         /* if (props.error === 'Account with phone number is already exist') {
        setErrorPopup('register_phone');
        dispatch(SetError(null));
      } else if (error === 'A customer with the same email address already exists in an associated website.') {
        setErrorPopup('register_email');
        dispatch(SetError(null));
      } */
         console.log(error);
      }
   }, [error]);

   const GetCurrentLevel = () => {
      let data = get(store, 'cart.loyaltyData', null);
      if (data.completed == undefined) {
         dispatch(ViewMamboProfile());
         return;
      }

      if (data) {
         let completed = data.completed == undefined ? 0 : data.completed.length;
         if (completed == 0) {
            completed = 1;
         }
         // if (completed == 4) {
         //    completed = 3;
         // }
         // console.log("completed" + completed.length);
         // if (completed.length != undefined) {
         let current_level_name = data.available[completed - 1].translations.find(
            trans => trans.languageCode == language?language:"en"
         ).name;
         let next_level;
         if (completed == 4) {
            completed = 3;
            next_level = data.available[completed - 1].imageUrl;
         } else {
            next_level = data.available[completed].imageUrl;
         }

         let prev_level = data.available[completed - 1].imageUrl;
         let points = data.available[completed - 1].customFields[0].value;
         let progress = 100;
         if (completed != 4) {
            progress =
               (data.available[completed].attrs.progress.criteriaProgress[0].completed /
                  data.available[completed].attrs.progress.criteriaProgress[0].required) *
               100;
            if (completed > 1) {
               if (
                  data.available[completed].attrs.progress.criteriaProgress[0].completed -
                     data.available[completed - 1].attrs.progress.criteriaProgress[0].required ==
                  0
               ) {
                  progress = 0;
               } else {
                  console.log(
                     data.available[completed].attrs.progress.criteriaProgress[0].completed -
                        data.available[completed - 1].attrs.progress.criteriaProgress[0].required
                  );
                  progress =
                     ((data.available[completed].attrs.progress.criteriaProgress[0].completed -
                        data.available[completed - 1].attrs.progress.criteriaProgress[0].required) /
                        data.available[completed].attrs.progress.criteriaProgress[0].required) *
                     100;
               }
            }
         } else {
         }

         console.log(data.available[completed].attrs.progress.criteriaProgress[0].completed);
         console.log(data.available[completed].attrs.progress.criteriaProgress[0].required);

         setlevelinfo({
            progress: Math.round(progress),
            points,
            prev_level,
            next_level,
            current_level_name,
         });
         // }
      }
   };

   const chooseErrorPopup = type => {
      if (type === 'login_no_account') {
         return {
            onSubmit: () => {
               setErrorPopup('none');
               history.push('/account/login');
            },
            onClose: () => setErrorPopup('none'),
         };
      } else if (type === 'register_email') {
         return {
            onSubmit: () => {
               setErrorPopup('none');
               history.push('/account/login');
            },
            onClose: () => {
               setErrorPopup('none');
               history.push('/account/login');
            },
         };
      } else if (type === 'incorrect_login') {
         return {
            onSubmit: () => {
               setErrorPopup('none');
               history.push('/account/login');
            },
            onClose: () => {
               setErrorPopup('none');
               history.push('/account/login');
            },
         };
      } else if (type === 'register_phone') {
         return {
            onSubmit: () => {
               setErrorPopup('none');
               history.push('/account/login');
            },
            onClose: () => {
               setErrorPopup('none');
               history.push('/account/login');
            },
         };
      }
      return false;
   };

   const OnSignIn = async () => {
      setanimate(true);
      var error = {};

      var reg = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');

      if (!/\S+@\S+\.\S+/.test(email.trim())) {
         error.email = 'Please enter a valid email Address';
      }
      if (pass === '') {
         error.pass =
            'Password should be at least 8 characters long with one from each a-z, A-Z, 0-9 and special characters.';
      }

      if (isObjEmpty(error)) {
         const res = await dispatch(Login(email.trim(), pass.trim(), cart_id, cart));
         setformerror({ email: null, pass: null });
         if (!res) {
            setErrorPopup('incorrect_login');
         }

         console.log(res);
         if (res) {
            dispatch(ViewCart());
            // history.push('/account');
            history.push(`/${prevTab}`);
         }
      } else {
         setformerror({ email: null, pass: null, ...error });
      }

      setanimate(false);
   };

   return (
      <div id="login-page-wrapper">
         <CircularLoader show={animate}></CircularLoader>
         {errorPopup !== 'none' && (
            <PopUps
               type="login"
               errorType={errorPopup}
               onSubmit={() => chooseErrorPopup(errorPopup).onSubmit()}
               onClose={() => chooseErrorPopup(errorPopup).onClose()}
            />
         )}
         <NavBar account2="Account" />
         <div className="account-container use-bootstrap">
            {/* props.token === null ? <Redirect to="/signup" /> : null */}
            {props.login ? (
               <div className={`account-list-cards ${props.token !== null ? 'disable' : ''}`}>
                  <div className="signincard">
                     <div className="email">
                        <FloatingInput
                           label={props.Environment.emailr}
                           value={email}
                           onChange={e => {
                              setemail(e.currentTarget.value);
                           }}
                           error={form_error.email}
                        />
                     </div>
                     <div className="pass">
                        <FloatingInput
                           label={props.Environment.passr}
                           type="password"
                           value={pass}
                           onChange={e => {
                              setpass(e.currentTarget.value);
                           }}
                           error={form_error.pass}
                        />
                     </div>
                     <div
                        className={`forgetpass`}
                        onClick={() => {
                           history.push('/resetpassword');
                        }}
                     >
                        {props.Environment.forgetp}
                     </div>
                  </div>
               </div>
            ) : null}
            {props.login ? (
               <div className={`signin-bt ${props.token !== null ? 'disable' : ''}`}>
                  <Button
                     className="button-red"
                     data={props.Environment.signabel}
                     onClick={() => {
                        OnSignIn();
                     }}
                  />
                  <div className="signintxt">
                     {props.Environment.donthvaccount}{' '}
                     <span
                        onClick={() => {
                           history.push('/signup');
                        }}
                     >
                        {props.Environment.singupp}
                     </span>
                  </div>
               </div>
            ) : null}

            <div
               className={`loyalty-card ${props.token == null ? 'disable' : ''}`}
               onClick={() => {
                  history.push('/heropath');
               }}
            >
               <div className="top">
                  <div className="left-img">
                     <img src={level_info.prev_level} />
                  </div>
                  <div className="progress-cont">
                     <div className="head">
                        <p>{level_info.current_level_name}</p>
                        <span>
                           <img src={`${props.Environment.img_path}makeicons/money.svg`} />x{level_info.points}%
                        </span>
                     </div>
                     <ProgressBar striped={true} animated now={level_info.progress} />
                  </div>
                  <div className="right-img">
                     <img src={level_info.next_level} />
                     <span>
                        <img src={`${Environment.img_path}makeicons/doer-lock.svg`} />
                     </span>
                  </div>
               </div>
               {/*  <div className="bottom">
                     <div className="img-cont">
                        <img src={`${props.Environment.img_path}makeicons/badge1.svg`}></img>
                     </div>
                     <div className="img-cont">
                        <img src={`${props.Environment.img_path}makeicons/placeholder2.svg`}></img>
                     </div>
                  </div> */}
            </div>

            {/* <p className="view-koinz" onClick={() => { history.push("/mykoinz") }}>{props.Environment.check} <span>{props.Environment.koinzandrewards}</span></p> */}

            {props.account ? (
               <div>
                  <div className={`account-list-cards ${props.token === null ? 'disable' : ''}`}>
                     <div className="list-cards">
                        <ul>
                           <li>
                              <Link to="/accountdetails">
                                 <div className="icontxt">
                                    <div className="list-icon">
                                       <img
                                          src={`${props.Environment.img_path}makeicons/ic_account_details.svg`}
                                          alt=""
                                       />
                                    </div>
                                    <div className="list-txt">{props.Environment.account}</div>
                                 </div>
                                 <div>
                                    <img src={`${props.Environment.img_path}makeicons/ic_list_arrow.svg`} alt="" />
                                 </div>
                              </Link>
                           </li>

                           {/* <li>
                           <Link to="/mykoinz">
                              <div className="icontxt">
                                 <div className="list-icon">
                                    <img src={`${props.Environment.img_path}makeicons/ic_my_coinz.svg`} alt="" />
                                 </div>
                                 <div className="list-txt">{props.Environment.mykoinz}</div>
                              </div>
                              <div>
                                 <img src={`${props.Environment.img_path}makeicons/ic_list_arrow.svg`} alt="" />
                              </div>
                           </Link>
                        </li> */}

                           <li>
                              <Link to="/myhistory">
                                 <div className="icontxt">
                                    <div className="list-icon">
                                       <img src={`${props.Environment.img_path}makeicons/ic_history.svg`} alt="" />
                                    </div>
                                    <div className="list-txt">{props.Environment.myhistory}</div>
                                 </div>
                                 <div>
                                    <img src={`${props.Environment.img_path}makeicons/ic_list_arrow.svg`} alt="" />
                                 </div>
                              </Link>
                           </li>

                           <li className="no-bar">
                              <Link to="/badgeinfo">
                                 <div className="icontxt">
                                    <div className="list-icon">
                                       <img src={`${props.Environment.img_path}makeicons/ic_refer_frd.svg`} alt="" />
                                    </div>
                                    <div className="list-txt">{props.Environment.refertfrd}</div>
                                 </div>
                                 <div>
                                    <img src={`${props.Environment.img_path}makeicons/ic_list_arrow.svg`} alt="" />
                                 </div>
                              </Link>
                           </li>
                        </ul>
                     </div>
                  </div>

                  <div className="account-list-cards">
                     <div className="list-cards">
                        <ul>
                           <li className="no-bar">
                              <Link to="/accountsettings">
                                 <div className="icontxt">
                                    <div className="list-icon">
                                       <img src={`${props.Environment.img_path}makeicons/ic_appsetting.svg`} alt="" />
                                    </div>
                                    <div className="list-txt">{props.Environment.appsetting}</div>
                                 </div>
                                 <div>
                                    <img src={`${props.Environment.img_path}makeicons/ic_list_arrow.svg`} alt="" />
                                 </div>
                              </Link>
                           </li>
                        </ul>
                     </div>
                  </div>

                  <div className="account-list-cards">
                     <div className="list-cards">
                        <ul>
                           <li>
                              {/* <Link to="/howitworks">
                              <div className="icontxt">
                                 <div className="list-icon">
                                    <img src={`${props.Environment.img_path}makeicons/ic_howitw.svg`} alt="" />
                                 </div>
                                 <div className="list-txt">{props.Environment.howitwork}</div>
                              </div>
                              <div>
                                 <img src={`${props.Environment.img_path}makeicons/ic_list_arrow.svg`} alt="" />
                              </div>
                           </Link> */}
                           </li>
                           {/* <li className="no-bar">
                           <Link to="/thismonthwinner">
                              <div className="icontxt">
                                 <div className="list-icon">
                                    <img src={`${props.Environment.img_path}makeicons/ic_winnerlist.svg`} alt="" />
                                 </div>
                                 <div className="list-txt">{props.Environment.winners}</div>
                              </div>
                              <div>
                                 <img src={`${props.Environment.img_path}makeicons/ic_list_arrow.svg`} alt="" />
                              </div>
                           </Link>
                        </li> */}
                        </ul>
                     </div>
                  </div>

                  <div className="account-list-cards">
                     <div className="list-cards">
                        <ul>
                           <li>
                              <Link to="/contactus">
                                 <div className="icontxt">
                                    <div className="list-icon">
                                       <img
                                          src={`${props.Environment.img_path}makeicons/ic_maillist.svg`}
                                          alt=""
                                          style={{ marginLeft: '-10px', marginRight: '-10px' }}
                                       />
                                    </div>
                                    <div className="list-txt">{props.Environment.contactus}</div>
                                 </div>
                                 <div>
                                    <img src={`${props.Environment.img_path}makeicons/ic_list_arrow.svg`} alt="" />
                                 </div>
                              </Link>
                           </li>
                           <li className="no-bar">
                              <Link to="/aboutapp">
                                 <div className="icontxt">
                                    <div className="list-icon">
                                       <img src={`${props.Environment.img_path}makeicons/ic_aboutapp.svg`} alt="" />
                                    </div>
                                    <div className="list-txt">{props.Environment.aboutapp}</div>
                                 </div>
                                 <div>
                                    <img src={`${props.Environment.img_path}makeicons/ic_list_arrow.svg`} alt="" />
                                 </div>
                              </Link>
                           </li>
                        </ul>
                     </div>
                  </div>

                  <div className={`account-list-cards ${props.token === null ? 'disable' : ''}`}>
                     <div className="list-cards">
                        <ul>
                           <li className="no-bar">
                              <Link
                                 onClick={() => {
                                    localStorage.removeItem('cartid');
                                    localStorage.removeItem('token');
                                    localStorage.removeItem('info');
                                    // $('html, body').animate({
                                    //    scrollTop: $(".fds").offset().top
                                    // }, 700);
                                    window.scrollTo(0, 0);
                                    dispatch({ type: LOGOUT });
                                    history.push('/');
                                 }}
                              >
                                 <div className="icontxt">
                                    <div className="list-icon">
                                       <img
                                          src={`${props.Environment.img_path}makeicons/logout.svg`}
                                          alt=""
                                          style={{ marginLeft: '-10px', marginRight: '-10px' }}
                                       />
                                    </div>
                                    <div className="list-txt">{props.Environment.logout}</div>
                                 </div>
                                 <div>
                                    <img src={`${props.Environment.img_path}makeicons/ic_list_arrow.svg`} alt="" />
                                 </div>
                              </Link>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            ) : null}
         </div>
      </div>
   );
};

const mapStateToProps = state => {
   return {
      token: state.auth.token,
      userinfo: state.auth.userinfo,
   };
};

export default connect(mapStateToProps)(Account);
