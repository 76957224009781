import { SETALLPRODUCTS, SETDESKTOPPRODUCTS, SETBANNERS, SETCATEGORIES, SETPRODBYCAT, GETCURRENCYDATA, SETPRIZESNEXTMONTH, SETCATEGORY, FETCH_WINNER, SETPREVROUTE, SETDEVICEIFO, SETCAMPAIGNMANAGER, SETENDINGSOONPRODUCTS, SETREFERID, SETLANG, SETRENDER, SETPOPUPDATA, SETREWARDS, SETRECALL, SETNOTIFICATION, ADDNOTIFICATION, REMOVENOTIFICATION, SETMASTERDATA, SETNOTIFICATIONLISTING, SETPREVTAB, SETCAMPAIGNBYCATEGORY } from "../actions/home/actionTypes"

const initialState = {
    allproducts: [],
    banners: [],
    currency: [],
    categories: [],
    prodbycat: [],
    prizesnextmonth: [],
    endingsoon: [],
    category: null,
    winnerList: [],
    campaign_manager: [],
    desktop_products: [],
    campaign_by_category: [],
    master_api: null,
    prevRoute: null,
    prevTab: null,
    device_token: undefined,
    device_type: undefined,
    refer_id: undefined,
    language: "en",
    render: false,
    popup: {
        render: true,
        show: false,
        text: null,
        img: ''
    },
    rewards: [],
    recall: true,
    notiList: [],
    notificationlisting: [],
    campaign_by_category: []
};

const HomeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GETCURRENCYDATA: return {
            ...state,
            currency: action.payload
        };
        case SETALLPRODUCTS: return {
            ...state,
            allproducts: action.payload
        };
        case SETBANNERS: return {
            ...state,
            banners: action.payload
        };
        case SETCATEGORIES: return {
            ...state,
            categories: action.payload,
            category: action.payload[0]
        };
        case SETPRODBYCAT: return {
            ...state,
            prodbycat: action.payload
        };
        case SETPRIZESNEXTMONTH: return {
            ...state,
            prizesnextmonth: action.payload
        };
        case SETCATEGORY: return {
            ...state,
            category: action.payload
        }
        case FETCH_WINNER: return {
            ...state,
            ...action  //ASK THANVEER
        }
        case SETPREVROUTE: return {
            ...state,
            prevRoute: action.payload
        }
        case SETPREVTAB: return {
            ...state,
            prevTab: action.payload
        }
        case SETDEVICEIFO: return {
            ...state,
            device_token: action.payload.device_token,
            device_type: action.payload.device_type
        }
        case SETCAMPAIGNMANAGER: return {
            ...state,
            campaign_manager: action.payload
        }
        case SETENDINGSOONPRODUCTS: return {
            ...state,
            endingsoon: action.payload
        }
        case SETREFERID: return {
            ...state,
            refer_id: action.payload
        }
        case SETLANG: return {
            ...state,
            language: action.payload
        }
        case SETDESKTOPPRODUCTS: return {
            ...state,
            desktop_products: action.payload
        }
        case SETRENDER: return {
            ...state,
            render: !state.render
        }
        case SETPOPUPDATA: return {
            ...state,
            popup: action.payload
        }
        case SETREWARDS: return {
            ...state,
            rewards: action.payload
        }
        case SETRECALL: return {
            ...state,
            recall: !state.recall
        }
        case SETNOTIFICATION: return {
            ...state,
            notiList: action.payload
        }
        case ADDNOTIFICATION: return {
            ...state,
            notiList: [...state.notiList, action.payload]
        }
        case REMOVENOTIFICATION: return {
            ...state,
            notiList: state.notiList.filter(item => item.prize_id != action.payload)
        }
        case SETMASTERDATA: return {
            ...state,
            master_api: action.payload
        }
        case SETNOTIFICATIONLISTING: return {
            ...state,
            notificationlisting: action.payload
        }
        case SETCAMPAIGNBYCATEGORY: return {
            ...state,
            campaign_by_category: action.payload
        }
        default: return state

    }
}

export default HomeReducer
