import React, { useRef, useState, useEffect } from 'react';
import { Environment } from '../../env';
import Button from '../../components/Button/Button';
import { TweenMax } from 'gsap';
import { Swipeable } from 'react-swipeable';
import { useHistory } from 'react-router-dom';
import { Tween } from 'react-gsap';
import { isObjEmpty } from '../../utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import { SetCurrency } from '../../redux/actions/account/actions';
import { GetCurrencyData } from "../../redux/actions/home/actions"
import { get } from "lodash"

const ActionSheetCurrency = props => {
   const [error, setError] = useState({});
   const store = useSelector(store => store)
   const [active, setActive] = useState(false);
   const SheetAddress = useRef(null);
   const history = useHistory();
   // const [currencydata, setcurrencydata] = useState([])
   const currency = useSelector(state => state.account.currency);
   const [curr, setcurr] = useState(currency);
   const dispatch = useDispatch();
   const isEditMode = props.location.query ? !!props.location.query.address : false;

   const handleGoBack = () => {
      dispatch(SetCurrency(curr));
      setActive(false);
      TweenMax.fromTo(SheetAddress.current, Environment.popupBackground.duration, { y: 0 }, { y: 1000 });
      setTimeout(() => {
         props.history.push({
            pathname: '/accountsettings',
            state: {
               data: null
            }
         });
      }, 700);
   };

   useEffect(() => {
      document.body.style.overflow = 'hidden';
      TweenMax.fromTo(SheetAddress.current, Environment.popupBackground.duration, { y: 600 }, { y: 0 });
      setTimeout(() => {
         setActive(true);
      }, 700);
      return () => (document.body.style.overflow = 'visible');
   }, []);

   useEffect(() => {

      if (get(store, 'auth.token', null) != null) {
         GetCurrencyexchangedata()
      }

   }, [])

   const GetCurrencyexchangedata = async () => {
      // dispatch(GetCurrencyData());
      let data = await dispatch(GetCurrencyData());

      console.log(data);
      // setcurrencydata(data.exchange_rates)
   }

   useEffect(() => {
      document.addEventListener('click', evt => {
         const flyoutElement = document.querySelector('.action-container');
         let targetElement = evt.target; // clicked element

         if (targetElement == flyoutElement) {
            handleGoBack();
            return;
         }
      });
   }, []);

   return (
      
      <Swipeable onSwipedDown={handleGoBack}>
         <div
            className="action-container currency-cont"
            ref={SheetAddress}
            style={{ backgroundColor: active ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0)' }}>
            <Tween
               from={{
                  y: '100%',
                  opacity: 1,
                  delay: props.Environment.popupContainer.delay,
                  duration: props.Environment.popupContainer.duration,
               }}
            >
               <div className="action-cards action-cards121">
                  <button onClick={() => {
                     history.goBack();
                  }} type="button" class="close1231" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">×</span>
                  </button>
                  <div className="cards-icon">
                     <img src={`${Environment.img_path}makeicons/ic_currency.svg`} alt="" />
                  </div>

                  {get(store, 'home.currency', []).map((item, index) => {
                     // {currencydata.map((item, index) => {
                     return (
                        <div>
                           <div className="main-language-container">
                              <div
                                 className="saved-cards-list-container"
                                 onClick={(e) => {
                                    setcurr(item.currency_to);


                                    // if (item.currency_to[0] == "A") {
                                    //    setcurr("A");
                                    // }
                                    // if (item.currency_to[0] == "U") {
                                    //    setcurr("U");
                                    // }
                                    //  var camp = campaign_manager.find(camp => camp.campaign_product_id == data.id);
                                    console.log(item.currency_to);
                                    console.log(store.home.currency);
                                 }}
                              >
                                 <input
                                    type="radio"
                                    name="cardlist"
                                    className="saved-cards-list"
                                    id={item.currency_to}
                                    checked={curr === item.currency_to ? true : false}
                                 />
                                 <label className="card-number-label" htmlFor={item.currency_to}>
                                    <div className="card-container-main">
                                       <div>{item.currency_to}</div>
                                       <div className="checked-img">
                                          <div></div>
                                       </div>
                                       <div className="radio"></div>
                                    </div>
                                 </label>
                              </div>

                           </div>
                        </div>
                     );

                  })}

                  <div className="">
                     <Button
                        className="button-red"
                        data={props.Environment.save}
                        onClick={() => {
                           handleGoBack();
                        }}
                     />
                  </div>
               </div>
            </Tween>
         </div>
      </Swipeable>
   );
};

export default ActionSheetCurrency;
