import { ADDTOCART, VIEWCART, UPDATECART, DELETEITEM, SHIPPINGINFO, ORDERID, REPLACEPRODUCT } from "../cart/actionTypes"
import { SETCARTID } from "../auth/actionTypes"
import AuthApi from "../../../api/AuthApi"
import Cookie from "js-cookie"
import { Environment as env_en } from "../../../env"
import { Environment as env_ar } from "../../../env_ar"

export const CreateQuote = () => async dispatch => {
  try {
    const res = await AuthApi({
      method: 'POST',
      url: '/guest-carts'
    });

    dispatch({
      type: SETCARTID,
      payload: res.data
    });

    localStorage.setItem("cartid", res.data)//sanchit
    console.log(res.data)
    return res.data;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export const GuestAddToCart = (cartid, sku, product_type = undefined, option_id = undefined, option_value = undefined, item = undefined) => async dispatch => {
  try {
    let data = {};
    if (product_type == "configurable") {
      data = {
        item_id: null,
        sku: item,
        qty: 1,
        name: '',
        price: 100,
        product_type,
        quote_id: cartid,
      };
    }
    else {
      data = {
        item_id: null,
        sku,
        qty: 1,
        name: '',
        price: 100,
        product_type,
        quote_id: cartid,
      };
    }

    // let data = {
    //   item_id: null,
    //   sku,
    //   qty: 1,
    //   name: "",
    //   price: 100,
    //   product_type,
    //   quote_id: cartid
    // }

    dispatch({
      type: ADDTOCART,
      payload: data
    });

    const res = await AuthApi({
      method: 'POST',
      url: '/guest-carts/' + cartid + '/items',
      data: {
        cartItem: {
          sku: sku,
          qty: 1,
          quote_id: cartid,
          product_type,
          product_option: {
            extension_attributes: {
              configurable_item_options: [
                {
                  option_id,
                  option_value
                }
              ]
            }
          }
        }
      }
    });

    dispatch({
      type: REPLACEPRODUCT,
      payload: res.data
    });

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export const GuestViewCart = cartid => async (dispatch, getState) => {

  let base_url = localStorage.getItem('langage') == "ar" ? env_ar : env_en

  try {
    const res = await AuthApi({
      baseURL: base_url.base_url,
      method: 'GET',
      url: '/guest-carts/' + cartid
    });

    if (res.data.items !== undefined) {
      dispatch({
        type: VIEWCART,
        payload: res.data
      });
    }

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export const GuestDeleteCartItem = (item_id, cartid) => async dispatch => {

  try {

    dispatch({
      type: DELETEITEM,
      payload: item_id
    });

    const res = await AuthApi({
      method: 'DELETE',
      url: '/guest-carts/' + cartid + '/items/' + item_id
    });

    if (res.data) {

    }

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export const GuestUpdateCartItem = (cartid, sku, qty, item_id, product_type = undefined, option_id = undefined, option_value = undefined) => async dispatch => {

  dispatch({
    type: UPDATECART,
    payload: {
      item_id,
      qty
    }
  })

  try {
    const res = await AuthApi({
      method: 'PUT',
      url: '/guest-carts/' + cartid + '/items/' + item_id,
      data: {
        cartItem: {
          sku: sku,
          qty: qty,
          quote_id: cartid,
          product_type,
          product_option: {
            extension_attributes: {
              configurable_item_options: [
                {
                  option_id,
                  option_value
                }
              ]
            }
          }
        }
      }
    });

    /* dispatch({
      type: UPDATECART,
      payload: res.data
    });  */

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

//checkout methods
export const GuestAddShippingInfo = (cart_id, data) => {
  return (dispatch) => {
    AuthApi.post('/guest-carts/' + cart_id + '/shipping-information', {
      addressInformation: {
        shipping_address: {
          country_id: data.country_id,
          street: data.street,
          city: data.city,
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email,
          telephone: data.telephone,
          save_in_address_book: data.save_in_address_book,
        },
        billing_address: {
          country_id: data.country_id,
          street: data.street,
          city: data.city,
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email,
          telephone: data.telephone,
        },
        shipping_carrier_code: 'flatrate',
        shipping_method_code: 'flatrate',
      },
    })
      .then(res => {
        dispatch({ type: SHIPPINGINFO, payload: res.data });
        console.log('came here' + res.data);
        //dispatch here
      })
      .catch(error => {
        //dispatch error here
        console.log(error);
      });
  }
}

//order placed
export const PaymentInfo = (token, data) => {
  return (dispatch) => {
    AuthApi.post("/carts/mine/payment-information", {
      paymentMethod: {
        method: data.paymentmethod
      },
      billing_address: {
        email: data.email,
        country_id: data.country_id,
        street: data.street,
        city: data.city,
        firstname: data.firstname,
        lastname: data.lastname,
        telephone: data.telephone
      }
    }
      , {
        headers: {
          Authorization: "Bearer " + token,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        }
      }).then((res) => {
        dispatch({ type: ORDERID, payload: res.data })
        console.log("order placed, ID :" + res.data)
        //dispatch here
      }).catch((error) => {
        //dispatch error here
        console.log(error)
      })
  }
}
