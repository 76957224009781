import React, { useRef } from "react"

const QuestionComponent = (props) => {

    const question = useRef([])
    const arrow = useRef([])

    const HandleClick = (index) => {
        console.log(question.current[index].style.height)
        if (question.current[index].style.height == "" || question.current[index].style.height == "45px") {
            question.current[index].style.height = "auto"
            arrow.current[index].style.transform = "rotate(180deg)"
        }
        else {
            question.current[index].style.height = "45px"
            arrow.current[index].style.transform = "rotate(0deg)"
        }

    }

    return (
        <div className="aboutkanzi-container">
            <div className="top-questions-card-container">
                <div className="top-questions">{props.title}</div>
                {props.questions.map((item, index) => (
                    <div className="questions-list" ref={(e) => { question.current[index] = e }} onClick={() => { HandleClick(index) }}>
                        {item.question}
                        <img src={`${props.Environment.img_path}makeicons/arrow-point-to-right.svg`} ref={(e) => { arrow.current[index] = e }} />
                        {/* <div className="answers-list">{item.answer}</div> */}
                        <div
                            dangerouslySetInnerHTML={{
                                __html: item.answer == undefined ? '' : item.answer,
                            }}
                        />
                    

                    </div>
                ))}
            </div>
        </div>
    )
}

export default QuestionComponent