//import Loader from 'react-loader-spinner'
import React from "react"
import Loader from "./Loader"
import { Environment } from "../../env"

const CircularLoader = (props) => {
    return (
        <div className={`screenloader-parent ${!props.show ? "stop-anim" : ""}`} style={{ backgroundColor: props.back ? "transparent" : "rgba(0,0,0,0.3)" }}>
            {/* <img src={`${Environment.img_path}images/kanzi-loader-v2.gif`} /> */}
            <Loader style={{ margin: '10px auto 60px' }} />
            {/* <Loader
                type="BallTriangle"
                color="#00e87b"
                height={60}
                width={60}
            //timeout={3000} //3 secs
            /> */}
        </div>
    )
}

export default CircularLoader