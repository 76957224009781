import axios from "axios";
import { Environment } from "../env";
import addOAuthInterceptor from "axios-oauth-1.0a";

export const algorithm = 'HMAC-SHA1';
export const key = 'd41f578e61a346de945e2cdf6052dc86';
export const secret = 'caf63a632f444be78e065e5e6c5d3872';

const client = axios.create({
  baseURL: process.env.NODE_ENV === 'development'
    ? Environment.membo_url : Environment.membo_url
});

/* addOAuthInterceptor(client, {
    key, secret, algorithm,
}); 
 */
export default client;
