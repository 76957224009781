import React, { useEffect, useState } from 'react';
import Button from '../../components/Button/Button';
import { Environment } from '../../env';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { AddToCart, DeleteCartItem, UpdateCartItem } from '../../redux/actions/cart/actions';
import { SetTipRead } from '../../redux/actions/home/actions';
import { Environment as en } from '../../env';
import { Environment as ar } from '../../env_ar';
import Loader from '../../components/Loader/Loader';
import GetApi from '../../api/GetApi';
import CircularLoader from '../../components/Loader/CircularLoader';
import ReactPixel from 'react-facebook-pixel';
import $ from 'jquery';
import { useLockBodyScroll, getParentSku } from '../../utils/Function';
import {
   CreateQuote,
   GuestAddToCart,
   GuestDeleteCartItem,
   GuestUpdateCartItem,
} from '../../redux/actions/guestcart/actions';

const Tip = props => {
   useLockBodyScroll();

   const { details, onSubmit, onClose, unsubscribe } = props;
   const dispatch = useDispatch();
   const store = useSelector(store => store);
   const [file, setfile] = useState(en);

   useEffect(() => {
      $('.fds').addClass('stopscrol');
      if (get(store, 'home.language', 'en') == 'en') {
         setfile(en);
      } else {
         setfile(ar);
      }
   }, [get(store, 'home.language', 'en')]);

   console.log(details);

   return (
      <div>
         <div
            className="pop-close"
            onClick={() => {
               dispatch(SetTipRead(details.tips_id));
               onClose();
            }}
         >
            <img src={`${file.img_path}icons/ic_close.svg`} alt="" />
         </div>
         <div className="popup-inside">
            <div className="cards-icon">
               <img src={`${file.img_path}icons/ic_tips.svg`} alt="" />
            </div>
            <div className="cards-title">{file.tipoftheday}</div>
            <p>{details.tip_text}</p>
            <Button
               className="button-red"
               data={file.gotit}
               onClick={() => {
                  dispatch(SetTipRead(details.tips_id));
                  onSubmit();
               }}
            />
            <div className="popups-links poplink">
               <div className="popup-link" onClick={unsubscribe}>
                  {file.optout}
               </div>
               {file.of_future_tips}
            </div>
         </div>
      </div>
   );
};

const VoteForFavorite = props => {
   useLockBodyScroll();
   const { details, onSubmit, onClose, unsubscribe } = props;
   const [option, setOption] = useState(0);
   const store = useSelector(store => store);
   const [file, setfile] = useState(en);

   useEffect(() => {
      $('.fds').addClass('stopscrol');
      if (get(store, 'home.language', 'en') == 'en') {
         setfile(en);
      } else {
         setfile(ar);
      }
   }, [get(store, 'home.language', 'en')]);

   return (
      <div>
         <div className="pop-close" onClick={onClose}>
            <img src={`${file.img_path}icons/ic_close.svg`} alt="" />
         </div>
         <div className="popup-inside">
            <div className="cards-title">{file.voteforfavorite}</div>
            <div className="vote-questions">
               <div className="q-main">
                  {details.poll_options.map((poll, i) => {
                     const alphabet = String.fromCharCode(65 + i);

                     return (
                        <div className="q-list">
                           <input
                              type="radio"
                              name="question"
                              id={'q' + poll.poll_options_id}
                              value={option}
                              onChange={() => setOption(poll.poll_options_id)}
                           />
                           <label className="questions" htmlFor={'q' + poll.poll_options_id}>
                              <span className="alfa-number">{alphabet}</span>
                              {poll.options}
                              <div className="questions-selected">
                                 <img src={`${file.img_path}icons/ic-checkedcard.svg`} alt="" />
                              </div>
                           </label>
                        </div>
                     );
                  })}
               </div>
            </div>
            <div className="form-checkbx">
               <input type="checkbox" id="notify" name="box" />
               <label htmlFor="notify">{file.notifytxt}</label>
            </div>
            <Button
               className="button-red"
               data="Vote now!"
               disabled={option === 0}
               onClick={() => onSubmit([details.polls_id, option])}
            />
            <div className="popups-links poplink">
               <div className="popup-link" onClick={unsubscribe}>
                  {file.optout}
               </div>
               {file.of_future_votes}
            </div>
         </div>
      </div>
   );
};

const TechnicalIssue = props => {
   const { onClose } = props;
   const store = useSelector(store => store);
   const [file, setfile] = useState(en);

   useEffect(() => {
      $('.fds').addClass('stopscrol');
      if (get(store, 'home.language', 'en') == 'en') {
         setfile(en);
      } else {
         setfile(ar);
      }
   }, [get(store, 'home.language', 'en')]);

   return (
      <div>
         <div className="pop-close" onClick={onClose}>
            <img src={`${file.img_path}icons/ic_close.svg`} alt="" />
         </div>
         <div className="popup-inside">
            <div className="cards-icon">
               <img src={`${file.img_path}icons/ic_techissue.svg`} alt="" />
            </div>
            <div
               className="cards-title"
               dangerouslySetInnerHTML={{
                  __html: file.techtext,
               }}
            ></div>
            <p
               dangerouslySetInnerHTML={{
                  __html: file.techbodytxt,
               }}
            ></p>
         </div>
      </div>
   );
};

const OutOfStock = props => {
   useLockBodyScroll();
   const { onSubmit, onClose } = props;
   const store = useSelector(store => store);
   const [file, setfile] = useState(en);

   useEffect(() => {
      $('.fds').addClass('stopscrol');
      if (get(store, 'home.language', 'en') == 'en') {
         setfile(en);
      } else {
         setfile(ar);
      }
   }, [get(store, 'home.language', 'en')]);

   return (
      <div>
         <div className="pop-close" onClick={onClose}>
            <img src={`${file.img_path}icons/ic_close.svg`} alt="" />
         </div>
         <div className="popup-inside">
            <div className="cards-title">{file.oohnotxt}</div>
            <p>{file.oohnobodytxt}</p>
            <Button className="button-red" data="Go to cart" onClick={onSubmit} />
         </div>
      </div>
   );
};

const NoInternet = props => {
   const { onSubmit } = props;
   const store = useSelector(store => store);
   const [file, setfile] = useState(en);
   const { onClose } = props;
   const history = useHistory();

   useEffect(() => {
      $('.fds').addClass('stopscrol');
      if (get(store, 'home.language', 'en') == 'en') {
         setfile(en);
      } else {
         setfile(ar);
      }
   }, [get(store, 'home.language', 'en')]);

   return (
      <div>
         {/* <div className="pop-close" onClick={() => {
            history.push("/")
         }} >
            <img src={`${file.img_path}icons/ic_close.svg`} alt="" />
         </div> */}
         <div className="popup-inside">
            <div className="cards-icon">
               <img src={`${file.img_path}icons/ic_nointernet.svg`} alt="" />
            </div>
            <div className="cards-title">{file.nointernet}</div>
            <p>{file.nointernetbodytxt}</p>
            {/* <Button className="button-red" data="Refresh" onClick={onSubmit} /> */}
         </div>
      </div>
   );
};
const Whatarekoinz1 = props => {
   // alert("d")
   useLockBodyScroll();
   const { onSubmit, onClose } = props;
   const store = useSelector(store => store);
   const [file, setfile] = useState(en);

   useEffect(() => {
      $('.fds').addClass('stopscrol');
      if (get(store, 'home.language', 'en') == 'en') {
         setfile(en);
      } else {
         setfile(ar);
      }
   }, [get(store, 'home.language', 'en')]);

   return (
      <div className="what-are-koinz">
         <div className="pop-close" onClick={onClose}>
            <img src={`${file.img_path}icons/ic_close.svg`} alt="" />
         </div>
         <div className="popup-inside">
            <div className="cards-icon">
               <img src={`${file.img_path}icons/what1.svg`} alt="" />
            </div>
            <div className="cards-title">
               {file.whatswipe}
               <br />
               {file.whatswipesecdesc}
            </div>
            <p className="koins-firsts">{file.whatswipedescription11}</p>

            <p className="koins-firsts">{file.whatswipedescription}</p>

            <p className="koins-firsts">{file.whatswipdeconfdesctpion}</p>

            <Button className="button-red" data={file.okthanks} onClick={onSubmit} />
            <div className="popups-links poplink">
               {file.learnmorein}
               <Link to="/faqs"> {file.faqs}.</Link>
            </div>
         </div>
      </div>
   );
};

const Termspagesds = props => {
   const [data, setData] = useState('');
   const store = useSelector(store => store);
   const [displayLoader, setDisplayLoader] = useState(true);
   const { onSubmit, onClose } = props;
   const [file, setfile] = useState(en);

   useEffect(() => {
      console.log('useEffect');
      getContent();
   }, []);

   const getContent = async () => {
      let url;
      if (localStorage.getItem('langage') === 'en') {
         url = '/cmsPage/5';
      } else {
         url = '/cmsPage/6';
      }
      try {
         setDisplayLoader(true);
         const res = await GetApi({
            baseURL: Environment.base_url,
            method: 'GET',
            url: url,
            headers: {
               crossorigin: true,
            },
         });
         // setDisplayLoader(false);
         if (res.data.content) {
            // alert("ds")
            setDisplayLoader(false);
            setData(res.data.content);
         }
      } catch (e) {
         setDisplayLoader(true);
         setData('<p>Something went wrong while fetching Draw Terms & Conditions</p>');
      }
   };
   return (
      <div className="">
         {!displayLoader ? (
            <div className="what-are-koinz what-are-koinzterms">
               <div className="pop-close" onClick={onClose}>
                  <img src={`/${file.img_path}icons/ic_close.svg`} alt="" />
               </div>
               <div className="popup-inside">
                  {data && (
                     <span className="card-content card-content32" dangerouslySetInnerHTML={{ __html: data }}></span>
                  )}
               </div>
            </div>
         ) : (
            <Loader />
         )}

         {/* <NavBar tnc="tnc" /> */}
         {/* <div className="drawtnc-main-container">
               <div className="drawtnc-card">
                  {data && <span className="card-content" dangerouslySetInnerHTML={{ __html: data }}></span>}
               </div>
         </div> */}
      </div>
   );
};

const Whatarekoinz = props => {
   useLockBodyScroll();
   const { onSubmit, onClose } = props;
   const store = useSelector(store => store);
   const [file, setfile] = useState(en);

   useEffect(() => {
      $('.fds').addClass('stopscrol');
      if (get(store, 'home.language', 'en') == 'en') {
         setfile(en);
      } else {
         setfile(ar);
      }
   }, [get(store, 'home.language', 'en')]);

   return (
      <div className="what-are-koinz">
         <div className="pop-close" onClick={onClose}>
            <img src={`${file.img_path}icons/ic_close.svg`} alt="" />
         </div>
         <div className="popup-inside">
            <div className="cards-icon">
               <img src={`${file.img_path}icons/ic_money.svg`} alt="" />
            </div>
            <div className="cards-title">{file.koinz}</div>
            <p className="site-e">
               Koinz are rewards points that you can use to shop and win. You can use your Koinz balance to take part in
               our campaigns for a chance to win a prize of your choice. The more Koinz you earn, the more you can
               spend!
            </p>
            <p className="site-a popid">
               كوينز هي نقاط مكافآت كنزي التي يمكنك استخدامها للتسوق والفوز. ستحصل على كوينز مع كل شراء لاستخدامها
               كوسيلة دفع عند تسوقك ومشاركتك في حملاتنا الرائعة. كلما زاد رصيد الكوينز لديك، أصبح بإمكانك التسوق أكثر
               وأكثر
            </p>
            <Button className="button-red" data={file.okthanks} onClick={onSubmit} />
            <div className="popups-links poplink">
               {file.learnmorein}
               <Link to="/"> {file.faqs}.</Link>
            </div>
         </div>
      </div>
   );
};

const KoinzaCount = props => {
   useLockBodyScroll();
   const { onSubmit, onClose } = props;
   const [count, setCount] = useState(0);
   const store = useSelector(store => store);
   const [file, setfile] = useState(en);

   useEffect(() => {
      $('.fds').addClass('stopscrol');
      if (get(store, 'home.language', 'en') == 'en') {
         setfile(en);
      } else {
         setfile(ar);
      }
   }, [get(store, 'home.language', 'en')]);

   return (
      <div>
         <div className="pop-close" onClick={onClose}>
            <img src={`${file.img_path}icons/ic_close.svg`} alt="" />
         </div>
         <div className="popup-inside">
            <div className="cards-icon">
               <img src={`${file.img_path}icons/ic_money.svg`} alt="" />
            </div>
            <div className="cards-title">
               {file.youhave} 2 {file.product}
            </div>
            <div className="">
               <div className="ticket-plus-minus">
                  <img
                     src={`${file.img_path}icons/ic_minus.svg`}
                     alt=""
                     onClick={() => setCount(prevState => prevState - 1)}
                  />
                  <p>{count}</p>
                  <img
                     src={`${file.img_path}icons/ic_plus.svg`}
                     alt=""
                     onClick={() => setCount(prevState => prevState + 1)}
                  />
               </div>
            </div>
            <Button className="button-red" data={file.done} onClick={onSubmit} />
         </div>
      </div>
   );
};

const LoginError = props => {
   const { errorType, onClose, onSubmit } = props;
   const store = useSelector(store => store);
   const [file, setfile] = useState(en);

   useEffect(() => {
      $('.fds').addClass('stopscrol');
      if (get(store, 'home.language', 'en') == 'en') {
         setfile(en);
      } else {
         setfile(ar);
      }
   }, [get(store, 'home.language', 'en')]);

   return (
      <div>
         <div className="pop-close" onClick={onClose}>
            <img src={`${file.img_path}icons/ic_close.svg`} alt="" />
         </div>
         <div className="popup-inside">
            <div className="cards-icon">
               <img src={`${file.img_path}icons/ic_awkward.svg`} alt="" />
            </div>

            {errorType === 'register_email' || errorType === 'register_phone' ? (
               <div>
                  <div className="cards-title">{file.awkward11}</div>
                  {errorType === 'incorrect_phonenumber' && <p>{file.weromga}</p>}
                  {/* {errorType === 'contactus' && <p>{file.weromga}</p>} */}
                  {errorType === 'incorrect_login' && <p>{file.wrnge}</p>}
                  {errorType === 'login_no_account' && <p>{file.awkwardbodytxt}</p>}
                  {errorType === 'register_email' && <p>{file.awkwardbodytxtforemail}</p>}
                  {errorType === 'register_phone' && <p>{file.awkwardbodytxtforphone}</p>}
                  <Button className="button-red" data={file.gotit} onClick={onSubmit} />
                  <div className="popups-links poplink">
                     {file.contacttxt}&nbsp;
                     <Link to="/contactus">{file.customercarehere}</Link>
                  </div>
               </div>
            ) : (
               <div>
                  <div className="cards-title">{file.awkward}</div>
                  {errorType === 'incorrect_phonenumber' && <p>{file.weromga}</p>}
                  {errorType === 'incorrect_login' && <p>{file.wrnge}</p>}
                  {errorType === 'login_no_account' && <p>{file.awkwardbodytxt}</p>}
                  {errorType === 'register_email' && <p>{file.awkwardbodytxtforemail}</p>}
                  {errorType === 'register_phone' && <p>{file.awkwardbodytxtforphone}</p>}
                  <Button className="button-red" data={file.gotit} onClick={onSubmit} />
                  <div className="popups-links poplink">
                     {file.contacttxt}&nbsp;
                     <Link to="/contactus">{file.customercarehere}</Link>
                  </div>
               </div>
            )}
         </div>
      </div>
   );
};

const ErrorModal = props => {
   const { errorType, onClose, onSubmit, errorMsg } = props;
   const store = useSelector(store => store);
   const [file, setfile] = useState(en);

   useEffect(() => {
      $('.fds').addClass('stopscrol');
      if (get(store, 'home.language', 'en') == 'en') {
         setfile(en);
      } else {
         setfile(ar);
      }
   }, [get(store, 'home.language', 'en')]);

   return (
      <div>
         <div className="pop-close" onClick={onClose}>
            <img src={`${file.img_path}icons/ic_close.svg`} alt="" />
         </div>
         <div className="popup-inside">
            <div className="cards-icon">
               <img src={`${file.img_path}icons/ic_awkward.svg`} alt="" />
            </div>

            <div>
               <div className="cards-title">{file.awkward}</div>
               <p>{errorMsg}</p>
               {/* <Button className="button-red" data={file.gotit} onClick={onSubmit} />
                  <div className="popups-links poplink">
                     {file.contacttxt}&nbsp;
               <Link to="/contactus">{file.customercarehere}</Link>
                  </div> */}
            </div>
         </div>
      </div>
   );
};

const LoayaltyPopup = props => {
   useLockBodyScroll();
   const { errorType, onClose, onSubmit } = props;
   const history = useHistory();
   const store = useSelector(store => store);
   const [file, setfile] = useState(en);

   useEffect(() => {
      if (get(store, 'home.language', 'en') == 'en') {
         setfile(en);
      } else {
         setfile(ar);
      }
   }, [get(store, 'home.language', 'en')]);

   return (
      <div className="loyalty-container cont1">
         <div className="pop-close" onClick={onClose}>
            <img src={`${file.img_path}icons/ic_close.svg`} alt="" />
         </div>
         <div className="popup-inside">
            <div className="cards-icon">
               <img src={props.data.url} alt="" />
            </div>
            <div className="cards-title">Congratulations!</div>

            <p
               className="txt1"
               dangerouslySetInnerHTML={{
                  __html: props.data.message == undefined ? '' : props.data.message.value,
               }}
            />

            {/* <p className="txt1">
            {props.data.message}
               </p> */}
            <p className="txt2">
               x{props.data.reward}٪ <span>KOINZ</span>
            </p>
            <p className="txt3">on every purchase</p>
            <Button
               className="button-red"
               data="Buy"
               onClick={() => {
                  history.push('/');
               }}
            />
            <div className="popups-links poplink">
               View
               <Link to="/mykoinz"> My Koinz and Rewards</Link>
            </div>
         </div>
      </div>
   );
};

const LoayaltyPopup1 = props => {
   useLockBodyScroll();
   const { errorType, onClose, onSubmit } = props;
   const history = useHistory();
   const store = useSelector(store => store);
   const [file, setfile] = useState(en);

   useEffect(() => {
      if (get(store, 'home.language', 'en') == 'en') {
         setfile(en);
      } else {
         setfile(ar);
      }
   }, [get(store, 'home.language', 'en')]);

   return (
      <div className="loyalty-container">
         <div className="pop-close" onClick={onClose}>
            <img src={`${file.img_path}icons/ic_close.svg`} alt="" />
         </div>
         <div className="popup-inside">
            <div className="cards-icon">
               <img src={`${file.img_path}icons/popupimg2.svg`} alt="" />
            </div>
            <div className="cards-title">Hey!</div>
            <p className="txt1">
               You are so close to win a new badge and free Koinz.
               <br />
               <br />
               Participate <span>5 times</span> in the same campaign to earn the badge!
            </p>
            <Button
               className="button-red"
               data="Participate"
               onClick={() => {
                  history.push('/');
               }}
            />
         </div>
      </div>
   );
};

const LoayaltyPopup2 = props => {
   useLockBodyScroll();
   const { errorType, onClose, onSubmit } = props;
   const history = useHistory();
   const store = useSelector(store => store);
   const [file, setfile] = useState(en);
   let image_url = props.data.image.trim().length == 0 ? `${file.img_path}icons/popupimg3.svg` : props.data.image;

   console.log(props);

   useEffect(() => {
      if (get(store, 'home.language', 'en') == 'en') {
         setfile(en);
      } else {
         setfile(ar);
      }
   }, [get(store, 'home.language', 'en')]);

   return (
      <div className="loyalty-container">
         <div className="pop-close" onClick={onClose}>
            <img src={`${file.img_path}icons/ic_close.svg`} alt="" />
         </div>
         <div className="popup-inside">
            <div className="cards-icon">
               <img src={image_url} alt="" />
            </div>
            <div className="cards-title">Congratulations!</div>
            <p
               className="txt1 extra"
               dangerouslySetInnerHTML={{
                  __html: props.data.message == undefined ? '' : props.data.message.value,
               }}
            />

            {/* <p className="txt1 extra">{props.data.message}</p> */}
            <div className="popups-links poplink">
               View&nbsp;
               <Link to="/mykoinz">My Koinz and Rewards</Link>
            </div>
         </div>
      </div>
   );
};
const Contactus = props => {
   const { errorType, onClose, onSubmit } = props;
   const history = useHistory();
   const store = useSelector(store => store);
   const [file, setfile] = useState(en);
   // let image_url = props.data.image.trim().length == 0 ? `${file.img_path}icons/popupimg3.svg` : props.data.image

   console.log(props);

   useEffect(() => {
      if (get(store, 'home.language', 'en') == 'en') {
         setfile(en);
      } else {
         setfile(ar);
      }
   }, [get(store, 'home.language', 'en')]);

   return (
      <div className="loyalty-container contacima">
         <div
            className="pop-close"
            onClick={() => {
               history.push('/');
            }}
         >
            <img src={`${file.img_path}icons/ic_close.svg`} alt="" />
         </div>
         <div className="popup-inside">
            <div class="cards-icon">
               <img src="./images/ic_success.svg" alt="" />
            </div>
            <div className="cards-title">{file.msent}</div>
            <p className="txt1 extra">{file.contactusucess}</p>
            {/* <div className="popups-links poplink">
               <Link to="/">Shop Now</Link>
            </div> */}
         </div>
      </div>
   );
};

const SizeSelection = props => {
   useLockBodyScroll();

   const { errorType, onClose, onSubmit } = props;
   const [products, setproducts] = useState([]);
   const [active, setactive] = useState(true);
   const [parent, setparent] = useState(null);
   const [added, setadded] = useState([]);
   const store = useSelector(store => store);
   const dispatch = useDispatch();
   const [file, setfile] = useState(en);
   const cart = useSelector(store => store.cart.cart);

   useEffect(() => {
      if (get(store, 'home.language', 'en') == 'en') {
         setfile(en);
      } else {
         setfile(ar);
      }
   }, [get(store, 'home.language', 'en')]);

   useEffect(() => {
      var product = [];

      setparent(props.parent);

      props.details.forEach(items => {
         var prod = get(store, 'home.allproducts', []).find(item => item.id == items);
         var cart_item = get(store, 'cart.cart', []).find(cart => cart.sku == prod.sku);
         if (cart_item == undefined) {
            prod.count = 0;
         } else {
            prod.count = cart_item.qty;
         }
         product.push(prod);
      });

      setproducts(product);
   }, []);

   console.log(parent);
   const addAddtocartFbPixel = parentSku => {
      let isAlreadyAdded = false;
      // const parentSku = element.sku.split('-')[0];
      isAlreadyAdded = cart.some(item => item.sku.includes(parentSku));

      if (!isAlreadyAdded) {
         ReactPixel.track('AddToCart');
      }
   };

   const onAdd = async (item, index) => {
      console.log(item);
      if (!active) return;
      const parentSku = getParentSku(item.sku);
      addAddtocartFbPixel(parentSku);

      setactive(false);

      var option_id;
      var option_value;
      if (parent.extension_attributes.configurable_product_options.find(item => item.label == 'size')) {
         // alert("Size");
         option_id = parent.extension_attributes.configurable_product_options.find(item => item.label == 'size');
         option_value = item.custom_attributes.find(attr => attr.attribute_code == 'size');
      }

      if (parent.extension_attributes.configurable_product_options.find(item => item.label.toLowerCase() == 'color')) {
         option_id = parent.extension_attributes.configurable_product_options.find(
            item => item.label.toLowerCase() == 'color'
         );
         option_value = item.custom_attributes.find(attr => attr.attribute_code.toLowerCase() == 'color');
      }

      // var option_id = parent.extension_attributes.configurable_product_options.find(item => (item.label == 'size' || item.label.toLowerCase() == 'color'));
      // var option_value = item.custom_attributes.find(attr => (attr.attribute_code == 'size' || attr.attribute_code.toLowerCase() == 'color'));

      console.log(option_id);
      if (item.count == 0) {
         setadded([...added, index]);
         //add to cart
         if (get(store, 'auth.token', null) !== null) {
            //logged in add to cart
            await dispatch(
               AddToCart(
                  get(store, 'auth.cartid', null),
                  parent.sku,
                  'configurable',
                  option_id != undefined ? option_id.attribute_id : undefined,
                  option_value != undefined ? option_value.value : undefined,
                  item.sku
               )
            );
         } else {
            if (get(store, 'auth.cartid', null) === null) {
               //create cart id for guest then login
               const quote = await dispatch(CreateQuote());
               await dispatch(
                  GuestAddToCart(
                     quote,
                     parent.sku,
                     'configurable',
                     option_id != undefined ? option_id.attribute_id : undefined,
                     option_value != undefined ? option_value.value : undefined,
                     item.sku
                  )
               );
            } else {
               //add to cart for guest
               await dispatch(
                  GuestAddToCart(
                     get(store, 'auth.cartid', null),
                     parent.sku,
                     'configurable',
                     option_id != undefined ? option_id.attribute_id : undefined,
                     option_value != undefined ? option_value.value : undefined,
                     item.sku
                  )
               );
            }
         }
      } else {
         //update
         console.log(item.sku);

         var cart_prod = get(store, 'cart.cart').find(cartitem => cartitem.sku == item.sku);
         console.log(cart_prod);
         // const cartItem = store.cart.cart.find(item => item.sku === props.item.product.sku);

         if (get(store, 'auth.token', null) !== null) {
            dispatch(
               UpdateCartItem(
                  get(store, 'auth.cartid', null),
                  item.sku,
                  cart_prod.qty + 1,
                  cart_prod.item_id,
                  'configurable',
                  option_id != undefined ? option_id.attribute_id : undefined,
                  option_value != undefined ? option_value.value : undefined
               )
            );
         } else {
            dispatch(
               GuestUpdateCartItem(
                  get(store, 'auth.cartid', null),
                  item.sku,
                  cart_prod.qty + 1,
                  cart_prod.item_id,
                  'configurable',
                  option_id != undefined ? option_id.attribute_id : undefined,
                  option_value != undefined ? option_value.value : undefined
               )
            );
         }
      }

      //this checks the total quantity
      /* var total = 0
      products.forEach((item) => { total += item.count }) */

      item.count = item.count + 1;
      var prod = products;
      prod[index] = item;
      setproducts([...prod]);

      setactive(true);

      let temp = [...added];

      temp.filter(i => i != index);

      setadded(temp);
   };

   const onSubtract = async (item, index) => {
      if (!active) return;

      setactive(false);

      // var option_id = parent.extension_attributes.configurable_product_options.find(item => item.label == 'size');
      // var option_value = item.custom_attributes.find(attr => attr.attribute_code == 'size');

      var option_id;
      var option_value;
      if (parent.extension_attributes.configurable_product_options.find(item => item.label == 'size')) {
         // alert("Size");
         option_id = parent.extension_attributes.configurable_product_options.find(item => item.label == 'size');
         option_value = item.custom_attributes.find(attr => attr.attribute_code == 'size');
      }

      if (parent.extension_attributes.configurable_product_options.find(item => item.label.toLowerCase() == 'color')) {
         option_id = parent.extension_attributes.configurable_product_options.find(
            item => item.label.toLowerCase() == 'color'
         );
         option_value = item.custom_attributes.find(attr => attr.attribute_code.toLowerCase() == 'color');
      }

      if (item.count == 0) {
         setactive(true);
         return;
      }
      if (item.count == 1) {
         var cart_prod = get(store, 'cart.cart').find(cartitem => cartitem.sku == item.sku);

         if (get(store, 'auth.token', null) !== null) {
            dispatch(DeleteCartItem(cart_prod.item_id));
         } else {
            dispatch(GuestDeleteCartItem(cart_prod.item_id, get(store, 'auth.cartid', null)));
         }
      } else {
         var cart_prod = get(store, 'cart.cart').find(cartitem => cartitem.sku == item.sku);

         if (get(store, 'auth.token', null) !== null) {
            dispatch(
               UpdateCartItem(
                  get(store, 'auth.cartid', null),
                  parent.sku,
                  cart_prod.qty - 1,
                  cart_prod.item_id,
                  'configurable',
                  option_id != undefined ? option_id.attribute_id : undefined,
                  option_value != undefined ? option_value.value : undefined
               )
            );
         } else {
            dispatch(
               GuestUpdateCartItem(
                  get(store, 'auth.cartid', null),
                  parent.sku,
                  cart_prod.qty - 1,
                  cart_prod.item_id,
                  'configurable',
                  option_id != undefined ? option_id.attribute_id : undefined,
                  option_value != undefined ? option_value.value : undefined
               )
            );
         }
      }

      item.count = item.count - 1;
      var prod = products;
      prod[index] = item;
      setproducts([...prod]);

      setactive(true);
   };

   const OnDone = () => {
      onSubmit();
      if (props.Callback) {
         props.Callback();
      }
   };
   // console.log(parent)

   return (
      <div className="sizeselection">
         <div className="pop-close" onClick={onClose}>
            <img src={`${file.img_path}icons/ic_close.svg`} alt="" />
         </div>
         <div className="popup-inside">
            <div className="cards-title site-e">Select your color or size</div>
            <div className="cards-title fdsdd site-a">إختر اللون أو المقاس</div>
            <div className="sizes-container">
               {products.map((item, index) => {
                  {
                     /* {products.sort((a, b) => (b.sku.split('-')[1] - a.sku.split('-')[1])).map((item, index) => { */
                  }
                  let show = added.find(i => i == index);

                  return (
                     <div className="size-quantity">
                        {/* <span className="shortform">{item.sku.split('-')[1]}</span> */}
                        <span className="sizetype">{item.sku.split('-')[1]}</span>
                        {show == null ? (
                           <span className="container-sizes">
                              <span
                                 className={`minus ${item.count == 0 ? '' : 'active'}`}
                                 onClick={() => {
                                    onSubtract(item, index);
                                 }}
                              >
                                 -
                              </span>
                              <span className="quant">{item.count}</span>
                              <span
                                 className={`plus ${'active'}`}
                                 onClick={() => {
                                    onAdd(item, index);
                                 }}
                              >
                                 +
                              </span>
                           </span>
                        ) : (
                           <span className="red">
                              {' '}
                              <img src={`${Environment.img_path}icons/check.svg`} alt="" />
                              Added
                           </span>
                        )}
                     </div>
                  );
               })}
            </div>
            <Button
               className="button-red"
               data="Done"
               onClick={() => {
                  OnDone();
               }}
            />
         </div>
      </div>
   );
};

const PopUps = ({ type, ...props }) => {
   useEffect(() => {
      $('.fds').addClass('fds433232');
      return () => $('.fds').removeClass('fds433232');
   }, []);
   return (
      <div className={`popups-container fd233f ${type == 'whatarekoinz1' ? 'whatarekoinz132' : ''}`}>
         <div className="popups-cards">
            {type === 'tip' && <Tip {...props} />}
            {type === 'vote' && <VoteForFavorite {...props} />}
            {type === 'issue' && <TechnicalIssue {...props} />}
            {type === 'stock' && <OutOfStock {...props} />}
            {type === 'offline' && <NoInternet {...props} />}
            {type === 'login' && <LoginError {...props} />}
            {type === 'whatarekoinz' && <Whatarekoinz {...props} />}
            {type === 'whatarekoinz1' && <Whatarekoinz1 {...props} />}
            {type === 'termspagesds' && <Termspagesds {...props} />}

            {type === 'koinzacount' && <KoinzaCount {...props} />}

            {type === 'size-selection' && <SizeSelection {...props} />}
            {type === 'loyalty-popup' && <LoayaltyPopup {...props} />}
            {type === 'loyalty-popup1' && <LoayaltyPopup1 {...props} />}
            {type === 'loyalty-popup2' && <LoayaltyPopup2 {...props} />}
            {type === 'contactus' && <Contactus {...props} />}
            {type === 'error' && <ErrorModal {...props} />}
         </div>
      </div>
   );
};

export default PopUps;
