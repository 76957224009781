import React from "react"
import { Environment } from "../../../env";
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { get } from "lodash";

const Collector = (props) => {

    const history = useHistory()
    const store = useSelector(store => store)

    return (
        <div className="collector-parent">
            <div className="head">
                <img src={`${Environment.img_path}makeicons/badge3.svg`} />
                <p>{props.Environment.collector}</p>
            </div>
            <div className="bg">
                <p>
                    {props.Environment.themoreshop}
                </p>

                <div className="table">
                {get(store, 'cart.badgeData.available', []).map((item) => (
                        <div className="column">
                            <div className="row1">
                                <p className="top">{props.Environment.participate} <span>{item.attrs.behaviours[0].behaviour.attrs.metadata.conditions[1].value} {props.Environment.times}</span></p>
                                <p className="bottom">{props.Environment.samecamp}</p>
                            </div>
                            <div className="row2">
                                <p className="top">{props.Environment.earn}</p>
                                <p className="bottom"><span>{item.prizes.points[0].points}</span> {props.Environment.koinz}</p>
                            </div>
                        </div>
                    ))}
                    {/* <div className="column">
                        <div className="row1">
                            <p className="top">Participate <span>5 times</span></p>
                            <p className="bottom">In the same campaign</p>
                        </div>
                        <div className="row2">
                            <p className="top">Earn</p>
                            <p className="bottom">500 <span>Koinz</span></p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="row1">
                            <p className="top">Participate <span>5 times</span></p>
                            <p className="bottom">In the same campaign</p>
                        </div>
                        <div className="row2">
                            <p className="top">Earn</p>
                            <p className="bottom">500 <span>Koinz</span></p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="row1">
                            <p className="top">Participate <span>5 times</span></p>
                            <p className="bottom">In the same campaign</p>
                        </div>
                        <div className="row2">
                            <p className="top">Earn</p>
                            <p className="bottom">500 <span>Koinz</span></p>
                        </div>
                    </div> */}
                </div>

                <h3>{props.Environment.yourac}</h3>

                <div className="list">
                    {props.data.map((item) => {

                        let totalParticipation = 0

                        item.data[0].items.forEach(campaign => {
                            totalParticipation += parseInt(campaign.qty_ordered)
                        })

                        return (
                            <div className="card">
                                <div className="head">
                                    {props.Environment.wina}<span> {item.campaign_prize_name}</span>
                                </div>
                                <div>
                                    <div className="meter-container">
                                        {get(store, 'cart.badgeData.available', []).map((item, index) => {
                                            return (
                                                <div className={`coin${index + 1}`}>
                                                    {/* <img/ src={`${Environment.img_path}makeicons/doercoin.svg`} /> */}
                                                    {item.prizes.points[0].points}
                                                </div>
                                            )
                                        })}
                                        {/* <div className="coin1">
                                            <img src={`${Environment.img_path}makeicons/doercoin.svg`} />
                                            600
                                        </div>
                                        <div className="coin2">
                                            <img src={`${Environment.img_path}makeicons/doercoin.svg`} />
                                            1K
                                        </div>
                                        <div className="coin3">
                                            <img src={`${Environment.img_path}makeicons/doercoin.svg`} />
                                            2K
                                        </div> */}
                                        {get(store, 'cart.badgeData.available', []).map((item, index) => {
                                            return (
                                                <div className={`badge${index + 1}`}>
                                                    <img src={`${Environment.img_path}makeicons/badge6.svg`} />
                                                    <span>{item.attrs.behaviours[0].behaviour.attrs.metadata.conditions[1].value}</span>
                                                </div>
                                            )
                                        })}
                                        {/* <div className="badge1">
                                            <img src={`${Environment.img_path}makeicons/badge6.svg`} />
                                            <span>10</span>
                                        </div>
                                        <div className="badge2">
                                            <img src={`${Environment.img_path}makeicons/badge6.svg`} />
                                            <span>10</span>
                                        </div>
                                        <div className="badge3">
                                            <img src={`${Environment.img_path}makeicons/badge6.svg`} />
                                            <span>10</span>
                                        </div> */}
                                        <span className="p-arrow"></span>
                                        <div className="counter">
                                            {totalParticipation}
                                        </div>
                                        <div className="meter">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="133.456" height="133.473" viewBox="0 0 133.456 133.473">
                                                <defs>
                                                    <style>
                                                        {/* .cls-1{fill:#82878d} */}
                                                    </style>
                                                </defs>
                                                <g id="Group_5396" transform="translate(4.693 4.009)">
                                                    <g id="Group_5067" transform="translate(0 .008)">
                                                        <g id="Group_3185" transform="translate(3.55 10.943)">
                                                            <path id="Rectangle_5740" d="M0 0H1.524V6.437H0z" class="cls-1" transform="rotate(45 8.625 112.809)" />
                                                            <path id="Path_2337" d="M0 6.115l1.471.393L3.064.393 1.593 0z" class="cls-1" transform="translate(38.361 2.95)" />
                                                            <path id="Rectangle_5741" d="M0 0H1.523V6.236H0z" class="cls-1" transform="rotate(75.071 8.655 104.057)" />
                                                            <g id="Group_3174" transform="translate(83.97 34.2)">
                                                                <path id="Rectangle_5742" d="M0 0H1.523V6.391H0z" class="cls-1" transform="rotate(51 2.484 5.207)" />
                                                                <path id="Rectangle_5743" d="M0 0H1.524V6.391H0z" class="cls-1" transform="rotate(57 -1.879 13.91)" />
                                                                <path id="Rectangle_5744" d="M0 0H1.524V6.391H0z" class="cls-1" transform="rotate(62.98 -5.48 21.096)" />
                                                                <path id="Rectangle_5745" d="M0 0H1.524V6.391H0z" class="cls-1" transform="rotate(68.95 -8.522 27.157)" />
                                                            </g>
                                                            <g id="Group_3175" transform="translate(47.046 5.917)">
                                                                <path id="Rectangle_5746" d="M0 0H1.523V6.391H0z" class="cls-1" transform="rotate(38.95 -6.554 46.644)" />
                                                                <path id="Rectangle_5747" d="M0 0H1.524V6.391H0z" class="cls-1" transform="rotate(33.046 -4.86 37.892)" />
                                                                <path id="Rectangle_5748" d="M0 0H1.523V6.391H0z" class="cls-1" transform="rotate(27.021 -2.52 25.511)" />
                                                                <path id="Rectangle_5749" d="M0 0H1.524V6.391H0z" class="cls-1" transform="rotate(21.032 1.147 6.179)" />
                                                            </g>
                                                            <g id="Group_3176" transform="translate(102.632 77.591)">
                                                                <path id="Rectangle_5750" d="M0 0H1.524V6.39H0z" class="cls-1" transform="rotate(80.44 3.151 3.725)" />
                                                                <path id="Rectangle_5751" d="M0 0H1.524V6.392H0z" class="cls-1" transform="rotate(86.44 -1.443 8.876)" />
                                                                <path id="Rectangle_5752" d="M0 0H6.39V1.523H0z" class="cls-1" transform="rotate(2.427 -455.715 40.234)" />
                                                                <path id="Rectangle_5753" d="M0 0H6.391V1.523H0z" class="cls-1" transform="rotate(8.405 -193.49 17.666)" />
                                                            </g>
                                                            <g id="Group_3177">
                                                                <path id="Rectangle_5754" d="M0 0H6.392V1.524H0z" class="cls-1" transform="rotate(80.469 .087 1.45)" />
                                                                <path id="Rectangle_5755" d="M0 0H6.391V1.524H0z" class="cls-1" transform="rotate(86.445 5.6 6.045)" />
                                                                <path id="Rectangle_5756" d="M0 0H1.522V6.39H0z" class="cls-1" transform="rotate(2.449 9.802 458.6)" />
                                                                <path id="Rectangle_5757" d="M0 0H1.524V6.39H0z" class="cls-1" transform="rotate(8.4 8.896 200.758)" />
                                                            </g>
                                                        </g>
                                                        <path id="Path_2327" fill="none" stroke="#dfe2e1" stroke-linecap="round" stroke-miterlimit="10" stroke-width="8px" d="M122.8 124.763A104.672 104.672 0 0 0 0 1.962" />
                                                    </g>
                                                    <g id="Group_5068">
                                                        <path id="Path_2327-2" fill="none" stroke="#69e386" stroke-linecap="round" stroke-miterlimit="10" stroke-width="5px" d="M55.2 6.05A104.1 104.1 0 0 0 0 1.97" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>

                                    </div>
                                    <div className="bt-action" onClick={() => {
                                        history.push("/")
                                    }}>
                                        {props.Environment.sagain}
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>

            </div>
        </div>
    )
}

export default Collector