import React, { Fragment, useState, useEffect, useRef } from 'react';
// import SemiCircleProgressBar from "react-progressbar-semicircle";
import { Environment } from '../../env';
import { useSelector, useStore } from 'react-redux';
import {
   GetPreviousOrders,
   GetClusteredData,
   GetOrderDetails,
   GetOrderD,
   GetAdminToken,
   GetOrderData,
} from '../../redux/actions/cart/actions';
import Loader from '../Loader/Loader';
import { TweenMax } from 'gsap';
import CustomProgressbar from '../PrizeList/CustomProgressbar';
import { Tween } from 'react-gsap';
import { get, clone } from 'lodash';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
   dateToDispFormat,
   DateFormatter,
   calcDaysFromCurrentDate,
   showTimeBasedCampaigns,
   prefixZeroIfSingleDigit,
   isOrderSuccessfull,
} from '../../utils/Function';
import TicketCluster from '../TicketCluster/TicketCluster';
import $ from 'jquery';

const TicketList = props => {
   const store = useSelector(store => store);
   const [isRendered, setRendered] = useState(false);
   const [tickets, setTickets] = useState([]);
   const [status, setStatus] = useState([]);

   const [ticketsCount, setTicketsCount] = useState({});
   const campaign = useSelector(store => store.home.campaign_manager);
   const all_products = useSelector(store => store.home.allproducts);
   const options = {
      dots: false,
      infinite: false,
      initialSlide: 0,
      speed: 500,
      slidesToScroll: 1,
      slidesToShow: 1,
      arrows: false,
      variableWidth: true,
      swipeToSlide: true,
      touchMove: true,
      afterChange: function (currentSlide) {
         // if (current_slide > 0) {
         //    let previousSlide = document.querySelectorAll('.slick-slide')[current_slide - 1];
         //    previousSlide.style.opacity = '0.5';
         // }
         // let currentSlide = document.querySelectorAll('.slick-slide')[current_slide];
         // currentSlide.style.opacity = '1';
      },
      beforeChange: function (slick, currentSlide, nextSlide) {
         /*  console.log("before",current_slide)
         if (current_slide > 0) {
            let previousSlide = document.querySelectorAll(".slick-slide")[current_slide - 1];
            previousSlide.style.opacity = "0.5";
         }
         let currentSlide = document.querySelectorAll(".slick-slide")[current_slide];

         currentSlide.style.opacity = "1"; */
      },
   };

   const handleTicketsCount = items => {
      const tickets = items.flatMap(cur => cur.items).map(cur => cur.extension_attributes.campaign_status);
      const activeTickets = tickets.reduce((acc, val) => {
         if (val === 1) {
            acc += 1;
         }
         return acc;
      }, 0);
      const closedTickets = tickets.reduce((acc, val) => {
         if (val === 0) {
            acc += 1;
         }
         return acc;
      }, 0);
      return { activeTickets, closedTickets };
   };

   useEffect(() => {
      setRendered(false);
      if (store.auth.token !== null) {
         if (props.orderid != undefined) {
            if (store.cart.orderid != props.orderid || tickets.length === 0) {
               GetAdminToken()
                  .then(res => {
                     GetOrderData(res, props.orderid)
                        .then(response => {
                           let array = [];
                           response.items.forEach((item, index) => {
                              console.log(item);
                              console.log(item.parent_item_id);

                              let obj = { data: [{ items: [item] }] };
                              console.log(obj);
                              // if (array.indexOf(obj) === -1) {
                              // if(obj.)
                              if (!item.parent_item_id) array.push(obj);
                              // }
                           });
                           console.log(array);
                           setTickets(array);
                           setStatus(response?.status);
                           setRendered(true);
                        })
                        .catch(e => console.log(e));
                  })
                  .catch(e => console.log(e));
            }
         } else {
            if (props.type == 'closedTickets') {
               console.log(props.type);
               setTickets(get(store, 'cart.clusteredData', []));
            } else {
               console.log(props.type);
               TicketRendering();
            }

            // if (campaign.length !== 0) {
            //    setRendered(true)
            // }

            //Demo
         }

         //GetPreviousOrders(store.auth.token, props.order)
         // } else {
         //setTickets(get(store, 'home.clusteredData', []))
      }
      setTimeout(() => setRendered(true), 500);
   }, []);

   useEffect(() => {
      //setTickets(get(store, 'cart.clusteredData', []))
      setTimeout(() => {
         const items = document.querySelectorAll('.slick-slide');

         if (items !== null) {
            items.forEach((element, index) => {
               element.style.zIndex = `-${index + 1}`;
            });
         }
      }, 2000);
   }, [get(store, 'cart.clusteredData', [])]);

   //starts here
   const TicketRendering = () => {
      if (props.type == 'openTickets') {
         let count = 0;
         let ticket_temp = [];
         get(store, 'cart.clusteredData', []).forEach((tkt, index) => {
            let new_array = [];
            tkt.data[0].items.forEach(item => {
               count = count + parseInt(item.qty_ordered);
               item.ticket_numbers.split(',').forEach(tktnumber => {
                  let tkt_item = clone(item);
                  tkt_item.tkt_num = tktnumber;
                  new_array.push(tkt_item);
               });
            });
            ticket_temp.push({ data: [{ items: new_array }] });
         });

         props.setQty(count);
         setTickets(ticket_temp);
      }
   };
   //ends here

   const SortTickets = orders => {};

   const getClosedDate = closedDate => {
      const date = new Date(closedDate);
      const day = date.getDate();
      const month = date.toLocaleString('default', { month: 'long' });
      const year = date.getFullYear();
      return `${day}, ${month} ${year}`;
   };
   const getPrizePercentage = (sales, stock, campaign) => {
      let prizePercentage = 0;
      if (showTimeBasedCampaigns(campaign?.campaign_type)) {
         let finished =
            calcDaysFromCurrentDate(campaign?.campaign_end_time, campaign?.activation_date) -
            calcDaysFromCurrentDate(campaign?.campaign_end_time);
         let total = calcDaysFromCurrentDate(campaign?.campaign_end_time, campaign?.activation_date);
         prizePercentage = (finished / total) * 100;
      } else {
         prizePercentage = (sales / stock) * 100;
      }

      return prizePercentage;
   };

   const defaultRender = (item, type) => {
      const sales = item.extension_attributes.total_sales;
      const stock = item.extension_attributes.total_stock;
      var prizePercentage = 0;
      if (type == 2) {
         prizePercentage = 100;
      } else {
         prizePercentage = (sales / stock) * 100;
      }

      const ticketList = {
         svgLeft: 274,
         svgRight: 193,
         cricle: {
            r: 130,
         },
         translate: 245,
         translateY: 100,
      };

      return (
         <div className="semi-circle-progressbar">
            <div className="dialdots-line">
               <img src={`${props.Environment.img_path}makeicons/dots.svg`} alt="" />
            </div>

            {showTimeBasedCampaigns(item.extension_attributes?.campaign_type) ? (
               <div className="progress-percentage">
                  <p style={{ width: '107%', margin: 0 }} className="total-value">
                     {props.Environment.Ends_In}
                  </p>
                  <p className="current-value">
                     {' '}
                     {prefixZeroIfSingleDigit(calcDaysFromCurrentDate(item.extension_attributes?.campaign_end_time))}
                  </p>
                  <p className="total-value">{props.Environment.Days}</p>
               </div>
            ) : (
               <>
                  {type === 1 ? (
                     <div className="progress-percentage">
                        <p className="current-value">{sales}</p>
                        <p className="sold-out site-e">
                           {props.Environment.SOLDTETX} <br /> {props.Environment.OUTSNT}
                        </p>
                        <p className="sold-out site-a">{props.Environment.ddds}</p>
                        <p className="total-value">{stock}</p>
                     </div>
                  ) : null}
                  {type === 2 ? (
                     <div className="progress-percentage" style={{ top: 0 }}>
                        <p className="sold-out1">
                           {/* SOLD
                      <br />
                      OUT */}
                           <img src="makeicons/sold-out.svg" alt="" />
                        </p>
                     </div>
                  ) : null}
               </>
            )}
            <CustomProgressbar
               progress={getPrizePercentage(sales, stock, item.extension_attributes)}
               soldOut={stock === sales ? true : false}
            />
         </div>
         //    <div className="semi-circle-progressbar">
         //       <div className="dialdots-line">
         //     <img src={`${props.Environment.img_path}makeicons/dots.svg`} alt="" />
         //   </div>
         //       {type === 1 ? (
         //          <div className="progress-percentage">
         //             <p className="current-value">
         //                {sales}
         //                <span className="sold">{props.Environment.SOLDTETX}</span>
         //             </p>
         //             <p className="sold-out">
         //                {props.Environment.OUTSNT} <span className="total-value">{stock}</span>
         //             </p>

         //          </div>
         //       ) : null}
         //       {type === 2 ? (
         //          <div className="progress-percentage" style={{ top: 0 }}>
         //             <p className="sold-out1">
         //                {/* SOLD
         //                <br />
         //                OUT */}
         //                <img src="makeicons/sold-out.svg" alt="" />
         //             </p>
         //          </div>
         //       ) : null}
         //       <CustomProgressbar progress={prizePercentage} soldOut={type === 2 ? true : false} />
         //    </div>
      );
   };

   if (!isRendered) {
      return <Loader style={{ margin: '10px auto 60px' }} />;
   }

   console.log(tickets.length);
   if (tickets.length == 1) {
      $('.ftempsi').addClass('ftempsinew');
   } else {
      $('.ftempsi').removeClass('ftempsinew');
   }

   return (
      <div className={props.type === 'openTickets' ? 'listing-parent' : 'closed-parent'}>
         <div className="shadow">
            {tickets.map((ticket, index) => {
               return (
                  <div
                     className={`ftempsi tkt-temp ${props.orderid != undefined ? 'order-d' : ''} ${
                        props.type === 'closedTickets' ? 'closed' : ''
                     }`}
                  >
                     {/* <Slider {...options}> */}
                     <TicketCluster items={ticket.data[0].items} type={props.type} show={props.orderid ? false : true}>
                        {ticket.data[0].items.map((item, index) => {
                           if (props.type === 'closedTickets' && index > 0) {
                              return;
                           }

                           const replace = false;

                           var camp = campaign.find(camp => camp.campaign_product_id == item.product_id);
                           var prize = undefined;

                           if (camp !== undefined) {
                              prize = all_products.find(item => item.id == camp.campaign_prize_id);
                              item.extension_attributes.total_stock = camp.campaign_total_tickets;
                              item.extension_attributes.total_sales = camp.campaign_total_sales;
                           } else {
                              return;
                           }

                           if (prize === undefined) {
                              return;
                           }

                           var isKoinz = item.extension_attributes.is_koinz;

                           const html = item.extension_attributes.prize_text
                              .replace(/<\/p>/g, '|')
                              .replace(/<p>/g, '')
                              .split('|')
                              .filter(cur => cur.trim())
                              .pop();

                           let ticket_numbers = item.extension_attributes.ticket_numbers.split(',');

                           //starts here
                           if (props.orderid != null) {
                              return (
                                 <TicketCluster show={true}>
                                    {ticket_numbers.map((tick, index1) => {
                                       return (
                                          <div className="ticket-listing" style={{ borderColor: '#c6cfd9' }}>
                                             <div className="ticket-counter">{index1 + 1}</div>
                                             {defaultRender(item, 1)}
                                             <div className="tkt-prize">
                                                <div className="tkt-ref-number">
                                                   {props.Environment.refnumber}&nbsp;
                                                   <span>{`${item.order_id}-${item.item_id}`}</span>
                                                </div>
                                                <div className="tkt-prize-name product-namedss">
                                                   {/* <span>Win</span>&nbsp; */}
                                                   <span>{prize != undefined ? prize.name : ''}</span>
                                                </div>
                                             </div>
                                             <div className="tkt-product">
                                                <div className="tkt-product-img">
                                                   <div className="product-img">
                                                      {isKoinz != '1' ? (
                                                         <img
                                                            src={
                                                               props.Environment.productUrl +
                                                               item.extension_attributes.image
                                                            }
                                                            alt=""
                                                         />
                                                      ) : (
                                                         <img
                                                            src={`${props.Environment.img_path}makeicons/ic-money.svg`}
                                                            alt=""
                                                         />
                                                      )}
                                                   </div>
                                                   <div className="tkt-product-detail">
                                                      <div className="tkt-icon-txt">
                                                         <span>
                                                            <img
                                                               src={`${props.Environment.img_path}makeicons/ic_calendar.svg`}
                                                               alt=""
                                                            />
                                                         </span>
                                                         <div className="icon-small-txt">
                                                            <div className="datettl">{props.Environment.dp}</div>
                                                            <div className="tktdate">
                                                               {/* {dateToDispFormat(item.created_at)} */}
                                                               {DateFormatter(item.created_at)}
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div className="tkt-icon-txt">
                                                         <span>
                                                            <img
                                                               src={`${props.Environment.img_path}makeicons/ic_tkt_ticket.svg`}
                                                               alt=""
                                                            />
                                                         </span>
                                                         <div className="icon-small-txt">
                                                            <div className="datettl">{props.Environment.tkn}</div>
                                                            <div className="tktdate">
                                                               {isOrderSuccessfull(status) ? tick : 'In Processing'}{' '}
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="tkt-prize-img">
                                                   <img
                                                      src={
                                                         props.Environment.productUrl +
                                                         get(
                                                            prize.custom_attributes.find(
                                                               attr => attr.attribute_code == 'thumbnail'
                                                            ),
                                                            'value',
                                                            ''
                                                         )
                                                      }
                                                      alt=""
                                                   />
                                                </div>
                                             </div>
                                          </div>
                                       );
                                    })}
                                 </TicketCluster>
                              );
                           }
                           //ends here

                           return (
                              <Fragment>
                                 {props.type ===
                                    'openTickets' /* && item.extension_attributes.campaign_status === 1 */ && (
                                    // <Tween from={{ x: '100%', duration: 0.5 }}>
                                    <div className="ticket-listing" style={{ borderColor: '#c6cfd9' }}>
                                       <div className="ticket-counter">{index + 1}</div>
                                       {defaultRender(item, 1)}
                                       <div className="tkt-prize">
                                          <div className="tkt-ref-number">
                                             {props.Environment.refnumber}&nbsp;
                                             <span>{`${item.order_id}-${item.item_id}`}</span>
                                          </div>
                                          <div className="tkt-prize-name product-namedss">
                                             {/* <span>Win</span>&nbsp; */}
                                             <span>{prize != undefined ? prize.name : ''}</span>
                                          </div>
                                       </div>
                                       <div className="tkt-product">
                                          <div className="tkt-product-img">
                                             <div className="product-img">
                                                {isKoinz != '1' ? (
                                                   <img
                                                      src={
                                                         props.Environment.productUrl + item.extension_attributes.image
                                                      }
                                                      alt=""
                                                   />
                                                ) : (
                                                   <img
                                                      src={`${props.Environment.img_path}makeicons/ic-money.svg`}
                                                      alt=""
                                                   />
                                                )}
                                             </div>
                                             <div className="tkt-product-detail">
                                                <div className="tkt-icon-txt">
                                                   <span>
                                                      <img
                                                         src={`${props.Environment.img_path}makeicons/ic_calendar.svg`}
                                                         alt=""
                                                      />
                                                   </span>
                                                   <div className="icon-small-txt">
                                                      <div className="datettl">{props.Environment.dp}</div>
                                                      <div className="tktdate">
                                                         {/* {dateToDispFormat(item.created_at)} */}
                                                         {DateFormatter(item.created_at)}
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="tkt-icon-txt">
                                                   <span>
                                                      <img
                                                         src={`${props.Environment.img_path}makeicons/ic_tkt_ticket.svg`}
                                                         alt=""
                                                      />
                                                   </span>
                                                   <div className="icon-small-txt">
                                                      <div className="datettl">{props.Environment.tkn}</div>
                                                      <div className="tktdate ticket-data">{item.tkt_num}</div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="tkt-prize-img">
                                             <img
                                                src={
                                                   props.Environment.productUrl +
                                                   get(
                                                      prize.custom_attributes.find(
                                                         attr => attr.attribute_code == 'thumbnail'
                                                      ),
                                                      'value',
                                                      ''
                                                   )
                                                }
                                                alt=""
                                             />
                                          </div>
                                       </div>
                                    </div>
                                    // </Tween>
                                 )}
                                 {props.type ===
                                    'closedTickets' /* && item.extension_attributes.campaign_status === 0 */ && (
                                    // <Tween from={{ x: '300px', opacity: 1, duration: 0.5, overflow: 'hidden' }}>
                                    <div
                                       className="ticket-listing closed-ticket-listing"
                                       style={{ borderColor: '#00e87b' }}
                                    >
                                       {defaultRender(item, 2)}
                                       <div className="closed-tkt-prize">
                                          <div className="closed-campaign-details">
                                             <div className="tkt-ref-number">{props.Environment.winnertxt}</div>
                                             <div className="tkt-prize-name">John Doe</div>
                                             <div className="tckt-date">10/2/2020</div>
                                          </div>
                                          <div className="closed-campaigns-img">
                                             <img
                                                src={
                                                   props.Environment.productUrl +
                                                   prize.custom_attributes.find(
                                                      attr => attr.attribute_code == 'thumbnail'
                                                   ).value
                                                } //{`${props.Environment.img_path}images/prize-img.png`}
                                                alt=""
                                             />
                                          </div>
                                       </div>
                                       <div className="tkt-product">
                                          <div className="tkt-number">
                                             {props.Environment.ticketnumbertxt}
                                             <span>{/* {`${item.order_id}-${item.item_id}`} */}199-268</span>
                                          </div>
                                       </div>
                                    </div>
                                    // </Tween>
                                 )}
                              </Fragment>
                           );
                        })}
                     </TicketCluster>
                     {/* </Slider> */}
                  </div>
               );
               /* const html = item.extension_attributes.prize_text.replace(/<\/p>/g, '|').replace(/<p>/g, '').split('|').filter(cur => cur.trim()).pop(); */
            })}
            {/* </Slider> */}
         </div>
      </div>
   );
};

export default TicketList;
