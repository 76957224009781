import React, { useRef, useEffect, useState } from 'react';
import Account from '../../pages/Account/Account';
import { Environment as en } from '../../env';
import { Environment as ar } from '../../env_ar';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
const script = document.createElement('script');
script.src =
   'https://i.ctnsnet.com/int/integration?pixel=67834349&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}';
script.async = true;
const DesktopAbout = () => {
   const [file, setfile] = useState(en);
   const store = useSelector(store => store);
   useEffect(() => {
      if (get(store, 'home.language', 'en') == 'en') {
         setfile(en);
      } else {
         setfile(ar);
      }
   }, [get(store, 'home.language', 'en')]);

   useEffect(() => {
      if (document.getElementById('desktop-about-page-wrapper'))
         document.getElementById('desktop-about-page-wrapper').appendChild(script);
      return () => {
         if (document.getElementById('desktop-about-page-wrapper'))
            document.getElementById('desktop-about-page-wrapper').removeChild(script);
      };
   }, []);
   return (
      <div className="desktop-about-container" id="desktop-about-page-wrapper">
         <div className="banner">
            <img src="images/desktop-header.jpg" alt="" />
         </div>

         <div className="content">
            <div className="desktop-header">{file.aboytheading}</div>
            <div className="desktop-description">{file.aboytheadingdesc}</div>
            <div className="desktop-header spacing-above">{file.aboytsecondheading}</div>
            <div className="desktop-description">{file.aboytseconddesc}</div>
            <div className="desktop-header spacing-above">{file.aboytthirdheading}</div>
            <div className="desktop-description">{file.aboytthirddesc}</div>

            {/* <div className="blocks-section">
               <div className="items">
                  <div className="aboue">
                     <img src="makeicons/search-icon1.svg" alt="" /></div>
                  <h1>Search </h1>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
               </div>
               <div className="items">
                  <div className="aboue">
                     <img src="makeicons/search-icon2.svg" alt="" /></div>
                  <h1>Buy</h1>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
               </div>
               <div className="items">
                  <div className="aboue">
                     <img src="makeicons/search-icon3.png" alt="" /></div>
                  <h1>Win</h1>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
               </div>
            </div> */}
         </div>
      </div>
   );
};

export default DesktopAbout;
