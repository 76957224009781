import React from 'react';
import { Environment } from '../../env';
import { useSelector } from 'react-redux';
import { convertNaNToZero } from './../../utils/Function';
import moment from 'moment-timezone';

const OrderHistoryList = props => {
   const campaign = useSelector(store => store.home.campaign_manager);
   const all_products = useSelector(store => store.home.allproducts);

   return (
      <div>
         {props.orders.map(order => {
            return order.items.map(item => {
               var camp = campaign.find(camp => camp.campaign_product_id == item.product_id);

               if (camp === undefined) {
                  return;
               }

               var product = all_products.find(item => item.id == camp.campaign_product_id);
               if (product == undefined) return;
               var prize = all_products.find(item => item.id == camp.campaign_prize_id);
               if (prize == undefined) return;

               let koin = parseInt(item.extension_attributes.koinz_amount) / item.qty_ordered;

               var image = product.custom_attributes.find(attr => attr.attribute_code == 'small_image');
               var image_prize = prize.custom_attributes.find(attr => attr.attribute_code == 'small_image');

               //console.log(image)
               console.log(item.extension_attributes.koinz_amount);
               console.log(item.qty_ordered);

               return [...Array(item.qty_ordered)].map((_, i) => {
                  const tickets = item.extension_attributes.ticket_numbers.split(',');
                  var input = item.created_at;

                  var fmt = 'YYYY-MM-DD h:mm:ss'; // must match the input
                  var zone = 'America/New_York';
                  var m = moment.tz(input, fmt, zone);

                  var isKoinz = item.extension_attributes.is_koinz;
                  return (
                     <div className="OrderHistory-listing">
                        <div className="orderhistory-product">
                           <div className="orderhistory-product-img">
                              <div className="product-img">
                                 {isKoinz != '1' ? (
                                    <img src={props.Environment.productUrl + image.value} alt="" />
                                 ) : (
                                    <img src={`${props.Environment.img_path}icons/ic-money.svg`} alt="" />
                                 )}
                                 {/* <img
                            src={props.Environment.productUrl + image.value}
                            alt=""
                          /> */}
                              </div>
                              <div className="orderhistory-product-detail">
                                 <div className="orderhistory-icon-txt">
                                    <div className="icon-small-txt">
                                       <div className="datettl">{props.Environment.ticketid}</div>
                                       <div className="tktdate">{tickets[i]}</div>
                                    </div>
                                 </div>
                                 <div className="orderhistory-icon-txt">
                                    <div className="icon-small-txt">
                                       <div className="datettl"> {props.Environment.koinz}</div>
                                       <div className="tktdate"> {Math.round(convertNaNToZero(koin))}</div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="orderhistory-prize-img">
                              <img src={props.Environment.productUrl + image_prize.value} alt="" />
                           </div>
                        </div>
                        <div className="orderhistory-purchased-detail">
                           <div className="order-date">{m.utc().format(fmt)}</div>
                           <div className="order-amt">
                              <span className="curncy-type site-e">
                                 {order.order_currency_code} <p> {item.price_incl_tax}</p>
                              </span>
                              <span className="curncy-type site-a">
                                 {' '}
                                 <p> {item.price_incl_tax}</p> AED{' '}
                              </span>
                           </div>
                        </div>
                     </div>
                  );
               });
            });
         })}
      </div>
   );
};

export default OrderHistoryList;
