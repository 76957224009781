import React, { useState, useEffect } from 'react';
import { Environment } from '../../../env';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';

const Doer = props => {
   const store = useSelector(store => store);
   const [doer, setdoer] = useState(null);
   const history = useHistory();
   const language = localStorage.getItem('langage');

   useEffect(() => {
      if (get(store, 'cart.userMissions', null) !== null) {
         let doer = get(store, 'cart.userMissions', null).available.find(item => {
            return item.name == 'The Doer';
         });
         setdoer(doer);
      }
   }, [get(store, 'cart.userMissions', null)]);

   const badge = () => (
      <div>
         <div className="badge">
            <img src={`${Environment.img_path}icons/badge4.svg`} />
            <span>
               <img src={`${Environment.img_path}icons/doer-lock.svg`} />
            </span>
         </div>
         <div className="badge">
            <img src={`${Environment.img_path}icons/badge4.svg`} />
            <span>
               <img src={`${Environment.img_path}icons/doer-lock.svg`} />
            </span>
         </div>
         <div className="badge">
            <img src={`${Environment.img_path}icons/badge4.svg`} />
            <span>
               <img src={`${Environment.img_path}icons/doer-lock.svg`} />
            </span>
         </div>
      </div>
   );

   return (
      <div className="doer-parent">
         <div className="head">
            <img src={`${Environment.img_path}icons/doer_badge.svg`} />
            <p>{props.Environment.doer}</p>
         </div>
         <div className="bg">
            <p>
               {doer != null ? doer.translations.find(trans => trans.languageCode == language).hint : ''}
               {/* All you have to do is buy 2 products in a single category. If you do this across the 5 categories, you’ll earn <span><img className="img-coin" src={`${Environment.img_path}icons/doercoin.svg`} /></span><span> 500 bonus Koinz</span> to spend. */}
            </p>

            {doer != null ? (
               <div className="badge-container">
                  <div className="row1">
                     {doer.attrs.rewards.map((reward, index) => {
                        if (index > 2) {
                           return;
                        }

                        let isCompleted = doer.attrs.progress.completedIds.find(id => id == reward.id);
                        let img =
                           isCompleted == null ? reward.imageUrl.replace('source', 'grayscale') : reward.imageUrl;

                        return (
                           <div className={`badge ${isCompleted != null ? 'active-b' : ''}`}>
                              <img style={{ height: '100px' }} className="badge-img" src={img} />
                              {isCompleted == null ? (
                                 <span>
                                    <img src={`${Environment.img_path}icons/doer-lock.svg`} />
                                 </span>
                              ) : null}
                              {/* isCompleted ? <img className="icon-img" src={`${Environment.img_path}icons/icon${index + 1}-active.svg`} /> : <img className="icon-img" src={`${Environment.img_path}icons/icon${index + 1}-inactive.svg`} /> */}
                           </div>
                        );
                     })}
                     {/* <div className="badge active-b">
                            <img className="badge-img" src={`${Environment.img_path}icons/badge4.svg`} />
                            <img className="icon-img" src={`${Environment.img_path}icons/icon1-active.svg`}/>
                        </div>
                        <div className="badge">
                            <img className="badge-img" src={`${Environment.img_path}icons/badge4.svg`} />
                            <span>
                                <img src={`${Environment.img_path}icons/doer-lock.svg`} />
                            </span>
                            <img className="icon-img" src={`${Environment.img_path}icons/icon2-inactive.svg`}/>
                        </div>
                        <div className="badge">
                            <img className="badge-img" src={`${Environment.img_path}icons/badge4.svg`} />
                            <span>
                                <img src={`${Environment.img_path}icons/doer-lock.svg`} />
                            </span>
                            <img className="icon-img" src={`${Environment.img_path}icons/icon3-inactive.svg`}/>
                        </div> */}
                  </div>
                  <div className="row2">
                     {doer.attrs.rewards.map((reward, index) => {
                        if (index < 3) {
                           return;
                        }

                        let isCompleted = doer.attrs.progress.completedIds.find(id => id == reward.id);
                        let img =
                           isCompleted == null ? reward.imageUrl.replace('source', 'grayscale') : reward.imageUrl;

                        return (
                           <div className={`badge ${isCompleted != null ? 'active-b' : ''}`}>
                              <img style={{ height: '100px' }} className="badge-img" src={img} />
                              {isCompleted == null ? (
                                 <span>
                                    <img src={`${Environment.img_path}icons/doer-lock.svg`} />
                                 </span>
                              ) : null}
                           </div>
                        );
                     })}
                     {/* <div className="badge">
                            <img className="badge-img" src={`${Environment.img_path}icons/badge4.svg`} />
                            <span>
                                <img src={`${Environment.img_path}icons/doer-lock.svg`} />
                            </span>
                            <img className="icon-img" src={`${Environment.img_path}icons/icon4-inactive.svg`}/>
                        </div>
                        <div className="badge">
                            <img className="badge-img" src={`${Environment.img_path}icons/badge4.svg`} />
                            <span>
                                <img src={`${Environment.img_path}icons/doer-lock.svg`} />
                            </span>
                            <img className="icon-img" src={`${Environment.img_path}icons/icon5-inactive.svg`}/>
                        </div>  */}
                  </div>
               </div>
            ) : null}
         </div>
         <div
            className="bt-action"
            onClick={() => {
               history.push('/');
            }}
         >
            {props.Environment.shopnow}
         </div>
      </div>
   );
};

export default Doer;
