import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useStore, useSelector } from 'react-redux';
import Cookie from 'js-cookie';
import Home from '../Home/Home';
import Tickets from '../Tickets/Tickets';
import Cart from '../Cart/Cart';
import PayReview from '../PayReview/PayReview';
import CheckoutDetails from '../CheckoutDetails/CheckoutDetails';
import Watchlist from '../Watchlist/Watchlist';
import EmptyKoinz from '../MyKoinz/EmptyKoinz';
import Account from '../Account/Account';
import Footer from '../../components/Footer/Footer';
import ScrollTop from '../../components/ScrollTop/ScrollTop';
import CartEmpty from '../../components/CartEmpty/CartEmpty';
import WinnerList from '../../components/WinnerList/WinnerList';
import ActionSheet from '../../components/ActionSheet/ActionSheet';
import ActionSheetAddress from '../../components/ActionSheetAddress/ActionSheetAddress';
import PopUps from '../../components/PopUps/PopUps';
import SignUp from '../../components/SignUp/SignUp';
import ProductDetail from '../ProductDetail/ProductDetail';
import MyHistory from '../MyHistory/MyHistory';
import { GetAllFavourite } from '../../redux/actions/favourites/actions';
import {
   ViewCart,
   ViewMamboProfile,
   LoyaltyData,
   UserMissions,
   BadgeData,
   GetClusteredData,
   GetConversionRates,
} from '../../redux/actions/cart/actions';
import { GuestViewCart } from '../../redux/actions/guestcart/actions';
import {
   SetPrevRoute,
   SetPopupData,
   SetRecall,
   GetAllProducts,
   GetBanners,
   GetPrizesNextMonth,
   GetCategories,
   Getendingsoonproducts,
   SetDeviceInfo,
   GetCampaignManager,
} from '../../redux/actions/home/actions';
import { GetNotifications as Noti } from '../../redux/actions/auth/actions';
import LanguageWrapper from '../../HigherOrderComponent/LanguageWrapper';
import PersonalDetails from '../PersonalDetails/PersonalDetails';
import HowItWorks from '../HowItWorks/HowItWorks';
import ContactUs from '../ContactUs/ContactUs';
import AboutApp from '../AboutApp/AboutApp';
import FAQs from '../FAQs/FAQs';
import DrawTnC from '../DrawTnC/DrawTnC';
import CheckoutYourDetails from '../CheckoutYourDetails/CheckoutYourDetails';
import OrderDetails from '../OrderDetails/OrderDetails';
import OrderCancel from '../OrderCancel/OrderCancel';
import OrderDeclined from '../OrderDeclined/OrderDeclined';
import OrderHistoryList from '../../components/OrderHistoryList/OrderHistoryList';
import SomethingWrong from '../../components/SomethingWrong/SomethingWrong';
import NotanAccount from '../../components/NotanAccount/NotanAccount';
import AccountDetails from '../AccountDetails/AccountDetails';
import ErrorBoundary from '../../components/Errors/ErrorBoundary';
import SavedAddress from '../SavedAddress/SavedAddress';
import ActionSheetcards from '../../components/ActionSheetcards/ActionSheetcards';
import PaymentMethod from '../PaymentMethod/PaymentMethod';
import MyKoinz from '../MyKoinz/MyKoinz';
import AccountSettings from '../AccountSettings/AccountSettings';
import ActionSheetNotification from '../../components/ActionSheetNotification/ActionSheetNotification';
import ActionSheetLanguage from '../../components/ActionSheetLanguage/ActionSheetLanguage';
import ActionSheetCurrency from '../../components/ActionSheetCurrency/ActionSheetCurrency';
import ShareIconSheet from '../../components/ShareIconSheet/ShareIconSheet';
import Notifications from '../../components/Notifications/Notifications';
import ThisMonthWinner from '../ThisMonthWinner/ThisMonthWinner';
import OnBoardingOne from '../OnBoarding/OnBoardingOne';
import TermsCondition from '../TermsCondition/TermsCondition';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import ReferFriend from '../ReferFriend/ReferFriend';
import ResetPassword from '../../components/ResetPassword/ResetPassword';
import ChangePassword from '../../components/ChangePassword/ChangePassword';
import ResetPassContainer from '../../components/ResetPassContainer/ResetPassContainer';
import FreeKoinz from '../FreeKoinz/FreeKoinz';
import HeroPath from '../HeroPath/HeroPath';
import { SETREFERID } from '../../redux/actions/home/actionTypes';
import DesktopTerms from '../DesktopTerms/DesktopTerms';
import DesktopTerms1 from '../DesktopTerms1/DesktopTerms1';
import DesktopTerms2 from '../DesktopTerms2/DesktopTerms2';
import DesktopAbout from '../DesktopAbout/DesktopAbout';
import KoinzDetails from '../KoinzDetails/KoinzDetails';
import BadgeInfo from '../BadgeInfo/BadgeInfo';
import DesktopHeader from '../../components/DesktopHeader/DesktopHeader';
import DesktopFooter from '../../components/DesktopFooter/DesktopFooter';
import TicketDownload from '../TicketDownload/TicketDownload';
import { SetLang } from '../../redux/actions/home/actions';

import { get } from 'lodash';
//website
import WebLogin from '../WebLogin/WebLogin';

const KanzApp = props => {
   const location = useLocation();
   const history = useHistory();
   const dispatch = useDispatch();
   const store = useSelector(store => store);
   const rewards = useSelector(store => store.home.rewards);
   const [show, setshow] = useState(true);
   const [offline, setOffline] = useState(false);
   const [, setRendered] = useState(false);
   const popupScreen = ['/product', '/WinnerList', '/cartempty'];
   const [prevRoute, setprevroute] = useState(null);
   let languageget = localStorage.getItem('langage');
   

   useEffect(() => {
      //reload issue
      if (store.auth.token == null) {
         dispatch(GetConversionRates());
      }
   }, []);

   // useEffect(() => {
      
   //    if (languageget == 'en') {
   //       //alert(document.getElementById('body-id').classList)
   //       dispatch(SetLang('en'));
   //       localStorage.setItem('langage', 'en');
   //       document.getElementById('body-id').classList.remove('site-ar');
   //       //alert(document.getElementById('body-id').classList)
   //       document.getElementById('body-id').classList.add('site-en');
   //    } else {
   //       //alert(document.getElementById('body-id').classList)
         
   //       if (location.pathname.includes('orderdetails') || location.pathname.includes('ob')) {

   //       }
   //       else{
   //          dispatch(SetLang('ar'));
   //       }
   //       localStorage.setItem('langage', 'ar');
   //       document.getElementById('body-id').classList.add('site-ar');
   //       document.getElementById('body-id').classList.remove('site-en');
   //    }
   // }, []);

   /* useEffect(() => {

      if (get(store, 'auth.token', null) != null && get(store, 'home.recall', false)) {
         //AppApiCalls()
         if (get(store, 'home.recall', false)) {
            dispatch(SetRecall())
         }
      }
   }, [get(store, 'auth.token', null), get(store, 'home.recall', false)]) */

   useEffect(() => {
      (async () => {
         setRendered(false);
         if (store.auth.token !== null) {
            if (store.auth.cartid != null) {
               await dispatch(ViewCart());
            }
         }
         setRendered(true);
      })();
   }, [store.auth.token]);

   const checkInternet = () => {
      window.navigator.onLine ? setOffline(false) : setOffline(true);
   };

   //this keeps track of previoud routes and internet
   useEffect(() => {
      checkInternet();
      dispatch(SetPrevRoute(prevRoute));
      setprevroute(location.pathname);
   }, [location]);

   //for guest view cart on first initialization
   useEffect(() => {
      (async () => {
         if (store.auth.token === null) {
            if (store.auth.cartid !== null) {
               await dispatch(GuestViewCart(store.auth.cartid));
            }
         }
      })();
   }, [store.auth.cartid]);

   //calls that are made after signup or login
   useEffect(() => {
      if (store.auth.token !== null) {
         MamboApiCalls();
         dispatch(Noti(get(store, 'auth.token', null)));
      } else {
         dispatch(GetAllFavourite(store.auth.token));
      }
   }, [store.auth.token]);

   useEffect(() => {
      if (get(store, 'home.rewards', []).length != 0) {
         dispatch(
            SetPopupData(true, true, get(store, 'home.rewards', [])[0].message, get(store, 'home.rewards', [])[0].img)
         );
      }
   }, [rewards.length]);

   //This is used to make mambo specific api calls after order placement
   useEffect(() => {
      if (location.pathname.includes('orderdetails')) {
         MamboApiCalls();
         dispatch(GetCampaignManager());
      }
   }, [location.pathname]);

   //This is used to call homepage Api's after language switch
   useEffect(() => {
      if (get(store, 'home.render', false)) {
         HomePageApi();
      }
   }, [get(store, 'home.render', false)]);

   const HomePageApi = async () => {
      await Promise.all([
         dispatch(GetCategories()),
         dispatch(GetPrizesNextMonth()),
         dispatch(GetBanners()),
         dispatch(GetAllProducts()),
         dispatch(Getendingsoonproducts()),
      ]);
      if (get(store, 'home.render', false)) {
         dispatch({ type: 'SETRENDER' });
      }
   };

   const MamboApiCalls = async () => {
      dispatch(GetClusteredData(get(store, 'auth.token', null)));
      await dispatch(ViewMamboProfile());
      await dispatch(GetConversionRates());
      await dispatch(LoyaltyData(store.auth.userinfo.id));
      await dispatch(UserMissions(store.auth.userinfo.id));
      await dispatch(BadgeData(store.auth.userinfo.id));
      await dispatch(GetAllFavourite(store.auth.token));
      //dispatch(ViewMamboProfile())
      /* let res1 = await dispatch(LoyaltyData(store.auth.userinfo.id))
      await dispatch(UserMissions(store.auth.userinfo.id))

      await dispatch(BadgeData(store.auth.userinfo.id))
      await dispatch(GetAllFavourite(store.auth.token)); */

      /* setTimeout(() => {
         dispatch(LoyaltyData(store.auth.userinfo.id))
      }, 2000)

      setTimeout(() => {
         dispatch(UserMissions(store.auth.userinfo.id))
      }, 4000)

      setTimeout(() => {
         dispatch(BadgeData(store.auth.userinfo.id))
      }, 6000) */
   };

   const OnMessageRecieved = message => {
      //alert("here")
      if (message.data === 'true') {
         //alert("here")
         //setshowob(true);
      } else {
         const data = JSON.parse(message.data);
         if (data.type === 'link') {
            if (data.page == 'refer') {
               //alert(data.id)
               dispatch({ type: SETREFERID, payload: data.id });
            }
         }
         if (data.type === 'token') {
            //alert(data.device_token)
            dispatch(SetDeviceInfo(data.device_token, data.device_type));
         }
      }
   };

   console.log(props);

   return (
      <ErrorBoundary history={history}>
         <img alt="" src="https://www.kanziapp.com/app-main-bg.jpeg" className="bg_image" />
         <div className="tempchecklanguage" id="body-id">
            <ScrollTop scrollDisabledPathname={['/product', '/', '/share', '']}>
               {/* <DesktopHeader /> */}
               <LanguageWrapper loadCompo={true} component={DesktopHeader} />
               {/* <DesktopHeader exact component={DesktopHeader} /> */}
               {offline && <PopUps type="offline" onSubmit={checkInternet} />}
               {/* <Route path="/savedaddress" exact render={props => <ActionSheetAddress type="login" {...props} />} /> */}
               <LanguageWrapper path="/badgeinfo" exact component={BadgeInfo} />
               <LanguageWrapper path="/heropath" exact component={HeroPath} />
               <LanguageWrapper path="/koinzdetails" exact component={KoinzDetails} />
               <LanguageWrapper path="/addcards_payment" component={ActionSheetcards} />
               <LanguageWrapper path="/addcards_checkout" component={ActionSheetcards} />
               <LanguageWrapper path="/addaddress_savedAddress" component={ActionSheetAddress} />
               <LanguageWrapper path="/addaddress_checkout" component={ActionSheetAddress} />
               <LanguageWrapper path="/notificationsettings" component={ActionSheetNotification} />
               <LanguageWrapper path="/language" component={ActionSheetLanguage} />
               <LanguageWrapper path={['/currency', '/currencyselection']} component={ActionSheetCurrency} />
               <LanguageWrapper path="/login" exact render={props => <ActionSheet type="login" {...props} />} />
               <LanguageWrapper path="/share" exact component={ShareIconSheet} />
               <LanguageWrapper path="/resetpass" component={ResetPassword} />
               <LanguageWrapper path="/resetpassword" component={ResetPassContainer} />
               <LanguageWrapper path="/changepass" component={ChangePassword} />
               <LanguageWrapper path="/newotp" exact component={ActionSheet} />
               <LanguageWrapper
                  path="/privacy-policy"
                  component={() => {
                     window.location.href = 'https://example.com/1234';
                     return null;
                  }}
               />
               <Switch>
                  <LanguageWrapper path="/referfriend" exact component={ReferFriend} />
                  <LanguageWrapper path="/privacypolicy" exact component={PrivacyPolicy} />
                  <LanguageWrapper path="/tnc" exact component={TermsCondition} />
                  <LanguageWrapper path="/desktop-terms" exact component={DesktopTerms} />
                  <LanguageWrapper path="/refund-cancelation" exact component={DesktopTerms1} />
                  <LanguageWrapper path="/delivery-shipment" exact component={DesktopTerms2} />
                  <LanguageWrapper path="/aboutkanzi" exact component={DesktopAbout} />
                  <LanguageWrapper path="/free-koinz" exact component={FreeKoinz} />
                  <LanguageWrapper path="/aboutapp" exact component={AboutApp} />
                  <LanguageWrapper path="/thismonthwinner" exact component={ThisMonthWinner} />
                  <LanguageWrapper path="/drawtnc" exact component={DrawTnC} />
                  <LanguageWrapper path="/faqs" exact component={FAQs} />
                  <LanguageWrapper path="/contactus" exact component={ContactUs} />
                  <LanguageWrapper path="/howitworks" exact component={HowItWorks} />
                  <LanguageWrapper path={['/paymentmethod', '/addcards_payment']} exact component={PaymentMethod} />
                  <LanguageWrapper
                     path={['/savedaddress', '/addaddress_savedAddress']}
                     exact
                     component={SavedAddress}
                  />
                  <LanguageWrapper path={['/personaldetails', '/newotp']} exact component={PersonalDetails} />
                  <LanguageWrapper
                     path={['/accountsettings', '/notificationsettings', '/language', '/currency']}
                     exact
                     component={AccountSettings}
                  />
                  <LanguageWrapper path="/mykoinz" exact component={MyKoinz} />
                  {/* <Route path="/accountsettings" component={AccountSettings} /> */}
                  <LanguageWrapper path="/notifications" exact component={Notifications} />
                  <LanguageWrapper path="/accountdetails" exact component={AccountDetails} />
                  <LanguageWrapper path="/notanaccount" exact component={NotanAccount} />
                  <LanguageWrapper path="/popups" exact component={PopUps} />
                  <LanguageWrapper path="/somethingwrong" exact component={SomethingWrong} />
                  <LanguageWrapper path="/orderhistorylist" exact component={OrderHistoryList} />
                  <LanguageWrapper path="/myhistory" exact component={MyHistory} />
                  <LanguageWrapper
                     path={['/checkoutdetails', '/addaddress_checkout', '/addcards_checkout']}
                     exact
                     component={CheckoutDetails}
                  />
                  <LanguageWrapper path="/orderdetails" exact component={OrderDetails} />
                  <LanguageWrapper path="/orderdetails/:orderId" exact component={OrderDetails} />
                  <LanguageWrapper path="/ordercancel" exact component={OrderCancel} />
                  <LanguageWrapper path="/orderdeclined" exact component={OrderDeclined} />
                  <LanguageWrapper path="/checkoutyourdetails" exact component={CheckoutYourDetails} />
                  <LanguageWrapper path="/payreview" exact component={PayReview} />
                  <LanguageWrapper path="/tickets" exact component={Tickets} />
                  <LanguageWrapper path={['/cart', '/login']} exact component={Cart} />
                  <LanguageWrapper path="/watchlist" exact component={Watchlist} />
                  <LanguageWrapper path="/emptykoinz" exact component={EmptyKoinz} />

                  <LanguageWrapper path="/account" exact component={Account} />
                  <LanguageWrapper path="/signup" exact component={SignUp} />
                  <LanguageWrapper
                     path={['/', '/share', '/currencyselection']}
                     component={Home}
                     exact={!popupScreen.includes(location.pathname)}
                  />
                  <LanguageWrapper path="/WinnerList" exact component={WinnerList} />
                  <LanguageWrapper path="/cartempty" exact component={CartEmpty} />
                  <Route path="/downloadticket" exact component={TicketDownload} />
                  <LanguageWrapper path="/account/login" exact component={WebLogin} />
                  {/* <Route path="*" component={SomethingWrong} /> */}
               </Switch>

               <Route path="/product" component={ProductDetail} />

               <LanguageWrapper loadCompo={true} component={DesktopFooter} />
            </ScrollTop>
         </div>
         {show && <Footer />}
      </ErrorBoundary>
   );
};

export default KanzApp;
