import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar';
import { Environment } from '../../env';
import { Tween } from 'react-gsap';
import { useSelector } from 'react-redux';
import GetApi from '../../api/GetApi';
import CircularLoader from '../../components/Loader/CircularLoader';
const script = document.createElement('script');
script.src =
   'https://i.ctnsnet.com/int/integration?pixel=67834349&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}';
script.async = true;
const PrivacyPolicy = props => {
   const [data, setData] = useState('');
   const store = useSelector(store => store);
   const [displayLoader, setDisplayLoader] = useState(false);

   useEffect(() => {
      console.log('useEffect');
      getContent();
   }, []);

   useEffect(() => {
      if (document.getElementById('privacy-policy-page-wrapper'))
         document.getElementById('privacy-policy-page-wrapper').appendChild(script);
      return () => {
         if (document.getElementById('privacy-policy-page-wrapper'))
            document.getElementById('privacy-policy-page-wrapper').removeChild(script);
      };
   }, []);

   const getContent = async () => {
      let url;
      if (localStorage.getItem('langage') === 'en') {
         url = '/cmsPage/7';
      } else {
         url = '/cmsPage/8';
      }
      try {
         setDisplayLoader(true);
         const res = await GetApi({
            baseURL: Environment.base_url,
            method: 'GET',
            url: url,
            headers: {
               crossorigin: true,
            },
         });
         setDisplayLoader(false);
         if (res.data.content) {
            setData(res.data.content);
         }
      } catch (e) {
         setDisplayLoader(false);
         setData('<p>Something went wrong while fetching Draw Terms & Conditions</p>');
      }
   };

   return (
      <div id="privacy-policy-page-wrapper" className="">
         <CircularLoader show={displayLoader}></CircularLoader>
         <NavBar privacypolicy="privacypolicy" />
         <div className="drawtnc-main-container">
            <h1 class="desktop-header">Privacy Policy</h1>
            <div className="drawtnc-card">
               {data && <span className="card-content" dangerouslySetInnerHTML={{ __html: data }}></span>}
            </div>
         </div>
      </div>
   );
};

export default PrivacyPolicy;
