import React, { useState, useEffect } from 'react';
import { prefixZeroIfSingleDigit } from '../../../src/utils/Function';
const TimerCounter = ({ closingOn }) => {
   const [timeCounter, setTimeCounter] = useState({ hours: '08', minute: '43', seconds: '57' });

   useEffect(() => {
      const timerFunction = setInterval(() => {
         let now;
         //  if (Platform.OS === 'android') {
         //     now = new Date().getTime() + 4 * 3600 * 1000;
         //  } else {
         now = new Date().getTime();
         //  }
         let endingon = new Date(closingOn);
         const distance = new Date(endingon).getTime() - now;
         console.log('closingOn =>', new Date(endingon));
         // alert(endingon.toLocaleTimeString ());
         // alert(Date.parse(closingOn));
         // console.log('Current Datetime =>', new Date());
         // console.log('closingOn =>', closingOn);
         // Time calculations for hours, minutes and seconds
         const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
         const minutes = distance < 0 ? 0 : Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
         const seconds = distance < 0 ? 0 : Math.floor((distance % (1000 * 60)) / 1000);

         console.log('hours =>', hours);
         console.log('minutes =>', minutes);
         console.log('seconds =>', seconds);
         setTimeCounter({
            hours: hours < 0 ? 0 : hours,
            minute: minutes < 0 ? 0 : minutes,
            seconds: seconds < 0 ? 0 : seconds,
         });
         if (distance < 0 || hours < 0) {
            clearInterval(timerFunction);
         }
      }, 1000);

      return () => {
         clearInterval(timerFunction);
      };
   }, []);
   const timeVal = {
      marginLeft: 3,
      marginRight: 3,
      color: '#102654',
      background: '#e4eefc',
      height: 30,
      width: 33,
      textAlign: 'center',
      paddingTop: 8,
   };
   return (
      <div className="time-counter">
         <div style={timeVal} className="time-value">
            {prefixZeroIfSingleDigit(timeCounter.hours)}
         </div>
         <div style={timeVal} className="time-value">
            {prefixZeroIfSingleDigit(timeCounter.minute)}
         </div>
         <div style={timeVal} className="time-value">
            {prefixZeroIfSingleDigit(timeCounter.seconds)}
         </div>
      </div>
   );
};

export default TimerCounter;
