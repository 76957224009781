import React, { useEffect, useState, useRef } from "react"
import { Environment } from '../../env';
import { GetTickets } from "../../redux/actions/home/actions"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import queryString from 'query-string';

const TicketDownload = () => {

    const [tickets, settickets] = useState([])
    const t_container = useRef(null)

    useEffect(() => {
        Tickets()
    }, [])


    const Tickets = async () => {
        var a = window.location.href;
        var detail = a.split('=')[1];
        console.log('campaignId' + detail);
        // alert(detail);
        let res = await GetTickets()

        settickets(res[0][detail].items);
        console.log(res[0][detail].items);
    }

    const DownloadTickets = () => {
        window.scrollTo(0, 0)

        setTimeout(() => {
            var a = window.location.href;
            var detail = a.split('=')[1];
            let campaignIdpa = 'campaign'+detail+".pdf";
            html2canvas(t_container.current).then(canvas => { // if you want see your screenshot in body.
                const imgData = canvas.toDataURL('image/png', 1.0);
                var pdf = new jsPDF("p", "mm", "a4");

                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();
                pdf.addImage(imgData, 'JPEG', 0, 0, width, height - 70/* 5, 10, width - 10, 120 */);
                pdf.save(campaignIdpa);
            });
        }, 1000)
    }

    return (
        <div className="ticketdownload-parent" ref={t_container}>
            {tickets.map((item, index) => {

                if (index == tickets.length - 1) {
                    console.log("hey")
                    DownloadTickets()
                }

                if (index > 10) {
                    return
                }

                return (
                    <div className="ticket">
                        <div className="row1">
                            <img src={Environment.img_path + 'makeicons/desktop-logo.svg'} />
                        </div>
                        <div className="row1">
                            <div className="col1">
                                <div className="info">
                                    <p className="type">Purchased by</p>
                                    <p className="name">{item.customer_firstname + ' ' + item.customer_lastname}</p>
                                </div>
                                <div className="info1">
                                    <p className="type">Mobile No</p>
                                    <p className="name">{item.phone_number}</p>
                                </div>

                            </div>
                            <div className="col2">
                                <div className="info">
                                    <p className="type">Purchased on</p>
                                    <p className="name">{item.created_at}</p>
                                </div>

                                <div className="info1">
                                    <p className="type">Ticket No</p>
                                    <p className="name">{item.ticket_numbers}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}

        </div>
    )
}

export default TicketDownload