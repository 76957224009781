import { act } from "react-dom/test-utils";
import { LOGIN, REGISTER, LOGOUT, SETERROR, AUTOLOGIN, SETCARTID, CUSTOMERUPDATE } from "../actions/auth/actionTypes"
import { ORDERID, EMPTYCART } from "../actions/cart/actionTypes"

const initialState = {
  token: null,
  userinfo: null,
  cartid: null,
  error: null
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        userinfo: {
          id: action.payload.data.id,
          firstname: action.payload.data.firstname,
          lastname: action.payload.data.lastname,
          phone_number: action.payload.data.phone_number,
          email: action.payload.data.email,
          gender: action.payload.data.gender,
          nationality: action.payload.data.nationality
        },
        token: action.payload.token,
        cartid: action.payload.cartid,
        error: null
      };
    case REGISTER:
      return {
        ...state,
        userinfo: {
          id: action.payload.data.id,
          firstname: action.payload.data.firstname,
          lastname: action.payload.data.lastname,
          phone_number: action.payload.data.phone_number,
          email: action.payload.data.email,
          gender: action.payload.data.gender,
          nationality: action.payload.data.nationality
        },
        token: action.payload.token,
        cartid: action.payload.cartid,
        error: null
      };
    case SETCARTID: console.log(action.payload)
      return {
        ...state,
        cartid: action.payload
      };
    case AUTOLOGIN:
      return {
        ...state,
        token: action.payload.token,
        userinfo: action.payload.data,
        cartid: action.payload.cartid,
        error: null
      };
    case LOGOUT:
      return {
        ...state,
        cartid: null,
        token: null,
        userinfo: null,
        error: null
      };
    case EMPTYCART:
      return {
        ...state,
        cartid: null
      }
    case SETERROR:
      return {
        ...state,
        error: action.payload
      };
    case ORDERID:
      return {
        ...state,
        cartid: null
      }
    case CUSTOMERUPDATE:
      return {
        ...state,
        userinfo: {
          firstname: action.payload.data.firstname,
          lastname: action.payload.data.lastname,
          phone_number: action.payload.data.phone_number,
          email: action.payload.data.email,
          gender: action.payload.data.gender,
          nationality: action.payload.data.nationality
        }
      }
    default:
      return state;
  }
}

export default AuthReducer;
