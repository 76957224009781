import React, { useState } from "react";
import { Environment } from "../../env"

const FloatingInput = ({ label, value, onChange, error, type }) => {

  const [showPass, setshowpass] = useState(false)

  return (
    <div className="floating-label">
      <input
        style={{ borderBottom: error ? "1px solid red" : "1px solid #e2e2e2" }}
        className={`floating-input ${error ? "error" : ""}`}
        type={showPass || type !== "password" ? "text" : "password"}
        placeholder=" "
        value={value}
        onChange={onChange}
      />
      <span className="highlight" />
      <label>{label}</label>
      {type === "password" ? <div className="label-icon" style={{ position: "absolute", top: 8, bottom: 0, right: 0 }} onClick={() => { setshowpass(!showPass) }}>
        <img src={`${Environment.img_path}makeicons/${showPass ? "eyeopen" : "eyeclose"}.svg`} alt="" />
      </div> : null}
      {type === "password" && error !== null ? <span className="pass-error" dangerouslySetInnerHTML={{__html:error}}></span> : null}
    </div>
  )
}

export default FloatingInput;
