import React, { useEffect, useState } from "react";
import { useDispatch, useStore, useSelector } from "react-redux";
import { Environment } from "../../env";
import NavBar from "../../components/NavBar/NavBar";
import CheckoutCart from "../../components/CheckoutCart/CheckoutCart";
import Button from "../../components/Button/Button";
import CircularLoader from "../../components/Loader/CircularLoader"
import { ORDERID } from "../../redux/actions/cart/actionTypes"
import { UserData } from "../../redux/actions/auth/actions"
import { SetRewards } from "../../redux/actions/home/actions"
import { PaymentInfo, CreatePointsActivity, ParticipateInCampaign, ViewMamboProfile, GetOrderDetails, GetAdminToken, EmptyKoinzProduct, isKoinz, GetPreviousOrders, ReferAFriend, PayByCard, GetCampaignCount, CreatePointsParticipation, koinzAmount } from "../../redux/actions/cart/actions";
import { get } from "lodash";

const PayReview = props => {
  const [data, setData] = useState({
    country_id: "",
    street: [],
    city: "",
    firstname: "",
    lastname: "",
    email: "",
    telephone: "",
    paymentmethod: ""
  });
  const [totalAmount, setTotalAmount] = useState(0);
  const store = useStore().getState();
  const dispatch = useDispatch();
  const campaign = useSelector(store => store.home.campaign_manager)
  const koinz_replacement = useSelector(store => store.cart.koinz_replacement)
  const useKoinz = useSelector(store => store.cart.useKoinz)
  const cart = useSelector(store => store.cart.cart)
  const all_products = useSelector(store => store.home.allproducts)
  const koinz = useSelector(store => store.mambo.data)
  const [Gtotal, setGtotal] = useState(0)
  const [show, setshow] = useState(false)
  const [koinzprod, setkoinzprod] = useState([])
  const [showurl, setshowurl] = useState({
    show: false,
    url: null
  })

  useEffect(() => {
    let amount = 0;
    store.cart.cart.forEach(element => {
      amount = amount + element.qty * element.price
    });

    if (useKoinz) {
      if (koinz.points[0].balance / 5 > amount) {
        setTotalAmount(0)
      } else {
        setTotalAmount(amount - (koinz.points[0].balance / 5))
      }
    } else {
      setTotalAmount(amount)
    }

    setGtotal(amount)

    //
  }, [/* store.cart.cart */])

  useEffect(() => {

  }, [totalAmount])

  console.log(showurl)

  const HandleReplaceWithKoinz = async (koinzdetail = null) => {

    var koinzforprod = []
    var koinzforparticipation = []
    var total_koinz = 0
    var conversion_rate = get(store, 'cart.config', []).find(item => item.name == "earning_conversion_rate")
    var product_conversion_rate = get(store, 'cart.config', []).find(item => item.name == "product_cost_conversion_rate")
    var customer_level = get(store, 'mambo.data', null)
    if (customer_level.level != null) {
      customer_level = customer_level.level.reward.customFields
      customer_level = customer_level.find(fields => fields.name == "loyalty_level_bonus")
    }
    var details = []


    koinz_replacement.forEach((item) => {
      cart.forEach((prod) => {
        if (item == prod.item_id) {
          koinzforprod.push(prod)
        }
      })
    })

    koinzforparticipation = cart.filter(el => !koinzforprod.includes(el))

    koinzforprod.forEach((prod) => {
      console.log(prod)
      var koinz = ((prod.qty * prod.price) * ((conversion_rate == undefined ? 0.2 : parseFloat(conversion_rate.value)))) + ((prod.qty * prod.extension_attributes.cost) * ((product_conversion_rate == undefined ? 1 : parseFloat(product_conversion_rate.value))))
      total_koinz = total_koinz + koinz
      details.push({ sku: prod.sku, koinz: koinz })
    })

    koinzforparticipation.forEach((prod) => {
      let koin = ((prod.qty * prod.price) * (conversion_rate == undefined ? 0.2 : parseFloat(conversion_rate.value)))
      total_koinz = total_koinz + koin
      console.log(total_koinz)
      details.push({ sku: prod.sku, koinz: koin })
    })

    total_koinz = total_koinz + (total_koinz * (customer_level.value == undefined ? 0 * 0.01 : parseFloat(customer_level.value) * 0.01))

    console.log(details)

    if (koinzdetail != null) {
      return details
    }

    const cp = await dispatch(CreatePointsActivity(Math.round(total_koinz)));
    if (cp) {
      //dispatch(ViewMamboProfile());
    }
  }

  const ParticipateinCampaign = async (res) => {
    const token = await GetAdminToken()
    const items = await GetOrderDetails(token, res)
    const ids = get(store, 'home.categories', [])

    let awarded = []

    items.forEach(async (prod, index) => {
      var product = all_products.find((all_prod) => {
        return all_prod.id == prod.product_id
      })

      if (product === undefined) { return }

      var campaign_id = campaign.find((camp) => {
        return camp.campaign_product_id == product.id
      })

      if (campaign_id === undefined) { return }

      var id = product.custom_attributes.find((attr) => {
        return attr.attribute_code == "category_ids"
      })

      if (id === undefined) { return }

      id = ids.find((cat_id) => {
        return cat_id.id == id.value[1]
      })

      console.log("id", id)

      console.log(prod)
      let count = await GetCampaignCount(prod.product_id, store.auth.token)

      if (count == false) {
        return
      }

      let res = await dispatch(ParticipateInCampaign(id.name.toLowerCase(), campaign_id.campaign_id, count));

      if (res.awarded.length !== 0) {
        res.awarded.forEach((award) => {
          awarded.push({ message: award.message, img: award.reward.imageUrl })
        })
      }


      if (index === items.length - 1) {
        console.log(awarded)
        dispatch(SetRewards(awarded))
        //await dispatch(ViewMamboProfile())
      }

    })

    await dispatch(CreatePointsParticipation())

  }

  const HandleReferer = async () => {
    var orders = await GetPreviousOrders(get(store, 'auth.token', null))

    if ((orders.items.length - 1) == 0) {


      var refer_id = await UserData(get(store, 'auth.token'), null)
      if (refer_id.custom_attributes !== undefined) {
        refer_id = refer_id.custom_attributes.find(attr => attr.attribute_code == "refer_id")
        console.log(refer_id)
        if (refer_id == null) {
          return
        }
        else {
          await ReferAFriend(store.auth.userinfo.id, refer_id.value)
        }
      }
    }
  }

  
  const orderAddressSaved = () => {
    let address = null
    console.log(props.location.id)
    console.log(store.cart.customerData)
    if (props.location.id != null) {
      if (props.location.id == 100) {
        address = store.cart.customerData[0]
      } else {
        address = store.cart.customerData.find(item => item.id === props.location.id);
      }
      console.log(address)
    } else {
      address = {
        street: ["", "", ""],
        city: "",
        firstname: "",
        lastname: "",
        email: "",
        telephone: ""
      }
    }

    return {
      paymentmethod: "cashondelivery",
      country_id: "AE",
      street: address.street,
      city: address.city,
      firstname: address.firstname,
      lastname: address.lastname,
      email: store.auth.userinfo.email,
      telephone: address.telephone,
    }
  }

  const orderNewAddress = () => {
    return {
      paymentmethod: "cashondelivery",
      country_id: "AE",
      street: props.location.data.street,
      city: props.location.data.city,
      firstname: props.location.data.firstname,
      lastname: props.location.data.lastname,
      email: store.auth.userinfo.email,
      telephone: props.location.data.telephone,
    };
  }

  //alert(window.frames['live-agent-container'].location)

  const OrderPlaced = async (type = 'payment') => {
    setshow(true)
    /* if (type == 'payment') {
      var url = await dispatch(PayByCard())
      setshow(false)
      if (url != false) {
        setshowurl({
          show: true,
          url: url
        })
      }
      return
    } */ 
    setshow(true)
    const address = props.location.from === "checkoutdetails"
      ? orderAddressSaved()
      : orderNewAddress();
    setData(address);
    if (address.street.length !== 0) {

      const res = await dispatch(PaymentInfo(address));

      //call mambo method after successfull order placement
      if (res !== false) {

        var koinzforprod = []

        koinz_replacement.forEach((item) => {
          var prod = cart.find((prod) => {
            return item == prod.item_id
          })
          koinzforprod.push(prod.sku)
        })

        if (koinzforprod.length !== 0) {
          await isKoinz(koinzforprod, res)
        }

          HandleReplaceWithKoinz(true).then(response => {
          koinzAmount(response, res)
        }).catch((error) => {
          console.log(error)
        })



        if (useKoinz) {
          //Fix Required
          if (koinz.points[0].balance / 5 > Gtotal) {
            await dispatch(CreatePointsActivity(-(Gtotal * 5)));
          } else {
            await dispatch(CreatePointsActivity(-koinz.points[0].balance));
          }
        }

        await HandleReferer()

        await HandleReplaceWithKoinz()

        await ParticipateinCampaign(res)

        dispatch(EmptyKoinzProduct())

        props.history.push('/orderdetails');

        dispatch({ type: ORDERID, payload: res })
      }
      props.history.push('/orderdetails');

    }
    setshow(false)
  }


  return (
    <div className="">
      <CircularLoader show={show}></CircularLoader>
      <NavBar payreview="true" />
      {showurl.show ? <iframe
        src={showurl.url}
        onLoad={async () => {
          if (document.getElementById('myframe').contentDocument != null) {
            if (document.getElementById('myframe').contentDocument.querySelector('.order-cancel') != null) {
              props.history.push('/ordercancel')
            }
            else if (document.getElementById('myframe').contentDocument.querySelector('.order-declined') != null) {
              props.history.push('/orderdeclined')
            } else {
              setshowurl({
                show: false,
                url: null
              })
              await OrderPlaced('success')
            }
          }
        }}
        title="Order Payment"
        height="100%"
        width="100%"
        name="live-agent-container"
        id="myframe"
        className="frame"
      /> : null}
      <div className="cart-container">
        {
          store.cart.cart.map(item => {
            console.log(item)
            return (
              <CheckoutCart
                {...props}
                type="payreview"
                item={item}
                totalAmount={totalAmount}
                setAmount={(amount) => {
                  setTotalAmount(totalAmount + amount)
                }} />
            )
          })
        }
        <div className="cart-amount-container">
          <div className="cart-subtotal-container">
            <div className="cart-subtotal">
              {Environment.subtotal}
              <span>{totalAmount} AED</span>
            </div>
            {/* <div className="cart-vat">
              {Environment.vat}
              <span>{Environment.vat_percentage}</span>
            </div> */}
          </div>
          <div className="koinz-balance-container">
            <div className="total-cart-amount">
              {Environment.total_amt}
              <span>AED {Math.round(/* totalAmount + 5 / 100 *  */totalAmount)}</span>
            </div>
          </div>
        </div>
        <div className="button-container">
          <Button className="button-red" data="Pay now" onClick={() => { OrderPlaced() }} />
        </div>
      </div>
    </div>
  );
};

export default PayReview;
