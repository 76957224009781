import React, { useRef, useState, useEffect } from "react";
import { Environment } from "../../env";
import Button from "../Button/Button";
import { TweenMax } from "gsap"
import { Swipeable } from 'react-swipeable'
import { useHistory } from 'react-router-dom'
import { Tween } from "react-gsap";
import { isObjEmpty } from "../../utils/utils";
import { useSelector, useDispatch } from "react-redux";
import { AddAddress, EditAddress } from "../../redux/actions/account/actions";
import { SetLang } from "../../redux/actions/account/actions"

const ChangePassword = props => {

  const [error, setError] = useState(false);
  const [active, setActive] = useState(false);
  const SheetAddress = useRef(null);
  const history = useHistory();
  const language = useSelector(state => state.account.language);
  const [lang, setlang] = useState(language)
  const dispatch = useDispatch();
  const isEditMode = props.location.query ? !!props.location.query.address : false;
  const [showPass, setshowpass] = useState(false)
  const [showRetypePass, setshowretypepass] = useState(false)

  const handleGoBack = () => {
    dispatch(SetLang(lang))
    setActive(false);
    TweenMax.fromTo(SheetAddress.current, 0.5, { y: 0, }, { y: 1000 });
    setTimeout(() => {
      props.history.push("/accountsettings");
    }, 700);
  }

  useEffect(() => {
    document.body.style.overflow = "hidden";
    TweenMax.fromTo(SheetAddress.current, 0.5, { y: 600 }, { y: 0 });
    setTimeout(() => {
      setActive(true)
    }, 700);
    return () => document.body.style.overflow = "visible";
  }, []);

  useEffect(() => {
    document.addEventListener("click", (evt) => {
      const flyoutElement = document.querySelector('.action-container');
      let targetElement = evt.target; // clicked element

      if (targetElement == flyoutElement) {
        handleGoBack()
        return;
      }

    });
  }, [])

  console.log(showRetypePass)

  return (
    <Swipeable onSwipedDown={handleGoBack}>
      <div className="action-container" ref={SheetAddress}
        style={{ backgroundColor: active ? "rgba(0,0,0,0.5)" : "rgba(0,0,0,0)" }}>
        <Tween from={{ y: "100%", opacity: 1, delay: 0.9, duration: 0.8 }}>
          <div className="action-cards">
            <div className="cards-icon pass">
              <img src={`${Environment.img_path}images/changepass.svg`} alt="" />
            </div>
            <div className="cards-title">
              Change password
            </div>
            <div className="main-language-container">
              <div className="floating-label">
                <input
                  className={`floating-input ${error ? "error" : ""}`}
                  type={showPass ? "text" : "password"}
                  //value={""}
                  onChange={() => { }}
                  autocomplete="new-password"
                />
                <div className="label-icon" onClick={() => { setshowpass(!showPass) }}>
                  <img src={`${Environment.img_path}makeicons/${showPass ? "eyeclose" : "eyeopen"}.svg`} alt="" />
                </div>
                {/* <span className="highlight" /> */}
                <label className="label_placeholder">New password</label>
              </div>
              <div className="floating-label" style={{marginBottom: 100}}>
                <input
                  className={`floating-input ${error ? "error" : ""}`}
                  type={showRetypePass ? "text" : "password"}
                  //value={""}
                  name="12"
                  onChange={() => { }}
                  autocomplete="new-password"
                />
                <div className="label-icon" onClick={() => { setshowretypepass(!showRetypePass) }}>
                  <img src={showRetypePass ? `${Environment.img_path}makeicons/eyeopen.svg` : `${Environment.img_path}makeicons/eyeclose.svg`} alt="" />
                </div>
                {/* <span className="highlight" /> */}
                <label className="label_placeholder">Retype new password</label>
              </div>
            </div>
            <div className="">
              <Button
                className="button-red"
                data={Environment.save}
                onClick={() => { handleGoBack() }} />
            </div>

          </div>
        </Tween>
      </div>
    </Swipeable>
  );
};

export default ChangePassword;
