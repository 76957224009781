import React, { useState, useEffect, useRef } from "react";
import NavBar from "../../components/NavBar/NavBar";
import { Environment } from "../../env";
import Button from "../../components/Button/Button";
import { Link, Redirect } from "react-router-dom";
import { useStore, useDispatch } from "react-redux"
import { AddShippingInfo } from "../../redux/actions/cart/actions"
import { TweenMax } from "gsap"
import { Tween } from "react-gsap";


const CheckoutYourDetails = props => {
  const [redirect, setredirect] = useState(false)
  const dispatch = useDispatch()
  const [data, setdata] = useState({
    country_id: "",
    street: [],
    city: "",
    firstname: "",
    lastname: "",
    email: "",
    telephone: "",
    save_in_address_book: "",
    addressid: ""
  });

  const [address1, setadddress1] = useState()
  const [error, seterror] = useState({
    city: null,
    firstname: null,
    lastname: null,
    email: null,
    addressline1: null,
    addressline2: null,
    save_in_address_book: null
  });

  const input1 = useRef(null)
  const input2 = useRef(null)
  const input3 = useRef(null)

  const [addressline1, setAddressline1] = useState("")
  const [addressline2, setAddressline2] = useState("")
  const [country, setCountry] = useState("AE")
  const [email, setEmail] = useState("");
  const store = useStore().getState()
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    if (store.auth.userinfo !== null) {
      input1.current.value = store.auth.userinfo.firstname || store.auth.userinfo.fname
      input2.current.value = store.auth.userinfo.lastname || store.auth.userinfo.lname
      input3.current.value = store.auth.userinfo.email
    }
  }, [])

  useEffect(() => {
    if (data.firstname !== "") {
      setredirect(true)
      setadddress1(data)
      dispatch(AddShippingInfo(data))
    }
  }, [data])

  const Checks = () => {
    var e = {
      email: null,
      firstname: null,
      lastname: null,
      addressline1: null,
      addressline2: null,
      city: null,
    };
    var re = /\S+@\S+\.\S+/;
    // if (data.shipping_address.country_id.trim().length === 0) {
    //   e.country_id = "Please enter a valid country name";
    // }
    if (input1.current.value.trim().length === 0) {
      e.firstname = "First Name cannot be empty";
    }
    if (input2.current.value.trim().length === 0) {
      e.lastname = "Last Name cannot be empty";
    }
    if (!re.test(input3.current.value.trim())) {
      e.email = "Please enter a valid Email Address!";
    }
    if (data.city.trim().length === 0) {
      e.city = "City cannot be empty";
    }
    if (addressline1.trim().length === 0) {
      e.addressline1 = "Address Line 1 cannot be empty"
    }
    if (addressline2.trim().length === 0) {
      e.addressline2 = "Address Line 2 cannot be empty"
    }
    if (
      e.email !== null ||
      e.firstname !== null ||
      e.lastname !== null ||
      e.addressline1 !== null ||
      e.addressline2 !== null ||
      e.city !== null) {
      seterror({ ...error, ...e });
      console.log("still here");
      return false
    }
    if (
      (e.email === null) &
      (e.firstname === null) &
      (e.lastname === null) &
      (e.addressline1 === null) &
      (e.addressline2 === null) &
      (e.city === null)
    ) {
      console.log("here")
      seterror({
        email: null,
        firstname: null,
        lastname: null,
        addressline1: null,
        addressline2: null,
        city: null,
      });
      // console.log({ ...data, firstname: input1.current.value, lastname: input2.current.value, email: input3.current.value, street: [addressline1, addressline2], country_id: "AE", telephone: store.auth.userinfo.phone_number, save_in_address_book: isChecked ? 1 : 0 })
      setdata({
        ...data,
        firstname: input1.current.value,
        lastname: input2.current.value,
        email: input3.current.value,
        street: [addressline1, addressline2],
        country_id: "AE",
        telephone: store.auth.userinfo.phone_number,
        save_in_address_book: isChecked ? 1 : 0
      })
      /* setdata(...data.firstname = input1.current.value)
      setdata(...data.lastname = input2.current.value)
      setdata(...data.email = input3.current.value)
      setdata(...data.street[0] = addressline1)
      setdata(...data.street[1] = addressline2)
      setdata(...data.country_id = "AE")
      if (store.auth.userinfo !== null) {
        setdata(...data.telephone = store.auth.userinfo.phone_number)
      } */
      /* else if (store.auth.userinfo === null) {
        setdata(...data.telephone = "111111111111")
      } */
      //else if (isChecked) {
      //setdata({ ...data.save_in_address_book = "1" })
      // setdata({ ...data.save_in_address_book = 1 })
      // }
      //else if (!isChecked) {
      //setdata({ ...data.save_in_address_book = "0" })
      //setdata({ ...data.save_in_address_book = 0 })
      // }
      return true
    }
  }
  /* const settingData = () => {
    return Checks();
  } */
  const AddShippingAddress = () => {
    if (Checks()) {
      //////setredirect(true)
      //////setadddress1(data)
      //if (store.auth.token !== null) {
      ///// console.log(data)
      ///// dispatch(AddShippingInfo(store.auth.token, data))
      /* } else {
        console.log("sdata", data)
        dispatch(GuestAddShippingInfo(store.auth.cartid, data))
      } */
    }
  }

  const handleCheckBox = (checked) => {
    if (checked) {
      setIsChecked(false)
      console.log(isChecked + "if")
    } else {
      setIsChecked(true)
      console.log(isChecked + "else")
    }
  }


  return (
    <div className="">
      {redirect ? <Redirect to={{ pathname: "/payreview", from: "checkoutyourdetails", data: address1 }} /> : null}

      <NavBar back="true" headertitle="Your Details" />
      <div className="yourdetails-container">
        <Tween from={{ x: "100%", opacity: 1, duration: 0.5 }}>
          <div className="forms-main">
            <div className="forms-cards">
              <div className="floating-form">
                <div className="floating-label">
                  <input className={`floating-input ${error.firstname !== null ? "error" : ""
                    }`}
                    type="text"
                    // value={store.auth.userinfo.firstname}
                    // contentEditable={true}
                    //defaultValue={firstName}
                    ref={input1}
                    placeholder=""
                    onChange={(e) => setdata({ ...data, firstname: e.currentTarget.value })
                      // setdata({...data, shipping_address: { ...data.shipping_address , firstname: e.currentTarget.value}});
                    } />
                  <span className="highlight" />
                  <label>
                    {Environment.firstname}
                  </label>
                </div>

                <div className="floating-label">
                  <input className={`floating-input ${error.lastname !== null ? "error" : ""
                    }`}
                    ref={input2}
                    placeholder=""
                    //value={lastName}
                    //onKeyUp={(e) => {setLastName(e.currentTarget.value); 
                    onChange={(e) => setdata({ ...data, lastname: e.currentTarget.value })
                    } />
                  <span className="highlight" />
                  <label>
                    {Environment.lastname}
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className={`floating-input ${error.email !== null ? "error" : ""}`}
                    type="text"
                    //value={email}
                    ref={input3}
                    placeholder=""
                    //value={store.auth.userinfo.email} contentEditable={false}  */}
                    onChange={(e) => {
                      setEmail(e.currentTarget.value)
                    }} />
                  <span className="highlight" />
                  <label>
                    {Environment.email}
                  </label>
                </div>
              </div>
            </div>


            <div className="form-screen-details">
              <h4>{Environment.Shipping}</h4>
              <h6>{Environment.delivertxt}</h6>
            </div>

            <div className="forms-cards">
              <div className="floating-form">
                <div className="floating-label">
                  <input
                    className={`floating-input ${error.addressline1 !== null ? "error" : ""}`}
                    type="text" placeholder=" "
                    onChange={(e) => {
                      setAddressline1(e.currentTarget.value);
                    }} />
                  <span className="highlight" />
                  <label>
                    {Environment.addressline1}
                  </label>
                </div>

                <div className="floating-label">
                  <input className={`floating-input ${error.addressline2 !== null ? "error" : ""
                    }`}
                    type="text" placeholder=" "
                    onChange={(e) => {
                      setAddressline2(e.currentTarget.value)
                    }}
                  />
                  <span className="highlight" />
                  <label>
                    {Environment.addressline2}
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className={`floating-input ${error.city !== null ? "error" : ""}`}
                    type="text"
                    placeholder=" "
                    onChange={(e) => setdata({ ...data, city: e.currentTarget.value })}
                  // setdata({...data, shipping_address: { ...data.shipping_address , city: e.currentTarget.value}});
                  />
                  <span className="highlight" />
                  <label>
                    {Environment.city}
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="floating-input"
                    type="text"
                    placeholder=" "
                    value="United Arab Emirates"
                  // onKeyUp={(e) => setdata({...data, country_id:e.currentTarget.value})
                  // setdata({...data, shipping_address: { ...data.shipping_address , country_id: e.currentTarget.value}});
                  />
                  <span className="highlight" />
                  <label>
                    {Environment.country}
                  </label>
                </div>

                <div className="form-checkbx">
                  <input
                    type="checkbox"
                    id="address"
                    name="box"
                    checked={isChecked}
                    //checked={isChecked}
                    onChange={() => handleCheckBox(isChecked)}
                  />
                  <label htmlFor="address">{Environment.addressttl}</label>
                </div>
              </div>
            </div>

            <div className="form-screen-details">
              <h4>{Environment.payment}</h4>
              <h6>{Environment.paytxt}</h6>
            </div>


            <div className="forms-cards">
              <div className="floating-form">
                <div className="floating-label"><input className="floating-input " type="text" placeholder=" " />
                  <span className="highlight" />
                  <label>
                    {Environment.cardname}
                  </label>
                </div>

                <div className="floating-label"><input className="floating-input " type="text" placeholder=" " />
                  <span className="highlight" />
                  <label>
                    {Environment.cardnumber}
                  </label>
                </div>

                <div className="input_inline">
                  <div className="floating-label carddate"><input className="floating-input" type="text" placeholder=" " />
                    <span className="highlight" />
                    <label>
                      {Environment.expirydate}
                    </label>
                  </div>

                  <div className="floating-label cardcvv">
                    <input className="floating-input" type="number"
                      placeholder=" " />
                    <span className="highlight" />
                    <label>
                      {Environment.cvv}
                    </label>
                  </div>
                </div>
                <div className="form-checkbx">
                  <input type="checkbox" id="payment" name="box" />
                  <label htmlFor="payment">{Environment.paymenthod}</label>
                </div>
              </div>
            </div>

            {/* <Link className="forms-cards apple-pay-btn">
              <span>
                <img
                  src={`${Environment.img_path}makeicons/ic_apple_pay.png`}
                  alt=""
                />
              </span>
              <span className="btn-text">
                {Environment.applepay}
              </span>
            </Link> */}

            <Button
              className="button-red"
              data="Review & Pay"
              onClick={AddShippingAddress}
            />
          </div>
        </Tween>
      </div>
    </div>
  );
};

export default CheckoutYourDetails;
