import React, { useRef, useState } from "react"
import { Swipeable } from 'react-swipeable';
import { Environment } from "../../env"

const TicketCluster = (props) => {

    let ticket = useRef([])
    const [currentIndex, setindex] = useState(0)

    const OnSwipeLeft = (index) => {
        if (props.type == "closedTickets") {
            return
        }
        if (index == props.children.length - 1) {
            return
        }

        setindex(index + 1)

        ticket.current[index].style.transform = `translate3d(-25px, 0px, 0px)`
        ticket.current[index].style.zIndex = index//0
        ticket.current[index].style.opacity = 0.5
        ticket.current[index].style.filter = "brightness(95%)"

        ticket.current[index + 1].style.transform = `translate3d(0px, 0px, 0px)`
        ticket.current[index + 1].style.zIndex = (props.children.length)
        ticket.current[index + 1].style.opacity = 1
        ticket.current[index + 1].style.filter = "unset"

    }

    const OnSwipeRight = (index) => {
        if (props.children.length == 1) {
            return
        }

        if (index == 0) {
            return
        }

        setindex(index - 1)

        ticket.current[index - 1].style.transform = `translate3d(0px, 0px, 0px)`
        ticket.current[index - 1].style.zIndex = (props.children.length)
        ticket.current[index - 1].style.opacity = 1
        ticket.current[index - 1].style.filter = "unset"


        for (let i = index; i < props.children.length; i++) {
            ticket.current[i].style.zIndex = ticket.current[i].style.zIndex - 1
        }

        ticket.current[index].style.transform = `translate3d(25px, 0px, 0px)`
        ticket.current[index].style.opacity = 0.5
        ticket.current[index].style.filter = "brightness(95%)"
        //ticket.current[index].style.zIndex = (props.children.length-1)


    }
    return (
        <div className={`ticket-cluster ${props.type == "closedTickets" ? 'clsd' : ''}`}>
            {props.show ? <span className={`left-arrow ${currentIndex == props.children.length - 1 ? 'disable' : ''}`} onClick={() => { OnSwipeLeft(currentIndex) }}>
                <img src={`${Environment.img_path}makeicons/arrowticket.svg`} />
            </span> : null}
            {props.show ? <span className={`right-arrow ${currentIndex == 0 ? 'disable' : ''}`} onClick={() => { OnSwipeRight(currentIndex) }}>
                <img src={`${Environment.img_path}makeicons/arrowticket.svg`} />
            </span> : null}
            {props.children.map((item, index) => {
                return (
                    <Swipeable onSwipedLeft={() => { OnSwipeLeft(index) }} onSwipedRight={() => { OnSwipeRight(index) }}>
                        <div ref={e => ticket.current[index] = e} className="child" style={{ zIndex: (props.children.length - index), transform: `translate3d(${index == 0 ? 0 : 25}px, 0px, 0px)` }}>
                            {props.children[index]}
                        </div>
                    </Swipeable>
                )
            })}
        </div>
    )
}

export default TicketCluster
