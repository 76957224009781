import { GENERATE_OTP } from "../actions/auth/actionTypes";

const initialState = {
   OTPmessage: {},
};

const OTPMessage = (state = initialState, action) => {
   switch (action.type) {
      case GENERATE_OTP:
         return {
            ...state,
            ...action,
         };
      default:
         return state;
   }
};

export default OTPMessage;
