import thunk from 'redux-thunk';
import { combineReducers, createStore, applyMiddleware } from "redux"
import { composeWithDevTools } from 'redux-devtools-extension';
import CartReducer from "../reducer/cart";
import HomeReducer from "../reducer/home";
import AuthReducer from "../reducer/auth";
import MamboReducer from '../reducer/mambo';
import AccountReducer from '../reducer/account';
import FavouritesReducer from '../reducer/favourites';
import OTPMessage from "../reducer/otp"

const rootReducer = combineReducers({
  auth: AuthReducer,
  cart: CartReducer,
  home: HomeReducer,
  mambo: MamboReducer,
  account: AccountReducer,
  favourite: FavouritesReducer,
  otpMessage:OTPMessage
});

const configureStore = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default configureStore;