import React, { useState, useEffect } from 'react';
import { Environment } from '../../env';
import TicketList from '../../components/TicketList/TicketList';
import { Link, useHistory } from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar';
import EmptyTicket from './EmptyTicket';
import { useStore } from 'react-redux';
import Button from '../../components/Button/Button';
const script = document.createElement('script');
script.src =
   'https://i.ctnsnet.com/int/integration?pixel=67834349&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}';
script.async = true;
const Tickets = props => {
   const [qty, setQty] = useState(0);
   const [order, setorder] = useState('DESC');
   const store = useStore().getState();
   const history = useHistory();
   const Sort = () => {
      order === 'DESC' ? setorder('ASC') : setorder('DESC');
   };
   const [show, setshow] = useState(false);

   useEffect(() => {
      if (document.getElementById('tickets-page-wrapper'))
         document.getElementById('tickets-page-wrapper').appendChild(script);
      setTimeout(() => {
         setshow(true);
      }, 170);
      return () => {
         if (document.getElementById('tickets-page-wrapper'))
            document.getElementById('tickets-page-wrapper').removeChild(script);
      };
   }, []);
   console.log(props);
   return (
      <div className="ticket-container" id="tickets-page-wrapper">
         <NavBar ticketheader="true" qty={qty} {...props} />

         {store.auth.token === null ? (
            <div className="order-container">
               {/* <div className="desktop-header">{props.Environment.yourtickrs}</div> */}
               <EmptyTicket {...props} />
            </div>
         ) : (
            <div className="watchlist-container watchlist-container2">
               {/* <div className="desktop-header">{props.Environment.yourtickrs}</div> */}
               <div className="order-details">
                  {qty == 0 ? <EmptyTicket {...props} /> : null}
                  {show ? (
                     <TicketList
                        {...props}
                        type="openTickets"
                        setQty={count => {
                           setQty(count);
                        }}
                        orderid={null}
                        hasQty
                        order={order}
                     />
                  ) : null}
                  {/* {show ? <h4>{props.Environment.closedcampaigntxt}</h4> : null} */}
                  {show ? (
                     <TicketList {...props} type="closedTickets" hasQty={false} orderid={null} order={order} />
                  ) : null}
                  {show ? (
                     <Button
                        className="button-red"
                        onClick={() => {
                           history.push('/myhistory');
                        }}
                        data={props.Environment.orderhistory}
                     />
                  ) : null}
                  {/* <div className="view-tckt-history">
                        <span>{props.Environment.viewtxt}</span>
                        <Link to="/myhistory">{props.Environment.orderhistory}</Link>
                     </div> */}
               </div>
            </div>
         )}
      </div>
   );
};

export default Tickets;
