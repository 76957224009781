import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar';
import { Environment } from '../../env';
import { Tween } from 'react-gsap';
import MonthWinner from '../../components/MonthWinner/MonthWinner';
import { useDispatch, useStore } from 'react-redux';
import { GetWinnerList } from '../../redux/actions/home/actions';
import { get } from 'lodash';
const script = document.createElement('script');
script.src =
   'https://i.ctnsnet.com/int/integration?pixel=67834349&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}';
script.async = true;
const ThisMonthWinner = props => {
   const history = useHistory();
   const store = useStore().getState();
   const dispatch = useDispatch();
   useEffect(() => {
      if (document.getElementById('this-month-winner-wrapper'))
         document.getElementById('this-month-winner-wrapper').appendChild(script);
      return () => {
         if (document.getElementById('this-month-winner-wrapper'))
            document.getElementById('this-month-winner-wrapper').removeChild(script);
      };
      dispatch(GetWinnerList());
   }, []);

   const onclick = () => {
      console.log('hey there');
      history.push('/faqs');
   };

   return (
      <div className="" id="this-month-winner-wrapper">
         <NavBar thismonthwinner="thismonthwinner" />
         <div className="thismonthwinner-main-container">
            {/* <h2>{props.Environment.emptyyourwinner}</h2> */}
            <Tween from={{ x: '100%', opacity: 1, duration: 0.5 }}>
               <div className="winner-page-container">
                  {get(store, 'home.winnerList', []).length == 0 ? (
                     <span className="nowinner-text">{props.Environment.winneraccouned}</span>
                  ) : null}
                  <div className="winner-page">
                     {get(store, 'home.winnerList', []).map(data => (
                        <MonthWinner winnerlist={data} />
                     ))}
                  </div>
                  <div className="howtowinner" onClick={() => history.push('/faqs')}>
                     {Environment.winnerfindouttxt}
                     {/* <Link to="/#/faqs"> */}
                     {/* <span>{Environment.findouttxt}</span> */}
                     {/* </Link> */}
                  </div>
               </div>
            </Tween>
         </div>
      </div>
   );
};

export default ThisMonthWinner;
