import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

const ScrollToTop = React.memo(props => {
   useEffect(() => {

      if (!props.scrollDisabledPathname.includes(props.location.pathname)) {
         //window.scrollTo({top: 0, left: 0 , behavior: 'smooth'})
         scrollToTop()
      }
      //window.scrollTo(0, 0);
   }, [props.location.pathname]);

   return props.children;
});

function scrollToTop() {
   if (document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0) {
      window.scrollTo(0, 0)
       //window.scrollBy(0, -50);
       //requestAnimationFrame(scrollToTop);
   }
}

export default withRouter(ScrollToTop);
