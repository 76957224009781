import GetApi from "../../../api/GetApi"
import AuthApi from "../../../api/AuthApi"
import { Environment as env_en } from "../../../env"
import { Environment as env_ar } from "../../../env_ar"
import Axios from "axios"
import {
  SETALLPRODUCTS,
  SETBANNERS,
  SETCATEGORIES,
  SETPRODBYCAT,
  SETPRIZESNEXTMONTH,
  SETCATEGORY,
  GETTIP, GETVOTE, SAVEVOTE, GETCURRENCYDATA,
  UNSUBSCRIBE_TIP,
  FETCH_WINNER,
  SETPREVROUTE,
  SETPREVTAB,
  SETDEVICEIFO,
  SETCAMPAIGNMANAGER,
  SETENDINGSOONPRODUCTS,
  SETLANG,
  SETDESKTOPPRODUCTS,
  SETPOPUPDATA,
  SETREWARDS,
  SETRECALL,
  SETNOTIFICATION,
  ADDNOTIFICATION,
  REMOVENOTIFICATION,
  SETMASTERDATA,
  SETCAMPAIGNBYCATEGORY
} from "./actionTypes"
// import { RedditIcon } from "react-share"
// import { isArray } from "jquery"


export const GetCategories = () => async (dispatch, getState) => {

  let base_url = localStorage.getItem('langage') == "ar" ? env_ar : env_en
  // alert(JSON.stringify(base_url))
  try {
    const res = await GetApi({
      baseURL: base_url.base_url,
      method: 'GET',
      url: '/categotylist?searchCriteria[filterGroups][0][filters][0][field]=entity_id&searchCriteria[filterGroups][0][filters][0][value]=1,2&searchCriteria[filterGroups][0][filters][0][conditionType]=nin&searchCriteria[filterGroups][0][filters][0][field]=parent_id&searchCriteria[filterGroups][0][filters][0][value]=2&searchCriteria[filterGroups][0][filters][0][conditionType]=eq&searchCriteria[sortOrders][1][field]=position&searchCriteria[sortOrders][1][direction]=ASC'
    })
    dispatch({
      type: SETCATEGORIES,
      payload: res.data.items
    })
    return res.data.items[0]
  } catch (e) {
    console.log(e)
    return false
  }
}

export const MasterApiData = () => async (dispatch, getState) => {

  let base_url = localStorage.getItem('langage') == "ar" ? env_ar : env_en

  try {
    const res = await GetApi({
      baseURL: base_url.base_url,
      method: 'GET',
      url: '/master-data'
    })
    dispatch({
      type: SETMASTERDATA,
      payload: res.data[0]
    })
    return true//res.data.items[0]
  } catch (e) {
    console.log(e)
    return false
  }
}
export const Ordermail = (orderid, method) => async (dispatch, getState) => {
  // alert('calling api');

  // let orderid = orderid.orderId;
  console.log(orderid);
  console.log(method);
  let orderid1 = orderid;
  if (method == "normal") {
    orderid1 = orderid;
    // alert(orderid1);
  }
  else {
    orderid1 = JSON.stringify(orderid1.orderId);
    console.log(orderid1)
    orderid1 = orderid1.replace(/^"|"$/g, '');
  }



  const token = getState().auth.token;
  const userinfo = getState().auth.userinfo;
  const carfinfo = getState().cart.customerData;
  let base_url = localStorage.getItem('langage') == 'ar' ? env_ar : env_en;

  try {
    const res = await AuthApi({
      method: 'GET',
      url: '/order-email/send/' + orderid1,
      headers: {
        Authorization: 'Bearer ' + token,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    });

    console.log("data api" + JSON.stringify(res.data));
    return res.data;
  } catch (e) {
    // alert(JSON.stringify(e))
    return null;
  }

}

export const GetCampaignManager = () => async (dispatch, getState) => {

  let base_url = localStorage.getItem('langage') == "ar" ? env_ar : env_en

  try {
    const response = await AuthApi({
      baseURL: base_url.base_url,
      method: 'GET',
      url: '/magedad-campaignmanagement?searchCriteria[pageSize]=0',
    })
    dispatch({
      type: SETCAMPAIGNMANAGER,
      payload: response.data.items
    })
    dispatch({
      type: SETCAMPAIGNBYCATEGORY,
      payload: response.data.items
    })
    console.log(response.data.items);
    return response.data.items

  } catch (e) {
    return false
    console.log(e)
  }
}

export const GetBanners = () => async (dispatch, getState) => {

  let base_url = localStorage.getItem('langage') == "ar" ? env_ar : env_en

  try {
    const res = await GetApi({
      baseURL: base_url.base_url,
      method: 'GET',
      url: '/bannermanagement/slider'
    })
    dispatch({
      type: SETBANNERS,
      payload: res.data.banners.items
    })
    return true
  } catch (e) {
    console.log(e)
    return false
  }
}

//Helper function for GetAllProducts
const FilterProducts = prod => {
  return prod.sort((x, y) => {
    const prop1 = x.custom_attributes[10].value / x.custom_attributes[11].value
    const prop2 = y.custom_attributes[10].value / y.custom_attributes[11].value
    return prop2 - prop1
  })
}

export const GetAllProducts = () => async (dispatch, getState) => {

  let base_url = localStorage.getItem('langage') == "ar" ? env_ar : env_en

  try {
    const res = await GetApi({
      baseURL: base_url.base_url,
      method: 'GET',
      url: '/products?searchCriteria[filterGroups][0][filters][0][field]=status&searchCriteria[filterGroups][0][filters][0][value]=1&searchCriteria[filterGroups][0][filters][0][conditionType]=eq'
    })
    const data = res.data.items
    dispatch({
      type: SETALLPRODUCTS,
      payload: data
    })
    return true
  } catch (e) {
    console.log(e)
    return false
  }
}

export const GetProdByCat = (id, campaigns) => async (dispatch, getState) => {
  try {
    var new_array = []
    var soldout_array = []
    var campaign_ids = getState().home.master_api[id];
    var campaign_manager = getState().home.campaign_manager;
    var campaigns = [];
    var category = getState().home.categories.find(cat => cat.id == id);

    if (campaign_ids || category.name == "All" || category.name.includes("كافّة  الجوائز ")) {
      if (category.name == "All" || category.name.includes("كافّة  الجوائز ")) {

        campaigns = getState().home.campaign_manager;

      } else {
        campaign_ids.forEach(id => {
          let campaign = campaign_manager.find(camp => camp.campaign_id == id);
          campaigns.push(campaign)
        })
      }
    }

    campaigns.forEach(campaign => {
      if (campaign.campaign_total_tickets == campaign.campaign_total_sales) {
        soldout_array.push(campaign);
      }
      else {
        new_array.push(campaign);
      }
    })
    campaigns = new_array.concat(soldout_array);
    dispatch({
      type: SETCAMPAIGNBYCATEGORY,
      payload: campaigns
    })
    return true
  } catch (e) {
    console.log(e)
    return false
  }
}

export const GetPrizesNextMonth = () => async (dispatch, getState) => {

  let base_url = localStorage.getItem('langage') == "ar" ? env_ar : env_en

  try {
    const res = await GetApi({
      baseURL: base_url.base_url,
      method: 'GET',
      url: '/products?searchCriteria[filterGroups][0][filters][0][field]=status&searchCriteria[filterGroups][0][filters][0][value]=1&searchCriteria[filterGroups][0][filters][0][conditionType]=eq&searchCriteria[filterGroups][0][filters][0][field]=attribute_set_id&searchCriteria[filterGroups][0][filters][0][value]=10&searchCriteria[filterGroups][0][filters][0][conditionType]=eq&searchCriteria[filterGroups][0][filters][0][field]=prize_next_month&searchCriteria[filterGroups][0][filters][0][value]=1&searchCriteria[filterGroups][0][filters][0][conditionType]=eq'
    })
    dispatch({
      type: SETPRIZESNEXTMONTH,
      payload: res.data.items
    })
    return true
  } catch (e) {
    console.log(e)
    return false
  }
}

/// New Curancy Data API

export const GetCurrencyData = () => async (dispatch, getState) => {
  const token = getState().auth.token;

  try {
    const res = await AuthApi({
      method: 'GET',
      url: '/directory/currency',
    })
    dispatch({ type: GETCURRENCYDATA })
    console.log(res.data);
    dispatch({ type: GETCURRENCYDATA, payload: res.data.exchange_rates })
    return res.data
  } catch (e) {
    console.log(e)
    return false
  }
}

export const GetTip = () => async (dispatch, getState) => {
  const token = getState().auth.token;
  let base_url = localStorage.getItem('langage') == "ar" ? env_ar : env_en

  try {
    const res = await AuthApi({
      baseURL: base_url.base_url,
      method: 'GET',
      url: '/today/tips',
      headers: {
        Authorization: "Bearer " + token
      }
    })
    dispatch({ type: GETTIP })
    console.log(res.data);
    return res.data.items
  } catch (e) {
    console.log(e);
    return false
  }
}

export const ShowTip = () => async (dispatch, getState) => {
  const token = getState().auth.token;
  //  let base_url = localStorage.getItem('langage') == "ar" ? env_ar : env_en

  var show = false;

  try {
    const res = await AuthApi({
      // baseURL: base_url.base_url,
      method: 'GET',
      url: '/tips/display',
      headers: {
        Authorization: "Bearer " + token
      }
    })

    if (res.data.includes("0")) {
      show = false;
    } else {
      show = true;
    }

    console.log(res.data)

    return show
  } catch (e) {
    console.log(e)
    return false
  }
}

export const SetTipRead = (tip_id) => async (dispatch, getState) => {
  const token = getState().auth.token;

  var date = new Date();

  console.log()

  var show = false;

  try {
    const res = await AuthApi({
      method: 'POST',
      url: '/tips/read/customer',
      data: {
        tipId: tip_id,
        tipDate: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      },
      headers: {
        Authorization: "Bearer " + token
      }
    })

    return true

  } catch (e) {
    console.log(e)
    return false
  }
}



export const UpdateTipAndVotePromotion = (data, type, value) => async (dispatch, getState) => {
  const token = getState().auth.token;

  try {
    await AuthApi({
      method: 'PUT',
      url: '/customers/me',
      data: {
        customer: {
          ...data,
          custom_attributes: [{
            attribute_code: type === 'tip' ? 'show_tips' : 'show_polls',
            value: value === 'subscribe' ? 1 : 0
          }]
        }
      },
      headers: {
        Authorization: "Bearer " + token
      }
    })
    dispatch({ type: UNSUBSCRIBE_TIP })
    return true
  } catch (e) {
    console.log(e)
    return false
  }
}

export const UpdateDevice = (data, device_token, device_type) => async (dispatch, getState) => {
  const token = getState().auth.token;

  try {
    await AuthApi({
      method: 'PUT',
      url: '/customers/me',
      data: {
        customer: {
          ...data,
          "custom_attributes": [
            {
              "attribute_code": "device_token",
              "value": device_token
            },
            {
              "attribute_code": "device_type",
              "value": device_type
            }
          ]
        }
      },
      headers: {
        Authorization: "Bearer " + token
      }
    })
    localStorage.setItem('DeviceDetach', true);
    // dispatch({ type: UNSUBSCRIBE_TIP })
    return true
  } catch (e) {
    console.log(e)
    return false
  }
}

export const GetVote = () => async (dispatch, getState) => {
  const token = getState().auth.token;

  try {
    const res = await AuthApi({
      method: 'GET',
      url: '/polls/list',
      headers: {
        Authorization: "Bearer " + token
      }
    })
    dispatch({ type: GETVOTE })
    return res.data.items
  } catch (e) {
    console.log(e)
    return false
  }
}

export const SaveVote = data => async (dispatch, getState) => {
  const token = getState().auth.token;

  if (!token) return false;

  try {
    await AuthApi({
      method: 'POST',
      url: '/polls/result',
      data: {
        pollResult: data
      },
      headers: {
        Authorization: "Bearer " + token
      }
    })
    dispatch({ type: SAVEVOTE })
    return true
  } catch (e) {
    console.log(e)
    return false
  }
}

export const SetCategory = category => dispatch => {
  dispatch({
    type: SETCATEGORY,
    payload: category
  })
}




export const GetWinnerList = () => async dispatch => {
  try {
    const response = await AuthApi({
      method: 'GET',
      url: '/winners',
    })
    dispatch({
      type: FETCH_WINNER,
      winnerList: response.data.items
    })
    console.log(response);
  } catch (e) {
    console.log(e)
  }

}

export const SetPrevRoute = (route) => dispatch => {
  dispatch({
    type: SETPREVROUTE,
    payload: route
  })
}

export const SetPrevTab = (tab) => dispatch => {
  dispatch({
    type: SETPREVTAB,
    payload: tab
  })
}


export const SetDeviceInfo = (device_token, device_type) => dispatch => {
  dispatch({
    type: SETDEVICEIFO,
    payload: { device_token, device_type }
  })
}

export const Getendingsoonproducts = () => async (dispatch, getState) => {

  let base_url = localStorage.getItem('langage') == "ar" ? env_ar : env_en

  try {
    const res = await GetApi({
      baseURL: base_url.base_url,
      method: 'GET',
      url: '/ending-soon?searchCriteria[pageSize]=20&searchCriteria[filterGroups][0][filters][0][field]=status&searchCriteria[filterGroups][0][filters][0][value]=1&searchCriteria[filterGroups][0][filters][0][conditionType]=eq&searchCriteria[filterGroups][0][filters][0][field]=attribute_set_id&searchCriteria[filterGroups][0][filters][0][value]=4&searchCriteria[filterGroups][0][filters][0][conditionType]=eq'
    })

    dispatch({
      type: SETENDINGSOONPRODUCTS,
      payload: res.data.items
    })
    return true
  } catch (e) {
    console.log(e)
    return false
  }
}


export const SetLang = (language) => dispatch => {
  dispatch({ type: SETLANG, payload: language })
}

export const GetDesktopProducts = () => async (dispatch, getState) => {

  let base_url = localStorage.getItem('langage') == "ar" ? env_ar : env_en
  try {
    const res = await GetApi({
      baseURL: base_url.base_url,
      method: 'GET',
      url: '/products?searchCriteria[filterGroups][0][filters][0][field]=status&searchCriteria[filterGroups][0][filters][0][value]=1&searchCriteria[filterGroups][0][filters][0][conditionType]=eq&searchCriteria[filterGroups][0][filters][0][field]=attribute_set_id&searchCriteria[filterGroups][0][filters][0][value]=4&searchCriteria[filterGroups][0][filters][0][conditionType]=eq'
    })

    dispatch({ type: SETDESKTOPPRODUCTS, payload: res.data.items })
    return true
  }
  catch (e) {

  }
}


export const GetTickets = async (id) => {
  let a = window.location.href;
  let detail = a.split('=')[1];
  console.log('campaignId1' + detail);
  let campaignId = detail;

  // alert(campaignId);


  try {
    const res = await GetApi({
      method: 'GET',
      url: `get-campaign-ticket-orders?campaignId=` + campaignId
    });

    console.log(res.data)

    return res.data
  }
  catch (e) {

  }
}

export const GenerateProductLink = async (id) => {
  try {
    const res = await Axios({
      method: 'POST',
      url: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDEa58jyckEbrBXaUy8M5oJ3AFGb5i9A6I',
      data: {
        //        "longDynamicLink": "https://kanzi.page.link/?link=https://kanziapp/products/&apn=com.kanziapp&ibi=com.kanziapp.KANZI",
        "longDynamicLink": `https://kanzi.page.link/?link=https://kanziapp/products/${id}&apn=com.kanziapp&afl=https://play.google.com/store/apps/details?id=com.kanziapp&ibi=com.kanziapp.KANZI&ifl=https://apps.apple.com/us/app/kanzi/id1563930820`,
      }
    });
    // alert(res.data.shortLink)
    if (res.status === 200) {
      return res.data.shortLink
    }
    console.log(res.data)
    return false
  }
  catch (error) {
    console.log(error)
    return false
  }
}

export const SetPopupData = (render = false, show = false, text = null, img = "") => dispatch => {
  dispatch({
    type: SETPOPUPDATA, payload: {
      render,
      show,
      text,
      img
    }
  })
}

export const SetRewards = rewards => dispatch => {
  dispatch({
    type: SETREWARDS,
    payload: rewards
  })
}

export const SetRecall = () => dispatch => {
  console.log("ehy from the other side")
  dispatch({
    type: SETRECALL
  })
}

export const GetNotifications = () => async (dispatch, getState) => {
  const token = getState().auth.token;
  let base_url = localStorage.getItem('langage') == "ar" ? env_ar : env_en

  try {
    const res = await AuthApi({
      baseURL: base_url.base_url,
      method: 'GET',
      url: '/upcoming-notification/mine?type=upcoming_prize',
      headers: {
        Authorization: "Bearer " + token,
        crossorigin: true
      }
    });
    console.log(res.data)

    if (Array.isArray(res.data)) {
      dispatch({
        type: SETNOTIFICATION,
        payload: res.data
      });
      return true;
    }
    return false;
  } catch (e) {
    console.log(e);
    return false;
  }
}


export const AddNotifications = (id) => async (dispatch, getState) => {
  const token = getState().auth.token;
  let base_url = localStorage.getItem('langage') == "ar" ? env_ar : env_en

  try {
    const res = await AuthApi({
      baseURL: base_url.base_url,
      method: 'POST',
      url: '/set-notification',
      headers: {
        Authorization: "Bearer " + token,
        crossorigin: true
      },
      data: {
        data: {
          entity_id: id,
          entity_type: "prize",
          notification_type: "upcoming_prize",
          notification_text: "Favourite Upcoming Prize."
        }
      }
    });

    console.log(res.data)

    if (res.data) {
      dispatch({
        type: ADDNOTIFICATION,
        payload: { prize_id: id, notification_id: res.data }
      });
      return true;
    }
    return false;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export const DeleteNotifications = (id, prize_id) => async (dispatch, getState) => {
  const token = getState().auth.token;
  let base_url = localStorage.getItem('langage') == "ar" ? env_ar : env_en

  dispatch({
    type: REMOVENOTIFICATION,
    payload: prize_id
  });

  try {
    const res = await AuthApi({
      baseURL: base_url.base_url,
      method: 'DELETE',
      url: '/upcoming-notification/mine?notificationId=' + id,
      headers: {
        Authorization: "Bearer " + token,
        crossorigin: true
      }
    });

    console.log(res.data)



    return true;

  } catch (e) {
    console.log(e);
    return false;
  }
}
