import { ADDFAVOURITE, REMOVEFAVOURITE, GETALLFAV, SETISFAV } from './actionTypes';
import GetApi from '../../../api/GetApi';
import { Environment as env_en } from '../../../env';
import { Environment as env_ar } from '../../../env_ar';

export const AddFavourite = (token, prod_id, product) => async (dispatch, getState) => {
   try {
      let camp = getState().home.campaign_manager.find(campaign => campaign.campaign_product_id == prod_id);
      let product = getState().home.allproducts.find(prod => prod.id == prod_id);

      if (token == null) {
         let prod = {
            product_id: prod_id,
            product: {
               total_stock: camp.campaign_total_tickets,
               total_sales: camp.campaign_total_sales,
               name: product.name,
               price: product.price,
               image: product.custom_attributes.find(attr => attr.attribute_code == 'image').value,
               type_id: product.type_id,
               sku: product.sku,
               campaign_type: camp?.campaign_type,
               campaign_end_time: camp?.campaign_end_time,
               activation_date: camp?.activation_date,
            },
            // wishlist_item_id: prod_id
         };

         let fav = [...getState().favourite.favourites, prod];

         localStorage.setItem('fav', JSON.stringify(fav));

         return dispatch({
            type: ADDFAVOURITE,
            payload: prod,
         });
      }
      dispatch({ type: ADDFAVOURITE, payload: { product_id: prod_id } });
      const res = await GetApi({
         method: 'POST',
         url: '/wishlist/add/' + prod_id,
         headers: {
            Authorization: 'Bearer ' + token,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
         },
      });

      if (res.data) {
         console.log(res.data);
         var prod = product;
         prod.wishlist_item_id = res.data;
         dispatch({ type: ADDFAVOURITE, payload: prod });
      }
   } catch (e) {
      console.log(e);
   }
};

export const RemoveFavourite = (token, wishlist_id, prod_id) => async (dispatch, getState) => {
   try {
      if (token == null) {
         let fav = getState().favourite.favourites;
         fav = fav.filter(item => item.product_id != prod_id);

         console.log(prod_id);
         console.log(fav);

         localStorage.setItem('fav', JSON.stringify(fav));
         dispatch({ type: REMOVEFAVOURITE, payload: prod_id });
         return;
      }
      dispatch({ type: REMOVEFAVOURITE, payload: prod_id });
      const res = await GetApi({
         method: 'DELETE',
         url: '/wishlist/delete/' + wishlist_id,
         headers: {
            Authorization: 'Bearer ' + token,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
         },
      });

      if (res.data) {
         // dispatch({ type: REMOVEFAVOURITE, payload: prod_id })
      }
   } catch (e) {
      console.log(e);
   }
};

export const GetAllFavourite = token => async (dispatch, getState) => {
   if (token == null) {
      let fav = localStorage.getItem('fav');
      if (fav == undefined) {
         dispatch({ type: GETALLFAV, payload: [] });
      } else {
         dispatch({ type: GETALLFAV, payload: JSON.parse(fav) });
      }

      return;
   }

   let base_url = localStorage.getItem('langage') == 'ar' ? env_ar : env_en;

   try {
      const res = await GetApi({
         baseURL: base_url.base_url,
         method: 'GET',
         url: '/wishlist/items',
         headers: {
            Authorization: 'Bearer ' + token,
            crossorigin: true,
         },
      });

      if (Array.isArray(res.data)) {
         dispatch({ type: GETALLFAV, payload: res.data });
         return res.data;
      }

      console.log(res.data);
      return false;
   } catch (e) {
      return false;
   }
};

export const SetisFav = () => dispatch => {
   console.log('hey');
   dispatch({ type: SETISFAV });
};
