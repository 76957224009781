import React from 'react';

const CustomProgressbar = props => {
   const {
      progress,
      maxRectRadius = 120,
      maxDash = 205,
      radius = 100,
      strokeWidth = 10,
      trackStrokeWidth = 10,
      strokeColor = progress > 50 ? '#e03a3f' : '#ffc700',
      pointerFillColor = progress > 50 ? '#e03a3f' : '#ffc700',
      soldOut = false,
   } = props;

   const rectRadius = maxRectRadius - (progress * maxRectRadius) / 100;
   const dashOffset = (maxDash - (progress * maxDash) / 100) * -1;

   return (
      <svg width="228" height="228" viewBox="0 0 228 228" style={{ transform: 'rotate(342deg)' }}>
         <circle
            cx="114"
            cy="114"
            r={radius}
            fill="none"
            stroke="#e6e6e6"
            strokeWidth={trackStrokeWidth}
            strokeDasharray={`${maxDash}, 628.3185307179587`}
            strokeLinecap="round"
            className="progress-ReactCustomizableProgressbar-track"
            style={{ transition: 'all 0.3s ease 0s' }}
         />
         <circle
            cx="114"
            cy="114"
            r={radius}
            fill="#ffffff00"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeDasharray={`${maxDash + dashOffset}, 628.3185307179587`}
            strokeDashoffset={dashOffset}
            strokeLinecap="round"
            className="progress-ReactCustomizableProgressbar-progress"
            style={{ transition: 'all 3s ease 0s' }}
         />
         {!soldOut && (
            <rect
               width="25"
               height="10"
               rx="5"
               fill={pointerFillColor}
               style={{
                  transformOrigin: '50% 50%',
                  transform: `rotate(${rectRadius}deg) translate(201px) translateY(105px)`,
                  transition: 'all 3s ease 0s',
               }}
            />
         )}
      </svg>
   );
};

export default CustomProgressbar;
