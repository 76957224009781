// import SomgitethingWrong from './components/SomethingWrong/SomethingWrong';

export const Environment = {
   prodapitoken: 'zs667jaikfh4rb34vfqv49k86vb7xcq7',
   // base_url: 'https://kanzimigration.bluelogic.ai/rest/V1', //for sit - enable this
   // app_domain_url: 'https://kanzimigration.bluelogic.ai', //for sit - enable this
   base_url: 'https://kanziapp.org/rest/V1',  //for production - enable this
   app_domain_url: 'https://kanziapp.org', //for production - enable this
   //base_path: "/", //route path in local
   //base_path: "/kanzi-app/", //path for live
   // membo_url: 'https://kanziapp.net',
   membo_url: 'https://kanziapp.net',
   img_path: './', // images variable in live
   //img_path: "", // images variable in local
   img_url: 'https://kanziapp.org/pub/media/magedad/banners/',
   productUrl: 'https://kanziapp.org/pub/media/catalog/product',
   otp_cards_title: 'OTP Verification',
   creatingacc: 'Create an account',
   nonefindouttxt: "Are you excited to know who's the luckiest of them all? Stay tuned!",
   vate: 'VAT Included.',
   koinznew: 'Koinz?',
   whatswipe: 'Swap your product',
   whatswipesecdesc: 'with Koinz!',
   vate1: 'Total (Including VAT)',
   whatswipedescription11: 'Did you know that you can swap your product with Koinz?',
   whatswipedescription: 'You can do that by clicking the green bar below the ticker.',
   whatswipdeconfdesctpion: 'Koinz can be redeemed against other campaigns to increase your chances of winning.',

   Product_description: 'Product description',
   Proize_description: 'Prize description',
   text_card_desc:
      'Your account will be created after you successfully place your order. Provide your personal details to get started.',
   next: 'Next',
   winnerexplore: 'Explore our winners',
   otp_cards_subtitle: 'We will send a <strong>One Time Password</strong> <br/> to the mobile number below',
   otp_verification_cards_subtitle: 'Please enter the <strong>One Time Password</strong> <br/> sent to ',
   otp_card_text1: 'Haven’t received the OTP?',
   resend_code: 'Resend code',
   verify: 'Verify',
   phone_number: 'Phone Number',
   firstname: 'First Name',
   cmap: 'View Details',
   lastname: 'Last Name',
   email: 'Email',
   nationality: 'Nationality',
   gender: 'Gender',
   male: 'Male',
   female: 'Female',

   // onboarding
   obaordigdra: 'Draw launches when all products are',
   obaordigdraspan: 'sold out!',
   obaordigchnage: 'FOR A CHANCE TO',
   obaordigdrachangewin: 'win',
   obaordigdrachangethis: 'this',
   obaordigdraskip: 'skip',
   obaordigdragetachnace: 'get a chance to',
   obaordigdraskipwin: 'WIN',
   obaordigdrasaedvalue: 'AED 5,000 WORTH OF GOLD',
   obaordigdrabuykoinz: 'Buy a KANZI T-SHIRT',
   obaordigdraawsvaluek: 'AED 150',
   obaordigdraawsaddcard: 'Add to cart',
   obaordigdrabuydv: 'Shop',
   obaordigdrabuythis: 'This',
   obaordigdrakoinzcollect: 'Earn',
   obaordigdraskipcardkoinz: 'Koinz?',
   obaordigdraviewdeatilds: 'View Details',
   obaordigdrakoinzdetails: 'Shop more to earn Koinz that you can redeem on future campaigns!',
   obaordigdDoneBtn: 'Done',
   obaordigdNextBtn: 'Next',

   //singuptxt

   singup: 'Sign up',
   singuptxt: 'Enter your personal details below',
   youtdetails: 'Your details',

   buycollectKoinz: 'Collect more to spend more', //'buy and collect them',
   endingsoon: 'Ending Soon!',
   levelofluxury: 'What would you like to ',
   // levelofluxury: 'What’s your level of ',
   luxury: 'win today?',
   // luxury: 'luxury?',
   cash: 'Cash',
   gold: 'Gold',
   motors: 'Motors',
   diamond: 'Diamond',
   prizes_next_month: 'Prizes next month!',
   who_celebrating: 'See who we’re celebrating this month!',
   winnerlist_title: 'This months winners!',
   emptycart_title: 'Your cart is empty!',
   emptycart_text: 'We’ve got some great campaigns at the moment! Let’s get shopping!',
   gmaplty: 'Get shopping',
   ct: 'cart',

   v1: 'USD - United States Dollar',
   v2: 'AED - United Arab Emirates Dirham',
   addressline1: 'Address line 1',
   addressline2: 'Address line 2',
   city: 'City',
   country: 'Country',
   cardname: 'Name on card',
   cardnumber: 'Card number',
   expirydate: 'Expiry Date',
   cvv: 'cvv',

   applepay: 'Or use Apple pay',
   paymenthod: 'Set as default payment method',
   apaymenthod: 'Provide Card Details',
   p1: 'Your name on card',
   p2: '16 digit card number',
   p3: '3 digit',
   paytxt: 'How would you like to pay?',
   payment: 'Payment',
   addressttl: 'Set as default shipping address',
   spanpasstry: 'Please use at least',
   agreetxt: 'I agree to the ',
   prodagreetxt: 'I agree to receive updates,offers ad promotions abount kanzi',
   agreetxt1: 'Terms & Conditions',
   delivertxt: 'Where should we deliver your items?',
   passagreetxt1: '1 uppercase letter',
   passagreetxt2: '1 lowercase letter',
   Shipping: 'Shipping',
   vate1: 'Total (including VAT)',
   //for pay & review pages
   trackpae: 'Track your progress In the',
   trackpae1: 'Tickets section.',
   trackpae2: 'Track your earnings! Go to',
   trackpae3: 'My Koinz',
   trackpae5: 'See other prizes',
   subtotal: 'Subtotal',
   subtotal_amt_aed: '200 AED',
   vat: 'Vat',
   vat_percentage: '5%',
   total_amt: 'Total',
   total_amt_aed: '210 AED',

   // woohoo

   woohootxt: 'Woohoo!',
   woohsubtxt: 'You’re one step closer to winning that prize!',
   deliverytimetxt: 'Your order will be delivered within 2 -3 business days',
   refnumber: 'Ref Number:',

   //for shipping and payments details
   saved_address: 'Your saved addresses',
   office: 'Office',
   home: 'Home',
   address: 'JLT, Indigo Icon Tower, 1504',
   add_address: 'Add a new address',
   saved_cards: 'Choose payment method',
   card_number: '/ Enter your Card Detail ',
   add_card: 'Add a new card',

   //for tickets page
   ticketnumbertxt: 'Ticket number',
   ticketnumber: '26551-FT',
   recentlypurchasedtxt: 'Showing recently purchased',
   closedcampaigntxt: 'Closed campaigns',
   viewtxt: 'View',
   orderhistory: 'Order History',
   winnertxt: 'WINNER',
   winnername: 'JOHN DOE',
   closeddate: 'March 15, 2020',
   tk: 'Tickets',

   //empty ticket
   zeroactivetckt: 'Zero active tickets!',
   nxtwinnertxt: 'Want to be our next winner? You need tickets for that!',

   //no history ticket
   orderwillappear: 'Your orders will appear here',
   recentorderhistory: 'Review your most recent order history, including order details and order ID.',
   order: 'Orders',

   tipoftheday: 'Tip of the day',
   optout: 'Opt out',
   of_future_tips: 'of future tips.',
   of_future_votes: 'of future voting.',
   voteforfavorite: 'Vote for your favorite prize!',
   notifytxt: 'Notify me when this prize is availavle',
   oopstxt: 'Oops!',
   somethingWrongtxt: 'Something went wrong here…',
   somethingwrongbodytxt:
      'Looks like we can’t find the link you’re looking for! Head back to the homepage to try again.',
   techtext: 'We’re experiencing <br/> technical issues',
   techbodytxt: 'It’s not you, it’s us. <br/> Please restart the app.',
   oohnotxt: 'ooh no!',
   oohnobodytxt: 'Looks like the products in your cart have all sold out! Try adding another prize!',
   nointernet: 'No Internet',
   nointernetbodytxt: 'If you’re sure you’re not on airplane mode, try connecting to your Wi-Fi again.',
   contactusucess: 'Thank you for contacting us. One of our Customer Service Representatives will contact you .',
   //  awkward: 'Oops! It seems you have typed an Incorrect email or password.',
   awkward: 'Oops! Something went wrong.',
   awkward11: 'Hey! Looks like you are already registered with us.',
   awkwardbodytxt: 'You’ve already given us your number! Make sure this number is correct or create a new account.',
   awkwardbodytxtforemail:
      'You’ve already given us your email, but this email is already registered with our system, please login with us.',
   awkwardbodytxtforphone:
      'This number is already registered with us. Can you please try to sign in with your login credentials.',
   contacttxt: 'Contact customer care',
   customercarehere: 'here.',

   //Account page

   notanaccount_title: 'We know nothing about you…',
   notanaccountbodytxt: 'Make your first purchase or create an account to get started.',
   alreadyan: 'Already have an account?',
   login: 'Login',
   account: 'Account',
   yourprofileheader_txt: 'You need to login to get full app benefits!',
   myhistory: 'My History',
   mykoinz: 'Koinz And Rewards',
   refertfrd: 'Refer A Friend',
   appsetting: 'App Settings',
   winners: 'Winners',
   howitwork: 'How It Works',
   contactus: 'Contact Us',
   aboutapp: 'About app',
   accountdetails: 'Account Details',

   personaldetail: 'Personal Details',
   paymentmethod: 'Payment Methods',
   savedaddress: 'Saved Address',
   password: 'Password',
   forgetp: 'Forgot password',
   donthaveaccount: "Don't have an account?",
   optional: '(optional)',
   accepttnc: 'Please accept terms and condition',
   gotp: 'Generate Otp',
   iotp: 'Invalid Otp',
   errorsignup: '<li>Must contain lowercase</li> <li>Must contain uppercase</li>',
   resetpass: 'Reset Password',
   npass: 'New Password',
   cpass: 'Current Password',
   rnpass: 'Retype New Password',
   //Saved Address
   Newaddress: 'New address',
   emirate: 'Emirate',
   area: 'Area',
   apartmentVilla: 'Apartment/Villa No.',
   Additionalinstructions: 'Additional instructions',
   Addressnickname: 'Address nickname',
   //  egbusinessbay: 'e.g. Business bay',
   egbusinessbay: 'e.g. The Onyx 1 Tower',
   //  buildingnamestreet: 'Building name / street',
   buildingnamestreet: 'e.g. The Greens',
   egapartment: 'e.g. Office 703',
   //  egapartment: 'e.g. apartment 1013',
   // egapartment: 'e.g. Office Number 43, 44',
   optional: 'Optional',
   egoffice: 'e.g. office',
   // egoffice: 'e.g. Alfahidi',

   koinzbanertext:
      'Koinz are rewards points that you can use to shop and win. You can use your Koinz balance to take part in our campaigns for a chance to win a prize of your choice. The more Koinz you earn, the more you can spend!',

   currentbalance: 'Current Balance',
   koinz: 'Koinz',
   whatare: 'What are Koinz?',
   koinzq: 'koinz?',
   shopnow: 'Shop Now',
   worth: 'Worth',
   wallet: 'Wallet',
   bought: 'Bought',
   earned: 'Earned',
   referrals: 'Referrals',
   tellyourfriends: 'Tell your friends',
   koinzpopupbodytxt:
      'Did you know you can buy & collect Koinz? Keep track of your Koinz balance in your wallet and use Koinz to participate in one of our luxury campaigns for a chance to win one of our marvelous prizes!',
   okthanks: 'Ok, thanks!',
   youhave: 'you have',
   product: 'Products',
   done: 'Done',
   learnmorein: 'Learn more in',
   faqs: 'FAQs',
   accountsettings: 'App Settings',
   language: 'Language',
   currency: 'Currency',
   notificationsettings: 'Notification Settings',
   sounds: 'Sounds',
   icanvotforaprize: 'I can vote for a prize',
   winnersareannounced: 'Winners are announced',
   contsucc: 'Message sent. Team will contact you shortly :',
   acampaignonmywatchlist: 'A campaign on my watchlist is about to end',
   save: 'Save',
   notifymewhen: 'Notify me when:',
   notifymewhen1: 'You need to login to manage your notification setting',
   notifications: 'Notifications',
   isnowups: 'A prize on your watchlist is now up for grabs!',
   getitbefore: 'Get it before its gone!',
   hoursago: 'hours ago',
   clossingsoon: 'A campaign on your watchlist is closing soon!',
   purchasetktnow: 'Purchase your tickets now!',
   announced: 'A new winner has been announced!',
   whattheywon: 'See what they won!',
   drawtnc: 'Draw Terms & Conditions',

   //for FAQs
   aboutkanzi: 'About Kanzi',
   campaigns: 'Campaigns',
   products: 'Products',
   prizedraws: 'Prize Draws',
   topquestions: 'About Kanzi',
   whatarekoinz: 'Who or what is Kanzi?',
   havequestions: 'Still have questions? We’ve got answers.',
   getintouch: 'Get in touch.',
   havequestionsterms: 'Please take a look at the',
   havequestionstermstounch: 'Draw Terms and Conditions',
   answersfaqs:
      "At Kanzi we wanted to make winning accessible for all, so we did just that! Welcome to your new digital and innovative shop to win experience. Every time you purchase one of our unique products  you'll automatically be entered into a draw to win a prize! Simple?  We think so! ",
   // answersfaqs:
   //    'Like Luxury? Who doesn’t! At Kanzi, we wanted to make luxury living easily accessible for all, so we did just that! Welcome to your new digital and innovative shopping experience. Every time you purchase one of our unique products, you’ll automatically be entered into a draw to win a luxury prize! Simple? We think so!',
   question2: 'What are the campaigns I see on Kanzi?',
   answer2:
      "Campaigns are the term we use for our prize draws and you'll notice we have a lot of them! New campaigns are always popping up and are created instantly at the close of an existing campaign. So don't you worry - you'll have a chance to win big!  ",
   // answer2:
   //    'Campaigns are the term we use for our prize draws, and you’ll notice we have a lot of them! New campaigns are always popping up, and are created instantly at the close of an existing campaign. So don’t you worry - you’ll have a chance to win big!',
   question3: 'Need to get in touch?',
   answer3:
      'We’re always here to help. If you have any inquiries related to your Kanzi experience, you can always get in touch with us. Visit the ‘contact us’ page and use the form to send us a message, or give us a call. ',
   quesAcc1: 'How do I create an account',
   quesAcc2: 'How do I change my password',
   quesAcc3: 'How do I delete my account',

   //for draw t n c
   tnccontent1:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of  using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for ‘lorem ipsum’ will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).',
   tnccontent2:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of  using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for ‘lorem ipsum’ will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).',
   tnccontent3:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of  using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for ‘lorem ipsum’ will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).',

   //contactus
   emailkanzi: 'Support@kanziapp.com',
   numburkanzi: '+97142756419',
   //  addresskanzi: 'Office Number 43, 44 Dubai municipality building, bur dubai. Alfahidi, dubai United Arab Emirates',
   addresskanzi: 'The Onyx 1 Tower - Office 703, The Greens, PO Box 449138, Dubai, UAE',
   kanzicontactformttl: 'We’d love to hear from you!',
   name: 'Name',
   message: 'Message',
   sendmessage: 'Send message',

   //for winner page
   howwinnerfindouttxt: 'How',
   winnerfindouttxt: 'How are winners selected?',
   findouttxt: 'Find out here.',
   winnername1: 'John Doe',
   french: 'French',

   //about app
   aboutapp: 'About App',
   tnc: 'Terms & Conditions',
   privacypolicy: 'Privacy Policy',
   kanzilicencetxt:
      'Kanzi is licensed and approved by the Dubai Economic  Department. All draws are conducted by a  representative of the DED.',

   //tnc & privacy policy
   tnc: 'Terms & Conditions',
   privacypolicy: 'Privacy Policy',

   //refer friend
   earnreward: 'Refer a friend and earn rewards!',
   getsharing: 'Get sharing!',
   generateLink: 'Generate link',

   //language and currency
   selectlanguage: 'Select Language',
   selectcurrency: 'Select Currency',

   //newly added
   hlearnmore: 'Learn more',
   hendtingtxt1: 'Ending',
   hendtingtxt2: 'soon!',
   hkoinztitle: 'Koinz',
   hupcomingtxt1: 'Upcoming',
   hupcomingtxt2: 'prizes!',
   hwinnertxt1: 'Winner',
   hwinnertxt2: 'announced!',
   emptyticket: 'Zero active tickets!',
   emptytickettxt1: 'Want to be our next winner? You need tickets for that!',
   cartdeliver: 'Delivered between 2-3 business days',
   carthomed: 'Home delivery',
   pickupmyself: 'Pick up myself - Free of charge',
   selfpicktxt: 'You can pickup starting from today',
   usemykoinz: 'Use my koinz',
   koinzinb: 'Koinz in balance',
   whatr: 'What are',
   uwillearn: 'You will earn',
   koinzpurchase: 'Koinz from this purchase',
   urticketfor: 'Your ticket for',
   replacewith: 'Replace with',
   replacewithkoinz: 'Replace this product with redeemable Koinz',
   blipradar: 'It’s a blip on the radar…',
   dontforget: 'Don’t forget!',
   keepeyeout: 'Keep an eye (or two) on the prize!',
   createaccount:
      'Create an account in order to keep a close eye on the campaigns you like the most – and increase your chances of winning!',
   donthvaccount: 'Don’t have an account?',
   singupp: 'Sign Up.',
   check: 'Check',
   chec: 'Checkout',
   koinzandrewards: 'My Koinz and Rewards',
   logout: 'Logout',
   ticketid: 'Ticket ID',
   referfriend:
      'Invite your friend and we will give you each 10 Koinz when they buy their first product from Kanzi. It’s our way of saying “thanks” for spreading the good word!',
   social: 'Social',
   copyrightkanzi: 'Copyrights 2021 Kanzi, Inc.',
   rightreserved: 'All Rights Reserved',
   gotit: 'Got it!',
   kanzversion: 'Version 1.3',
   wrnge: 'It seems you have typed an Incorrect email or password.',
   //  wrnge: 'Please try again.',
   weromga: "Account doesn't exit.Please create an account",
   AEDTETX: 'AED',
   SOLDTETX: 'SOLD',
   OUTSNT: 'OUT OF',
   KANZI: 'KANZI',
   AddTCart: 'Add to cart',
   AddTCartn: 'Added',
   kblipradar: 'Collect more to spend more',
   emailabel: 'Email',
   passabel: 'Password',
   signabel: 'Sign In',
   savabel: 'Save Changes',
   mmail: 'Male',
   fmail: 'Female',
   uaev: 'United Arab Emirates',
   y: 'Your',
   ts: 'tickets for',
   t: 'ticket for',
   kblipradar: 'Collect more to spend more',
   messenger: 'The Ambassador',
   doer: 'The Doer',
   collector: 'The Collector',
   themoreshop: 'the more you shop, the more you earn free koinz!',
   participate: 'Participate',
   times: 'times',
   samecamp: 'in the same campaign',
   earn: 'Earn',
   yourac: 'Your active campaigns',
   sagain: 'Shop Again',
   wina: 'Win a',
   moreparticipate: 'the more you participate in a campaign, the more',
   yearn: 'you Earn!',
   mreward: 'My Rewards and Badges',
   pyourorder: 'Preview your order',
   wheresdeliver: 'Where should we deliver your items?',
   yorders: 'Your orders',
   delivery: 'Delivery',
   adpickup: 'Address of pick up location',
   pnow: 'Pay Now',
   porder: 'Place Order',
   ypickup: 'Your pick up location',
   cda: 'Choose delivery address',
   dp: 'Date Purchased',
   tkn: 'Ticket Number',

   AboutApp: [
      {
         question: 'What does Kanzi do?',
         answer:
            "At Kanzi  we wanted to make winning accessible to all, so we did just that! Welcome to your new digital and innovative shop to win  experience. Every time you purchase one of our unique products  you'll automatically enter a draw to win a prize! Simple?  We think so!  ",
         // answer:
         //    'Like luxury? So do we. At Kanzi, we wanted to make luxury living easily accessible for all, so we did just that. Welcome to your new digital and innovative shopping experience. Every time you purchase one of our products, you have a chance to win one of our luxury prizes. It’s that simple!',
      },
      {
         question: 'How does it work?',
         answer:
            'We run ‘campaigns’, which is the term we use for our prize draws. On our app, you’ll see each campaign shown clearly as a product/prize combo. When you buy a product, you get a ticket to enter the draw to win a particular luxury prize. Once the target number of products are sold, the draw takes place and a winner is announced!',
      },
      {
         question: 'How do I get in touch?',
         answer:
            'We’re always here to help! If you have any inquiries related to your Kanzi experience, you can always get in touch with us. Visit the Contact Us page and use the form to send us a message, or you can give us a call.',
      },
   ],

   Account_faq: [
      {
         question: 'How do I get in touch?',
         answer:
            'We’re always here to help! If you have any inquiries related to your Kanzi experience, you can always get in touch with us. Visit the Contact Us page and use the form to send us a message, or you can give us a call.',
      },
      {
         question: 'How do I sign in?',
         answer:
            'Sign in by entering your registered email address and password. We’ll then send you a one time password (OTP) to your phone number for verification.',
      },
      {
         question: 'How do I change my password?',
         answer:
            "If you have a forgetful moment  don t worry! It happens to us all. Hit the  forgot password  button on the Login page and we'll send you a reset link to your registered email address. ",
      },
   ],

   products_faq: [
      {
         question: 'Are all products made by Kanzi?',
         answer: 'Yes, we rep Kanzi all the way!',
      },
      {
         question: 'How do I receive my purchased product? ',
         answer:
            'You have a couple of options to choose from! You can either pick up your item for free, or we deliver it to you for a small fee. Choose whichever method suits you best.',
      },
   ],

   prizedraws_faq: [
      {
         question: 'How do I track my tickets?',
         answer: 'To keep an eye on your active tickets, check out the Tickets section anytime.',
      },
      {
         question: 'How do I increase my chances of winning?',
         answer:
            ' To up your odds of winning, buy as many products as you can from a single campaign! Not only does this mean you’ve helped move the entire campaign closer to a prize draw, it also means you’ll have more entries in the competition. Good luck!',
      },
      {
         question: 'How do I check who wins a campaign?',
         answer:
            " To find out who wins a campaign you've entered into, you can either check the 'winners'section, or you'll get a push notification through our app. If you win (fingers crossed!), we'll be in touch right away using your registered contact details.",
      },
      {
         question: 'How do I get my prize, if I win?',
         answer:
            ' Your prize will be waiting safely at our Dubai office, ready for collection. Have a look at our Terms and Conditions for more details.*',
      },
      {
         question: 'How often do you create new campaigns?',
         answer:
            'All the time! You always have the same chance at winning, as a new campaign pops up whenever an existing campaign closes.',
      },
   ],

   koinz_faq: [
      {
         question: 'What are Koinz?',
         answer:
            'Koinz are basically our version of rewards points that you can use to shop our products and win. The more Koinz you collect, the more you can spend!',
      },
      {
         question: 'How do I earn Koinz?',
         answer:
            "Each time you shop with Kanzi, you earn more Koinz! It's that easy. If you want to earn bonus Koinz, we have a lots of cool ways you can do it.\n \n1. Swap products for Koinz: Whenever you shop on Kanzi, you can always get Koinz instead of the product, at the exact same amount. \n \n2. Refer a friend: Earn Koinz whenever you refer friends to Kanzi! Each time you hit a certain number of referrals, you’ll unlock a badge. If you unlock all badges, your mission is complete (woohoo!) and you’ll win even more bonus Koinz.\n \n3. Loyalty Program: Our Loyalty Program has 4 levels. The more you buy, the easier it is to achieve the next level! As you move up each one, you get 2.5% more Koinz with each purchase! \n \n4. All categories shopping: Shop across all our luxury categories and reap more rewards. All you have to do is buy 2 products in every category, to unlock mega bonus Koinz to spend! \n \n5. Repeat buys: Earn free koinz every time you unlock a badge, when you buy the same product 5, 10 or 25 times. What better reason to shop for all your friends and family in one go?",
      },
      {
         question: 'How do I check my Koinz balance?',
         answer: "Keep track of how many Koinz you have left in 'Koinz & Rewards'",
      },
      {
         question: 'Can I combine Koinz and cash to buy products?',
         answer: 'Yup! You can use Koinz and cash to make purchases, to make your life a little easier.',
      },
   ],
   fav: 'Favourites',
   ctunlock: 'categories to unock',
   date: 'Date',
   trans: 'Transactions',
   heropathtxt: "Climb the hero's path to unlock rewards and reach new prestiges!",
   level: 'Level:',
   morepart: 'more participations to become a ',
   moreshop: 'The more you shop, the higher you climb, each new level gives you more bonus Koinz.',
   gshopping: 'Get Shopping',
   msent: 'Message Sent',
   popupBackground: {
      duration: 0.3,
      delay: 0.2,
   },
   popupContainer: {
      duration: 0.1,
      delay: 0.1,
   },
   paymentGateway: {
      hostedApiKey:
         'YTVmZGVmZTktNDkwOS00OTdhLTkzNmQtODc3YTk2YTcxN2YwOjFlYjdhZjkyLTg0NGQtNGRiNi1hZTE0LTQ3ODFlYzA3YzQzMA==',
      outletRef: '74a44d37-151d-4a64-b8d5-f255f35f84ca',
      apikey: 'YTljNGRmOWEtNDc0My00OTBhLWFkNzQtYzliMmJkY2RmN2Q3OmM1M2JkM2I2LTk1MjktNDEyYi1iOTZkLTBiMDQyNzMyYzEzMw==',
   }, //sandbox
   // paymentGateway: {
   //    hostedApiKey:
   //       'ZjE0NTJjOTAtN2NhYi00NjVjLTg1MWYtODcyMmVjYzQ4N2QzOjU4OTQ1NmVmLTZmNjQtNDg4Yy05YWI2LWQ2NDRhNDczNjYwZA==',
   //    outletRef: 'b9777379-9946-4489-8972-2ebcfc8939a5',
   //    apikey: 'ZGMxMmZjZWQtODYyZC00ODFkLWIwNzQtMTc5ZTQ1OGNjZjJmOmU2ODIwOGRmLTdhMjQtNGE4ZS05ZGZjLTQ1NmU5MDAyMzljYg==',
   // }, //production
   sdk: 'https://paypage.sandbox.ngenius-payments.com/hosted-sessions/sdk.js',
   makePayment: 'Make Payment',
   cardError: 'Please check your card details and try again.',
   addCardDetails: 'Please provide your card details',
   saveCardConsentTxt: 'Save my card details',
   authenticateCard: 'Authenticate',
   Overview: 'Overview',
   y: 'Your',
   ts: ' tickets for',
   t: ' ticket for',
   //About us
   aboytheading: 'About Kanzi',
   aboytheadingdesc:
      'At Kanzi, we believe luxury living should be accessible to all and we created a way to make that possible. Kanzi is a customer centric, user-friendly, playful e-commerce platform that offers you a chance to shop, play and win!',
   aboytsecondheading: 'How ?',
   aboytseconddesc:
      'We run ‘campaigns’, which is the term we use for our prize draws. On Kanzi, you’ll see each campaign shown clearly as a product/prize combo. When you buy a product, you get a ticket to enter the draw to win a particular luxury prize. Once the target number of products are sold, the draw takes place and a winner is announced.',
   aboytthirdheading: 'Where ?',
   aboytthirddesc:
      'Eager to start winning? You can access Kanzi through our website or download the Kanzi app which is available on Apple Store and Google Play.',

   //Header
   homeheader: 'Homepage',
   aboutheader: 'About Kanzi',
   favheader: 'Favourites',
   ticketheaders: 'Tickets',
   winnerheader: 'Winners',
   howitheader: 'How it works',
   yourtickrs: 'Your Tickets',
   emptyyourtickrs: 'Favourites',
   emptyyourwinner: 'Winners',
   cartemty: 'Cart',
   myaccounts: 'My Account',

   //Sign in

   SignIn: 'Sign In',
   SignUp: 'Sign Up',
   Signinaccount: 'Account',
   Signdetail: 'Your details',
   emailr: 'Email',
   passr: 'Password',
   winneraccouned: 'Are you excited to know who the luckiest of them all? Stay tuned!',
   accountsettimn: 'Settings',
   accountsettings2: 'Account Details',
   //footer
   QuickLinks: 'Quick Links',
   AboutKanzi: 'About Kanzi',
   TermsConditions: 'Terms & Conditions',
   PrivacyPolicy: 'Privacy Policy',
   RefundCancellation: ' Refund & Cancellation',
   DeliveryShipment: 'Delivery & Shipment',
   Supports: 'Supports',
   ContactUs: 'Contact Us',
   FAQs: 'FAQs',
   DownloadApp: 'Download App',
   Social: 'Social',
   WeAccept: 'We Accept',
   FequentlyAskedQuestions: 'Fequently Asked Questions',
   TermsAndConditions: 'Terms and Conditions',
   Ends_In: 'Ends In',
   Days: 'Days',
};
