import { isNil } from 'lodash';

export const isObjEmpty = obj => Object.getOwnPropertyNames(obj).length === 0;

export const isArrEmpty = arr => !(Array.isArray(arr) && arr.length);

export const decimalUptoTwoDigits = value => {
   console.log(value);
   if (value < 0) {
      value = 0;
   }
   if (value) {
      if (isNaN(value) || isNil(value)) return 0;
      // console.log(Math.round((Number(value) + Number.EPSILON) * 100) / 100)
      return Math.round(value);
   } else return 0;
};

export const getStoreId = store => {
   let storeId = 0;
   if (localStorage.getItem('langage') === 'en') {
      storeId = 1;
   } else {
      storeId = 2;
   }
   return storeId;
};
