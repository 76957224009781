import React from 'react';
import CircularLoader from "../Loader/CircularLoader"
import { Environment } from "../../env"
/**
 * Generic Function
 *
 * Reusable for all the Components
 *
 */

export default props => {
   return (
      <div className="button-container">
         {props.soldout ? (
            <div>
               <div className="soldout-button site-e">
                  Draw date:
               <br />
                  {props.date ? props.date : "To Be Announced"}
               </div>
               <div className="soldout-button site-a">
                  تاريخ السحب:
               <br />
                  {props.date ? props.date : "To Be Announced"}
               </div>
            </div>
         ) : (
               <div>
                  {/* {props.type == 3 ? <span className="img-cont">
                     <img src={`${Environment.img_path}makeicons/check.svg`} alt="" />
                  </span> : null} */}
                  {/* <div>
                  <CircularLoader />
               </div> */}
                  <button
                     //ref={ref2}
                     onClick={props.onClick}
                     className={props.className}
                     id={props.id}
                     disabled={props.disabled}
                  >
                     {props.children}
                     {props.data}
                  </button>
               </div>
            )
         }
      </div >
   );
};
