import React, { useRef, useState, useEffect } from 'react';
import Button from '../Button/Button';
import { TweenMax } from 'gsap';
import { Swipeable } from 'react-swipeable';
import { useHistory } from 'react-router-dom';
import { Tween } from 'react-gsap';
import { isObjEmpty } from '../../utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import { AddAddress, EditAddress } from '../../redux/actions/account/actions';
import { SetLang } from '../../redux/actions/account/actions';
import { ResetPass, ResetForgetPass } from '../../redux/actions/auth/actions';
import { get } from 'lodash';
import CircularLoader from '../../components/Loader/CircularLoader';


const ResetPassword = props => {
   const customer_id = useSelector(state => get(state, 'auth.userinfo.id', ''));
   const [error, setError] = useState({
      currentpass: null,
      newpass: null,
   });
   const [active, setActive] = useState(false);
   const SheetAddress = useRef(null);
   const history = useHistory();
   // const email = useSelector(state => state.auth.userinfo.email)
   const dispatch = useDispatch();
   const [show, setshow] = useState(false);
   const [showPass, setshowpass] = useState(false);
   const [showRetypePass, setshowretypepass] = useState(false);
   const [currentpass, setcurrentpass] = useState('');
   const [newpass, setnewpass] = useState('');
   const [uppercase, setuppercase] = useState(false);
   const [lowercase, setlowercase] = useState(false);
   const [passnotvalu, setpassnotvalu] = useState(false);


   const errormsg = ""
   const errormsg2 = "Password not matched"
   const errormsg1 = ""

   const prevTab = useSelector(store => store.home.prevTab)

   const handleGoBack = () => {
      setActive(false);
      TweenMax.fromTo(SheetAddress.current, 0.5, { y: 0 }, { y: 1000 });
      setTimeout(() => {
         props.history.push('/resetpassword');
      }, 500);
   };

   useEffect(() => {
      document.body.style.overflow = 'hidden';
      TweenMax.fromTo(SheetAddress.current, 0.5, { y: 600 }, { y: 0 });
      setTimeout(() => {
         setActive(true);
      }, 700);
      return () => (document.body.style.overflow = 'visible');
   }, []);

   useEffect(() => {
      document.addEventListener('click', evt => {
         const flyoutElement = document.querySelector('.action-container');
         let targetElement = evt.target; // clicked element

         if (targetElement == flyoutElement) {
            handleGoBack();
            return;
         }
      });
   }, []);
   const onFormChange = (type, e, direct = false) => {
      var myElement = document.getElementById('newpass').value;
      setcurrentpass(e.currentTarget.value);

      if (type == "currentpass") {
         var reg = new RegExp('/^[A-Z]*$/');

         if (myElement.trim().match(/[A-Z]/)) {
            setuppercase(true)
         }
         else {
            setuppercase(false)
         }

         if (myElement.trim().match(/[a-z]/)) {
            setlowercase(true)
         }
         else {
            setlowercase(false)
         }
         if (myElement.trim().match(/[A-Z]/)) {

            if (myElement.trim().match(/[a-z]/)) {
               setpassnotvalu(true)

            }
            else {
               setpassnotvalu(false)
            }

            // matches
         } else {
            if (myElement.trim().match(/[a-z]/)) {
               if (myElement.trim().match(/[A-Z]/)) {
                  setpassnotvalu(true)
               }
               else {
                  setpassnotvalu(false)
               }
            }
            // doesn't match
         }
         console.log(passnotvalu)

      }
   };
   const OnSave = async () => {
      var error = {};
      //alert(JSON.stringify(props.location.state))
      var reg = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');

      if (props.location.state === undefined) {
         // if (!reg.test(newpass)) {
         //    error.newpass = errormsg;
         // }
         // if (!reg.test(currentpass)) {
         //    error.currentpass = errormsg;
         // }
         let passnotvalu = false;
         let passnotvalu1 = false;
         var reg = new RegExp('/^[A-Z]*$/');
         // strs[i].match(/[A-Z]/)
         console.log(newpass.length)
         if (newpass.length == 0) {
            error.newpass = errormsg;
         }
         if (currentpass.length == 0) {
            error.currentpass = errormsg1;
         }
         if (newpass.trim().match(/[A-Z]/)) {
            // alert("verify")
            if (newpass.trim().match(/[a-z]/)) {
               error.newpass = null;
               passnotvalu = true;
            }
            else {
               error.newpass = errormsg;
               passnotvalu = false;
            }

            // matches
         } else {
            if (newpass.trim().match(/[a-z]/)) {
               if (newpass.trim().match(/[A-Z]/)) {
                  error.newpass = null;
                  passnotvalu = true;
               }
               else {
                  error.newpass = errormsg;
                  passnotvalu = false;
               }
            }
            // doesn't match
         }


         if (currentpass.trim().match(/[A-Z]/)) {
            // alert("verify")
            if (currentpass.trim().match(/[a-z]/)) {
               error.currentpass = null;
               passnotvalu1 = true;
            }
            else {
               error.currentpass = errormsg1;
               passnotvalu1 = false;
            }

            // matches
         } else {
            if (currentpass.trim().match(/[a-z]/)) {
               if (currentpass.trim().match(/[A-Z]/)) {
                  error.currentpass = null;
                  passnotvalu1 = true;
               }
               else {
                  error.currentpass = errormsg1;
                  passnotvalu1 = false;
               }
            }
            // doesn't match
         }
         console.log(passnotvalu)
         console.log(passnotvalu1)
         console.log(isObjEmpty(error))

         if (passnotvalu && passnotvalu1) {
            // setshow(true);
            const res = await dispatch(ResetPass(currentpass, newpass, customer_id));
            if (res) {
               // setshow(false);
               // history.push('/account');
               history.push(`/${prevTab}`);

            }
         } else {
            setError({
               currentpass: null,
               newpass: null,
               ...error,
            });
         }
      } else if (props.location.state.type === 'forget') {
         console.log("ds")
         if (newpass !== currentpass) {
            error.newpass = errormsg;
            error.currentpass = errormsg1;
         }
         // else if (!reg.test(currentpass)) {
         //    error.currentpass = errormsg1;
         //    error.newpass = errormsg;
         // }

         let passnotvalu = false;
         let passnotvalu1 = false;
         var reg = new RegExp('/^[A-Z]*$/');
         // strs[i].match(/[A-Z]/)
         console.log(newpass.length)
         if (newpass.length == 0) {
            error.newpass = errormsg;
         }
         if (currentpass.length == 0) {
            error.currentpass = errormsg1;
         }
         if (newpass.trim().match(/[A-Z]/)) {
            // alert("verify")
            if (newpass.trim().match(/[a-z]/)) {
               // error.newpass = null;
               passnotvalu = true;
            }
            else {
               // error.newpass = errormsg;
               passnotvalu = false;
            }

            // matches
         } else {
            if (newpass.trim().match(/[a-z]/)) {
               if (newpass.trim().match(/[A-Z]/)) {
                  // error.newpass = null;
                  passnotvalu = true;
               }
               else {
                  // error.newpass = errormsg;
                  passnotvalu = false;
               }
            }
            // doesn't match
         }


         if (currentpass.trim().match(/[A-Z]/)) {
            // alert("verify")
            if (currentpass.trim().match(/[a-z]/)) {
               // error.currentpass = null;
               passnotvalu1 = true;
            }
            else {
               // error.currentpass = errormsg1;
               passnotvalu1 = false;
            }

            // matches
         } else {
            if (currentpass.trim().match(/[a-z]/)) {
               if (currentpass.trim().match(/[A-Z]/)) {
                  // error.currentpass = null;
                  passnotvalu1 = true;
               }
               else {
                  // error.currentpass = errormsg1;
                  passnotvalu1 = false;
               }
            }
            // doesn't match
         }
         console.log(passnotvalu)
         console.log(passnotvalu1)
         console.log(isObjEmpty(error))

         if (newpass !== currentpass) {
            console.log("dsds")
            error.newpass = 'Passwords are not same';
            error.currentpass = 'Passwords are not same';

         }
         else {
            if (passnotvalu && passnotvalu1) {
               console.log("pass")
               // error.currentpass = null;
               // error.newpass = null;
               setshow(true);
               const res = await dispatch(
                  ResetForgetPass(props.location.state.email, currentpass, props.location.state.token)
               );
               if (res) {
                  setshow(false);
                  // history.push('/account');
                  history.push(`/${prevTab}`);
               }
            } else {
               setError({
                  currentpass: null,
                  newpass: null,
                  ...error,
               });
            }
         }


         /* if (currentpass !== newpass) {
                return alert("Passwords need to be same")
            } */
      }
   };

   return (
      <div>
         <CircularLoader show={show}></CircularLoader>
         <Swipeable onSwipedDown={handleGoBack}>
            <div
               className="action-container currency-contadd"
               ref={SheetAddress}
               style={{ backgroundColor: active ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0)' }}
            >
               <Tween from={{ y: '100%', opacity: 1, delay: 0.9, duration: 0.8 }}>
                  <div className="action-cards action-cards123">
                     <button onClick={() => {
                        history.goBack();
                     }} type="button" class="close1231" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                     </button>
                     <div className="cards-icon pass">
                        <img src={`${props.Environment.img_path}images/changepass.svg`} alt="" />
                     </div>
                     <div className="cards-title">{props.Environment.resetpass}</div>
                     <div className="main-language-container">
                        <div className="floating-label">
                           <input
                              style={{ borderBottom: error.currentpass !== null ? '1px solid red' : '1px solid #e2e2e2' }}
                              className={`floating-input ${error ? 'error' : ''}`}
                              type={showPass ? 'text' : 'password'}
                              value={currentpass}
                              id="newpass"
                              onChange={e => onFormChange('currentpass', e)}
                              // onChange={e => {
                              //    setcurrentpass(e.currentTarget.value);
                              // }}
                              autocomplete="new-password"
                           />
                           <div
                              className="label-icon"
                              onClick={() => {
                                 setshowpass(!showPass);
                              }}
                           >
                              <img src={`${props.Environment.img_path}icons/${showPass ? 'eyeopen' : 'eyeclose'}.svg`} alt="" />
                           </div>
                           {/* <span className="highlight" /> */}
                           <label className="label_placeholder">
                              {props.location.state === undefined ? props.Environment.cpass : props.Environment.npass}
                           </label>
                           {error.currentpass !== null ? <span className="pass-error">{error.currentpass}</span> : null}
                        </div>
                        <div className="floating-label newfor" style={{ marginBottom: 100, marginTop: error.currentpass !== null ? 35 : 30 }}>
                           <input
                              style={{ borderBottom: error.newpass !== null ? '1px solid red' : '1px solid #e2e2e2' }}
                              className={`floating-input ${error ? 'error' : ''}`}
                              type={showRetypePass ? 'text' : 'password'}
                              value={newpass}

                              name="12"
                              onChange={e => {
                                 setnewpass(e.currentTarget.value);
                              }}
                              autocomplete="new-password"
                           />
                           <div
                              className="label-icon"
                              onClick={() => {
                                 setshowretypepass(!showRetypePass);
                              }}
                           >
                              <img
                                 src={
                                    showRetypePass
                                       ? `${props.Environment.img_path}icons/eyeopen.svg`
                                       : `${props.Environment.img_path}icons/eyeclose.svg`
                                 }
                                 alt=""
                              />
                           </div>
                           <div className="epassv">
                              <span className="spanpd">{props.Environment.spanpasstry} </span>
                              <div className="bocinsing">
                                 <div className="form-checkbx">
                                    <input
                                       type="checkbox"
                                       id="address"
                                       name="box"
                                       checked={uppercase}

                                    />
                                    <label htmlFor="address">{props.Environment.passagreetxt1}
                                    </label>
                                 </div>
                                 <div className="form-checkbx">
                                    <input
                                       type="checkbox"
                                       id="address"
                                       name="box"
                                       checked={lowercase}
                                    />
                                    <label htmlFor="address">{props.Environment.passagreetxt2}


                                    </label>
                                 </div>
                              </div>
                           </div>
                           {/* <span className="highlight" /> */}
                           <label className="label_placeholder">
                              {props.location.state === undefined ? props.Environment.npass : props.Environment.rnpass}
                           </label>
                           {error.newpass !== null ? <span className="pass-error">{error.newpass}</span> : null}
                        </div>
                     </div>
                     <div className="">
                        <Button
                           className="button-red"
                           data={props.Environment.save}
                           onClick={() => {
                              OnSave();
                           }}
                        />
                     </div>
                  </div>
               </Tween>
            </div>
         </Swipeable>
      </div>
   );
};

export default ResetPassword;
