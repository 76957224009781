import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar';

const FreeKoinz = () => {
   const genericCta = 'Shop now';
   const koinzList = [
      {
         image: 'makeicons/koinz-1.svg',
         header: 'Swap products for koinz',
         desc:
            'Whenever you shop on Kanzi, you always have the option to get koinz instead of the product, at the same amount.',
         url: '/',
         cta: genericCta,
      },
      {
         image: 'makeicons/koinz-2.svg',
         header: 'Refer a friend',
         desc:
            'Earn koinz when you refer friends to Kanzi. Each time you hit a certain number of referrals, you’ll unlock a new badge to get free koinz. If you manage to unlock all badges, your mission is complete and you’ll win even more bonus koinz!',
         url: '/referfriend',
         cta: 'Refer a friend',
      },
      {
         image: 'makeicons/koinz-3.svg',
         header: 'Loyalty Program',
         desc:
            'Our Loyalty Program has 5 levels: K, O, I, N, Z. With each purchase, you earn koinz that increase in value as you move up each level. The more you buy, the quicker you can move to the next level!',
         url: '/',
         cta: genericCta,
      },
      {
         image: 'makeicons/koinz-4.svg',
         header: 'Category shopping',
         desc:
            'The more categories you shop, the more rewards you reap. All you have to do is buy 2 products in a single category, to get those extra koinz. If you do this across 5 categories, you’ll get even more bonus koinz to spend.',
         url: '/',
         cta: genericCta,
      },
      {
         image: 'makeicons/koinz-5.svg',
         header: 'Repeat buys',
         desc:
            'Earn free koinz every time you unlock a badge, when you buy the same product 5, 10 or 25 times. There’s no better reason to shop for your friends and family, than to have an even higher chance to win the prize of your dreams.',
         url: '/',
         cta: genericCta,
      },
   ];
   return (
      <div className="free-koinz-container">
         <NavBar freeKoinz={true} />

         <section className="koinz-items-section">
            {koinzList.map(data => (
               <figure className="koinz-list">
                  <img src={data.image} alt="" />
                  <figcaption>
                     <h1>{data.header}</h1>
                     <p>{data.desc}</p>

                     <Link to={data.url}>{data.cta}</Link>
                  </figcaption>
               </figure>
            ))}
         </section>
      </div>
   );
};

export default FreeKoinz;
