import React, { useState, useEffect } from "react";
import { useStore, useDispatch } from "react-redux"
import { useHistory } from 'react-router-dom';
import { Tween } from "react-gsap";
import axios from "axios";
import NavBar from "../../components/NavBar/NavBar";
import { Environment } from "../../env";
import Button from "../../components/Button/Button";
import ActionSheet from "../../components/ActionSheet/ActionSheet";
import { UpdateCustomer } from "../../redux/actions/account/actions"
import FloatingInput from "./FloatingInput";
import { isObjEmpty } from "../../utils/utils";
import Nationality from './Nationality.json';
import { UpdateMamboProfile } from "../../redux/actions/cart/actions"

const PersonalDetails = (props) => {
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone_number: "",
    nationality: "",
    gender: ""
  });
  const [type, setType] = useState('current');
  const [countryCode, setCountryCode] = useState('+91');
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const store = useStore().getState();
  const history = useHistory();

  useEffect(() => {
    if (store.auth.userinfo !== null) {
      const { firstname, lastname, phone_number, email, gender, nationality } = store.auth.userinfo;

      setData({
        firstname, lastname, phone_number, email, nationality, gender
      });
    }
    (async () => {
      const res = await axios({
        method: 'GET',
        url: 'https://ipapi.co/json'
      });
      setCountryCode(res.data.country_calling_code);
    })();
  }, []);

  const SaveCustomerData = async () => {
    const check = handleChecks();
    if (!check) return false;

    if (store.auth.userinfo?.phone_number !== data.phone_number) {
      setType('verify');
    } else {
      await dispatch(UpdateMamboProfile(data.email, data.firstname, data.lastname))
      await dispatch(UpdateCustomer(data, false, countryCode));
      history.push('/accountdetails');
    }
  };

  const handleChecks = () => {
    const errorTemplate = {};
    if (data.firstname.trim().length === 0) {
      errorTemplate.firstname = "First Name cannot be empty";
    }
    if (data.lastname.trim().length === 0) {
      errorTemplate.lastname = "Last Name cannot be empty";
    }
    if (data.phone_number.trim().length === 0) {
      errorTemplate.phone_number = "Please enter a valid phone number";
    }
    if (!/\S+@\S+\.\S+/.test(data.email.trim())) {
      errorTemplate.email = "Please enter a valid Email Address!";
    }
    if (!isObjEmpty(errorTemplate)) {
      setError(errorTemplate);
      return false;
    } else {
      const { firstname, lastname, phone_number, email, gender, nationality } = data;

      setData({
        firstname, lastname, phone_number,
        email, nationality, gender
      });
      setError(errorTemplate);
      console.log(data);
      return true;
    }
  }

  const onFormChange = (type, e) => {
    const value = e.target.value;
    let formData = { ...data };
    formData = {
      ...formData,
      [type]: value
    }
    setData(formData);
  }

  return (
    <div className="">
      <NavBar personalDetails="personalDetails" />
      {
        type === 'verify' && (
          <ActionSheet
            {...props}
            type="verify"
            phonenumber={data.phone_number}
            countrycode={countryCode}
            data={data}
          />
        )
      }
      <div className="singup-container">
        <Tween from={{ x: "100%", opacity: 1, duration: 0.5 }}>
          <div className="forms-main">
            <div className="forms-cards">
              <div className="floating-form">
                <FloatingInput
                  label={props.Environment.firstname}
                  value={data.firstname}
                  onChange={e => onFormChange('firstname', e)}
                  error={error.firstname}
                />
                <FloatingInput
                  label={props.Environment.lastname}
                  value={data.lastname}
                  onChange={e => onFormChange('lastname', e)}
                  error={error.lastname}
                />
                <div className="floating-label">
                  <input
                    className={`floating-input code-input`}
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    placeholder=" "
                    value={countryCode}
                    onChange={e => setCountryCode(e.target.value)}
                  />
                  <input
                    className={`floating-input mobile-input ${error.phone_number ? "error" : ""}`}
                    type="text"
                    inputMode="numeric"
                    placeholder=" "
                    value={data.phone_number}
                    onChange={e => onFormChange('phone_number', e)}
                  />
                  <span className="highlight" />
                  <label>{props.Environment.phone_number}</label>
                </div>
                <FloatingInput
                  label={props.Environment.email}
                  value={data.email}
                  onChange={e => onFormChange('email', e)}
                  error={error.email}
                />
                <div className="floating-label">
                  <select
                    className="floating-select"
                    value={data.gender}
                    onChange={e => onFormChange('gender', e)}
                  >
                    <option value="1">{props.Environment.mmail}</option>
                    <option value="2">{props.Environment.fmail}</option>
                  </select>
                  <span className="highlight" />
                  <label>{props.Environment.gender}</label>
                </div>
                {/* <div className="floating-label">
                  <select
                    className="floating-select"
                    value={data.nationality}
                    onChange={e => onFormChange('nationality', e)}
                  >
                    {
                      Nationality.map(cur => (
                        <option
                          value={cur.value}
                          selected={cur.value === data.nationality}
                        >{cur.label}</option>
                      ))
                    }
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                  </select>
                  <span className="highlight"/>
                  <label>{props.Environment.nationality}</label>
                </div> */}
              </div>
            </div>
          </div>
          <div className="button-container-main">
            <Button
              className="button-red"
              data={props.Environment.savabel}
              onClick={SaveCustomerData} />
          </div>
        </Tween>
      </div>
    </div>
  )
};

export default PersonalDetails;
