import AuthApi from "../../../api/AuthApi";
import Cookie from "js-cookie";
import { CUSTOMERUPDATE, ADD_ADDRESS, EDIT_ADDRESS, SETLANGUAGE, SETCURRENCY, SETNOTIFICATIONS, SETNOTIFICATIONLISTING } from "./actionTypes";

export const UpdateCustomer = (data, isPhoneNumberInc, countrycode) => async (dispatch, getState) => {
  const token = getState().auth.token;
  const customAttributes = isPhoneNumberInc ? [
    {
      attribute_code: "nationality",
      value: data.nationality
    },
    {
      attribute_code: "phone_number",
      value: data.phone_number
    },
    {
      attribute_code: "dial_code",
      value: countrycode
    }
  ] : [{
    attribute_code: "nationality",
    value: data.nationality
  }];

  try {
    const res = await AuthApi({
      method: 'PUT',
      url: '/customers/me',
      data: {
        customer: {
          email: data.email,
          firstname: data.firstname,
          lastname: data.lastname,
          gender: data.gender,
          website_id: 1,
          custom_attributes: customAttributes
        }
      },
      headers: {
        Authorization: "Bearer " + token
      }
    });

    dispatch({
      type: CUSTOMERUPDATE,
      payload: {
        data: {
          email: res.data.email,
          firstname: res.data.firstname,
          lastname: res.data.lastname,
          gender: res.data.gender,
          nationality: res.data.custom_attributes[0].value,
          phone_number: isPhoneNumberInc ? res.data.custom_attributes[1].value : data.phone_number
        }
      }
    });

    localStorage.removeItem("token");
    localStorage.removeItem("info");
    localStorage.setItem("token", res.data.extension_attributes.token);
    localStorage.setItem('info', JSON.stringify({
      email: res.data.email,
      firstname: res.data.firstname,
      lastname: res.data.lastname,
      gender: res.data.gender,
      nationality: res.data.custom_attributes[0].value,
      phone_number: isPhoneNumberInc ? res.data.custom_attributes[1].value : data.phone_number,
    }));

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export const AddAddress = data => async (dispatch, getState) => {
  const token = getState().auth.token;

  try {
    const res = await AuthApi({
      method: 'POST',
      url: '/addresses/mine',
      data: {
        address: {
          country_id: data.countryId,
          street: [
            data.apartment,
            data.addressLine1,
            data.area
          ],
          telephone: data.telephone,
          postcode: "xx", // TEMPORARY
          city: "xx", // TEMPORARY
          firstname: data.firstname,
          lastname: data.lastname,
          prefix: data.nickname,
          default_shipping: data.isDefault ? 1 : 0,
          custom_attributes: [
            {
              attribute_code: "address_nickname",
              value: data.nickname
            }
          ]
        }
      },
      headers: {
        Authorization: "Bearer " + token
      }
    });

    dispatch({
      type: ADD_ADDRESS,
      payload: res.data
    });

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export const EditAddress = data => async (dispatch, getState) => {
  const token = getState().auth.token;

  try {
    const res = await AuthApi({
      method: 'PUT',
      url: '/addresses/mine',
      data: {
        address: {
          id: data.id,
          customer_id: data.customer_id,
          country_id: data.countryId,
          street: [
            data.apartment,
            data.addressLine1,
            data.area
          ],
          telephone: data.telephone,
          postcode: "xx", // TEMPORARY
          city: "xx", // TEMPORARY
          firstname: data.firstname,
          lastname: data.lastname,
          default_shipping: data.isDefault ? 1 : 0,
          custom_attributes: [
            {
              attribute_code: "address_nickname",
              value: data.nickname
            }
          ]
        }
      },
      headers: {
        Authorization: "Bearer " + token
      }
    });

    dispatch({
      type: EDIT_ADDRESS,
      payload: res.data
    });

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}


export const SetLang = (language) => {
  return dispatch => {
    dispatch({ type: SETLANGUAGE, payload: language })
  }
}

export const SetCurrency = (currency) => {
  return dispatch => {
    dispatch({ type: SETCURRENCY, payload: currency })
  }
}

export const SetNotification = (notification_text) => async (dispatch, getState) => {
  let notification_type = null
  let id = null
  if (notification_text == "1") {
    notification_type = "vote_prize"
    id = 1
  }
  if (notification_text == "2") {
    notification_type = "winner_announced"
    id = 2
  }
  if (notification_text == "3") {
    notification_type = "watch_campaign"
    id = 3
  }
  try {
    let res = await AuthApi({
      method: 'POST',
      url: "/set-notification",
      data: {
        data: {
          entity_id: 0, entity_type: "setting", notification_type, notification_text
        }
      },
      headers: {
        Authorization: "Bearer " + getState().auth.token
      }
    })

    console.log(res.data)

    let noti_list = getState().account.notification

    noti_list.push({
      notification_id: res.data,
      type: notification_type,
      id: id
    })

    dispatch({ type: SETNOTIFICATIONS, payload: noti_list })
  }
  catch (e) {

  }
}

export const DeleteNotification = (id,noti_id) => async (dispatch, getState) => {

  let noti = getState().account.notification.filter(notification => notification.notification_id != id)

  try {
    let res = await AuthApi({
      method: 'DELETE',
      url: "/upcoming-notification/mine?notificationId=" + id,
      headers: {
        Authorization: "Bearer " + getState().auth.token
      }
    })

    dispatch({ type: SETNOTIFICATIONS, payload: noti })
  }
  catch (e) {

  }
}



export const GetNotificationStatus = () => async (dispatch, getState) => {
  try {
    let res = await AuthApi({
      method: 'GET',
      url: "/upcoming-notification/mine?type=setting",
      headers: {
        Authorization: "Bearer " + getState().auth.token
      }
    })


    console.log(res.data)
    if (Array.isArray(res.data)) {
      let noti = res.data

      noti.map(item => {
        if (item.type == "vote_prize") {
          item.id = 1
        }
        if (item.type == "winner_announced") {
          item.id = 2
        }
        if (item.type == "watch_campaign") {
          item.id = 3
        }
        return item
      })

      dispatch({ type: SETNOTIFICATIONS, payload: noti })
      return true
    }

    return false

  } catch (e) {
    console.log(e)
    return false
  }
}

export const GetNotificationListing = () => async dispatch => {
  try {
    let res = await AuthApi({
      method: 'GET',
      url: "/notification-setting-list"
    })

    if (res.data.items != undefined) {
      dispatch({ type: SETNOTIFICATIONLISTING, payload: res.data.items })
      return true
    }

    return false

  } catch (e) {
    console.log(e)
    return false
  }
}

