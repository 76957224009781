import React, { useEffect, useState } from 'react';
import { Link , useHistory } from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar';
import { Environment } from '../../env';
import { Tween } from 'react-gsap';
import Button from '../../components/Button/Button';
import { useSelector } from "react-redux"
import { GenerateUniqueLink } from "../../redux/actions/auth/actions"
import { get } from "lodash"
import Messenger from "../BadgeInfo/Components/Messenger"

const ReferFriend = props => {

   const store = useSelector(store => store)
   const history = useHistory()

   const generateLink = async () => {
      const udid = get(store, 'auth.userinfo', { id: 0 })
      console.log(udid.id)
      var link = await GenerateUniqueLink(udid.id)
      if (link != false) window.ReactNativeWebView.postMessage("Hey! Checkout this cool app - " + link);
   }

   return (
      <div className="">
         <NavBar referfriend="referfriend" />
         <div className="referfriend-main-container">
            <Tween from={{ x: '100%', opacity: 1, duration: 0.5 }}>
               <div className="referfriendcard-conatiner">
                  <div className="card-block">
                     <Messenger title="Refer a friend and be a Messenger" />
                     {/* <Button
                        className="button-red koin"
                        onClick={() => { history.push("/mykoinz") }}
                        data={"My Koinz and Rewards"}
                     /> */}
                     {/* <div className="card-content">
                           <div className="card-header">{props.Environment.earnreward}</div>
                           <span>
                              {' '}
                              {props.Environment.referfriend}
                           </span>
                        </div> */}
                  </div>
                  {/* <div className="generate-link-button">
                     {props.Environment.getsharing}
                     <Button className="button-red" data="Generate link" onClick={() => { generateLink() }} />
                  </div> */}
               </div>
            </Tween>
         </div>
      </div>
   );
};

export default ReferFriend;
