import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useStore, useDispatch } from 'react-redux';
import { Environment } from '../../env';
import { CreateCartId } from '../../redux/actions/auth/actions';
import Button from '../../components/Button/Button';
import TicketList from '../../components/TicketList/TicketList';
import { Tween } from 'react-gsap';
import { ORDERID } from '../../redux/actions/cart/actionTypes';

const OrderDeclined = () => {
   const [hasOrderId, setOrderId] = useState(false);
   const store = useStore().getState();
   const dispatch = useDispatch();
   const history = useHistory();

   useEffect(() => {
      (async () => {
         if (store.auth.token !== null) {
            if (store.auth.cartid === null) {
               await dispatch(CreateCartId(store.auth.token));
               setOrderId(true);
            }
         }
      })();
   }, [store.auth.cartid]);

   console.log(hasOrderId);

   return (
      <div className="">
         <div className="order-container-d">
            <div className="order-declined order-extra-details">
               <Tween from={{ y: '-100%', opacity: 0, duration: 0.5 }}>
                  <div className="koinz-logo">
                     <img src={`${Environment.img_path}images/declined.svg`} alt="kanzi-logo" />
                  </div>
               </Tween>
               <h2>Payment Declined</h2>
               <p>
                  You seem to have canceled <br /> the transaction. Do you want to try once
                  <br /> again?
               </p>
               {/* <Button
            className="button-red"
            data="Go back to cart"
            onClick={() => {
              history.push('/cart');
            }}
          />
              {hasOrderId && (
                 <React.Fragment>
                    <TicketList
                       replace={true}
                       type="openTickets"
                       hasQty={false}
                       orderid={+store.cart.orderid}
                       order="ASC"
                    />
                 </React.Fragment>
              )} */}
               <div class="checkoutcart-container">
                  <div>
                     <div class="checkoutcart-main">
                        <div class="checkoutcart-cards">
                           <div class="checkoutcart-prize">
                              <div class="checkoutcart-name">
                                 <div class="money-img">
                                    <img src="https://kanziapp.org/pub/media/catalog/product/c/a/cap_1.png" alt="" />
                                 </div>
                                 <div class="checkoutcart-koinz">
                                    <span>New CAP</span>
                                    <span> AED 317 </span>
                                 </div>
                              </div>
                              <div class="checkout-product">
                                 <img src="https://kanziapp.org/pub/media/catalog/product/i/p/ipad_1.png" alt="" />
                              </div>
                           </div>
                           <div class="checkout-ticket-count">
                              <div class="product-name">
                                 Your ticket for<span> Ipad</span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="tktsection" style={{ paddingBottom: 68 }}>
               Click here to explore
               <Link to="/">Campaigns.</Link>
            </div>
         </div>
      </div>
   );
};

export default OrderDeclined;
