import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './styles/style.scss';
import App from './App';
import { Environment } from './env';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import configureStore from './redux/store/configureStore';
import { AutoLogin } from './redux/actions/auth/actions';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import ru from 'javascript-time-ago/locale/ru';
import ReactPixel from 'react-facebook-pixel';

const Index = props => {
   const [rendered, setRendered] = useState(false);

   const fbPixelIntegration = () => {
      const advancedMatching = { em: 'kanziapp0@gmail.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
      const options = {
         autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
         debug: false, // enable logs
      };
      ReactPixel.init('172231144990611', advancedMatching, options);

      ReactPixel.pageView(); // For tracking page view
   };

   useEffect(() => {
      fbPixelIntegration();
      (async () => {
         await configureStore.dispatch(AutoLogin());
         setRendered(true);
      })();
      TimeAgo.addDefaultLocale(en);
   }, []);

   return (
      <Provider store={configureStore}>
         {!rendered && (
            <div className="splash-screen" style={{ direction: 'rtl' }}>
               <img src={`${Environment.img_path}images/logoloader.png`} alt="Splash Screen" />
            </div>
         )}
         {rendered && <App />}
      </Provider>
   );
};

// ReactDOM.render(
//   <Index />,
//   document.getElementById("root")
// );
const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
   ReactDOM.hydrate(<Index />, rootElement);
} else {
   ReactDOM.render(<Index />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
