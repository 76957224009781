import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import './Home.css';
import { useStore, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
// import { Environment } from '../../env';
import NavBar from '../../components/NavBar/NavBar';
import PrizeList from '../../components/PrizeList/PrizeList';
import WinnerList from '../../components/WinnerList/WinnerList';
import PopUps from '../../components/PopUps/PopUps';
import Glider, { GliderMethods } from 'react-glider';
import { TweenMax } from 'gsap';
import {
   SetPopupData,
   SetRewards,
   AddNotifications,
   DeleteNotifications,
   GetNotifications,
   GenerateProductLink,
} from '../../redux/actions/home/actions';
import { ViewCart } from './../../redux/actions/cart/actions';
import 'glider-js/glider.min.css';
import { get, isNil } from 'lodash';
import { DefaultPlayer as Video } from 'react-html5video';
// import { GetNotifications as Noti } from "../../redux/actions/auth/actions"
import 'react-html5video/dist/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

//import { Tween } from "react-gsap";
import {
   GetCategories,
   GetAllProducts,
   GetProdByCat,
   GetPrizesNextMonth,
   GetBanners,
   SetCategory,
   GetTip,
   GetVote,
   GetCurrencyData,
   SaveVote,
   UpdateTipAndVotePromotion,
   UpdateDevice,
   GetWinnerList,
   SetDeviceInfo,
   GetCampaignManager,
   Getendingsoonproducts,
   ShowTip,
   SetTipRead,
   SetLang,
   GetDesktopProducts,
   MasterApiData,
} from '../../redux/actions/home/actions';
import { SETREFERID } from '../../redux/actions/home/actionTypes';
// import { ViewMamboProfile, GetConversionRates } from '../../redux/actions/cart/actions';
import { isArrEmpty } from '../../utils/utils';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-circular-progressbar/dist/styles.css';
import DesktopBanner from './DesktopBanner';
import { calcDaysFromCurrentDate, showTimeBasedCampaigns, prefixZeroIfSingleDigit } from '../../utils/Function';

const promotionENV = 'promotion';
const script = document.createElement('script');
script.src =
   'https://i.ctnsnet.com/int/integration?pixel=67834349&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}';
script.async = true;
const Home = props => {
   // unuseLockBodyScroll()
   const [, setActive] = useState(false);
   const [, setSubCategory] = useState(null);
   const [isRendered, setRendered] = useState(true);
   const [isProdRendered, setProdRendered] = useState(true);
   const [isRendered1, setRendered1] = useState(true);
   const [isRendered2, setRendered2] = useState(true);
   const [isRendered3, setRendered3] = useState(true);
   const [hasTipPopup, setTipPopup] = useState(false);
   const [hasVotePopup, setVotePopup] = useState(false);
   const [play, setplay] = useState(true);
   const [mute, setmute] = useState(true);
   const [tipDetail, setTipDetails] = useState({});
   const [voteDetail, setVoteDetails] = useState({});
   const [prod_id, setprodid] = useState(null); //sanchit
   const store = useSelector(store => store);
   const dispatch = useDispatch();
   const history = useHistory();
   const [showob, setshowob] = useState(false);
   const allprod = useSelector(store => store.home.allproducts);
   const prevRoute = useSelector(store => store.home.prevRoute);
   const winnersection = useRef(null);
   const banners = useSelector(store => store.home.banners);
   const campaign_manager = useSelector(store => store.home.campaign_manager);
   const upcoming = useRef(null);
   const [banner_id, setbannerid] = useState(0);
   const [showpopup, setshowpopup] = useState(false);
   const [configs, setconfigs] = useState([]);
   const [parent, setparent] = useState(null);
   const [temp, settemp] = useState(0);
   const [langu, setLangu] = useState(false);
   const location = useLocation();
   const allproducts = useSelector(state => state.home.allproducts);
   const campaign_by_category = useSelector(state => state.home.campaign_by_category);
   const device_token = useSelector(state => state.home.device_token);
   const device_type = useSelector(state => state.home.device_type);
   const [campaignData, setCampaignData] = useState([]);
   const Reactslider = useRef();

   const mainslider = {
      dots: true,
      infinite: true,
      initialSlide: 0,
      speed: 500,
      slidesToScroll: +1,
      arrows: false,
      fade: true,
   };

   const endingsoon = useRef(null);
   const nextmonth = useRef(null);

   useEffect(() => {
      if (campaign_by_category.length) {
         /** Sort campaign array as per date */
         const soldItem = campaign_by_category.filter(ele => ele.status === 'sold');
         const activeItems = campaign_by_category.filter(ele => ele.status !== 'sold');
         const sortedByDateTime = activeItems.sort(
            (a, b) => new Date(b?.activation_date).getTime() - new Date(a?.activation_date).getTime()
         );
         setCampaignData([...sortedByDateTime, ...soldItem]);
      }
   }, [campaign_by_category]);

   useEffect(() => {
      if (document.getElementById('home-page-wrapper'))
         document.getElementById('home-page-wrapper').appendChild(script);
      return () => {
         if (document.getElementById('home-page-wrapper'))
            document.getElementById('home-page-wrapper').removeChild(script);
      };
   }, []);

   useEffect(() => {
      if (window.screen.width > 600) {
         dispatch(GetDesktopProducts());
      }
   }, []);

   useEffect(() => {
      //dispatch(GetConversionRates())

      if (navigator.appVersion.includes('Android')) {
         document.addEventListener('message', OnMessageRecieved);
      } else {
         window.addEventListener('message', OnMessageRecieved);
      }

      return () => {
         if (navigator.appVersion.includes('Android')) {
            document.removeEventListener('message', OnMessageRecieved);
         } else {
            window.removeEventListener('message', OnMessageRecieved);
         }
      };
   }, []);

   useEffect(() => {
      if (props.location.props) {
         if (props.location.props.top) {
            window.scroll(0, 0);
         }
      }
   }, [location]);

   useEffect(() => {
      if ((allprod.length !== 0) & (prod_id !== null)) {
         onLearnMore(prod_id);
         setprodid(null);
         /* const prod = allprod.find(item => {
            return item.id == prod_id;
         });
         const campaign = campaign_manager.find(camp => {
            return prod.id == camp.campaign_product_id;
         });
         prod.custom_attributes.map(att => {
            if (att.attribute_code === 'total_stock') {
               att.value = campaign.campaign_total_tickets;
            }
            return att;
         });

         history.push({
            pathname: '/product1',
            query: { type: 'product' },
            props: prod,
         }); */
      }
   }, [prod_id, allprod]);

   useEffect(() => {
      if (prevRoute === null) {
         return;
      }
      if (prevRoute.includes('thismonthwinner')) {
         if (winnersection.current != null) {
            winnersection.current.scrollIntoView({ behaviour: 'smooth' });
         }
      }
   }, [prevRoute]);

   //const endingsoon = React.useRef<GliderMethods>();

   // const endingsoon = {
   //   dots: false,
   //   infinite: true,
   //   //initialSlide: 0,
   //   speed: 1000,
   //   //slidesToShow: 3,
   //   slidesToScroll: 1,
   //   arrows: false,
   //   autoplay: false,
   //   variableWidth: true,
   //   // responsive: [
   //   //   {
   //   //     breakpoint: 600,
   //   //     settings: {
   //   //       slidesToShow: 2,
   //   //       slidesToScroll: 2,
   //   //       initialSlide: 2,
   //   //     },
   //   //   },
   //   // ],
   // };

   // const nextmonth = {
   //   dots: false,
   //   infinite: true,
   //   //initialSlide: 0,
   //   speed: 1000,
   //   //slidesToShow: 3,
   //   slidesToScroll: 1,
   //   arrows: false,
   //   autoplay: true,
   //   variableWidth: true,
   // };

   //commented only for demo

   useEffect(() => {
      if (localStorage.getItem('DeviceDetach') === null || localStorage.getItem('DeviceDetach') === 'null') {
         // setTimeout(() => {
         //    DevieDetach()
         // }, 10000)
      }

      

      if (showob) {
         if (localStorage.getItem('onboarding') === null || localStorage.getItem('onboarding') === 'null') {
            props.history.push('/ob');
         }
      }

      // props.history.push('/ob');
      //props.history.push("/ob")
      // alert("nside"+localStorage.getItem('onboarding'));
      if (localStorage.getItem('onboarding') === null || localStorage.getItem('onboarding') === 'null') {
         // alert("onboarding inde")
         //   setTimeout(() => {
         //  }, 1000)
      }
   }, [showob]);

   /* useEffect(() => {
      if (Cookie.get("onboarding") === undefined) {
         setTimeout(() => {
            props.history.push("/ob")
         }, 3000)
      }
   }, []) */

   useEffect(() => {
      let languageget = localStorage.getItem('langage');
      if (languageget === null) {
         localStorage.setItem('langage', 'en');
      }
      
      if (store.home.allproducts.length === 0 || store.home.categories.length === 0) {
         //Demo after use this
         // if (store.home.allproducts.length === 0  || store.home.render  ) { //Demo

         if (store.home.render) {
            dispatch({ type: 'SETRENDER' });
         }

         (async () => {
            setRendered(false);
            setRendered1(false);
            setRendered2(false);
            setRendered3(false);
            setProdRendered(false);
            // DONT SWIPE POSITION

            const apiCallArray = [
               dispatch(GetCampaignManager()),
               dispatch(GetCategories()),
               dispatch(GetPrizesNextMonth()),
               dispatch(Getendingsoonproducts()),
               dispatch(GetBanners()),
               dispatch(GetAllProducts()),
               dispatch(GetWinnerList()),
               dispatch(MasterApiData()),
               dispatch(GetCurrencyData()),
            ];

            if (store.auth.token !== null) {
               apiCallArray.push(dispatch(GetNotifications()));
               apiCallArray.push(dispatch(ViewCart()));
            }

            const [campaigns, category, , , ,] = await Promise.all(apiCallArray);
            
            await dispatch(GetProdByCat(category.id, campaigns));
            setSubCategory(category);
            setRendered(true);
            TweenMax.fromTo('.visible1', 0.5, { opacity: 1, display: 'flex' }, { opacity: 0, display: 'none' });
            //setProdRendered(true);
            setTimeout(() => {
               setRendered1(true);
               TweenMax.fromTo('.visible2', 0.5, { opacity: 1, display: 'flex' }, { opacity: 0, display: 'none' });
            }, 250);
            setTimeout(() => {
               setRendered2(true);
               TweenMax.fromTo('.radiobtn', 0.5, { opacity: 0.5 }, { opacity: 1 });
            }, 500);
            setTimeout(() => {
               setProdRendered(true);
            }, 750);
            setTimeout(() => {
               setRendered3(true);
            }, 1000);
            await promotion();
            //TweenMax.fromTo(".home-main-slider", 500, { opacity: 0 }, { opacity: 1 })
         })();
      }
   }, []);

   //need todo remove end

   const OnMessageRecieved = message => {

      if (message.data === 'true') {
         //alert("here")
         setshowob(true);
      } else {
         let data;
         if (typeof message.data === 'string') {
            data = JSON.parse(message.data);
         } else {
            data = message.data;
         }
         // const data = JSON.parse(message.data);

         if (data.type === 'token') {
            dispatch(SetDeviceInfo(data.device_token, data.device_type));
         } else if (data.type === 'link') {
            if (data.page === 'product') {
               setprodid(data.id);
            } else if (data.page === 'upcomingprizes') {
               setTimeout(() => {
                  upcoming.current.scrollIntoView();
               }, 1000);
            } else if (data.page == 'refer') {
               //alert(data.id)
               dispatch({ type: SETREFERID, payload: data.id });
            }
         } else if (data.type == 'lang') {
            
            // alert(data.data);
            let languageget = localStorage.getItem('langage');
            
         
            if (data.data == 'Arabic') {
               localStorage.setItem('langage', 'ar');
               document.getElementById('body-id').classList.add('site-ar');
               // alert(localStorage.getItem('langage'));
               if (localStorage.getItem('langage') == 'en') {
                  dispatch({ type: 'SETRENDER' });
               }
               // dispatch(SetLang('ar'));
               setLangu(true);
            } else {
               localStorage.setItem('langage', 'en');
               if (localStorage.getItem('langage') == 'ar') {
                  dispatch({ type: 'SETRENDER' });
               }
               //   dispatch(SetLang('en'));
               setLangu(false);
            }
         }
      }
   };

   const promotion = async () => {
      if (store.auth.token) {
         const show = await dispatch(ShowTip());
         const tips = await dispatch(GetTip());
         const tip_id = Math.floor(Math.random() * tips.length);
         //const tip = getRandomPromotionDetails('tip', tips, 'tips_id');
         
         if ((tips.length != 0) & show) {
            
            setTipDetails(tips[tip_id]);
            setTipPopup(true);
         }
         /* if (!tip) {
            const votes = await dispatch(GetVote());
            const vote = getRandomPromotionDetails('vote', votes, 'polls_id');
            if (vote) setVoteDetails(vote);
            setVotePopup(!!vote);
         } */
      }
   };

   const playVideo = e => {
      const vid = document.getElementById('kazivedio');
      
      if (play == true) {
         setplay(false);
         vid.play();
      } else {
         vid.pause();
         setplay(true);
      }
   };

   const pauseVideo = e => {
      const vid = document.getElementById('kazivedio');
      // alert(vid.muted);
      vid.muted = !vid.muted;
      if (mute == true) {
         setmute(false);
         vid.muted = true;
      } else {
         setmute(true);
         vid.muted = false;
      }
   };

   const onLearnMore = stored_id => {
      var id = stored_id == undefined ? store.home.banners[banner_id].product_id : stored_id;

      

      if (id == null) {
         return;
      }

      if (store.home.allproducts.length !== 0) {
         var camp = campaign_manager.find(camp => camp.campaign_product_id == id);

         if (camp == undefined) {
            return;
         }

         var product = allprod.find(item => item.id == camp.campaign_product_id);
         var prize = allprod.find(item => item.id == camp.campaign_prize_id);

         product.custom_attributes.unshift({ attribute_code: 'total_stock', value: camp.campaign_total_tickets });
         product.custom_attributes.unshift({ attribute_code: 'total_sales', value: camp.campaign_total_sales });

         if ((product != undefined) & (prize != undefined)) {
            history.push({
               pathname: '/product',
               query: { type: 'prize' },
               props: { prize: prize, product: product },
            });
         }
      }
   };

   const ShowProductDetails = (id, type) => {
      var camp = campaign_manager.find(camp => camp.campaign_product_id == id);

      if (camp == undefined) {
         return;
      }

      var product = allprod.find(item => item.id == camp.campaign_product_id);
      var prize = allprod.find(item => item.id == camp.campaign_prize_id);

      product.custom_attributes.unshift({ attribute_code: 'total_stock', value: camp.campaign_total_tickets });
      product.custom_attributes.unshift({ attribute_code: 'total_sales', value: camp.campaign_total_sales });

      product.custom_attributes.map(item => {
         if (item.attribute_code == 'short_description') {
            item.value = camp.campaign_product_line;
         }
         return item;
      });

      prize.custom_attributes.map(item => {
         if (item.attribute_code == 'short_description') {
            if (langu == true) {
               item.value = camp.campaign_prize_line_ar;
            } else {
               item.value = camp.campaign_prize_line;
            }
         }
         return item;
      });

      history.push({
         pathname: '/product',
         query: { type },
         props: { prize: prize, product: product },
      });
   };

   const getRandomPromotionDetails = (name, data, id = null) => {
      const nameId = id === null ? `${name}s_id` : id;
      let LSPromotion = localStorage.getItem(promotionENV);
      if (!LSPromotion) {
         localStorage.setItem(
            promotionENV,
            JSON.stringify({
               [name]: '',
            })
         );
         LSPromotion = localStorage.getItem(promotionENV);
      }
      const LSPromotionParse = JSON.parse(LSPromotion);
      const LSPromotionIds = LSPromotionParse[name];
      if ((LSPromotionIds && data.length === LSPromotionIds.split(', ').length) || isArrEmpty(data)) {
         return false;
      } else if (LSPromotionIds) {
         const tipIds = LSPromotionIds.split(', ');
         const modifiedTips = data.filter(cur => {
            if (!tipIds.includes(cur[nameId])) return cur;
            return false;
         });
         const length = Math.floor(Math.random() * modifiedTips.length);
         const stringify = JSON.stringify({
            ...LSPromotionParse,
            [name]: LSPromotionIds + ', ' + modifiedTips[length][nameId],
         });
         localStorage.setItem(promotionENV, stringify);
         return modifiedTips[length];
      }
      const length = Math.floor(Math.random() * data.length);
      const stringify = JSON.stringify({
         ...LSPromotionParse,
         [name]: data[length][nameId],
      });
      localStorage.setItem(promotionENV, stringify);
      return data[length];
   };

   const handleVoteSubmit = async data => {
      const [poll_id, poll_options_id] = data;
      const res = await dispatch(
         SaveVote({
            poll_id,
            poll_options_id,
         })
      );
      if (res) setVotePopup(false);
   };

   const handleUnsubscribeTip = async () => {
      const { email, firstname, lastname } = store.auth.userinfo;
      await dispatch(
         UpdateTipAndVotePromotion(
            {
               email,
               firstname,
               lastname,
               website_id: 1,
            },
            'tip',
            'unsubscribe'
         )
      );
      setTipPopup(false);
   };

   // const DevieDetach = async () => {
   //    const { email, firstname, lastname } = store.auth.userinfo;
   //    await dispatch(
   //       UpdateDevice(
   //          {
   //             email,
   //             firstname,
   //             lastname,
   //             website_id: 1,

   //          },
   //          device_token,
   //          device_type,
   //       )
   //    );
   // };

   const handleUnsubscribeVote = async () => {
      const { email, firstname, lastname } = store.auth.userinfo;
      await dispatch(
         UpdateTipAndVotePromotion(
            {
               email,
               firstname,
               lastname,
               website_id: 1,
            },
            'votes',
            'unsubscribe'
         )
      );
      setVotePopup(false);
   };

   const handleNavTabs = async item => {
      
      setProdRendered(false);
      setSubCategory(item);
      await dispatch(SetCategory(item));
      await dispatch(GetProdByCat(item.id, get(store, 'home.campaign_manager', [])));
      setProdRendered(true);
   };

   const gliderSettings = {
      draggable: true,
      slidesToShow: 2.5,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 575,
            settings: {
               slidesToShow: 'auto',
               slidesToScroll: 'auto',
               itemWidth: 150,
               duration: 0.25,
            },
         },
      ],
   };

   const OnShared = async () => {
      let link = await GenerateProductLink('5');
      // let campaign = store.home.campaign_manager.find(camp => camp.campaign_product_id == props.item.product.id)
      // alert(campaign);
      let linknew = 'Check this upcoming prize,  \n \n' + link;
      //alert(linknew)
      window.ReactNativeWebView.postMessage(
         JSON.stringify({
            type: 'share',
            message: linknew,
         })
      );
      //window.ReactNativeWebView.postMessage('https://kanzi.page.link/6BG2');
   };
   const OnSharedupcoming = async name => {
      let link = await GenerateProductLink('5');
      //let link =  dispatch(GenerateProductLink("5"));
      // let campaign = store.home.campaign_manager.find(camp => camp.campaign_product_id == props.item.product.id)
      // alert(campaign);
      let linknew = 'Check this upcoming prize,  \n \n' + name + '\n \n' + link;
      //alert(linknew)
      window.ReactNativeWebView.postMessage(
         JSON.stringify({
            type: 'share',
            message: linknew,
         })
      );
   };

   const Slicksettings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
         {
            breakpoint: 1200,
            settings: {
               slidesToShow: 3,
            },
         },
         {
            breakpoint: 972,
            settings: {
               slidesToShow: 2.5,
            },
         },
         {
            breakpoint: 767,
            settings: {
               slidesToShow: 2,
            },
         },
         {
            breakpoint: 660,
            settings: {
               slidesToShow: 1.8,
            },
         },
      ],
   };

   const OnSubmit = () => {
      dispatch(SetPopupData(false, false));
      let rewards = get(store, 'home.rewards', []);
      rewards.shift();
      setTimeout(() => {
         dispatch(SetRewards(rewards));
      }, 3000);
   };

   return (
      <div className="home-container" id="home-page-wrapper">
         {get(store, 'home.popup.show', false) ? (
            <PopUps
               data={{ message: get(store, 'home.popup.text', ''), image: get(store, 'home.popup.img', '') }}
               type="loyalty-popup2"
               onSubmit={OnSubmit}
               onClose={OnSubmit}
            />
         ) : null}
         {hasTipPopup && (
            <PopUps
               type="tip"
               details={tipDetail}
               onClose={() => setTipPopup(false)}
               onSubmit={() => setTipPopup(false)}
               unsubscribe={handleUnsubscribeTip}
               {...props}
            />
         )}
         {hasVotePopup && (
            <PopUps
               type="vote"
               details={voteDetail}
               onClose={() => setVotePopup(false)}
               onSubmit={data => handleVoteSubmit(data)}
               unsubscribe={handleUnsubscribeVote}
               {...props}
            />
         )}
         {showpopup ? (
            <PopUps
               type="size-selection"
               parent={parent}
               details={configs}
               onClose={() => setshowpopup(false)}
               onSubmit={() => setshowpopup(false)}
               {...props}
            />
         ) : null}
         <div className="">
            <NavBar homeheader="true" {...props} />
            <DesktopBanner />
            <div className="home-main-slider">
               {isRendered ? (
                  <Slider
                     {...mainslider}
                     afterChange={index => {
                        setbannerid(index);
                        if (document.getElementById('kazivedio') !== null) {
                           document.getElementById('kazivedio').pause();
                        }
                     }}
                  >
                     {banners.map((item, index) => {
                        var show = false;
                        if (item.product_id != null) {
                           show = true;
                        }

                        return (
                           <div /* data-index={index} */ key={index}>
                              <div
                                 className="slider-slides"
                                 // onClick={() => {
                                 //    onLearnMore();
                                 // }}
                              >
                                 {get(item, 'slide_type', '') === 'image' ? (
                                    <div>
                                       <div
                                          className="skeleton-slider visible1"
                                          style={{ position: 'absolute', zIndex: 1000 }}
                                       />
                                       {/* <div className="slider-overlay" /> */}
                                       <img className="slider-img" src={item.image_url} alt="" />
                                       <div className="slider-text">
                                          <h4>
                                             {get(store, 'home.language', 'en') == 'en' ? item.name : item.name_ar}
                                          </h4>
                                          {show ? (
                                             <span
                                                className="slider-btn"
                                                onClick={() => {
                                                   onLearnMore();
                                                }}
                                             >
                                                {props.Environment.hlearnmore}
                                             </span>
                                          ) : null}
                                       </div>
                                    </div>
                                 ) : (
                                    <div>
                                       {/* <div className="play-pause">
                                             <img src="images/pause.svg" className="pause" alt="" />
                                             <img onClick={event => pauseVideo(event)} src="images/mute.svg" className="mute" alt="" />
                                          </div> */}
                                       <Video
                                          loop
                                          className="slider-img"
                                          id="kazivedio"
                                          src={get(item, 'image_url', '')}
                                          loop
                                          controls={[]}
                                          //controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                                          //  poster={`${props.props.Environment.img_path}images/you.png`}
                                          poster="https://www.kanziapp.com/images/imagep.png"
                                          preload="true"
                                          onCanPlayThrough={() => {
                                             // Do stuff
                                          }}
                                       >
                                          <source src={get(item, 'image_url', '')} type="video/mp4" />
                                       </Video>
                                       {/* <video
                                          className="slider-img"
                                          id="kazivedio"
                                          src={get(item, 'image_url', '')}
                                          loop
                                       /> */}
                                       {/* <ReactPlayer url={item.image_url} style={{width: 100}}/> */}
                                       <div className="slider-controls">
                                          <span onClick={event => playVideo(event)}>
                                             <img
                                                src={`${props.Environment.img_path}icons/${
                                                   play == true ? 'ic-play.svg' : 'ic-pause.svg'
                                                }`}
                                                alt=""
                                                //onClick={()=>toggleVideo()}
                                             />
                                          </span>
                                          <span onClick={event => pauseVideo(event)}>
                                             <img
                                                src={`${props.Environment.img_path}icons/${
                                                   mute == true ? 'ic-unmute.svg' : 'ic-mute.svg'
                                                }`}
                                                alt=""
                                             />
                                          </span>
                                       </div>
                                    </div>
                                 )}
                              </div>
                           </div>
                        );
                     })}
                  </Slider>
               ) : (
                  <div className="skeleton-slider" />
               )}
            </div>
         </div>
         <div className="page-wrapper">
            <div className="endingsoon-main-slider">
               {!isRendered || get(store, 'home.endingsoon', []).length != 0 ? (
                  <h4 className="ending-soon-header">
                     {props.Environment.hendtingtxt1} <span>{props.Environment.hendtingtxt2}</span>
                  </h4>
               ) : null}
               <div className="E-list">
                  {isRendered ? (
                     <Glider ref={endingsoon} {...gliderSettings}>
                        {get(store, 'home.endingsoon', []).map((data, index) => {
                           var camp = campaign_manager.find(camp => camp.campaign_product_id == data.id);

                           if (camp == undefined) {
                              return;
                           }

                           

                           var product = allprod.find(item => item.id == camp.campaign_product_id);
                           if (product == undefined) return;
                           var prize = allprod.find(item => item.id == camp.campaign_prize_id);
                           if (prize == undefined) return;

                           data.custom_attributes.unshift({
                              attribute_code: 'total_stock',
                              value: camp.campaign_total_tickets,
                           });
                           data.custom_attributes.unshift({
                              attribute_code: 'total_sales',
                              value: camp.campaign_total_sales,
                           });

                           var sales = camp.campaign_total_sales;

                           var stocks = camp.campaign_total_tickets;

                           var small_image = prize.custom_attributes.find(x => {
                              return x.attribute_code === 'small_image';
                           });

                           var small_image_product = product.custom_attributes.find(x => {
                              return x.attribute_code === 'small_image';
                           });

                           var meta_title = data.custom_attributes.find(x => {
                              return x.attribute_code === 'meta_title';
                           });

                           const getPrizePercentage = () => {
                              let prizePercentage = 0;
                              if (showTimeBasedCampaigns(camp?.campaign_type)) {
                                 let finished =
                                    calcDaysFromCurrentDate(camp?.campaign_end_time, camp?.activation_date) -
                                    calcDaysFromCurrentDate(camp?.campaign_end_time);
                                 let total = calcDaysFromCurrentDate(camp?.campaign_end_time, camp?.activation_date);
                                 prizePercentage = (finished / total) * 100;
                              } else {
                                 prizePercentage = (sales / stocks) * 100;
                              }

                              return prizePercentage;
                           };

                           /* if (index > 3) return null; */
                           return (
                              <div key={index}>
                                 <div className="endingsoon-progressbar">
                                    <div
                                       className="skeleton-circle visible2"
                                       style={{ position: 'absolute', zIndex: 1000 }}
                                    />
                                    <div
                                       className="skeleton-text visible2"
                                       style={{ position: 'absolute', zIndex: 1000, bottom: 30 }}
                                    />
                                    <CircularProgressbarWithChildren value={getPrizePercentage()}>
                                       <div className="endingsoon-product-main">
                                          <div
                                             className="endingsoon-prize"
                                             onClick={() => {
                                                ShowProductDetails(data.id, 'prize');
                                             }}
                                          >
                                             <img
                                                src={
                                                   props.Environment.productUrl +
                                                   (small_image == undefined ? '' : small_image.value)
                                                }
                                                alt=""
                                             />
                                          </div>
                                          <div
                                             className="endingsoon-product"
                                             onClick={() => {
                                                ShowProductDetails(data.id, 'product');
                                             }}
                                          >
                                             <img
                                                src={
                                                   props.Environment.productUrl +
                                                   (small_image_product == undefined ? '' : small_image_product.value)
                                                }
                                                alt=""
                                             />
                                          </div>
                                       </div>
                                    </CircularProgressbarWithChildren>
                                    <div className="koinz-figurs">
                                       
                                       <h6>
                                          {showTimeBasedCampaigns(camp?.campaign_type)
                                             ? `Ends In ${calcDaysFromCurrentDate(camp?.campaign_end_time)} Days`
                                             : sales == undefined
                                             ? 1
                                             : sales / stocks == undefined
                                             ? 1
                                             : stocks}
                                       </h6>
                                       <div className="endingsoon-prize-name">{data.name}</div>
                                    </div>
                                 </div>
                              </div>
                           );
                        })}
                     </Glider>
                  ) : (
                     <div style={{ display: 'flex', overflow: 'hidden' }}>
                        {Array(5).fill(
                           <div className="endingsoon-progressbar">
                              <div className="skeleton-circle" />
                              <div className="skeleton-text" />
                           </div>
                        )}
                     </div>
                  )}
               </div>

               <Link to="/mykoinz" className="home-green-btn">
                  <div className="icons-text">
                     <span>
                        <img src={`${props.Environment.img_path}images/koinz-round-icon-money.svg`} alt="" />
                     </span>
                     <div className="description">
                        <p className="header">{props.Environment.hkoinztitle}</p>
                        <p className="sub-header">{props.Environment.buycollectKoinz}</p>
                     </div>
                  </div>
                  <div>
                     <img src={`${props.Environment.img_path}images/btn-bg.svg`} alt="" />
                  </div>
               </Link>
               <div className="prize-listing-main">
                  <h4>
                     {props.Environment.levelofluxury}
                     <span>{props.Environment.luxury}</span>
                     {/* What’s your level of <span>luxury?</span> */}
                  </h4>
                  <div className="prize-listing-filter">
                     {isRendered ? (
                        <div className="radio-btns">
                           {get(store, 'home.categories', []).map((item, index) => {
                              var image = item.custom_attributes.find(x => x.attribute_code === 'image');

                              return (
                                 <div key={index} style={{ direction: 'ltr' }} onClick={() => handleNavTabs(item)}>
                                    <input type="radio" id={item.name} name="filter" />
                                    <label
                                       className={`radiobtn 
                            ${store.home.category.name === item.name ? 'Cselected' : ''}`}
                                       htmlFor="cash"
                                    >
                                       {(get(item, 'name', '') !== 'All' ||
                                          get(item, 'name', '') !== 'كافّة  الجوائز ') && (
                                          <span id={item.name}>
                                             <img
                                                src={
                                                   props.Environment.app_domain_url +
                                                   (image == undefined ? '' : image.value)
                                                }
                                                alt=""
                                             />
                                          </span>
                                       )}
                                       <span className="radiobtn_name">{get(item, 'name', '')}</span>
                                    </label>
                                 </div>
                              );
                           })}
                        </div>
                     ) : (
                        <div style={{ display: 'flex' }}>{Array(3).fill(<div className="skeleton-tabs" />)}</div>
                     )}
                  </div>
                  {isProdRendered ? (
                     campaignData.map((campaign, index) => {
                        if (isNil(campaign)) {
                           return;
                        } else {
                           
                           if (campaign.status == 'active' || campaign.status == 'sold') {
                              // const productData=get(store, 'home.prodbycat');

                              var prize = allproducts.find(prod => {
                                 return prod.id == campaign.campaign_prize_id;
                                 // return prize.id == campaign.campaign_product_id;
                              });

                              var item = allproducts.find(prod => {
                                 return prod.id == campaign.campaign_product_id;
                                 // return prize.id == campaign.campaign_product_id;
                              });
                           } else {
                              return;
                           }
                        }

                        item.custom_attributes.unshift({
                           attribute_code: 'total_stock',
                           value: campaign.campaign_total_tickets,
                        });
                        item.custom_attributes.unshift({
                           attribute_code: 'total_sales',
                           value: campaign.campaign_total_sales,
                        });

                        item.custom_attributes.map(item => {
                           if (item.attribute_code == 'short_description') {
                              item.value = campaign.campaign_product_line;
                           }
                           return item;
                        });

                        prize.custom_attributes.unshift({
                           attribute_code: 'short_description',
                           value:
                              localStorage.getItem('langage') == 'ar'
                                 ? campaign.campaign_prize_line_ar
                                 : campaign.campaign_prize_line,
                        });
                        item.custom_attributes.unshift({
                           attribute_code: 'short_description',
                           value:
                              localStorage.getItem('langage') == 'ar'
                                 ? campaign.campaign_product_line_ar
                                 : campaign.campaign_product_line,
                        });

                        item.custom_attributes.unshift({
                           attribute_code: 'campaign_close_text',
                           value:
                              localStorage.getItem('langage') == 'ar'
                                 ? campaign.campaign_close_text_ar
                                 : campaign.campaign_close_text_en,
                        });

                        prize.custom_attributes.map(item => {
                           if (item.attribute_code == 'short_description') {
                              // item.value = campaign.campaign_prize_line;

                              item.value =
                                 localStorage.getItem('langage') == 'ar'
                                    ? campaign.campaign_prize_line_ar
                                    : campaign.campaign_prize_line;

                              // if (langu == true) {
                              //    item.value = campaign.campaign_prize_line_ar;
                              // }
                              // else {
                              //    item.value = campaign.campaign_prize_line;
                              // }
                           }
                           return item;
                        });

                        

                        return (
                           <PrizeList
                              {...props}
                              homelisting="true"
                              item={{ prize: prize, product: item, campaign: campaign }}
                              status={campaign.status}
                              index={index}
                              callback={configs => {
                                 setconfigs(configs);
                                 setparent(item);
                                 setshowpopup(true);
                              }}
                           />
                        );
                     })
                  ) : (
                     <div>
                        {Array(2).fill(
                           <div className="skeleton-product--parent">
                              <div className="skeleton-product" />
                           </div>
                        )}
                     </div>
                  )}
               </div>

               <div className="next-month-slider" ref={upcoming}>
                  <h4>
                     {props.Environment.hupcomingtxt1} <span>{props.Environment.hupcomingtxt2}</span>
                  </h4>
                  {isRendered && (
                     <Glider
                        ref={nextmonth}
                        draggable
                        slidesToShow={2.5}
                        slidesToScroll={1}
                        responsive={[
                           {
                              breakpoint: 575,
                              settings: {
                                 slidesToShow: 'auto',
                                 slidesToScroll: 'auto',
                                 itemWidth: 150,
                                 duration: 0.25,
                              },
                           },
                        ]}
                     >
                        {get(store, 'home.prizesnextmonth', []).map(item => {
                           var thumbnail = item.custom_attributes.find(x => {
                              return x.attribute_code === 'thumbnail';
                           });

                           var meta_keyword = item.custom_attributes.find(x => x.attribute_code === 'meta_keyword');

                           let noti = get(store, 'home.notiList', []).find(noti => noti.prize_id == item.id);

                           return (
                              <div className="month-slider">
                                 <div className="listing-prize">
                                    <div className="listing-prize-icons">
                                       {/* <span className="ic-share"
                                          onClick={() => { OnSharedupcoming(item.name) }}>
                                          <img src={`${props.Environment.img_path}icons/ic-share.svg`} alt="" />
                                       </span> */}
                                       <span
                                          className="ic-notification"
                                          onClick={() => {
                                             if (store.auth.token == null) {
                                                return history.push('/account');
                                             }
                                             if (noti) {
                                                dispatch(DeleteNotifications(noti.notification_id, item.id));
                                             } else {
                                                dispatch(AddNotifications(item.id));
                                             }
                                          }}
                                       >
                                          <img
                                             style={{ width: '90%', marginRight: '0' }}
                                             src={`${props.Environment.img_path}icons/${
                                                noti ? 'notiactive' : 'ic-notification-gray'
                                             }.svg`}
                                             alt=""
                                          />
                                       </span>
                                    </div>
                                    <div className="prize-img">
                                       <img
                                          src={
                                             props.Environment.productUrl +
                                             (thumbnail == undefined ? '' : thumbnail.value)
                                          }
                                          alt=""
                                       />
                                    </div>
                                 </div>
                                 <div className="prize-name">{item.name}</div>
                              </div>
                           );
                        })}
                     </Glider>
                  )}
               </div>
               {/* <Link to="/thismonthwinner" className="home-green-btn">
                  <div className="icons-text">
                     <span className="winner-img">
                        <img src={`${props.Environment.img_path}images/winner-james.jpg`} alt="" />
                     </span>
                     <span className="winner-img">
                        <img src={`${props.Environment.img_path}images/winner-james.jpg`} alt="" />
                     </span>
                     <span className="winner-img">
                        <img src={`${props.Environment.img_path}images/winner-james.jpg`} alt="" />
                     </span>
                     <span className="winner-text">
                        <p className="header">WINNERS</p>
                        <p className="sub-header">See who we’re celebrating</p>
                     </span>
                     <div className="btn-img">
                        <img src={`${props.Environment.img_path}images/winner-btn-bg.svg`} alt="" />
                     </div>
                  </div>
               </Link> */}
               {get(store, 'home.winnerList', []).length != 0 ? (
                  <div className="this-month-winner-main" ref={winnersection}>
                     <h4>
                        {props.Environment.hwinnertxt1}&nbsp;
                        <span>{props.Environment.hwinnertxt2}</span>
                     </h4>
                     <WinnerList winnerlist={store.home.winnerList} homepagewinnerlist="true" {...props} />
                  </div>
               ) : null}
            </div>
         </div>

         {/* <div className="desktop-products-carousel">
            <div className="container">
               <h1 className="desktop-header">
                  KANZI <span>Products</span>
               </h1>
               <Slider {...Slicksettings} ref={Reactslider}>
                  {get(store, 'home.desktop_products', []).map(item => (
                     <div className="product-items">
                        <h1>{item.sku}</h1>
                        <img
                           src={
                              props.Environment.productUrl +
                              item.custom_attributes.find(attr => attr.attribute_code == 'image').value
                           }
                           alt=""
                        />
                        <button>Read More</button>
                     </div>
                  ))}
               </Slider>
            </div>
         </div> */}
         <Link to="/mykoinz" className="home-green-btn">
            <div className="desktop-koinz-banner">
               <div className="container">
                  <img src="icons/desktop-koinz.svg" className="left-img" alt="" />
                  <h1>{props.Environment.koinz}</h1>
                  <p>{props.Environment.buycollectKoinz}</p>
                  <button>{props.Environment.cmap}</button>
                  <img src="images/btn-bg.svg" className="right-img" alt="" />
               </div>
            </div>
         </Link>
      </div>
   );
};

export default Home;
