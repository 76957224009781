import { ADDFAVOURITE, REMOVEFAVOURITE, GETALLFAV , SETISFAV } from "../actions/favourites/actionTypes"
import { LOGOUT } from "../actions/auth/actionTypes"

const initialState = {
    favourites: [],
    isfavourite: false
};

const FavouritesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADDFAVOURITE:
            return {
                ...state,
                favourites: [...state.favourites, action.payload]
            };
        case REMOVEFAVOURITE:
            return {
                ...state,
                favourites: state.favourites.filter((item) => { return item.product_id != action.payload })
            }
        case GETALLFAV:
            return {
                ...state,
                favourites: action.payload
            }
        case LOGOUT:
            return {
                ...state,
                favourites: []
            }
        case SETISFAV: console.log("from redux")
            return {
                ...state,
                isfavourite: !state.isfavourite
            }
        default:
            return state;
    }
}

export default FavouritesReducer;
