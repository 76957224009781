import React, { useState, useEffect, useRef } from "react";
import { Link, Redirect } from "react-router-dom";
import NavBar from "../../components/NavBar/NavBar";
import { Environment } from "../../env";
import { TweenMax } from "gsap";
import { Tween } from "react-gsap";

const AccountDetails = props => {
  return (
    <div>
      <NavBar accountdetails="Account Details" />
      <div className="account-container">
        <div className="desktop-header">{props.Environment.accountsettings2}</div>
        {props.token === null ? <Redirect to="/signup" /> : null}
        <Tween from={{ x: "100%", opacity: 1, duration: 0.5 }}>
          <div className="account-list-cards">
            <div className="list-cards">
              <ul>
                <li>
                  <Link to="/personaldetails">
                    <div className="icontxt">
                      <div className="list-icon">
                        <img src={`${props.Environment.img_path}makeicons/ic_personallist.svg`} alt="" />
                      </div>
                      <div className="list-txt">
                        {props.Environment.personaldetail}
                      </div>
                    </div>
                    <div>
                      <img src={`${props.Environment.img_path}makeicons/ic_list_arrow.svg`} alt="" />
                    </div>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/paymentmethod">
                    <div className="icontxt">
                      <div className="list-icon">
                        <img src={`${props.Environment.img_path}makeicons/ic_paymentlist.svg`} alt="" />
                      </div>
                      <div className="list-txt">
                        {props.Environment.paymentmethod}
                      </div>
                    </div>
                    <div>
                      <img src={`${props.Environment.img_path}makeicons/ic_list_arrow.svg`} alt="" />
                    </div>
                  </Link>
                </li> */}
                <li>
                  <Link to="/savedaddress">
                    <div className="icontxt">
                      <div className="list-icon">
                        <img src={`${props.Environment.img_path}makeicons/ic_saved_address.svg`} alt="" />
                      </div>
                      <div className="list-txt">
                        {props.Environment.savedaddress}
                      </div>
                    </div>
                    <div>
                      <img src={`${props.Environment.img_path}makeicons/ic_list_arrow.svg`} alt="" />
                    </div>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/">
                    <div className="icontxt">
                      <div className="list-icon">
                        <img src={`${props.Environment.img_path}makeicons/ic_password.svg`} alt="" />
                      </div>
                      <div className="list-txt">
                        {props.Environment.password}
                      </div>
                    </div>
                    <div>
                      <img src={`${props.Environment.img_path}makeicons/ic_list_arrow.svg`} alt="" />
                    </div>
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </Tween>
      </div>
    </div>
  );
};

export default AccountDetails;
