import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Tween } from "react-gsap";
import { Link, useHistory } from "react-router-dom";
import { Environment } from "../../env";
import Button from "../../components/Button/Button";
import NavBar from "../../components/NavBar/NavBar";
import ActionSheet from "../ActionSheet/ActionSheet";
import Nationality from "../../pages/PersonalDetails/Nationality.json";
import FloatingInput from "../../pages/PersonalDetails/FloatingInput";
import { SetError } from "../../redux/actions/auth/actions";
import { isObjEmpty } from "../../utils/utils";
import PopUps from "../PopUps/PopUps";
import CircularLoader from "../Loader/CircularLoader";
import $ from "jquery";

const script = document.createElement("script");
script.src =
  "https://i.ctnsnet.com/int/integration?pixel=67834349&nid=2142538&cont=s&gdpr_pd={GDPR_PD}&gdpr_consent={GDPR_CONSENT_85}";
script.async = true;

const Signup = props => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const [type, setType] = useState("current");
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone_number: "",
    nationality: "Indian",
    gender: "0",
    pass: ""
  });
  const [ischecked, setchecked] = useState(false);
  const [countryCode, setCountryCode] = useState("+971");
  const [chekcs, setchekcs] = useState("");
  const [error, setError] = useState({});
  const history = useHistory();
  const token = useSelector(state => state.auth.token);
  const refer_id = useSelector(state => state.home.refer_id);
  const dispatch = useDispatch();
  const error_redux = useSelector(state => state.auth.error);
  const [errorPopup, setErrorPopup] = useState("none");
  const [termsPopup, setTermsPopup] = useState(false);
  const [animate, setanimate] = useState(false);
  const [uppercase, setuppercase] = useState(false);
  const [lowercase, setlowercase] = useState(false);
  const [passnotvalu, setpassnotvalu] = useState(false);

  useEffect(() => {
    if (document.getElementById("signUp-page-wrapper"))
      document.getElementById("signUp-page-wrapper").appendChild(script);
    return () => {
      if (document.getElementById("signUp-page-wrapper"))
        document.getElementById("signUp-page-wrapper").removeChild(script);
    };
  }, []);

  useEffect(
    () => {
      var val = $("#ddlViewBy").val();
      var lbl = $("#ddlViewBy option:selected").text();
      $("#ddlViewBy").prepend(
        "<option value='" +
          val +
          "' data-value='selected' selected hidden>" +
          val +
          "</option>"
      );

      $("#ddlViewBy").on("change", function() {
        var val = $("#ddlViewBy").val();
        var lbl = $("#ddlViewBy option:selected").text();

        $("#ddlViewBy option[data-value='selected']").attr("value", val);
        $("#ddlViewBy option[data-value='selected']").text(val);

        $("#ddlViewBy").val(val);
        // alert(val);
        setCountryCode(val);
      });
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          console.log("IF");
          // for removing login
        } else {
          if (
            ref.current &&
            !ref1.current.contains(event.target) &&
            !ref2.current.contains(event.target)
          ) {
            var d = document.getElementById("f1");
            d.className += " radiobtn1";
            var d1 = document.getElementById("f2");
            d1.className += " radiobtn2";
          }
          if (ref.current && ref1.current.contains(event.target)) {
            const el = document.querySelector("#f1");
            const el1 = document.querySelector("#f2");
            if (el.classList.contains("radiobtn1")) {
              el.classList.remove("radiobtn1");
            }
            if (el1.classList.contains("radiobtn2")) {
              el1.classList.remove("radiobtn2");
            }
          }
        }
      }

      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    },
    [ref]
  );

  useEffect(() => {
    if (token !== null) {
      history.push("/");
    }
    (async () => {
      const res = await axios({
        method: "GET",
        url: "https://ipapi.co/json"
      });
      setCountryCode(res.data.country_calling_code);
    })();
    // alert(refer_id)
  }, []);

  useEffect(
    () => {
      if (error_redux !== null) {
        if (error_redux === "Account with phone number is already exist") {
          setErrorPopup("register_phone");
          dispatch(SetError(null));
        } else if (
          error_redux ===
          "A customer with the same email address already exists in an associated website."
        ) {
          setErrorPopup("register_email");
          dispatch(SetError(null));
        }
        console.log(error_redux);
      }
    },
    [error_redux]
  );

  const chooseErrorPopup = type => {
    if (type === "login_no_account") {
      return {
        onSubmit: () => {
          setErrorPopup("none");
          history.push("/signup");
        },
        onClose: () => setErrorPopup("none")
      };
    } else if (type === "register_email") {
      return {
        onSubmit: () => {
          setErrorPopup("none");
          history.push("/account");
        },
        onClose: () => {
          setErrorPopup("none");
          history.push("/account");
        }
      };
    } else if (type === "register_phone") {
      return {
        onSubmit: () => {
          setErrorPopup("none");
          history.push("/account");
        },
        onClose: () => {
          setErrorPopup("none");
          history.push("/account");
        }
      };
    }
    return false;
  };

  const Checks = () => {
    const errorTemplate = {};
    var reg = new RegExp("/^[A-Z]*$/");
    // strs[i].match(/[A-Z]/)

    if (countryCode.trim().length === 0) {
      errorTemplate.countryCode = "Country Code cannot be empty";
    }
    if (
      data.phone_number.trim().length <= 3 ||
      data.phone_number.trim().length > 16
    ) {
      errorTemplate.phone_number = "Phone should be atleast 15 digit long";
    }
    if (data.firstname.trim().length === 0) {
      errorTemplate.firstname = "First Name cannot be empty";
    }
    if (passnotvalu == false) {
      errorTemplate.pass = props.Environment.errorsignup;
    }
    if (data.lastname.trim().length === 0) {
      errorTemplate.lastname = "Last Name cannot be empty";
    }
    if (data.nationality === "") {
      errorTemplate.nationality = "Nationality cannot be empty";
    }
    if (!/\S+@\S+\.\S+/.test(data.email.trim())) {
      errorTemplate.email = "Please enter a valid Email Address!";
    }
    if (data.gender === "") {
      errorTemplate.gender = "Please select a gender to continue";
    }

    if (!ischecked) {
      setchekcs(props.Environment.accepttnc);
      errorTemplate.TandC = "Please accept terms and condition";
    } else {
      setchekcs("");
    }

    if (!isObjEmpty(errorTemplate)) {
      setError(errorTemplate);
      console.log(errorTemplate);
      setanimate(false);
      return false;
    } else {
      const {
        firstname,
        lastname,
        phone_number,
        email,
        gender,
        nationality,
        pass
      } = data;

      setData({
        firstname,
        lastname,
        phone_number,
        email,
        nationality,
        gender,
        pass
      });
      setError(errorTemplate);
      setanimate(false);
      return true;
    }
  };

  const OnSubmit = async () => {
    const check = Checks();
    if (!check) return false;

    console.log(data);
    setType("signup");
  };

  const onFormChange = (type, e, direct = false) => {
    var myElement = document.getElementById("passrd").value;
    console.log(myElement);
    console.log(type);
    console.log(e);
    if (type == "pass") {
      var reg = new RegExp("/^[A-Z]*$/");

      if (myElement.trim().match(/[A-Z]/)) {
        setuppercase(true);
      } else {
        setuppercase(false);
      }

      if (myElement.trim().match(/[a-z]/)) {
        setlowercase(true);
      } else {
        setlowercase(false);
      }
      if (myElement.trim().match(/[A-Z]/)) {
        if (myElement.trim().match(/[a-z]/)) {
          setpassnotvalu(true);
        } else {
          setpassnotvalu(false);
        }

        // matches
      } else {
        if (myElement.trim().match(/[a-z]/)) {
          if (myElement.trim().match(/[A-Z]/)) {
            setpassnotvalu(true);
          } else {
            setpassnotvalu(false);
          }
        }
        // doesn't match
      }
      console.log(passnotvalu);
    }
    if (type == "gender") {
      if (e == "1") {
        $("#f2").addClass("genderclasss");
        $("#f1").removeClass("genderclasss");
      } else if (e == "2") {
        $("#f2").removeClass("genderclasss");
        $("#f1").addClass("genderclasss");
      }
    }
    const value = direct ? e : e.target.value;
    let formData = { ...data };

    if (type == "firstname" || type == "lastname") {
      console.log("d");
      // var re = /^[A-Za-z]*$/;
      var re = /^[A-Za-z]/;

      if (e.target.value === "" || re.test(e.target.value)) {
        formData = {
          ...formData,
          [type]: value
        };
        setData(formData);
      }
    } else {
      formData = {
        ...formData,
        [type]: value
      };
      setData(formData);
    }
  };

  return (
    <div className="" id="signUp-page-wrapper">
      <CircularLoader show={animate} />
      {errorPopup !== "none" &&
        <PopUps
          type="login"
          errorType={errorPopup}
          onSubmit={() => chooseErrorPopup(errorPopup).onSubmit()}
          onClose={() => chooseErrorPopup(errorPopup).onClose()}
        />}
      {termsPopup &&
        <PopUps
          type="termspagesds"
          onSubmit={() => {
            setErrorPopup("none");
            setTermsPopup(false);
            // alert("dsdsd")
          }}
          onClose={() => {
            setErrorPopup("none");
            setTermsPopup(false);
          }}
        />}

      {type === "signup" &&
        <ActionSheet
          {...props}
          type="signup"
          callback={() => {
            setType("current");
          }}
          phonenumber={data.phone_number}
          countrycode={countryCode}
          data={data}
          backTo={props.location.backTo || "/"}
        />}
      <NavBar back="true" signup={props.Environment.singup} />
      <div className="singup-container" ref={ref}>
        <Tween from={{ x: "100%", opacity: 1, duration: 0.5 }}>
          <div className="forms-main">
            <div className="forms-cards">
              <div className="floating-form">
                {/* <FloatingInput
                          label={props.Environment.firstname}
                          value={data.firstname}
                          onChange={e => onFormChange('firstname', e)}
                          error={error.firstname}
                       /> */}
                <div className="floating-label">
                  <input
                    className={`floating-input floating-form ${error.firstname
                      ? "error"
                      : ""}`}
                    type="text"
                    maxlength="35"
                    inputMode="text"
                    placeholder=""
                    value={data.firstname}
                    error={error.firstname}
                    onChange={e => onFormChange("firstname", e)}
                  />
                  <span className="highlight" />
                  <label>
                    {props.Environment.firstname}
                  </label>
                </div>
                {/* <FloatingInput
                          label={props.Environment.lastname}
                          value={data.lastname}
                          onChange={e => onFormChange('lastname', e)}
                          error={error.lastname}
                       /> */}
                <div className="floating-label">
                  <input
                    className={`floating-input floating-form ${error.lastname
                      ? "error"
                      : ""}`}
                    type="text"
                    inputMode="text"
                    maxlength="35"
                    placeholder=""
                    value={data.lastname}
                    onChange={e => onFormChange("lastname", e)}
                    error={error.lastname}
                  />
                  <span className="highlight" />
                  <label>
                    {props.Environment.lastname}
                  </label>
                </div>
                <div className="floating-label">
                  <select
                    name="countryCode"
                    className={`floating-input code-input`}
                    type="text"
                    placeholder=" "
                    // value={countryCode}
                    id="ddlViewBy"
                    onChange={e => setCountryCode(e.target.value)}
                  >
                    <option data-countryCode="DZ" value="+213">
                      Algeria (+213)
                    </option>
                    <option data-countryCode="AD" value="+376">
                      Andorra (+376)
                    </option>
                    <option data-countryCode="AO" value="+244">
                      Angola (+244)
                    </option>
                    <option data-countryCode="AI" value="+1264">
                      Anguilla (+1264)
                    </option>
                    <option data-countryCode="AG" value="+1268">
                      Antigua &amp; Barbuda (+1268)
                    </option>
                    <option data-countryCode="AR" value="+54">
                      Argentina (+54)
                    </option>
                    <option data-countryCode="AM" value="+374">
                      Armenia (+374)
                    </option>
                    <option data-countryCode="AW" value="+297">
                      Aruba (+297)
                    </option>
                    <option data-countryCode="AU" value="+61">
                      Australia (+61)
                    </option>
                    <option data-countryCode="AT" value="+43">
                      Austria (+43)
                    </option>
                    <option data-countryCode="AZ" value="+994">
                      Azerbaijan (+994)
                    </option>
                    <option data-countryCode="BS" value="+1242">
                      Bahamas (+1242)
                    </option>
                    <option data-countryCode="BH" value="+973">
                      Bahrain (+973)
                    </option>
                    <option data-countryCode="BD" value="+880">
                      Bangladesh (+880)
                    </option>
                    <option data-countryCode="BB" value="+1246">
                      Barbados (+1246)
                    </option>
                    <option data-countryCode="BY" value="+375">
                      Belarus (+375)
                    </option>
                    <option data-countryCode="BE" value="+32">
                      Belgium (+32)
                    </option>
                    <option data-countryCode="BZ" value="+501">
                      Belize (+501)
                    </option>
                    <option data-countryCode="BJ" value="+229">
                      Benin (+229)
                    </option>
                    <option data-countryCode="BM" value="+1441">
                      Bermuda (+1441)
                    </option>
                    <option data-countryCode="BT" value="+975">
                      Bhutan (+975)
                    </option>
                    <option data-countryCode="BO" value="+591">
                      Bolivia (+591)
                    </option>
                    <option data-countryCode="BA" value="+387">
                      Bosnia Herzegovina (+387)
                    </option>
                    <option data-countryCode="BW" value="+267">
                      Botswana (+267)
                    </option>
                    <option data-countryCode="BR" value="+55">
                      Brazil (+55)
                    </option>
                    <option data-countryCode="BN" value="+673">
                      Brunei (+673)
                    </option>
                    <option data-countryCode="BG" value="+359">
                      Bulgaria (+359)
                    </option>
                    <option data-countryCode="BF" value="+226">
                      Burkina Faso (+226)
                    </option>
                    <option data-countryCode="BI" value="+257">
                      Burundi (+257)
                    </option>
                    <option data-countryCode="KH" value="+855">
                      Cambodia (+855)
                    </option>
                    <option data-countryCode="CM" value="+237">
                      Cameroon (+237)
                    </option>
                    <option data-countryCode="CA" value="+1">
                      Canada (+1)
                    </option>
                    <option data-countryCode="CV" value="+238">
                      Cape Verde Islands (+238)
                    </option>
                    <option data-countryCode="KY" value="+1345">
                      Cayman Islands (+1345)
                    </option>
                    <option data-countryCode="CF" value="+236">
                      Central African Republic (+236)
                    </option>
                    <option data-countryCode="CL" value="+56">
                      Chile (+56)
                    </option>
                    <option data-countryCode="CN" value="+86">
                      China (+86)
                    </option>
                    <option data-countryCode="CO" value="+57">
                      Colombia (+57)
                    </option>
                    <option data-countryCode="KM" value="+269">
                      Comoros (+269)
                    </option>
                    <option data-countryCode="CG" value="+242">
                      Congo (+242)
                    </option>
                    <option data-countryCode="CK" value="+682">
                      Cook Islands (+682)
                    </option>
                    <option data-countryCode="CR" value="+506">
                      Costa Rica (+506)
                    </option>
                    <option data-countryCode="HR" value="+385">
                      Croatia (+385)
                    </option>
                    <option data-countryCode="CU" value="+53">
                      Cuba (+53)
                    </option>
                    <option data-countryCode="CY" value="+90392">
                      Cyprus North (+90392)
                    </option>
                    <option data-countryCode="CY" value="+357">
                      Cyprus South (+357)
                    </option>
                    <option data-countryCode="CZ" value="+42">
                      Czech Republic (+42)
                    </option>
                    <option data-countryCode="DK" value="+45">
                      Denmark (+45)
                    </option>
                    <option data-countryCode="DJ" value="+253">
                      Djibouti (+253)
                    </option>
                    <option data-countryCode="DM" value="+1809">
                      Dominica (+1809)
                    </option>
                    <option data-countryCode="DO" value="+1809">
                      Dominican Republic (+1809)
                    </option>
                    <option data-countryCode="EC" value="+593">
                      Ecuador (+593)
                    </option>
                    <option data-countryCode="EG" value="+20">
                      Egypt (+20)
                    </option>
                    <option data-countryCode="SV" value="+503">
                      El Salvador (+503)
                    </option>
                    <option data-countryCode="GQ" value="+240">
                      Equatorial Guinea (+240)
                    </option>
                    <option data-countryCode="ER" value="+291">
                      Eritrea (+291)
                    </option>
                    <option data-countryCode="EE" value="+372">
                      Estonia (+372)
                    </option>
                    <option data-countryCode="ET" value="+251">
                      Ethiopia (+251)
                    </option>
                    <option data-countryCode="FK" value="+500">
                      Falkland Islands (+500)
                    </option>
                    <option data-countryCode="FO" value="+298">
                      Faroe Islands (+298)
                    </option>
                    <option data-countryCode="FJ" value="+679">
                      Fiji (+679)
                    </option>
                    <option data-countryCode="FI" value="+358">
                      Finland (+358)
                    </option>
                    <option data-countryCode="FR" value="+33">
                      France (+33)
                    </option>
                    <option data-countryCode="GF" value="+594">
                      French Guiana (+594)
                    </option>
                    <option data-countryCode="PF" value="+689">
                      French Polynesia (+689)
                    </option>
                    <option data-countryCode="GA" value="+241">
                      Gabon (+241)
                    </option>
                    <option data-countryCode="GM" value="+220">
                      Gambia (+220)
                    </option>
                    <option data-countryCode="GE" value="+7880">
                      Georgia (+7880)
                    </option>
                    <option data-countryCode="DE" value="+49">
                      Germany (+49)
                    </option>
                    <option data-countryCode="GH" value="+233">
                      Ghana (+233)
                    </option>
                    <option data-countryCode="GI" value="+350">
                      Gibraltar (+350)
                    </option>
                    <option data-countryCode="GR" value="+30">
                      Greece (+30)
                    </option>
                    <option data-countryCode="GL" value="+299">
                      Greenland (+299)
                    </option>
                    <option data-countryCode="GD" value="+1473">
                      Grenada (+1473)
                    </option>
                    <option data-countryCode="GP" value="+590">
                      Guadeloupe (+590)
                    </option>
                    <option data-countryCode="GU" value="+671">
                      Guam (+671)
                    </option>
                    <option data-countryCode="GT" value="+502">
                      Guatemala (+502)
                    </option>
                    <option data-countryCode="GN" value="+224">
                      Guinea (+224)
                    </option>
                    <option data-countryCode="GW" value="+245">
                      Guinea - Bissau (+245)
                    </option>
                    <option data-countryCode="GY" value="+592">
                      Guyana (+592)
                    </option>
                    <option data-countryCode="HT" value="+509">
                      Haiti (+509)
                    </option>
                    <option data-countryCode="HN" value="+504">
                      Honduras (+504)
                    </option>
                    <option data-countryCode="HK" value="+852">
                      Hong Kong (+852)
                    </option>
                    <option data-countryCode="HU" value="+36">
                      Hungary (+36)
                    </option>
                    <option data-countryCode="IS" value="+354">
                      Iceland (+354)
                    </option>
                    <option data-countryCode="IN" value="+91">
                      India (+91)
                    </option>
                    <option data-countryCode="ID" value="+62">
                      Indonesia (+62)
                    </option>
                    <option data-countryCode="IR" value="+98">
                      Iran (+98)
                    </option>
                    <option data-countryCode="IQ" value="+964">
                      Iraq (+964)
                    </option>
                    <option data-countryCode="IE" value="+353">
                      Ireland (+353)
                    </option>
                    <option data-countryCode="IL" value="+972">
                      Israel (+972)
                    </option>
                    <option data-countryCode="IT" value="+39">
                      Italy (+39)
                    </option>
                    <option data-countryCode="JM" value="+1876">
                      Jamaica (+1876)
                    </option>
                    <option data-countryCode="JP" value="+81">
                      Japan (+81)
                    </option>
                    <option data-countryCode="JO" value="+962">
                      Jordan (+962)
                    </option>
                    <option data-countryCode="KZ" value="+7">
                      Kazakhstan (+7)
                    </option>
                    <option data-countryCode="KE" value="+254">
                      Kenya (+254)
                    </option>
                    <option data-countryCode="KI" value="+686">
                      Kiribati (+686)
                    </option>
                    <option data-countryCode="KP" value="+850">
                      Korea North (+850)
                    </option>
                    <option data-countryCode="KR" value="+82">
                      Korea South (+82)
                    </option>
                    <option data-countryCode="KW" value="+965">
                      Kuwait (+965)
                    </option>
                    <option data-countryCode="KG" value="+996">
                      Kyrgyzstan (+996)
                    </option>
                    <option data-countryCode="LA" value="+856">
                      Laos (+856)
                    </option>
                    <option data-countryCode="LV" value="+371">
                      Latvia (+371)
                    </option>
                    <option data-countryCode="LB" value="+961">
                      Lebanon (+961)
                    </option>
                    <option data-countryCode="LS" value="+266">
                      Lesotho (+266)
                    </option>
                    <option data-countryCode="LR" value="+231">
                      Liberia (+231)
                    </option>
                    <option data-countryCode="LY" value="+218">
                      Libya (+218)
                    </option>
                    <option data-countryCode="LI" value="+417">
                      Liechtenstein (+417)
                    </option>
                    <option data-countryCode="LT" value="+370">
                      Lithuania (+370)
                    </option>
                    <option data-countryCode="LU" value="+352">
                      Luxembourg (+352)
                    </option>
                    <option data-countryCode="MO" value="+853">
                      Macao (+853)
                    </option>
                    <option data-countryCode="MK" value="+389">
                      Macedonia (+389)
                    </option>
                    <option data-countryCode="MG" value="+261">
                      Madagascar (+261)
                    </option>
                    <option data-countryCode="MW" value="+265">
                      Malawi (+265)
                    </option>
                    <option data-countryCode="MY" value="+60">
                      Malaysia (+60)
                    </option>
                    <option data-countryCode="MV" value="+960">
                      Maldives (+960)
                    </option>
                    <option data-countryCode="ML" value="+223">
                      Mali (+223)
                    </option>
                    <option data-countryCode="MT" value="+356">
                      Malta (+356)
                    </option>
                    <option data-countryCode="MH" value="+692">
                      Marshall Islands (+692)
                    </option>
                    <option data-countryCode="MQ" value="+596">
                      Martinique (+596)
                    </option>
                    <option data-countryCode="MR" value="+222">
                      Mauritania (+222)
                    </option>
                    <option data-countryCode="YT" value="+269">
                      Mayotte (+269)
                    </option>
                    <option data-countryCode="MX" value="+52">
                      Mexico (+52)
                    </option>
                    <option data-countryCode="FM" value="+691">
                      Micronesia (+691)
                    </option>
                    <option data-countryCode="MD" value="+373">
                      Moldova (+373)
                    </option>
                    <option data-countryCode="MC" value="+377">
                      Monaco (+377)
                    </option>
                    <option data-countryCode="MN" value="+976">
                      Mongolia (+976)
                    </option>
                    <option data-countryCode="MS" value="+1664">
                      Montserrat (+1664)
                    </option>
                    <option data-countryCode="MA" value="+212">
                      Morocco (+212)
                    </option>
                    <option data-countryCode="MZ" value="+258">
                      Mozambique (+258)
                    </option>
                    <option data-countryCode="MN" value="+95">
                      Myanmar (+95)
                    </option>
                    <option data-countryCode="NA" value="+264">
                      Namibia (+264)
                    </option>
                    <option data-countryCode="NR" value="+674">
                      Nauru (+674)
                    </option>
                    <option data-countryCode="NP" value="+977">
                      Nepal (+977)
                    </option>
                    <option data-countryCode="NL" value="+31">
                      Netherlands (+31)
                    </option>
                    <option data-countryCode="NC" value="+687">
                      New Caledonia (+687)
                    </option>
                    <option data-countryCode="NZ" value="+64">
                      New Zealand (+64)
                    </option>
                    <option data-countryCode="NI" value="+505">
                      Nicaragua (+505)
                    </option>
                    <option data-countryCode="NE" value="+227">
                      Niger (+227)
                    </option>
                    <option data-countryCode="NG" value="+234">
                      Nigeria (+234)
                    </option>
                    <option data-countryCode="NU" value="+683">
                      Niue (+683)
                    </option>
                    <option data-countryCode="NF" value="+672">
                      Norfolk Islands (+672)
                    </option>
                    <option data-countryCode="NP" value="+670">
                      Northern Marianas (+670)
                    </option>
                    <option data-countryCode="NO" value="+47">
                      Norway (+47)
                    </option>
                    <option data-countryCode="OM" value="+968">
                      Oman (+968)
                    </option>
                    <option data-countryCode="PW" value="+680">
                      Palau (+680)
                    </option>
                    <option data-countryCode="PA" value="+507">
                      Panama (+507)
                    </option>
                    <option data-countryCode="PG" value="+675">
                      Papua New Guinea (+675)
                    </option>
                    <option data-countryCode="PY" value="+595">
                      Paraguay (+595)
                    </option>
                    <option data-countryCode="PE" value="+51">
                      Peru (+51)
                    </option>
                    <option data-countryCode="PH" value="+63">
                      Philippines (+63)
                    </option>
                    <option data-countryCode="PL" value="+48">
                      Poland (+48)
                    </option>
                    <option data-countryCode="PT" value="+351">
                      Portugal (+351)
                    </option>
                    <option data-countryCode="PR" value="+1787">
                      Puerto Rico (+1787)
                    </option>
                    <option data-countryCode="QA" value="+974">
                      Qatar (+974)
                    </option>
                    <option data-countryCode="RE" value="+262">
                      Reunion (+262)
                    </option>
                    <option data-countryCode="RO" value="+40">
                      Romania (+40)
                    </option>
                    <option data-countryCode="RU" value="+7">
                      Russia (+7)
                    </option>
                    <option data-countryCode="RW" value="+250">
                      Rwanda (+250)
                    </option>
                    <option data-countryCode="SM" value="+378">
                      San Marino (+378)
                    </option>
                    <option data-countryCode="ST" value="+239">
                      Sao Tome &amp; Principe (+239)
                    </option>
                    <option data-countryCode="SA" value="+966">
                      Saudi Arabia (+966)
                    </option>
                    <option data-countryCode="SN" value="+221">
                      Senegal (+221)
                    </option>
                    <option data-countryCode="CS" value="+381">
                      Serbia (+381)
                    </option>
                    <option data-countryCode="SC" value="+248">
                      Seychelles (+248)
                    </option>
                    <option data-countryCode="SL" value="+232">
                      Sierra Leone (+232)
                    </option>
                    <option data-countryCode="SG" value="+65">
                      Singapore (+65)
                    </option>
                    <option data-countryCode="SK" value="+421">
                      Slovak Republic (+421)
                    </option>
                    <option data-countryCode="SI" value="+386">
                      Slovenia (+386)
                    </option>
                    <option data-countryCode="SB" value="+677">
                      Solomon Islands (+677)
                    </option>
                    <option data-countryCode="SO" value="+252">
                      Somalia (+252)
                    </option>
                    <option data-countryCode="ZA" value="+27">
                      South Africa (+27)
                    </option>
                    <option data-countryCode="ES" value="+34">
                      Spain (+34)
                    </option>
                    <option data-countryCode="LK" value="+94">
                      Sri Lanka (+94)
                    </option>
                    <option data-countryCode="SH" value="+290">
                      St. Helena (+290)
                    </option>
                    <option data-countryCode="KN" value="+1869">
                      St. Kitts (+1869)
                    </option>
                    <option data-countryCode="SC" value="+1758">
                      St. Lucia (+1758)
                    </option>
                    <option data-countryCode="SD" value="+249">
                      Sudan (+249)
                    </option>
                    <option data-countryCode="SR" value="+597">
                      Suriname (+597)
                    </option>
                    <option data-countryCode="SZ" value="+268">
                      Swaziland (+268)
                    </option>
                    <option data-countryCode="SE" value="+46">
                      Sweden (+46)
                    </option>
                    <option data-countryCode="CH" value="+41">
                      Switzerland (+41)
                    </option>
                    <option data-countryCode="SI" value="+963">
                      Syria (+963)
                    </option>
                    <option data-countryCode="TW" value="+886">
                      Taiwan (+886)
                    </option>
                    <option data-countryCode="TJ" value="+7">
                      Tajikstan (+7)
                    </option>
                    <option data-countryCode="TH" value="+66">
                      Thailand (+66)
                    </option>
                    <option data-countryCode="TG" value="+228">
                      Togo (+228)
                    </option>
                    <option data-countryCode="TO" value="+676">
                      Tonga (+676)
                    </option>
                    <option data-countryCode="TT" value="+1868">
                      Trinidad &amp; Tobago (+1868)
                    </option>
                    <option data-countryCode="TN" value="+216">
                      Tunisia (+216)
                    </option>
                    <option data-countryCode="TR" value="+90">
                      Turkey (+90)
                    </option>
                    <option data-countryCode="TM" value="+7">
                      Turkmenistan (+7)
                    </option>
                    <option data-countryCode="TM" value="+993">
                      Turkmenistan (+993)
                    </option>
                    <option data-countryCode="TC" value="+1649">
                      Turks &amp; Caicos Islands (+1649)
                    </option>
                    <option data-countryCode="TV" value="+688">
                      Tuvalu (+688)
                    </option>
                    <option data-countryCode="UG" value="+256">
                      Uganda (+256)
                    </option>
                    <option data-countryCode="UA" value="+380">
                      Ukraine (+380)
                    </option>
                    <option data-countryCode="AE" value="+971" selected>
                      United Arab Emirates (+971)
                    </option>
                    <option data-countryCode="UY" value="+598">
                      Uruguay (+598)
                    </option>
                    <option data-countryCode="US" value="+1">
                      USA (+1)
                    </option>
                    <option data-countryCode="UZ" value="+7">
                      Uzbekistan (+7)
                    </option>
                    <option data-countryCode="VU" value="+678">
                      Vanuatu (+678)
                    </option>
                    <option data-countryCode="VA" value="+379">
                      Vatican City (+379)
                    </option>
                    <option data-countryCode="VE" value="+58">
                      Venezuela (+58)
                    </option>
                    <option data-countryCode="VN" value="+84">
                      Vietnam (+84)
                    </option>
                    <option data-countryCode="VG" value="+84">
                      Virgin Islands - British (+1284)
                    </option>
                    <option data-countryCode="VI" value="+84">
                      Virgin Islands - US (+1340)
                    </option>
                    <option data-countryCode="WF" value="+681">
                      Wallis &amp; Futuna (+681)
                    </option>
                    <option data-countryCode="YE" value="+969">
                      Yemen (North)(+969)
                    </option>
                    <option data-countryCode="YE" value="+967">
                      Yemen (South)(+967)
                    </option>
                    <option data-countryCode="ZM" value="+260">
                      Zambia (+260)
                    </option>
                    <option data-countryCode="ZW" value="+263">
                      Zimbabwe (+263)
                    </option>
                  </select>
                  {/* <input
                              className={`floating-input code-input`}
                              type="text"
                              maxLength="6"
                              inputMode="numeric"
                              pattern="[0-9]*"
                              placeholder=" "
                              value={countryCode}
                              onChange={e => setCountryCode(e.target.value)}
                           /> */}
                  <input
                    className={`floating-input mobile-input ${error.phone_number
                      ? "error"
                      : ""}`}
                    type="number"
                    onInput={e =>
                      (e.target.value = e.target.value.slice(0, 15))}
                    inputMode="numeric"
                    placeholder="xx xxxxxxx"
                    value={data.phone_number}
                    onChange={e => onFormChange("phone_number", e)}
                  />
                  <span className="highlight" />
                  <label>
                    {props.Environment.phone_number}
                  </label>
                </div>
                {/* <div className="floating-label">

                  <input
                    className={`floating-input floating-form`}
                    type="text"
                    maxlength="30"
                    placeholder=""
                    value={data.email}
                    onChange={e => onFormChange('email', e)}
                    error={error.email}
                  />
                  <span className="highlight" />
                  <label>{props.Environment.email}</label>
                </div> */}

                <div className="floating-label">
                  <input
                    className={`floating-input floating-form ${error.email
                      ? "error"
                      : ""}`}
                    type="text"
                    placeholder=""
                    value={data.email}
                    onChange={e => onFormChange("email", e)}
                    error={error.email}
                  />
                  <span className="highlight" />
                  <label>
                    {props.Environment.email}
                  </label>
                </div>
                <div className="password-field">
                  <div className="floating-label">
                    <input
                      className={`floating-input floating-form ${error.pass
                        ? "error"
                        : ""}`}
                      type="password"
                      id="passrd"
                      placeholder=""
                      value={data.pass}
                      onChange={e => onFormChange("pass", e)}
                      error={error.pass}
                    />
                    <span className="highlight" />
                    <label>
                      {props.Environment.password}
                    </label>
                  </div>
                  <span className="spanpd">
                    {props.Environment.spanpasstry}{" "}
                  </span>
                  <div className="bocinsing">
                    <div className="form-checkbx">
                      <input
                        type="checkbox"
                        id="address"
                        name="box"
                        checked={uppercase}
                      />
                      <label htmlFor="address">
                        {props.Environment.passagreetxt1}
                      </label>
                    </div>
                    <div className="form-checkbx">
                      <input
                        type="checkbox"
                        id="address"
                        name="box"
                        checked={lowercase}
                      />
                      <label htmlFor="address">
                        {props.Environment.passagreetxt2}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="floating-label" style={{ display: "none" }}>
                  <select
                    className="floating-select"
                    value={data.nationality}
                    onChange={e => onFormChange("nationality", e)}
                  >
                    <option value="">--Select--</option>
                    {Nationality.map(cur =>
                      <option value={cur.value}>
                        {cur.label}
                      </option>
                    )}
                  </select>
                  <span className="highlight" />
                  <label>
                    {props.Environment.nationality}
                  </label>
                </div>
                <div className="floating-label">
                  <div className="gender">
                    <span>
                      {props.Environment.gender +
                        " " +
                        props.Environment.optional}
                    </span>
                    <div className="radio-btns" ref={ref1}>
                      <input
                        type="radio"
                        id="male"
                        name="gender"
                        value="male"
                      />
                      <label
                        id="f2"
                        className="radiobtn"
                        htmlFor="male"
                        onClick={() => onFormChange("gender", "1", true)}
                      >
                        {props.Environment.male}
                      </label>
                      <input
                        type="radio"
                        id="female"
                        name="gender"
                        value="female"
                      />
                      <label
                        className="radiobtn"
                        id="f1"
                        htmlFor="female"
                        onClick={() => onFormChange("gender", "2", true)}
                      >
                        {props.Environment.female}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-checkbx">
              <input
                type="checkbox"
                id="terms"
                name="box"
                checked={ischecked}
                onClick={() => {
                  setchecked(!ischecked);
                }}
              />
              <label className="addresnd" htmlFor="terms">
                {props.Environment.agreetxt} &nbsp;
                {/* <br /> */}
                <span className="tncpass">
                  <p
                    onClick={() => {
                      setTermsPopup(true);
                    }}
                  >
                    {props.Environment.agreetxt1}
                  </p>
                </span>
              </label>
              <span className="checrrr">
                {chekcs}
              </span>
            </div>
            <div ref={ref2}>
              <Button
                className="dsd button-red"
                data={props.Environment.next}
                onClick={OnSubmit}
              />
            </div>
          </div>
        </Tween>
      </div>
    </div>
  );
};

export default Signup;
