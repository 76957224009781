import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
// import { Environment } from "../../env";
import { Environment as en } from '../../env';
import { Environment as ar } from '../../env_ar';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Button from '../../components/Button/Button';
import {
   currencyconvertrate,
   calcDaysFromCurrentDate,
   showTimeBasedCampaigns,
   prefixZeroIfSingleDigit,
} from '../../utils/Function';
import { TweenMax } from 'gsap';
import { useStore, useDispatch, useSelector } from 'react-redux';
import { AddToCart, DeleteCartItem, UpdateCartItem } from '../../redux/actions/cart/actions';
import {
   CreateQuote,
   GuestAddToCart,
   GuestDeleteCartItem,
   GuestUpdateCartItem,
} from '../../redux/actions/guestcart/actions';
import { AddFavourite, RemoveFavourite } from '../../redux/actions/favourites/actions';
import { Swipeable } from 'react-swipeable';
import CustomProgressbar from '../../components/PrizeList/CustomProgressbar';
import { useLockBodyScroll } from '../../utils/Function';
import PopUps from '../../components/PopUps/PopUps';
import ReactPixel from 'react-facebook-pixel';

import { get } from 'lodash';

const ProductPropsCheck = props => {
   if (!props.location.props) {
      props.history.replace('/');
      return null;
   }

   return <ProductDetail {...props} />;
};

const ProductDetail = props => {
   // useLockBodyScroll();
   const sheet = useRef(null);
   const [active1, setActive1] = useState(false);
   const [show, setShow] = useState(false);
   const token = useSelector(store => store.auth.token);
   const store = useSelector(store => store);
   const dispatch = useDispatch();
   const campaign_manager = useSelector(store => store.home.campaign_manager);
   const campaign = campaign_manager.find(camp => camp.campaign_product_id == props.location.props.product.id);
   const [type, setType] = useState(1);
   const [tabS, setTabS] = useState(props.location.query ? (props.location.query.type === 'product' ? 2 : 1) : 1);
   const cartItem = useSelector(store => store.cart.cart).find(item => item.sku === props.location.props.product.sku);
   const [count, setCount] = useState(cartItem ? cartItem.qty : 1);
   const [data, setData] = useState(null);

   const favourites = useSelector(store => store.favourite.favourites);
   const sales = props.location.props.product.custom_attributes.find(x => x.attribute_code === 'total_sales').value;
   const stock = props.location.props.product.custom_attributes.find(x => x.attribute_code === 'total_stock').value;
   const cost = props.location.props.product.custom_attributes.find(x => x.attribute_code === 'price');
   const prizePercentage = (sales / stock) * 100;
   const mainSlider = {
      dots: true,
      infinite: true,
      //initialSlide: 0,
      speed: 100,
      cssEase: 'linear',
      slidesToShow: 1,
      fade: true,
      draggable: true,
      arrows: true,
      autoplay: false,
      centerMode: true,
      slidesToScroll: 1,
      //variableWidth: true,
   };
   const prod = props.location.props.product;
   const [isfav, setfav] = useState(null);
   const [active, setactive] = useState(true);

   // const store = useSelector(store => store);
   const [file, setfile] = useState(en);

   const [showpopup, setshowpopup] = useState(false);
   const [configs, setconfigs] = useState([]);
   const [parent, setparent] = useState(null);
   const [price, setprice] = useState(null);

   const [slider, setSlider] = useState([]);

   useEffect(() => {
      document.body.style.overflow = 'hidden';
      //TweenMax.fromTo(sheet.current, 0.5, { y: 1000 }, { y: 0 });
      setTimeout(() => {
         setActive1(true);
      }, 600);
      setData(props.location.props);
      setSlider(getSliderImages(tabS, props.location.props.product));
      return () => (document.body.style.overflow = 'visible');
   }, []);

   useEffect(() => {
      if (get(store, 'home.language', 'en') == 'en') {
         setfile(en);
      } else {
         setfile(ar);
      }
   }, [get(store, 'home.language', 'en')]);

   useEffect(() => {
      var fav = favourites.find(item => {
         return item.product_id == props.location.props.product.id;
      });
      if (fav != null) {
         setfav(fav);
      } else {
         setfav(null);
      }
   }, [favourites]);

   useEffect(() => {
      setType(cartItem ? 2 : 1);
   }, [store.cart.cart]);

   useEffect(() => {
      if (props.location.props.product.type_id == 'configurable') {
         var cartItems = store.cart.cart.filter(item => item.sku.includes(props.location.props.product.sku));
         var qty = 0;
         cartItems.forEach(element => {
            qty += element.qty;
         });

         var child_prod = store.home.allproducts.find(
            item => item.id == props.location.props.product.extension_attributes.configurable_product_links[0]
         );
         if (child_prod != undefined) {
            setprice(child_prod.price);
         }

         setType(cartItems.length !== 0 ? 2 : 1);
         setCount(qty !== 0 ? qty : 1);
      } else {
         setType(cartItem ? 2 : 1);
         setCount(cartItem ? cartItem.qty : 1);
      }
   }, [store.cart.cart, props.item]);

   const getSliderImages = (tab, data) => {
      const type = tab === 1 ? props.location.props.prize : props.location.props.product;
      let images = [];
      type.media_gallery_entries.forEach((img, index) => {
         // if (index == 1) {
         //    return
         // }
         images.push(file.productUrl + img.file);
      });
      return images; /* [
         file.productUrl + type.custom_attributes.find(x => x.attribute_code === "image").value,
         file.productUrl + type.custom_attributes.find(x => x.attribute_code === "image").value,
         file.productUrl + type.custom_attributes.find(x => x.attribute_code === "image").value
      ] */
   };

   const ShouldOpenPopup = () => {
      setconfigs(props.location.props.product.extension_attributes.configurable_product_links);
      setparent(props.location.props.product);
      setshowpopup(true);
   };

   const addToCart = async () => {
      if (!active) {
         return;
      }

      if (props.location.props.product.type_id == 'configurable') {
         ShouldOpenPopup();
      } else {
         setType(3);
         ReactPixel.track('AddToCart');
      }

      setactive(false);
      if (store.auth.token !== null) {
         await dispatch(AddToCart(store.auth.cartid, props.location.props.product.sku));
      } else {
         if (store.auth.cartid === null) {
            const quote = await dispatch(CreateQuote());
            await dispatch(GuestAddToCart(quote, props.location.props.product.sku));
         } else {
            await dispatch(GuestAddToCart(store.auth.cartid, props.location.props.product.sku));
         }
      }
      setType(2);
      setactive(true);
   };

   const onPlus = async () => {
      if (!active) {
         return;
      }
      if (props.location.props.product.type_id == 'configurable') {
         ShouldOpenPopup();
         return;
      }
      //setactive(false)
      if (store.auth.token !== null) {
         await dispatch(UpdateCartItem(store.auth.cartid, cartItem.sku, count + 1, cartItem.item_id));
      } else {
         await dispatch(GuestUpdateCartItem(store.auth.cartid, cartItem.sku, count + 1, cartItem.item_id));
      }
      setCount(cartItem.qty);
      setactive(true);
   };

   const onMinus = async () => {
      if (!active) {
         return;
      }
      if (props.location.props.product.type_id == 'configurable') {
         ShouldOpenPopup();
         return;
      }
      //setactive(false)
      if (count === 1) {
         //alert for delete item
         if (store.auth.token !== null) {
            await dispatch(DeleteCartItem(cartItem.item_id));
         } else {
            await dispatch(GuestDeleteCartItem(cartItem.item_id, store.auth.cartid));
         }
         setType(1);
      } else {
         //update it with less number
         if (store.auth.token !== null) {
            await dispatch(UpdateCartItem(store.auth.cartid, cartItem.sku, count - 1, cartItem.item_id));
         } else {
            await dispatch(GuestUpdateCartItem(store.auth.cartid, cartItem.sku, count - 1, cartItem.item_id));
         }
      }
      setCount(cartItem.qty);
      setactive(true);
   };

   const onTabClick = tab => {
      setTabS(tab);
      setSlider(getSliderImages(tab, data));
   };

   const handleGoBack = () => {
      setActive1(false);
      //TweenMax.fromTo(sheet.current, 0.5, { y: 0 }, { y: 1000 });
      setTimeout(() => {
         props.history.push('/');
      }, 200);
   };

   const handleGoBack1 = () => {
      // if(show == true){
      //   setShow(false)
      // }
      // else{
      //   setShow(true);
      // }
      setActive1(false);
      //TweenMax.fromTo(sheet.current, 1, { y: 0 }, { y: 2000 });
      setTimeout(() => {
         props.history.push('/');
      }, 200);
   };

   const OnHearted = async () => {
      if (token === null) {
         //new additions
         if (isfav !== null) {
            dispatch(RemoveFavourite(null, null, props.location.props.product.id));
            return;
         } else {
            prod.product_id = prod.id;
            await dispatch(AddFavourite(null, prod.id, null));
            return;
         }
      } else if (isfav) {
         dispatch(RemoveFavourite(store.auth.token, isfav.wishlist_item_id, props.location.props.product.id));
      } else {
         prod.product_id = prod.id;
         dispatch(AddFavourite(store.auth.token, prod.id, prod));
      }
   };

   const OnShared = () => {
      let link = props.location.props.product.custom_attributes.find(attr => attr.attribute_code == 'share_link').value;
      window.ReactNativeWebView.postMessage(link);
      //history.push("/share")
   };
   const getPrizePercentage = () => {
      let prizePercentage = 0;
      if (showTimeBasedCampaigns(campaign?.campaign_type)) {
         let finished =
            calcDaysFromCurrentDate(campaign?.campaign_end_time, campaign?.activation_date) -
            calcDaysFromCurrentDate(campaign?.campaign_end_time);
         let total = calcDaysFromCurrentDate(campaign?.campaign_end_time, campaign?.activation_date);
         prizePercentage = (finished / total) * 100;
      } else {
         prizePercentage = (sales / stock) * 100;
      }

      return prizePercentage;
   };

   // useLockBodyScroll();

   return (
      // onSwipedDown = { handleGoBack }
      <Swipeable>
         <div
            ref={sheet}
            className="product-container product-containerss"
            style={{ backgroundColor: active ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0)' }}
         >
            {showpopup ? (
               <PopUps
                  type="size-selection"
                  parent={parent}
                  details={configs}
                  onClose={() => setshowpopup(false)}
                  onSubmit={() => setshowpopup(false)}
                  {...props}
               />
            ) : null}
            {/* <img
               src={`${file.img_path}makeicons/down-arrow.svg`}
               alt=""
               className="pullback-arrow slider1 close1"
               onClick={handleGoBack1}
            /> */}
            <div className="product-pullback">
               <img src={`${file.img_path}makeicons/ic_close.svg`} className="close-cross" onClick={handleGoBack1} />
               <div className="semi-circle-progressbar">
                  <div className="dialdots-line">
                     <img src={`${file.img_path}makeicons/dots.svg`} alt="" />
                  </div>
                  <div className="progress-percentage">
                     {showTimeBasedCampaigns(campaign?.campaign_type) ? (
                        <>
                           <p style={{ width: '100%' }} className="total-value">
                              Ends In
                           </p>
                           <p style={{ fontSize: '16px' }} className="total-value">
                              {prefixZeroIfSingleDigit(calcDaysFromCurrentDate(campaign?.campaign_end_time))}
                           </p>
                           <p className="total-value">Days</p>
                        </>
                     ) : (
                        <>
                           {' '}
                           <p className="current-value">{sales}</p>
                           <p className="sold-out">
                              {file.SOLDTETX} <br /> {file.OUTSNT}
                           </p>
                           <p className="total-value">{stock}</p>
                        </>
                     )}
                  </div>
                  <div>
                     <CustomProgressbar progress={getPrizePercentage()} />
                  </div>
               </div>
               <div className="listing-prize-icons">
                  {/* <span onClick={OnShared}>
                     <img src={`${file.img_path}makeicons/ic-share.svg`} alt="" />
                  </span> */}
                  <span onClick={OnHearted}>
                     <img
                        src={`${file.img_path}makeicons/${isfav !== null ? 'ic-fav-active.svg' : 'ic-fav.svg'}`}
                        alt=""
                     />
                  </span>
               </div>
               <div className="product-main-slider">
                  <Slider {...mainSlider}>
                     {slider.map((value, index) => {
                        return (
                           <div key={index}>
                              <div className="slider-slides">
                                 <img src={value} alt="" className="slider-img" />
                              </div>
                           </div>
                        );
                     })}
                  </Slider>
                  <div className="">
                     <ul id="nav-tab" className="tabs-main">
                        <li className={tabS === 1 ? 'active' : ''} onClick={() => onTabClick(1)}>
                           Prize description
                        </li>
                        <li className={tabS === 2 ? 'active' : ''} onClick={() => onTabClick(2)}>
                            Product description
                        </li>
                     </ul>
                     <div className="tab-content">
                        <div className={`tab-pane ${tabS === 1 ? 'active' : ''}`} id="tab1">
                           <h3>Overview</h3>
                           <div
                              className="detail-overview"
                              dangerouslySetInnerHTML={{
                                 __html: props.location.props.prize.custom_attributes.find(
                                    x => x.attribute_code === 'description'
                                 ).value,
                              }}
                           />
                        </div>
                        <div className={`tab-pane ${tabS === 2 ? 'active' : ''}`} id="tab2">
                           <h3>Overview</h3>
                           <div
                              className="detail-overview-new"
                              dangerouslySetInnerHTML={{
                                 __html: props.location.props.product.custom_attributes.find(
                                    x => x.attribute_code === 'description'
                                 ).value,
                              }}
                           />
                        </div>
                        {/* <div className="howtowiner">
                           Want to know how it works?
                          <Link to="/">Find out here.</Link>
                        </div> */}
                     </div>
                  </div>
               </div>
               <div className="buy-button-panel">
                  <div className="buy-product-name">
                     <div className="product-title">
                        <span>
                           <p
                              dangerouslySetInnerHTML={{
                                 __html: props.location.props.product.custom_attributes.find(
                                    x => x.attribute_code === 'short_description'
                                 ).value,
                              }}
                           />
                           {/*  {
                             props.location.props.product.custom_attributes.find(
                                x => x.attribute_code === 'short_description'
                             ).value
                          } */}
                        </span>
                        <span className="product-amount">
                           {' '}
                           {store.account.currency}{' '}
                           {price != null
                              ? currencyconvertrate(price, store)
                              : currencyconvertrate(props.location.props.product.price, store)}
                        </span>
                     </div>
                  </div>
                  {type === 1 || type == 3 ? (
                     <div>
                        <Button
                           className={`button-red site-e ${type == 3 ? 'green' : ''}`}
                           onClick={addToCart}
                           data={type == 1 ? 'Add to Cart' : 'Added'}
                        />
                        <Button
                           className={`button-red site-a ${type == 3 ? 'green' : ''}`}
                           onClick={addToCart}
                           data={type == 1 ? 'أضف إلى العربة' : 'تمّت الإضافة'}
                        />
                     </div>
                  ) : (
                     <div className="home-plus-minus">
                        <Button className="button-red minus" onClick={onMinus}>
                           -
                        </Button>
                        <p>
                           {props.location.props.product.type_id == 'configurable' ? (
                              <p>{count !== 0 ? count : 1}</p>
                           ) : (
                              <p>{cartItem ? cartItem.qty : 1}</p>
                           )}
                        </p>
                        <Button className="button-red plus" onClick={onPlus}>
                           +
                        </Button>
                     </div>
                  )}
               </div>
            </div>
         </div>
      </Swipeable>
   );
};

export default ProductPropsCheck;
