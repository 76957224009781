export const CART_FOR_GTAG = 'CART_FOR_GTAG';
export const ADDTOCART = 'ADDTOCART';
export const REMOVEFROMCART = 'REMOVEFROMCART';
export const SETCARTID = 'SETCARTID';
export const VIEWCART = 'VIEWCART';
export const VIEWMAMBOPROFILE = 'VIEWMAMBOPROFILE';
export const UPDATECART = 'UPDATECART';
export const UPDATECARTITEMSELECTION = 'UPDATECARTITEMSELECTION';
export const DELETEITEM = 'DELETEITEM';
export const UPDATEKOINZMODE = 'UPDATEKOINZMODE';
export const ADDCONSTANTS = 'ADDCONSTANTS';
export const SETLOYALTYDATA = 'SETLOYALTYDATA';
export const ADDKOINZDETAILS = 'ADDKOINZDETAILS';
export const SETUSERMISSIONS = 'SETUSERMISSIONS';
export const SETBADGEDATA = 'SETBADGEDATA';
export const SETCLUSTEREDDATA = 'SETCLUSTEREDDATA';
export const SETPREVIOUSORDERS = 'SETPREVIOUSORDERS';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const REPLACEPRODUCT = 'REPLACEPRODUCT';
export const EMPTYCART = 'EMPTYCART';
export const SETPROMOCODE = 'SETPROMOCODE';

//checkout actions

export const SETADDRESS = 'SETADDRESS';
export const SHIPPINGINFO = 'SHIPPINGINFO';
export const ORDERID = 'ORDERID';
export const CREATEPOINTS = 'CREATEPOINTS';
export const ADDKOINZPROD = 'ADDKOINZPROD';
export const REMOVEKOINZPROD = 'REMOVEKOINZPROD';
export const SETUSEKOINZ = 'SETUSEKOINZ';
export const EMPTYKOINZPROD = 'EMPTYKOINZPROD';

//payment gateway actions

export const SETCUSTPROFILE = 'SETCUSTPROFILE';
export const SETSAVEDCARDS = 'SETSAVEDCARDS';
export const SETORDERAMOUNT = 'SETORDERAMOUNT';
