import { useLayoutEffect } from 'react';

/**
 * Removes the / from the URL or from the given data
 *
 * @param {*} data
 */
export const replaceUrl = data => data.replace('/', '');

export const generateToken = () => {
   const a = Date.now();
   const b = 'SecretKey';
   const c = Math.random();
   const z = a + b + c;

   return z;
};

export const currencyconvertrate = (price, store) => {
   let rate = 1;
   if (store.account.currency == 'USD') {
      // alert("ds")
      rate = store.home.currency.find(item => item.currency_to == 'USD');
      rate = rate.rate;
   }

   return Math.round(price * rate);
};

/**
 *
 * Generic Function to resolve reducer
 *
 * @param {string} state
 * @param {string} action
 */
export const resolve_redux_state = (state = null, action = null) => {
   if (action && state) {
      return {
         ...state,
         ...action,
      };
   }

   return {
      ...state,
   };
};

/**
 * Replacing String With These Characters
 *
 * @param {*} str
 *
 */
export const decodeString = str => {
   return str
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&#x27;/g, "'")
      .replace(/&#x2F;/g, '/');
};

/**
 * Prevent Background Scroll when a modal is open
 *
 */

export const preventScroll = () => {
   // When the modal is shown, we want a fixed body
   document.body.style.position = 'fixed';
   document.body.style.top = `-${window.scrollY}px`;
};

/**
 * Enable Backgroundscroll after closing of the Modal
 *
 */

export const enableScroll = () => {
   // When the modal is hidden...
   const scrollY = document.body.style.top;
   document.body.style.position = '';
   document.body.style.top = '';
   window.scrollTo(0, parseInt(scrollY || '0') * -1);
};

/**
 * Display Date with / Example : 0/10/2020
 *
 * @param {*} date
 */
export const dateToDispFormat = date => {
   let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

   if (month.length < 2) month = '0' + month;
   if (day.length < 2) day = '0' + day;

   return [day, month, year].join('/');
};

export const DateFormatter = date => {
   let split = date.split(' ');
   let dates = split[0].split('-');
   return dates[2] + '/' + dates[1] + '/' + dates[0];
};

// Smooth Scrolling into a section on Click
export const scrollSection = element => () => {
   document.querySelector(`#${element}`).scrollIntoView({
      behavior: 'smooth',
   });
};

// Format Json String with Color
export const syntaxHighlight = json => {
   json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
   return json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
      function (match) {
         var cls = 'number';
         if (/^"/.test(match)) {
            if (/:$/.test(match)) {
               match = match.replace(/[":']/g, '');
               cls = 'key';
            } else {
               match = match.replace(/["]/g, "'");
               cls = 'string';
            }
         } else if (/true|false/.test(match)) {
            cls = 'boolean';
         } else if (/null/.test(match)) {
            cls = 'null';
         }
         return '<span class="' + cls + '">' + match + '</span>';
      }
   );
};
// Outputs the Json highlter on to the DOM
export const output = (element, inp) => {
   const pre = document.querySelector(element);
   if (pre !== null) {
      pre.appendChild(document.createElement('pre')).innerHTML = inp;
   }
};

// Copy to Clopboard
export const copyToClipboard = element => {
   var range = document.createRange();
   range.selectNode(document.getElementById(element));
   window.getSelection().removeAllRanges(); // clear current selection
   window.getSelection().addRange(range); // to select text
   document.execCommand('copy');
   window.getSelection().removeAllRanges(); // to deselect
};

// Same Height Div
export const sameHeightDiv = tag => {
   const allParagraph = document.querySelectorAll(tag);
   let maxHeight = 0;
   allParagraph.forEach(element => {
      if (element.clientHeight > maxHeight) {
         maxHeight = element.clientHeight;
      }
   });

   allParagraph.forEach(element => {
      element.style.height = maxHeight + 'px';
   });
};

// Disable Background Scroll on Popup
export const useLockBodyScroll = () => {
   useLayoutEffect(() => {
      // Get original body overflow
      const originalStyle = window.getComputedStyle(document.body).overflow;
      // Prevent scrolling on mount
      document.body.style.overflow = 'hidden'; // temp disable sanjay
      // Re-enable scrolling when component unmounts
      return () => (document.body.style.overflow = originalStyle);
   }, []);
};
export const unuseLockBodyScroll = () => {
   // alert("cllled 2")
   // console.log("scroll un locl hogaya")
   // alert("ds")
   // Prevent scrolling on mount
   document.body.style.overflow = 'visible';
};

export const comify = nStr => {
   nStr += '';
   var x = nStr.split('.');
   var x1 = x[0];
   var x2 = x.length > 1 ? '.' + x[1] : '';
   var rgx = /(\d+)(\d{3})/;
   while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
   }
   return x1 + x2;
};

// check if NaN , then resolve to zero
export const convertNaNToZero = value => {
   if (isNaN(value)) return 0;
   else return value;
};

export const showTimeBasedCampaigns = campType => {
   let showTimeBasedCamp = false;
   if (campType === '1') {
      showTimeBasedCamp = true;
   }
   return showTimeBasedCamp;
};

export const calcDaysFromCurrentDate = (till, from = new Date()) => {
   // let fromDate = new Date(from?.replace(' ', 'T'));
   let tillDate;
   let fromDate;
   // let fromDate = from?.includes(' ') ? new Date(from?.replace(' ', 'T')) : new Date(from);

   if (typeof from === 'object') {
      fromDate = from;
   } else {
      fromDate = from?.includes(' ') ? new Date(from?.replace(' ', 'T')) : new Date(from);
   }

   if (typeof till === 'object') {
      tillDate = till;
   } else {
      tillDate = till?.includes(' ') ? new Date(till?.replace(' ', 'T')) : new Date(till);
   }

   // Find the distance between now and the count down date
   const distance = Number(tillDate.getTime()) - Number(fromDate.getTime());
   // Time calculations for days
   let days = Math.floor(distance / (1000 * 60 * 60 * 24));
   if (days < 0) days = 0;
   return days;
};

export const prefixZeroIfSingleDigit = input => {
   return input?.toString().length === 1 ? '0' + input : input;
};

export const isOrderSuccessfull = orderStatus => {
   let result = false;
   if (
      orderStatus === 'ngenius_complete' ||
      orderStatus === 'complete' ||
      orderStatus === 'couponpay' ||
      orderStatus === 'paid'
   ) {
      result = true;
   }
   return result;
};

export const getParentSku = sku => {
   if (sku) {
      const tempArr = sku?.split('-');
      return tempArr[0];
      // if (tempArr.length === 2 || tempArr.length === 1) {
      //    return tempArr[0];
      // } else {
      //    tempArr.splice(tempArr.length - 1, 1);
      //    return tempArr.join('-');
      // }
   }
   return false;
};
